import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import MainApp from "./containers/MainApp";
import reportWebVitals from "./reportWebVitals";
import "./i18nextConf";

import "./styles/Global.scss";
// Load the navigation stack
ReactDOM.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <MainApp />
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

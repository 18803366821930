import React, { useState } from "react";
import AssetsImg from "../../../../assets/images/index";
import { Image, Overlay, Popover, PopoverContent } from "react-bootstrap";
import "./Styles.scss";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { deleteStaffMember } from "../../../../core/Api/staff";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import menuIcon from "../../../../assets/images/three-dots.svg";

const MemberEditCard = (props) => {
  const { t } = useTranslation();
  const { member, updateMembers } = props;
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [target, setTarget] = useState(null);
  const handleDeleteStaffMember = async () => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this member? This action cannot be undone."
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleteLoader(true);
        const result = await deleteStaffMember(member.supplier_staff_id);
        if (result && result.code < 400) {
          Swal.fire("Deleted Successfully", "", "success");
          updateMembers(member.supplier_staff_id);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
        setDeleteLoader(false);
      }
    });
  };

  const profilePopover = () => {
    return (
      <React.Fragment>
        <PopoverContent>
          <div
            onClick={() => {
              props.onClick(true);
              setTarget(null);
            }}
            className="mb-2"
          >
            View
          </div>

          <div
            onClick={() => {
              setTarget(null);
              props.onClick();
            }}
            className="mb-2"
          >
            Edit
          </div>

          {!deleteLoader ? (
            <div
              onClick={() => {
                handleDeleteStaffMember();
                setTarget(null);
              }}
              className="mb-2"
            >
              Delete
            </div>
          ) : (
            <Spinner animation="border" />
          )}
        </PopoverContent>
      </React.Fragment>
    );
  };

  return (
    <div className="member-edit-card" onMouseLeave={() => setTarget(null)}>
      <div className="edit">
        <Image
          src={menuIcon}
          alt="menu-icon"
          className="three-dots"
          onClick={(event) => setTarget(target ? null : event.target)}
        />
      </div>
      <Overlay show={Boolean(target)} target={target} placement="bottom-end">
        <Popover bsPrefix="remove-selected-item staff-menu-popup">
          {profilePopover()}
        </Popover>
      </Overlay>
      <Image
        src={`${member && member.profile_pic_url}`}
        className="profile-image"
      />
      <div className="caption"></div>
      <div className="name">{member && member.staff_name}</div>
      <div className="desig">{member && member.title ? member.title : "-"}</div>
      <Image src={AssetsImg.ic_material_mail_outline} className="email" />
    </div>
  );
};

MemberEditCard.propTypes = {};

export default MemberEditCard;

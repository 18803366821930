/* global google */
import React from "react";
import { DrawingManager } from "@react-google-maps/api";

const MapDrawingComponent = ({ addFence }) => {
	const onPolygonComplete = polygon => {
		const selectedFence = polygon.getPath().getArray().map(latLng => {
			return { lat: latLng.lat(), lng: latLng.lng() };
		});
		addFence([...selectedFence]);
		polygon.setMap(null);
	};

	return (
		<DrawingManager
			onPolygonComplete={onPolygonComplete}
			options={{
				drawingControl: true,
				drawingControlOptions: {
					position: google.maps.ControlPosition.TOP_CENTER,
					drawingModes: [google.maps.drawing.OverlayType.POLYGON]
				},
				polygonOptions: {
					editable: true,
					geodesic: true,
					strokeColor: "#ff2527",
					strokeOpacity: 0.75,
					strokeWeight: 2,
					clickable: true,
				}
			}}
		/>
	);
};

export default MapDrawingComponent;

import React from "react";
import Hoc from "../Hoc";
import CategoriesList from "./CategoriesList";

const Categories = () => {
  return (
    <Hoc activeMenu="services_category">
      <CategoriesList />
    </Hoc>
  );
};

export default Categories;

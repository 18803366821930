
import CompanyGroups from "../Companies/Groups";
import Hoc from "../Hoc";
import "./Styles.scss";

const Groups = () => {

  return (
    <Hoc activeMenu="groups">
      <CompanyGroups />
    </Hoc>
  )
}

export default Groups
import Axios from "../Axios";
import moment from "moment";

export const globalSettings = async () => {
  try {
    const response = await Axios.get("/settings/global_settings");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveGeneralSettings = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_global_settings/general",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const saveCommonSettings = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_global_settings/common_app",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCustomerSettings = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_global_settings/customer",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const saveSupplierSettings = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_global_settings/supplier",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const savePushNotifications = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/send_push_notification",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveAvatarSettings = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_global_settings/avatars",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setLanguage = async (language) => {
  try {
    const response = await Axios.get(
      `/settings/set_language?language=${language}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPermissions = async (id) => {
  try {
    const response = await Axios.get(
      `/settings/permission_list?user_type_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUserTypes = async () => {
  try {
    const response = await Axios.get(`/settings/user_type_list`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteUserType = async (id) => {
  try {
    const response = await Axios.get(`/settings/delete_user_type/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveUserType = async (data) => {
  try {
    const response = await Axios.post("/settings/save_user_type", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const savePermissions = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_user_type_permissions",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLogs = async (
  per_page,
  offset,
  search_phrase,
  date_from,
  date_to
) => {
  try {
    let endPoint = `/settings/logs_restapi`;
    if (date_from || date_to || search_phrase) {
      if (date_to && date_from && search_phrase) {
        endPoint += `?search_for=${search_phrase}&date_from=${moment(
          date_from
        ).format("YYYY-MM-DD")}&date_to=${moment(date_to).format(
          "YYYY-MM-DD"
        )}&method=get&authorized_status=1&per_page=${per_page}&offset=${offset}`;
      } else if (date_to && date_from) {
        endPoint += `?date_from=${moment(date_from).format(
          "YYYY-MM-DD"
        )}&date_to=${moment(date_to).format(
          "YYYY-MM-DD"
        )}&method=get&authorized_status=1&per_page=${per_page}&offset=${offset}`;
      } else if (date_from) {
        endPoint += `?date_from=${moment(date_from).format(
          "YYYY-MM-DD"
        )}&method=get&authorized_status=1&per_page=${per_page}&offset=${offset}`;
      } else if (date_to) {
        endPoint += `?date_to=${moment(date_to).format(
          "YYYY-MM-DD"
        )}&method=get&authorized_status=1&per_page=${per_page}&offset=${offset}`;
      } else if (search_phrase) {
        endPoint += `?search_for=${search_phrase}&method=get&authorized_status=1&per_page=${per_page}&offset=${offset}`;
      }
    } else {
      endPoint += `?method=get&authorized_status=1&per_page=${per_page}&offset=${offset}`;
    }
    const response = await Axios.get(endPoint);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCountryList = async () => {
  try {
    const response = await Axios.get(`settings/country_code_list`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCityByCountryCode = async (code) => {
  try {
    const response = await Axios.get(`settings/cities_by_country/${code}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

import React, { useState, useRef } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

import SimpleReactValidator from "simple-react-validator";
import UserInput from "../../components/Inputs";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { serviceSave } from "../../core/Api/services";
import { toast } from "react-toastify";
import SearchableDropdown from "../../components/Dropdown/SearchableDropdown";

const Serviceform = (props) => {
  const { i18n, t } = useTranslation();
  const { fetchServices, handleClose, serviceData, categoriesOptions } = props;

  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCatergory, setSelectedCategory] = useState(() => ({
    ...props.categoryDetail,
  }));
  const [, forceUpdate] = useState();

  const servicesOptions = serviceData
    .filter((item) => item.category_id == selectedCatergory.category_id)
    .map((row) => ({
      label: i18n.language == "ar" ? row.name_ar : row.name_en,
      value: row.service_id,
    }));

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSelectedCategory({
      ...selectedCatergory,
      [name]: value,
    });
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory({
      ...selectedCatergory,
      category_id: value,
    });
  };

  const handleServiceChange = (value) => {
    setSelectedCategory({
      ...selectedCatergory,
      new_service_id: value,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          service_id: selectedCatergory.service_id ?? 0,
          category_id: selectedCatergory.category_id,
          service_name_en: selectedCatergory.name_en,
          service_name_ar: selectedCatergory.name_ar,
          brand_name_en:selectedCatergory?.brand_name_en||null,
          brand_name_ar:selectedCatergory?.brand_name_ar||null,
          new_service_id: selectedCatergory.new_service_id ?? 0,
          is_active: selectedCatergory.is_active ?? 0,
        };
        const response = await serviceSave(data);
        if (response?.code == 200) {
          toast.success(response.message);
          await fetchServices();
          handleClose();
        } else {
          throw new Error(response?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  return (
    <Container className="service-form-container">
      <Modal.Body scrollable={true}>
        <Row>
          <Col md="12">
            <SearchableDropdown
              label="Category"
              placeholder="Select Category"
              options={categoriesOptions}
              value={categoriesOptions.find(
                (item) => item.value == selectedCatergory.category_id
              )}
              onChange={(value) => handleCategoryChange(value.value)}
            />
            {simpleValidator.current.message(
              "category_id",
              selectedCatergory.category_id,
              "required"
            )}
          </Col>
          <Col md="12" className="mt-4">
            <UserInput
              label="Service Name (EN)"
              name="name_en"
              placeholder="Service Name"
              value={selectedCatergory.name_en}
              onChange={handleChange}
            />
          </Col>
          <Col md="12" className="mt-4">
            <UserInput
              label="Service Name (AR)"
              name="name_ar"
              placeholder="Service Name"
              value={selectedCatergory.name_ar}
              onChange={handleChange}
            />
          </Col>
          {/* <Col md="12" className="mt-4">
            <UserInput
              label={`${t('Brand Name')} (EN)`}
              name="brand_name_en"
              placeholder={`${t('Brand Name')} (EN)`}
              value={selectedCatergory.brand_name_en}
              onChange={handleChange}
            />
          </Col>
          <Col md="12" className="mt-4">
            <UserInput
              label={`${t('Brand Name')} (AR)`}
              name="brand_name_ar"
              placeholder={`${t('Brand Name')} (AR)`}
              value={selectedCatergory.brand_name_ar}
              onChange={handleChange}
            />
          </Col> */}
          <Col md="12" className="mt-4">
            <SearchableDropdown
              label="New Service"
              name="username"
              placeholder="Select Service"
              options={servicesOptions}
              value={servicesOptions.find(
                (item) => item.value == selectedCatergory.new_service_id
              )}
              onChange={(value) => handleServiceChange(value.value)}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="footer">
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button disabled={isLoading} onClick={handleSave} className="save-btn">
          Save changes
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default Serviceform;

import React from "react";
import Hoc from "../Hoc";
import BookingsTable from "./BookingsTable";

const Bookings = (props) => {
  return (
    <Hoc activeMenu="bookings">
      <BookingsTable {...props} />
    </Hoc>
  );
};

export default Bookings;

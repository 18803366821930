import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/Primary';
import CustomDropdown from '../../../components/Dropdown';
import FullPageSpinner from '../../../components/FullPageSpinner';
import Hoc from '../../Hoc';
import './Styles.scss';
import PartnersGraph from './partnersGraph';
import ServicesBarGraph from './servicesGraph';
import { getPartnersSummaryReport } from '../../../core/Api/Reports';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const yearOptions = [
	{ label: '2015', value: '2015' },
	{ label: '2016', value: '2016' },
	{ label: '2017', value: '2017' },
	{ label: '2018', value: '2018' },
	{ label: '2019', value: '2019' },
	{ label: '2020', value: '2020' },
	{ label: '2021', value: '2021' },
	{ label: '2022', value: '2022' },
	{ label: '2023', value: '2023' },
];

const PartnersSummaryReport = () => {
	const { t } = useTranslation();
	const [data, setData] = useState({});
	const [filterSchema, setFilterSchema] = useState({
		year: '2023',
		quarter: 'Q1',
	});
	const [isLoading, setLoading] = useState(true);

	const quarterOptions = [
		{ label: t('Q1'), value: 'Q1' },
		{ label: t('Q2'), value: 'Q2' },
		{ label: t('Q3'), value: 'Q3' },
		{ label: t('Q4'), value: 'Q4' },
	];

	useEffect(() => {
		fetchBookings(filterSchema);
	}, []);

	const fetchBookings = async (filters) => {
		setLoading(true);
		const response = await getPartnersSummaryReport({
			year: filters?.year,
			quarter: filters?.quarter,
		});
		if (response.code == 200) {
			setData({
				activeSalons: response?.result?.active_partners?.salon ?? 0,
				activeFreelancers: response?.result?.active_partners?.freelancer ?? 0,
				services: response?.result?.services ?? {},
				registrations: {
					monthlyPartnersData: {
						labels: _.map(
							response?.result?.registrations_monthly ?? [],
							(r) => r.month_name
						),
						partners: _.map(
							response?.result?.registrations_monthly ?? [],
							(r) => Number(r.monthly_partners)
						),
						cumulatedPartners: _.map(
							response?.result?.registrations_monthly ?? [],
							(r) => Number(r.commulated_partners)
						),
					},
					weeklyPartnersData: {
						labels: _.map(
							response?.result?.registrations_weekly ?? [],
							(r) => r.week_name
						),
						partners: _.map(response?.result?.registrations_weekly ?? [], (r) =>
							Number(r.weekly_partners)
						),
						cumulatedPartners: _.map(
							response?.result?.registrations_weekly ?? [],
							(r) => Number(r.commulated_partners)
						),
					},
				},
			});
		} else {
			toast.error(response && response.message);
		}
		setLoading(false);
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleSearch = async () => {
		fetchBookings({ ...filterSchema });
	};

	return (
		// <Hoc activeMenu="partnersSummaryReport">
			<div className="partnersSummaryReport">
				<div className="table-wrapper pr-3">
					{isLoading && <FullPageSpinner />}
					<Row className="filtersCont">
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'year')}
								name="year"
								value={
									yearOptions?.find((r) => r.value == filterSchema?.year) ||
									null
								}
								options={yearOptions}
								label={t('Select Year')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'quarter')}
								name="quarter"
								value={
									quarterOptions?.find(
										(r) => r.value == filterSchema?.quarter
									) || null
								}
								options={quarterOptions}
								label={t('Select Quarter')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<PrimaryButton
								disabled={isLoading}
								onClick={handleSearch}
								text={t('Search')}
							/>
						</Col>
					</Row>
					<Container className="mainContent" fluid>
						<Row className="topRowCont">
							<Col lg={4} md={4} sm={12} className="">
								<div className="headingCont">
									<p>
										{t('Partnerships')} {filterSchema?.year}-
										{filterSchema?.quarter}
									</p>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12} className="">
								<div className="totalCont">
									<p className="num">{data?.activeSalons} </p>
									<p className="text">{t('Total Active Salons')}</p>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12} className="">
								<div className="topCont">
									<div className="totalCont freelancer">
										<p className="num">{data?.activeFreelancers} </p>
										<p className="text">{t('Total Active Freelancers')}</p>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="graphsCont">
							<Col lg={8} md={8} sm={12}>
								<div className="multiGraphContent">
									<PartnersGraph
										graphsData={data?.registrations?.monthlyPartnersData ?? {}}
										barGraphLabel={t('Monthly New Partners')}
										lineGraphLabel={t('Monthly Cumulated Partners')}
									/>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12}>
								<div className="barGraphContent">
									<div className="bottomSecContent">
										<p>{t('Service By Location')}</p>
										<div className="barGraphCont">
											<ServicesBarGraph graphsData={data} />
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="graphsCont">
							<Col lg={12} md={12} sm={12}>
								<div className="multiGraphContent">
									<PartnersGraph
										graphsData={data?.registrations?.weeklyPartnersData ?? {}}
										title={t('Weekly Registrations Report')}
										barGraphLabel={t('Weekly New Partners')}
										lineGraphLabel={t('Weekly Cumulated Partners')}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		// </Hoc>
	);
};

export default PartnersSummaryReport;

import Axios from "../Axios";

export const requestAssignDriver = async (booking_id, supplier_driver_id) => {
  const data = {
    booking_id,
    supplier_driver_id,
  };
  try {
    const response = await Axios.post("/booking/assign_driver", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const endJourneyRequest = async (booking_id, supplier_id) => {
  const data = {
    booking_id,
    supplier_id,
  };
  try {
    const response = await Axios.post("/booking/end_journey", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const startJourneyRequest = async (booking_id, supplier_id) => {
  const data = {
    booking_id,
    supplier_id,
  };
  try {
    const response = await Axios.post("/booking/start_journey", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatedCurrentLocation = async (
  supplier_id,
  booking_id,
  lat,
  lng
) => {
  const data = {
    supplier_id,
    booking_id,
    lat_lng: `${lat},${lng}`,
  };
  try {
    const response = await Axios.post("/booking/update_current_location", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

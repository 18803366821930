import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";

import "./Styles.scss";
const DateInput = (props) => {
  const { label, className, disabled, required, placeholder, dateFormat = "dd/MM/yyyy", isClearable = false } = props;
  return (
    <div className={`date-input ${className ? className : ""}`}>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
        </Form.Label>
      )}
      <DatePicker
        dateFormat={dateFormat}
        disabled={disabled}
        selected={props.value}
        onChange={(date) => props.onChange(date)}
        includeDates={props.includeDates}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
        isClearable={isClearable}
      />
    </div>
  );
};

DateInput.propTypes = {};

export default DateInput;

import { useEffect, useRef, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import UserInput from "../../../components/Inputs";
import CustomTextArea from "../../../components/Textarea";
import { addVoucherPurchase, getVoucherPurchasesByVoucherId } from "../../../core/Api/giftvouchers";
import { toast } from "react-toastify";
import FullPageSpinner from "../../../components/FullPageSpinner";
import PrimaryButton from "../../../components/Buttons/Primary";
import DateInput from "../../../components/DateInput";
import moment from "moment";
import CustomTableComponent from "../../../components/CustomTableComponent";
import AssetsImg from "images-col";


const initVoucherModel = {
  trx_date: new Date(),
  expiry_date: null,
  qty: null,
  unit_price: null,
  discount: null,
  notes: null,
  reference_no: null,
}


const VouchersPurchaseForm = ({
  voucherId = null,
  sellerId = null,
  onClose,
}) => {
  const isDisabled = false;
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        arabic: {
          message: t("Must be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return isArabic.test(val);
          },
          required: true,
        },
        english: {
          message: t("Must be english"),
          rule: (val) => {
            const isEnglish = /[a-zA-Z0-9]/;
            return isEnglish.test(val);
          },
          required: true,
        },
      },
    })
  );
  const offset = 0;
  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [voucherModel, setVoucherModel] = useState({ ...initVoucherModel })
  const [, forceUpdate] = useState(0);
  const [voucherPurchases, setVoucherPurchases] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (voucherId) {
      loadInitialData(voucherId);
    } else {
      setLoading(false)
    }
  }, [voucherId, sellerId])

  const loadInitialData = async (id) => {
    setLoading(true);
    const res = await getVoucherPurchasesByVoucherId({
      id,
      per_page: pageSize,
      offset
    });
    setLoading(false)
    if (res?.code < 400) {
      setVoucherPurchases(res?.result?.result_data);
      setTotalCount(res?.result?.total_records || 0);

    } else {
      toast.error(res?.message || t('Something wrong!!'))
    }
  }

  const handleInputChange = (e, name) => {
    setVoucherModel({
      ...voucherModel,
      [name]: e.target.value
    })
  }

  const handleSaveVoucher = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      let data = {
        ...voucherModel,
        seller_id: sellerId,
        voucher_id: voucherId,
        trx_date: moment(voucherModel?.trx_date).format('YYYY-MM-DD'),
        expiry_date: moment(voucherModel?.expiry_date).format('YYYY-MM-DD')
      }
      const result = await addVoucherPurchase(data);
      setLoading(false);
      if (result?.code == 200) {
        toast.success('Saved successfully');
        onClose && onClose(false)
      } else {
        toast.error(result?.message || "Something went wrong.Please try again");
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      toast.error("Please enter valid details");
    }
  };

  const handleDateChange = (date, name) => {

    setVoucherModel({
      ...voucherModel,
      [name]: date && new Date(date) || null,
    });
  };

  const columns = [
    {
      name: t("Trx. Date"),
      key: 'trx_date',
      formatter: null,
    },
    {
      name: t("Quantity"),
      key: 'qty',
    },
    {
      name: t("Price"),
      key: 'unit_price',
      formatter: null,
    },
    {
      name: t("Dis. Price"),
      key: 'discount',
      formatter: null,
    },
  ]

  return <div className="vouchers-form p-3">
    {loading && <FullPageSpinner /> || null}
    <Accordion >
      {/* <Card> */}
      <Accordion.Toggle as={Card.Header} onClick={() => setIsActive(!isActive)} eventKey="0">
        <div className='d-flex justify-content-between align-items-center'>
          <span>{t('Expand to add voucher purchase')}</span> <img className={isActive && 'arrow-icons'} src={AssetsImg?.icon_arrow_down} ></img>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
              <Row className="m-0 mb-2">
                <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
                  <UserInput
                    required
                    label={t("Quantity")}
                    disabled={isDisabled}
                    placeholder={t("Quantity")}
                    name="qty"
                    value={voucherModel?.qty}
                    onChange={(event) =>
                      handleInputChange(event, "qty")
                    }
                  />
                  {simpleValidator.current.message(
                    "qty",
                    voucherModel && voucherModel.qty,
                    "required"
                  )}
                </Col>
                <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
                  <UserInput
                    required
                    label={t("Unit Price")}
                    disabled={isDisabled}
                    placeholder={t("Unit Price")}
                    name="unit_price"
                    value={voucherModel?.unit_price}
                    onChange={(event) =>
                      handleInputChange(event, "unit_price")
                    }
                  />
                  {simpleValidator.current.message(
                    "unit_price",
                    voucherModel && voucherModel.unit_price,
                    "required"
                  )}
                </Col>
                <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
                  <UserInput
                    required
                    label={t("Discount")}
                    disabled={isDisabled}
                    placeholder={t("Discount")}
                    name="discount"
                    value={voucherModel?.discount}
                    onChange={(event) =>
                      handleInputChange(event, "discount")
                    }
                  />
                  {simpleValidator.current.message(
                    "discount",
                    voucherModel && voucherModel.discount,
                    "required"
                  )}
                </Col>
                <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
                  <DateInput
                    label={t("Transaction date")}
                    value={voucherModel.trx_date}
                    onChange={(date) => handleDateChange(date, "trx_date")}
                  />
                  {simpleValidator.current.message(
                    "trx_date",
                    voucherModel && voucherModel.trx_date,
                    "required"
                  )}
                </Col>
                <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
                  <DateInput
                    label={t("Expiry date")}
                    value={voucherModel.expiry_date}
                    onChange={(date) => handleDateChange(date, "expiry_date")}
                  />
                  {simpleValidator.current.message(
                    "expiry_date",
                    voucherModel && voucherModel.expiry_date,
                    "required"
                  )}
                </Col>
                <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
                  <UserInput
                    required
                    label={t("Reference")}
                    disabled={isDisabled}
                    placeholder={t("Reference")}
                    name="reference_no"
                    value={voucherModel?.reference_no}
                    onChange={(event) =>
                      handleInputChange(event, "reference_no")
                    }
                  />
                  {simpleValidator.current.message(
                    "reference_no",
                    voucherModel && voucherModel.reference_no,
                    "required"
                  )}
                </Col>
              </Row>
              <Row className="m-0 mb-2">
                <Col xl="12" lg="12" md="12" sm="12">
                  <CustomTextArea
                    label={t("Notes")}
                    placeholder={t("Notes")}
                    name="notes"
                    value={voucherModel.notes}
                    onChange={(event) => handleInputChange(event, "notes")}
                  />
                </Col>
              </Row>
              <Row className="m-0 mt-3">
                <Col md="12" className="pt-5   d-flex justify-content-end">
                  <PrimaryButton
                    text={t("Save")}
                    onClick={() => handleSaveVoucher()}
                    style={{ width: '200px' }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
      {/* </Card> */}
    </Accordion>




    <Card>
      <Row className="mt-4">
        {totalCount > 0 &&
          (<CustomTableComponent
            responsive={true}
            hover={true}
            showSequence={true}
            pageSize={pageSize}
            pageNo={pageNo}
            columns={columns || []}
            data={voucherPurchases || []}
          />) || null}
      </Row>
    </Card>

  </div>
}

export default VouchersPurchaseForm;
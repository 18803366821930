import Axios from "../Axios";
import moment from "moment";
export const getAllBookingsByCustomerId = async (supplier_id, customer_id) => {
  try {
    const response = await Axios.get(
      `/booking/all?supplier_id=${supplier_id}&customer_id=${customer_id}&status_list=1&grouped_by_status=1&services=true`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getBookingSummaryByCustomerId = async (id) => {
  try {
    const response = await Axios.get(`/booking/summary?customer_id=${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getBookingById = async (id, customerId = 0) => {
  try {
    const response = await Axios.get(
      `/booking/get/${id}?customer_id=${customerId}&services=true`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getBookingHistoryByBookingId = async (id) => {
  try {
    const response = await Axios.get(`/booking/booking_flow/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCalendarScheduleByStaffId = async (
  supplier_id,
  id,
  application_type,
  status_id
) => {
  try {
    const response = await Axios.get(
      `/booking/calendar_data/${supplier_id}?supplier_staff_id=${id}&application_type=${application_type}&status_id=${status_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveOfflineBooking = async (data) => {
  try {
    const response = await Axios.post("booking/save_offline_booking", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const rescheduleBooking = async (data) => {
  try {
    const response = await Axios.post("booking/reschedule", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateStatus = async (data) => {
  try {
    const response = await Axios.post("/booking/update_status", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setNoShow = async (booking_id) => {
  try {
    const response = await Axios.get(`booking/set_no_show/${booking_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDeliveryFeeCharges = async (supplier_id, address_id) => {
  try {
    const response = await Axios.get(`supplier/delivery_fee?supplier_id=${supplier_id}&address_id=${address_id}
    `);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const cancelBooking = async (booking_id, requested_by) => {
  const data = {
    booking_id,
    requested_by,
    in_account_to:2
  };
  try {
    const response = await Axios.post("booking/cancel_booking", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const markAsPaid = async (params={}) => {
  try {
    const response = await Axios.post("booking/mark_as_paid", params);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const manageGuestBooking = async (booking_id) => {
  const data = {
    booking_id,
  };
  try {
    const response = await Axios.post("booking/manage_guest_booking", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllBookings = async (
  per_page,
  offset,
  search_phrase,
  date_from,
  date_to,
  include_total=false,
  supplier_id=null
) => {
  try {
    let endPoint = `/booking/all?per_page=${per_page}&offset=${offset}`;
    if (date_from || date_to) {
      if (date_from && date_to) {
        endPoint += `&date_from=${moment(date_from).format(
          "YYYY-MM-DD"
        )}&date_to=${moment(date_to).format("YYYY-MM-DD")}`;
      } else if (date_from) {
        endPoint += `&date_from=${moment(date_from).format("YYYY-MM-DD")}`;
      } else if (date_to) {
        endPoint += `&date_to=${moment(date_to).format("YYYY-MM-DD")}`;
      }
    }
     if (search_phrase) {
      endPoint += `&search_phrase=${search_phrase}`;
    } 
    if(include_total){
      endPoint += `&include_total=${1}`
    }
    if(supplier_id){
      endPoint += `&supplier_id=${supplier_id}`
    }
    const response = await Axios.get(endPoint);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllDisputedBookings = async () => {
  try {
    const response = await Axios.get("/booking/disputed");
    return response.data;
  } catch (error) {
    return error;
  }
};

import React from 'react';
import { Container, Row, Col, Image, } from "react-bootstrap";
import Hoc from '../../Hoc';
import './Styles.scss';
import AssetsImg from "images-col";
import PrimaryButton from '../../../components/Buttons/Primary';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RoundedButton from '../../../components/Buttons/Rounded';
import Card from '../../../components/Card';
import { getSellerById } from '../../../core/Api/giftvouchers';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Vouchers from '../Vouchers';
import { checkActionPermission } from '../../../utils/helper';


const SellerProfile = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const match = useRouteMatch()

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [sellerModel, setSellerModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      const results = await getSellerById(match?.params?.id);
      setIsLoading(false)
      if (results?.code == 200) {
        setSellerModel(results?.result)
      } else {
        toast.error(results?.message || "Something went wrong");
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message || "Something went wrong");
    }
  }

  console.log('sellerModel :', sellerModel);

  return <React.Fragment>
    <Hoc activeMenu="seller">
      <Container className="seller-profile">
        <Row>
          <Col md="5" className="personal-detail">
            <Card>
              <div className='profile-img-cont'>
                <Image
                  src={sellerModel?.logo || AssetsImg?.img_default}
                />
              </div>
              <div className="name">{sellerModel?.seller_name}</div>
              <Row>
               {checkActionPermission("gift_voucher_sellers","canEdit") &&  <PrimaryButton
                  text={t("Edit Seller")}
                  onClick={() =>
                    history.push(`/seller/${sellerModel?.seller_id}`)
                  }
                  style={{
                    width: "30%",
                  }}
                /> || null}
              </Row>
              <hr />
              <div className="filled-data">
                <label>{t("Contact Person")}</label>
                <div className="response">
                  {sellerModel?.contact_person_1}
                </div>
                <label>{t("Seller Category")}</label>
                <div className="response">
                  {sellerModel?.category}
                </div>
                <label>{t("Location")}</label>
                <div className="response">
                  {sellerModel?.address?.location?.toUpperCase() ?? 'N/A'}
                </div>
              </div>
            </Card>
          </Col>
          <Col md="7">
            <Card className={"custom-card sar-section"}>
              <Row className="mt-4">
                <Col>
                  <div className="title">
                    {`SAR ${sellerModel?.vouchers_summary?.total_vouchers ?? 0}`}
                  </div>
                  <div className="value text-nowrap">{t("Total Vouchers")}</div>
                </Col>
                <Col>
                  <div className="title">
                    {`SAR ${sellerModel?.vouchers_summary?.total_purchased ?? 0}`}
                  </div>
                  <div className="value">{t("Total In")}</div>
                </Col>
                <Col>
                  <div className="title">
                    {`SAR ${sellerModel?.vouchers_summary?.total_sold ?? 0}`}
                  </div>
                  <div className="value">{t("Total Out")}</div>
                </Col>
                <Col>
                  <div className="title">
                    {`SAR ${sellerModel?.vouchers_summary?.available_qty ?? 0}`}
                  </div>
                  <div className="value">{t("Available")}</div>
                </Col>
              </Row>
            </Card>
            <Card className={"custom-card past-section"}>
              {/* <div className="cta">
                <RoundedButton
                  onClick={() => setActive(true)}
                  isActive={active}
                  label={t("Vouchers")}
                />
              </div> */}
              <div className='pt-2'>
                <Vouchers
                  sellerDetails={sellerModel} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Hoc>
  </React.Fragment>
}

export default SellerProfile;
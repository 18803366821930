import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import UserInput from "../../components/Inputs";
import { Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import CustomDropdown from "../../components/Dropdown";
import { useTranslation } from "react-i18next";
import { savePushNotifications } from "../../core/Api/Settings";
import PrimaryButton from "../../components/Buttons/Primary";
import { checkActionPermission } from "../../utils/helper";

const BlackListForm = (props) => {
	const { t } = useTranslation();
	const simpleValidator = useRef(new SimpleReactValidator());
	const [notificationDetails, setNotificationDetails] = useState({
		title: "",
		message: "",
		topic: "",
		app_type: "BOTH",
	});
	const [isLoading, setLoading] = useState(false);
	const [, forceUpdate] = useState(0);
	const appTypeOptions = [
		{ label: t("BOTH"), value: "BOTH" },
		{ label: t("PARTNER"), value: "PARTNER" },
		{ label: t("CUSTOMER"), value: "CUSTOMER" },
	];

	const handleChange = (event) => {
		const value = event.target.value;
		setNotificationDetails({
			...notificationDetails,
			[event.target.name]: event.target.value,
		});
	};

	const handleSave = async () => {
		toast.dismiss();
		setLoading(true);
		try {
			if (simpleValidator.current.allValid()) {
				const result = await savePushNotifications(notificationDetails);
				if (result.code === 200) {
					toast.success("Notifications Sent Successfully");
					setNotificationDetails({
						title: "",
						message: "",
						topic: "",
						app_type: "BOTH",
					});
				} else {
					throw new Error(result?.message ?? "Something went wrong");
				}
			} else {
				simpleValidator.current.showMessages();
				forceUpdate(1);
				throw new Error("Please enter valid details" ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
		setLoading(false);
	};

	return (
		<div className="push-notifications-cont">
			<div className="'row mb-5">
				<div className="col-12">
					<UserInput required label="Title" name="title" placeholder="Enter title" value={notificationDetails.title} onChange={handleChange} />
					{simpleValidator.current.message("title", notificationDetails.title, "required")}
				</div>
				<div className="col-12">
					<UserInput required label="Topic" name="topic" placeholder="Enter topic" value={notificationDetails.topic} onChange={handleChange} />
					{simpleValidator.current.message("topic", notificationDetails.topic, "required")}
				</div>
				<div className="col-12">
					<UserInput
						required
						label="Message"
						name="message"
						placeholder="Enter message"
						value={notificationDetails.message}
						onChange={handleChange}
					/>
					{simpleValidator.current.message("message", notificationDetails.message, "required")}
				</div>
				<div className="col-12">
					<CustomDropdown
						options={appTypeOptions}
						label={t("App Type")}
						onChange={(value) =>
							setNotificationDetails({
								...notificationDetails,
								app_type: value?.value,
							})
						}
						value={appTypeOptions.find((row) => row.value === notificationDetails.app_type) || ""}
					/>
				</div>
			</div>
			<div className="'row">
				<div className="col-12 actions-cont">
					{checkActionPermission("push_notifications", "canAdd") && (
						<PrimaryButton text={t("Send Notification")} onClick={handleSave} isLoading={isLoading} />
					)}
				</div>
			</div>
		</div>
	);
};

export default BlackListForm;

import React from "react";
import "./Styles.scss";
const RoundedButton = (props) => {
  const { label, onClick, isActive } = props;
  return (
    <div
      onClick={onClick}
      className={`rounded-button  ${isActive ? "active" : ""}`}
    >
      {label}
    </div>
  );
};

export default RoundedButton;

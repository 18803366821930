import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomTableComponent from '../../../components/CustomTableComponent';

const DetailReport = ({ data, pageNo = 1, pageSize = 10 }) => {
	const { t } = useTranslation();

	const columns = [
		{
			name: t('date'),
			key: 'date',
		},
		{
			name: t('Partner Type'),
			key: 'type',
			formatter: (row, key) => {
				return <span>{row[key]?.toUpperCase()}</span>;
			},
		},
		{
			name: t('Location Type'),
			key: 'location_type',
			formatter: (row, key) => {
				return <span>{row[key]?.toUpperCase()}</span>;
			},
		},
		{
			name: t('totalbookings'),
			key: 'total_bookings',
			formatter: null,
		},
		{
			name: t('totalAmount'),
			key: 'total_amount',
			formatter: (row, key) => {
				return (
					(row?.total_amount && parseFloat(row?.total_amount).toFixed(2)) || 0
				);
			},
		},
	];

	return (
		<>
			<CustomTableComponent
				responsive={true}
				hover={true}
				showSequence={true}
				pageSize={pageSize}
				pageNo={pageNo}
				columns={columns || []}
				data={data || []}
			/>
		</>
	);
};

export default DetailReport;

import React, { useEffect, useState } from "react";
import Hoc from "../Hoc";
import { Container, Col, Row } from "react-bootstrap";
import SearchInput from "../../components/Inputs/Search";
import PrimaryButton from "../../components/Buttons/Primary";
import FullScreenPopup from "../../components/FullScreenPopup";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import EmployeeListing from "./Listing";
import SaveEmployee from "./Add";
import { checkActionPermission } from "../../utils/helper";

const Employees = ({ history, match }) => {
	const { t } = useTranslation();
	const [openPopup, setOpenPopup] = useState(false);
	const [employeeId, setEmployeeId] = useState(null);
	const [searchInput, setSearchInput] = useState("");
	const [reloadClients, setReloadClients] = useState(false);
	const [isSaved, setIsSaved] = useState(false);

	useEffect(() => {
		const state = history.location.state;
		if (state?.addEmployee) {
			setOpenPopup(true, history.location.state?.employeeId);
		} else {
			setOpenPopup(false);
		}
	}, [history.location.state]);

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
	};

	const openOverlay = (replace, employeeId = null) => {
		setReloadClients(false);
		const pathname = history.location.pathname + history.location.search;
		const state = history.location.state;
		setEmployeeId(employeeId || null);
		if (replace === true) {
			history.replace(pathname, {
				...state,
				addEmployee: true,
				employeeId,
			});
		} else {
			history.push(pathname, {
				...state,
				addEmployee: true,
				employeeId,
			});
		}
	};

	const closeOverlay = (saved) => {
		if (saved === true || !isSaved) {
			history.goBack();
			setReloadClients(true);
			setIsSaved(false);
		} else {
			confirmClose();
		}
	};

	const confirmClose = async () => {
		toast.dismiss();
		Swal.fire({
			text: t("Are you sure you want to discard the changes? This action cannot be undone."),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Discard"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				history.goBack();
				setReloadClients(true);
				setIsSaved(false);
			}
		});
	};

	const updateSaved = (saved) => {
		if (saved) {
			setIsSaved(saved);
		} else if (!isSaved) {
			setIsSaved(true);
		}
	};

	return (
		<React.Fragment>
			{!history.location.state?.addEmployee && (
				<Hoc activeMenu="hr_employees">
					<Container fluid className="employee">
						<Row className="heading-row mt-0">
							<Col xl="3" lg="4" md="6" sm="12">
								<SearchInput classNames="pt-0" onChange={handleSearchChange} placeholder={t("Search ")} />
							</Col>
							<Col xl="6" lg="6" md="5" sm="12" className="cta-col">
								{checkActionPermission("hr_employees", "canAdd") && <PrimaryButton text={t("New Employee")} onClick={openOverlay} />}
							</Col>
						</Row>
						<EmployeeListing
							history={history}
							searchInput={searchInput}
							reloadClients={reloadClients}
							handleReload={setReloadClients}
							openOverlay={openOverlay}
						/>
					</Container>
				</Hoc>
			)}
			{(openPopup && (
				<FullScreenPopup open={openPopup} onClose={closeOverlay}>
					<SaveEmployee updateSaved={updateSaved} onClose={closeOverlay} employeeId={employeeId} history={history} />
				</FullScreenPopup>
			)) ||
				null}
		</React.Fragment>
	);
};

export default Employees;

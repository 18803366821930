import React from "react";
import Hoc from "../Hoc";
import SupplierDrivertable from "./DriverTable";
import "./Styles.scss";

const SupplierDriver = (props) => {
  const { match } = props;
  return (
    <Hoc activeMenu={match.params.id ? "supplier_driver" : "supplier_driver"}>
      <SupplierDrivertable {...props} />
    </Hoc>
  );
};

export default SupplierDriver;

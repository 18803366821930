import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomTableComponent from "../../../components/CustomTableComponent";

const DetailReport = ({ data, pageNo = 1, pageSize = 10 }) => {
  const { t, i18n } = useTranslation();
  
  const columns = [
    {
      name: t("Month"),
      key: 'month',
      formatter: null,
    },
    {
      name: t("serviceCategory"),
      key: i18n?.language == 'en' && 'category_en' || 'category_ar',
      formatter: null,
    },
    {
      name: t("serviceName"),
      key: i18n?.language == 'en' && 'name_en' || 'name_ar',
      formatter: null,
    },
    {
      name: t("supplier"),
      key: 'supplier_name',
      formatter: null,
    },
    {
      name: t("totalbookings"),
      key: 'total_bookings',
      formatter: null,
    },
    {
      name: t("totalAmount"),
      key: 'total_amount',
      formatter: (row, key) => {
        return row?.total_amount && parseFloat(row?.total_amount).toFixed(2) || 0
      },
    },
  ]

  return (
    <>
    <CustomTableComponent
      responsive={true}
      hover={true}
      showSequence={true}
      pageSize={pageSize}
      pageNo={pageNo}
      columns={columns || []}
      data={data || []}
    />
  </>
  )
}

export default DetailReport;
import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Form } from "react-bootstrap";
import dropDownArrow from "../../../src/assets/images/chevron-down.svg";

import "./Styles.scss";
import clsx from "clsx";

const CustomDropdown = (props) => {
  const {
    onChange,
    options,
    disabled,
    label,
    sublabel,
    highlightedOptions,
    classNames,
    required,
    customIcon,
  } = props;
  const [value, setValue] = useState(options[0]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    } else {
      setValue(options[0]);
    }
    // eslint-disable-next-line
  }, [props.value]);
  useEffect(() => {
    if (!props.value && options[0] != value) {
      setValue(options[0]);
    }
    // eslint-disable-next-line
  }, [options]);

  return (
    <div className={`custom-dropdown ${classNames}`}>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
          {sublabel && <div className="subtitle">{sublabel}</div>}
        </Form.Label>
      )}
      <div>
        <DropdownButton disabled={disabled} title={value.label}>
          {options.map((item, index) => (
            <Dropdown.Item
              key={index}
              className={clsx(
                highlightedOptions?.includes(item.label) && "custom-option"
              )}
              onClick={() => {
                onChange && onChange(item);
                setValue(item);
              }}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        {customIcon && <img alt="arrow" src={dropDownArrow} />}
      </div>
    </div>
  );
};

CustomDropdown.propTypes = {};

export default CustomDropdown;

import React from "react";
import i18next from "i18next";

import "./Styles.scss";
import { Button } from "react-bootstrap";
import { capitalCase } from "change-case";
import { setLanguage } from "./core/Api/Settings";
import { toast } from "react-toastify";

const LanguageSelect = (props) => {
  const selected = localStorage.getItem("i18nextLng") || "en";
  const buttontext =
    selected == "ar"
      ? props.title
        ? "english"
        : "en"
      : props.title
      ? "arabic"
      : "ar";

  const handleClick = async () => {
    const language = selected == "ar" ? "en" : "ar";
    try {
      const response = await setLanguage(language);
      toast.success(response.message);
      i18next.changeLanguage(language);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className=" d-flex justify-content-end align-items-center language-select-root">
      <Button onClick={handleClick}>{capitalCase(buttontext)}</Button>
    </div>
  );
};

export default LanguageSelect;

import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import CustomDropdown from "../../../components/Dropdown";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";

import PrimaryButton from "../../../components/Buttons/Primary";
import DangerButton from "../../../components/Buttons/Danger";
import SecondaryButton from "../../../components/Buttons/Secondary";
import DateInput from "../../../components/DateInput";
import "react-datepicker/dist/react-datepicker.css";
import "./Styles.scss";
import clsx from "clsx";
import { getAuthAttribute, getDateOptions } from "../../../utils/helper";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import {
  getStaffShifts,
  saveStaffShift,
  getStaffCloseDates,
  deleteStaffShift,
} from "../../../core/Api/staff";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import FullPageSpinner from "../../../components/FullPageSpinner";

const StaffListing = (props) => {
  const { t } = useTranslation();
  const supplierId = props.supplierId
    ? props.supplierId
    : getAuthAttribute("supplier_id");
  const startTimeRef = useRef(null);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        time: {
          message: t("End time must be greater than Start time"),
          rule: (endTime, params) => {
            if (!endTime || params[0] == "") {
              return false;
            }
            const startTime = params.join(" ", ":").replace(" ", ":");
            return moment(endTime, "LT").isAfter(moment(startTime, "LT"));
          },
          required: true,
        },
      },
    })
  );
  const repeatOptions = [
    { label: t("Don't Repeat"), value: "never" },
    { label: t("Weekly"), value: "weekly" },
  ];
  const [, forcedUpdate] = useState(null);
  const dateOptions = [...getDateOptions()];
  const [closeDates, setCloseDates] = useState([]);
  const [supplierStaffId, setSupplierStaffId] = useState(0);
  const [staffSifts, setStaffShifts] = useState([]);
  const [staffShiftModel, setStaffShiftModel] = useState(null);
  const [staffOptions, setStaffOptions] = useState([
    {
      label: t("All"),
      value: 0,
    },
  ]);

  const [dateRange, setDateRange] = useState([
    moment().startOf("week").format(),
    moment().endOf("week").format(),
  ]);
  const [startDate, endDate] = dateRange;
  const [isEdit, setEdit] = useState(false);
  const [endDates, setEndDates] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const headers = [];
  for (let i = 0; i < 7; i++) {
    headers.push(moment(startDate).add(i, "d").format("dd DD MMM"));
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    const result = await getStaffShifts({
      supplier_id: supplierId,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      supplier_staff_id: supplierStaffId,
    });
    const closedDatesData = await getStaffCloseDates(supplierId);
    if (closedDatesData && closedDatesData.code == 200) {
      setCloseDates(closedDatesData.result);
    } else {
      toast.error(closedDatesData && closedDatesData.message);
    }

    if (result && result.code == 200) {
      const shiftDates = [];
      for (let i = 0; i < 7; i++) {
        const shiftDate = moment(startDate).add(i, "d").format("YYYY-MM-DD");
        let isClosed = false;
        if (closedDatesData && closedDatesData.result) {
          for (let i = 0; i < closedDatesData.result.length; i++) {
            if (
              moment(shiftDate) >=
              moment(closedDatesData.result[i].start_date) &&
              moment(shiftDate) <= moment(closedDatesData.result[i].end_date)
            ) {
              isClosed = true;
            }
          }
        }
        shiftDates.push({
          shiftDate,
          shiftStart: "",
          shiftEnd: "",
          isClosed,
          end_date: null,
        });
      }
      const options = [...staffOptions];
      const shifts = result.result.map((row) => {
        const dates = [...shiftDates];
        options.push({
          value: row.supplier_staff_id,
          label: `${row.first_name} ${row.last_name}`,
        });
        row.shifts.forEach((times) => {
          const shiftIndex = dates.findIndex(
            (date) => date.shiftDate == times.shift_date
          );
          if (shiftIndex != -1) {
            dates[shiftIndex] = {
              shiftDate: times.shift_date,
              shiftStart: moment(
                `${times.shift_date} ${times.shift_start}`
              ).format("hh:mm A"),
              shiftEnd: moment(`${times.shift_date} ${times.shift_end}`).format(
                "hh:mm A"
              ),
              staff_shift_id: times.staff_shift_id,
              isClosed: dates[shiftIndex]["isClosed"],
              end_date: times.end_date,
              recurring_mode: times.recurring_mode,
              recurring_type: times.recurring_type,
            };
          }
        });
        return {
          name: `${row.first_name} ${row.last_name}`,
          supplier_staff_id: row.supplier_staff_id,
          shifts: dates,
        };
      });
      setStaffShifts(shifts);
      setStaffOptions(options);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const handleWeekChanges = (type) => {
    if (type == "prev") {
      setDateRange([
        moment(startDate).subtract(7, "days").startOf("week"),
        moment(endDate).subtract(7, "days").endOf("week"),
      ]);
      applyFilters({
        startDate: moment(startDate).subtract(7, "days").startOf("week"),
        endDate: moment(endDate).subtract(7, "days").endOf("week"),
        supplierStaffId,
      });
    } else if (type == "next") {
      setDateRange([
        moment(startDate).add(7, "days").startOf("week"),
        moment(endDate).add(7, "days").endOf("week"),
      ]);
      applyFilters({
        startDate: moment(startDate).add(7, "days").startOf("week"),
        endDate: moment(endDate).add(7, "days").endOf("week"),
        supplierStaffId,
      });
    }else if(type == "today"){
      setDateRange([
        moment().startOf("week").format(),
        moment().endOf("week").format(),
      ])
    }
  };

  const handleDateSelect = (date) => {
    setDateRange([moment(date).startOf("week"), moment(date).endOf("week")]);
    applyFilters({
      startDate: moment(date).startOf("week"),
      endDate: moment(date).endOf("week"),
      supplierStaffId,
    });
  };

  const applyFilters = async (filters) => {
    const result = await getStaffShifts({
      supplier_id: supplierId,
      start_date: moment(filters.startDate).format("YYYY-MM-DD"),
      end_date: moment(filters.endDate).format("YYYY-MM-DD"),
      supplier_staff_id: filters.supplierStaffId,
    });

    if (result && result.code == 200) {
      const shiftDates = [];
      for (let i = 0; i < 7; i++) {
        const shiftDate = moment(filters.startDate)
          .add(i, "d")
          .format("YYYY-MM-DD");
        let isClosed = false;
        if (closeDates && closeDates.length) {
          for (let i = 0; i < closeDates.length; i++) {
            if (
              moment(shiftDate) >= moment(closeDates[i].start_date) &&
              moment(shiftDate) <= moment(closeDates[i].end_date)
            ) {
              isClosed = true;
            }
          }
        }
        shiftDates.push({
          shiftDate,
          shiftStart: "",
          shiftEnd: "",
          isClosed,
        });
      }

      const shifts = result.result.map((row) => {
        const dates = [...shiftDates];
        row.shifts.forEach((times) => {
          const shift = dates.find(
            (date) => date.shiftDate == times.shift_date
          );
          if (shift) {
            const indexOf = dates.findIndex(
              (date) => date.shiftDate == times.shift_date
            );
            dates[indexOf] = {
              shiftDate: times.shift_date,
              shiftStart: moment(
                `${times.shift_date} ${times.shift_start}`
              ).format("hh:mm A"),
              shiftEnd: moment(`${times.shift_date} ${times.shift_end}`).format(
                "hh:mm A"
              ),
              isClosed: dates[indexOf]["isClosed"],
            };
          }
        });
        return {
          name: `${row.first_name} ${row.last_name}`,
          supplier_staff_id: row.supplier_staff_id,
          shifts: dates,
        };
      });
      setStaffShifts(shifts);
    } else {
      toast.error(result && result.message);
    }
  };

  const setWorkingHourModel = (model) => {
    if (model.isClosed) {
      return;
    }
    if (model.shiftDate) {
      const dates = [];
      const year = moment(model.shiftDate).format("YYYY");
      const week = moment(model.shiftDate).isoWeek();
      const weeks =
        moment(`${year}-12-25`).weeks() +
        moment(`${year + 1}-12-25`).weeks() +
        moment(`${year + 2}-12-25`).weeks();
      dates.push(new Date(moment(model.shiftDate).format()));
      for (let i = Number(year); i < Number(year) + 4; i++) {
        let days = 7;
        for (let j = week; j <= weeks; j++) {
          dates.push(
            new Date(moment(model.shiftDate).add(days, "days").format())
          );
          days += 7;
        }
      }
      setEndDates(dates);
    }
    if (model.staff_shift_id) {
      setStaffShiftModel({
        ...staffShiftModel,
        start_date: moment(model.shiftDate).format(),
        supplier_staff_id: model.supplier_staff_id,
        name: model.name,
        shiftDate: model.shiftDate,
        end_date: new Date(moment(model.end_date)),
        start_time: moment(`${model.shiftDate} ${model.shiftStart}`).format(
          "hh:mm A"
        ),
        end_time: moment(`${model.shiftDate} ${model.shiftEnd}`).format(
          "hh:mm A"
        ),
        recurring_mode: model.recurring_mode,
        recurring_type: model.recurring_type,
        staff_shift_id: model.staff_shift_id,
      });
      startTimeRef.current = moment(
        `${model.shiftDate} ${model.shiftStart}`
      ).format("hh:mm A");
      setEdit(true);
    } else {
      setStaffShiftModel({
        ...staffShiftModel,
        start_date: moment(model.shiftDate).format(),
        supplier_staff_id: model.supplier_staff_id,
        name: model.name,
        shiftDate: model.shiftDate,
      });
    }
  };

  const handleChangeSaveStaffSift = (event, name) => {
    const obj = { ...staffShiftModel };
    obj[name] = event;
    if (name == "start_time") {
      obj.end_date = new Date(moment(obj.shiftDate).format());
    }
    if (name == "end_date") {
      obj.recurring_mode = "specific date";
    }
    startTimeRef.current = obj.start_time;
    setStaffShiftModel({
      ...obj,
    });
  };

  const handleSaveStaffShift = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {
      const data = {
        supplier_id: supplierId,
        supplier_staff_id: staffShiftModel.supplier_staff_id,
        recurring_type: staffShiftModel.recurring_type,
        start_date: moment(staffShiftModel.start_date).format("YYYY-MM-DD"),
        end_date: moment(
          staffShiftModel.recurring_type == "weekly"
            ? staffShiftModel.end_date
            : staffShiftModel.start_date
        ).format("YYYY-MM-DD"),
        start_time: moment(staffShiftModel.start_time, "hh:mm A").format(
          "HH:mm"
        ),
        end_time: moment(staffShiftModel.end_time, "hh:mm A").format("HH:mm"),
        recurring_mode: staffShiftModel.recurring_mode,
      };
      const result = await saveStaffShift(data);
      if (result && result.code == 200) {
        props && props.setUpdateProfileStatus(true)
        handleClose();
        loadData();
        toast.success("Saved Successfully");
      } else {
        toast.error((result && result.message) || "Something wrong");
      }
    } else {
      simpleValidator.current.showMessages();
      toast.error("Please enter valid details");
      forcedUpdate(true);
    }
  };

  const handleDelete = (onlySingle) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete ${onlySingle ? "this shift" : "the upcoming shifts"
        }? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          supplier_id: supplierId,
          staff_shift_id: staffShiftModel.staff_shift_id,
          delete_upcoming: onlySingle ? "0" : "1",
        };
        const result = await deleteStaffShift(data);
        if (result && result.code == 200) {
          toast.success(result.message);
          setShow(false);
          setStaffShiftModel(null);
          await loadData();
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const handleClose = () => {
    isEdit && setEdit(false);
    forcedUpdate(null);
    setStaffShiftModel(null);
    startTimeRef.current = null;
  };

  return (
    <React.Fragment>
      <Container className="staff-listing" fluid>
        <Row className="cta-row">
          <div className="mb-3 date-control-div">
            <div className="date-control">
              <div
                className="previous-date"
                onClick={() => {
                  handleWeekChanges("prev");
                }}
              >
                <Image src={leftArrow} />
              </div>
              <button
                  className="today-btn"
                  onClick={() => handleWeekChanges('today')}
                >{t("Today")}</button>
              <div className="picker">
                <DatePicker
                  selectsRange={true}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  onSelect={handleDateSelect}
                />
              </div>
              <div
                className="next-date"
                onClick={() => {
                  handleWeekChanges("next");
                }}
              >
                <Image src={rightArrow} />
              </div>
            </div>
          </div>
          <div className="staff-options">
            <SearchableDropdown
              options={staffOptions}
              placeholder={t("Working Staff")}
              onChange={(value) => {
                applyFilters({
                  startDate,
                  endDate,
                  supplierStaffId: value.value,
                });
                setSupplierStaffId(value.value);
              }}
            />
          </div>
        </Row>
        <Row className="staff-table">
          <Col>
            {isLoading && (
              <div className="spinner-wrap">
                <Spinner animation="border" />
                <span>{t("Please wait, we're loading data")}</span>
              </div>
            ) || (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t("Staff")}</th>
                      {headers.map((row, key) => (
                        <th key={key} className="text-nowrap">
                          {row}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {staffSifts.map((row, index) => (
                      <tr key={index}>
                        <td key={`name_${index}`}>{row.name}</td>
                        {row.shifts.map((times, i) => {
                          return (
                            <td
                              key={`shift_${i}`}
                              className={clsx(
                                times.isClosed && "close-date",
                                !(times.shiftStart && times.shiftEnd) &&
                                "add-symbol"
                              )}
                            >
                              <span
                                key={`shift_${i}`}
                                className={"time-slot"}
                                onClick={() =>
                                  setWorkingHourModel({
                                    name: row.name,
                                    supplier_staff_id: row.supplier_staff_id,
                                    ...times,
                                    staff_shift_id: times.staff_shift_id,
                                  })
                                }
                              >
                                {times.shiftStart && times.shiftEnd
                                  ? `${times.shiftStart}-${times.shiftEnd}`
                                  : "+"}
                              </span>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        size="lg"
        show={Boolean(staffShiftModel)}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Save ${staffShiftModel && staffShiftModel.name} Hours`}
            <small>
              (
              {`${staffShiftModel &&
                moment(staffShiftModel.shiftDate).format("dddd, DD MMMM YYYY")
                })`}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row className="mt-0">
              <Col md="6" className="mt-3">
                <SearchableDropdown
                  required
                  label={t("Start Time")}
                  placeholder={t("Select start time")}
                  onChange={(value) =>
                    handleChangeSaveStaffSift(value.value, "start_time")
                  }
                  options={dateOptions}
                  value={dateOptions.find(
                    (value) => value.value == staffShiftModel?.start_time
                  )}
                />
                {simpleValidator.current.message(
                  "start_time",
                  staffShiftModel && staffShiftModel.start_time,
                  "required"
                )}
              </Col>
              <Col md="6" className="mt-3">
                <SearchableDropdown
                  required
                  label={t("End Time")}
                  placeholder={t("Select end time")}
                  onChange={(value) =>
                    handleChangeSaveStaffSift(value.value, "end_time")
                  }
                  options={dateOptions}
                  value={dateOptions.find(
                    (value) => value.value == staffShiftModel?.end_time
                  )}
                />
                {simpleValidator.current.message(
                  "end_time",
                  staffShiftModel && staffShiftModel.end_time,
                  `required|time:${startTimeRef.current
                    ? startTimeRef.current?.replace(":", " ")
                    : ""
                  }`
                )}
              </Col>
              <Col
                md={
                  staffShiftModel && staffShiftModel.recurring_type != "weekly"
                    ? "12"
                    : "6"
                }
                className="mt-3"
              >
                <CustomDropdown
                  label={t("Repeats")}
                  options={repeatOptions}
                  value={
                    repeatOptions.find(
                      (row) =>
                        row.value ==
                        (staffShiftModel && staffShiftModel.recurring_type)
                    ) || ""
                  }
                  onChange={(selected) =>
                    setStaffShiftModel({
                      ...staffShiftModel,
                      recurring_type: selected.value,
                    })
                  }
                />
              </Col>
              {staffShiftModel && staffShiftModel.recurring_type == "weekly" && (
                <Col md="6" className="mt-3">
                  <DateInput
                    label={t("End Repeat")}
                    value={staffShiftModel && staffShiftModel.end_date}
                    onChange={(event) =>
                      handleChangeSaveStaffSift(event, "end_date")
                    }
                    includeDates={[...endDates]}
                    minDate={new Date()}
                  />
                  {simpleValidator.current.message(
                    "end_date",
                    staffShiftModel && staffShiftModel.end_date,
                    "required"
                  )}
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            {isEdit && (
              <DangerButton
                text={t("Delete")}
                onClick={() =>
                  staffShiftModel.recurring_type == "weekly"
                    ? setShow(true)
                    : handleDelete(true)
                }
              />
            )}
          </div>
          <div className="d-flex">
            <SecondaryButton text={t("Cancel")} onClick={handleClose} />
            <PrimaryButton text={t("Save")} onClick={handleSaveStaffShift} />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        size="lg"
        backdropClassName="delete-repeating-shift-backdrop"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Repeating Shift</Modal.Title>
        </Modal.Header>
        {staffShiftModel && (
          <Modal.Body>
            You are deleting a shift that repeats weekly. Deleting upcoming
            shifts will overwrite{" "}
            <span className="font-bold">{`${staffShiftModel.name}'s ${moment(
              staffShiftModel.end_date
            ).format("dddd")}`}</span>{" "}
            schedule up to
            <span className="font-bold">
              {" " + moment(staffShiftModel.end_date).format("DD-MMMM-YYYY")}
            </span>
          </Modal.Body>
        )}
        <Modal.Footer className="delete-shift-footer">
          <Button variant="light" onClick={() => setShow(false)}>
            {t("Close")}
          </Button>
          <div>
            <Button onClick={() => handleDelete(false)} variant="light">
              {t("Delete upcoming shifts")}
            </Button>
            <Button onClick={() => handleDelete(true)} variant="danger">
              {t("Delete this shift only")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

StaffListing.propTypes = {};

export default StaffListing;

import { useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import FullPageSpinner from "../FullPageSpinner";
import PhoneNumberInput from "../Inputs/PhoneNumber";
import { sendOtp, verifyOtp } from "../../core/Api/Auth";
import { toast } from "react-toastify";
import UserInput from "../Inputs";
import moment from "moment";
import { updateMobileNumber } from "../../core/Api/supplier";

const PartnerMobileNumber = ({ onClose }) => {
	const { t } = useTranslation();
	const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
	const otpVaildator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
	const [isLoading, setIsLoading] = useState(false);
	const [mobileNumber, setMobileNumber] = useState("+966");
	const [, forceUpdate] = useState();
	const [number, setNumber] = useState(null);
	const [currStep, setCurrStep] = useState(1);
	const [otp, setOTP] = useState(null);

	const handlePhoneNumberChange = (value) => {
		if (!value.countryCode || !value.phoneNumber) return;
		setMobileNumber(`${value.countryCode}-${value.phoneNumber}`);
		setNumber(value.phoneNumber);
	};

	const handleSendOTP = async () => {
		setIsLoading(true);
		try {
			if (simpleValidator.current.allValid()) {
				const data = {
					mobile: mobileNumber,
					action: "supplier_mobile",
				};
				const response = await sendOtp(data);
				if (response?.code === 200) {
					toast.success(response.message);
					setCurrStep(2);
				} else {
					throw new Error(response?.message ?? "Something went wrong");
				}
			} else {
				simpleValidator.current.showMessages();
				forceUpdate(1);
				throw new Error("Please enter valid details" ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
		setIsLoading(false);
	};

	const handleVerifyOTP = async () => {
		setIsLoading(true);
		try {
			if (otpVaildator.current.allValid()) {
				const data = {
					mobile: mobileNumber,
					code: otp,
				};
				const response = await verifyOtp(data);
				if (response?.code === 200) {
					toast.success(response.message);
          const updateRes = await updateMobileNumber(mobileNumber);
          if (updateRes?.code === 200) {
            toast.success(updateRes.message);
            onClose();
          } else {
            throw new Error(updateRes?.message ?? "Something went wrong");
          }
				} else {
					throw new Error(response?.message ?? "Something went wrong");
				}
			} else {
				otpVaildator.current.showMessages();
				forceUpdate(1);
				throw new Error("Please enter valid details" ?? "Something went wrong");
			}
		} catch (error) {
		setIsLoading(false);
			toast.error(error.message ?? "Something went wrong");
		}
		setIsLoading(false);
	};

	const handleRemindLater = () => {
		localStorage.setItem(
			"partner_mobile_remind_later",
			JSON.stringify({
				timeStamp: moment().format("MM DD YYYY HH:mm"),
			})
		);
		onClose();
	};
	return (
		<Modal show={true} onHide={onClose} backdrop="static" keyboard={false}>
			<Modal.Header>
				<Modal.Title>{t("Add Mobile Number")}</Modal.Title>
			</Modal.Header>

			<Container>
				<Modal.Body>
					{(isLoading && <FullPageSpinner />) || null}
					{(currStep == 1 && (
						<Row>
							<Col xl="12" lg="12" md="12" sm="12" className="mb-2">
								<h3>Verify you mobile number</h3>
								<p>Please enter country code and mobile number</p>
							</Col>
							<Col xl="12" lg="12" md="12" sm="12" className="mb-2 mt-3">
								<PhoneNumberInput
									required
									label={t("Phone Number")}
									placeholder="5xxxxxxx"
									selectedValues={(values) => handlePhoneNumberChange(values)}
									value={mobileNumber}
									maxLength="9"
								/>
								{simpleValidator.current.message("mobile", number, "required|checkMobileNumber")}
							</Col>
						</Row>
					)) || (
						<Row>
							<Col xl="12" lg="12" md="12" sm="12" className="mb-2">
								<h3>Verify OTP</h3>
								<p>Please enter otp sent to this mobile number</p>
								<p>{mobileNumber}</p>
							</Col>
							<Col xl="12" lg="12" md="12" sm="12" className="mb-2 mt-3">
								<UserInput label={t("OTP")} name="otp" placeholder="Enter OTP" value={otp} onChange={(event) => setOTP(event.target.value)} />
								{otpVaildator.current.message("mobile", otp, "required")}
							</Col>
						</Row>
					)}
				</Modal.Body>

				<Modal.Footer className="footer">
					{(currStep == 1 && (
						<Button disabled={isLoading} onClick={handleSendOTP} variant="primary" className="save-btn">
							{t("Send")}
						</Button>
					)) || (
						<Button disabled={isLoading} onClick={handleVerifyOTP} variant="primary" className="save-btn">
							{t("Verify")}
						</Button>
					)}
					{currStep == 1 && (
						<Button onClick={handleRemindLater} variant="white">
							{t("Remind me later")}
						</Button>
					)}
				</Modal.Footer>
			</Container>
		</Modal>
	);
};

export default PartnerMobileNumber;

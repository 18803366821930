
import Axios from "../Axios";

export const getAllCategories = async () => {
  try {
    const response = await Axios.get(
      `/category/list/false`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCategory = async (data) => {
  try {
    const response = await Axios.post(
      "/category/save",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
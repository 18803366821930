import Axios from "../Axios";

export const getEmployees = async (params) => {
  try {
    const { per_page = 10, offset = 0, search_phrase = "", status_id = '' } = params;
    const response = await Axios.get(
      `employee/?per_page=${per_page}&offset=${offset}&search_phrase=${search_phrase}&status_id=${status_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getEmployeeById = async (id) => {
  try {
    const response = await Axios.get(
      `employee/index/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getEmployeeByRole = async (role) => {
  try {
    const response = await Axios.get(
      `employee/get_by_role?role=${role}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteEmployee = async (id) => {
  try {
    const response = await Axios.get(
      `/employee/delete/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateEmployeeStatus = async (data = {}) => {
  try {
    const response = await Axios.post("/employee/update_status", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveEmployee = async (data = {}) => {
  try {
    const response = await Axios.post("/employee/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDepartments = async () => {
  try {
    const response = await Axios.get(
      `settings/department`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getDesignations = async () => {
  try {
    const response = await Axios.get(
      `settings/designation`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
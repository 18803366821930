import ic_close from "./ic_close.png";
import ic_building from "./ic_building.svg";
import ic_cc_money from "./ic_credit-card-business-money-payment.svg";
import ic_dashboard from "./ic_dashboard.svg";
import ic_feather_bell from "./ic_feather-bell.svg";
import ic_ionic_ios_menu from "./ic_ionic-ios-menu.svg";
import ic_feather_calender from "./ic_feather-calendar.svg";
import ic_offers from "./ic_offers.svg";
import ic_ionic_md_search from "./ic_ionic-md-search.svg";
import ic_cancel from "./ic_cancel.svg";
import ic_feather_edit from "./Icon material-mode-edit.png";
import ic_material_mail_outline from "./Icon feather-mail@2x.png";
import ic_logout_icon from "./log-out.svg";
import ic_eye_fill from "./eye-fill.svg";
import img_default from './album-default.jpg';
import icon_arrow_down from  './icon_arrow_down.png';
import icon_add from  './icon-plus.png';

const AssetsImg = {
  ic_close,
  ic_building,
  ic_cc_money,
  ic_dashboard,
  ic_feather_bell,
  ic_ionic_ios_menu,
  ic_feather_calender,
  ic_offers,
  ic_ionic_md_search,
  ic_cancel,
  ic_feather_edit,
  ic_material_mail_outline,
  ic_logout_icon,
  ic_eye_fill,
  img_default,
  icon_arrow_down,
  icon_add
};

export default AssetsImg;

import Axios from "../Axios";
export const getPartners = async (offset, searchInput, is_booking_page = 0,per_page=10, is_detailed=0) => {
  try {
  //   const params={
  //     category_id:-1,
  //     customer_id:-1,
  //     lat_lng:'24.7967878,46.6247059',
  //     per_page:per_page,
  //     offset:offset,
  //     is_detailed:is_detailed,
  //     is_booking_page:is_booking_page,
  //     q:new URLSearchParams({
  //       "search_phrase": searchInput,
  //       "cluster": "",
  //       "sort_type": "",
  //       "filter":new URLSearchParams( {
  //         "cash": "", 
  //         "card": "", 
  //         "price_min": "", 
  //         "price_max": "", 
  //         "distance_min": "", 
  //         "distance_max": ""
  //       }).toString(), 
  //     }).toString()
  //   }
  // const searchParams = new URLSearchParams(params).toString();

    const response = await Axios.get(
      `/supplier?category_id=-1&customer_id=-1&lat_lng=24.7967878,46.6247059&per_page=${per_page}&offset=${offset}&is_detailed=${is_detailed}&is_booking_page=${is_booking_page}&q={"search_phrase":"${searchInput}","cluster":"","filter":{"cash":"","card":"","price_min":"","price_max":"","distance_min":"","distance_max":""},"sort_type":""}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllPartners = async (params) => {
  try {
    const { offset, per_page } = params;
    const response = await Axios.get(`/supplier?per_page=${per_page}&offset=${offset}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const checkStaffTimeSlotAvaiability = async (params) => {
  try {
    const { staff_id, option_id, date , time } = params;
    const response = await Axios.get(`/supplier/timeslots_by_date_staff_service?staff_id=${staff_id}&option_id=${option_id}&date=${date}&time=${time}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getComplains = async () => {
  try {
    const response = await Axios.get("/care/complains");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveMedia = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_media", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateMediaCaption = async (data) => {
  try {
    const response = await Axios.post("/supplier/update_media_caption", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const saveSortedMedia = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_media_sort", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMedia = async (supplierId) => {
  try {
    const response = await Axios.get(`/supplier/${supplierId}/media`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getBookingTransactions = async (data) => {
  try {
    const response = await Axios.post("/report/booking_transactions", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPartnersList = async () => {
  try {
    const response = await Axios.get("/supplier/list");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveFeatureMedia = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_feature_image", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFeaturedSalonsList = async (params) => {
  try {
    const { per_page = 10, offset = 0, status_id = 1 } = params;
    const response = await Axios.get(`/supplier/list_featured?per_page=${per_page}&offset=${offset}&status_id=${status_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setFeaturedSalon = async (data) => {
  try {
    const response = await Axios.post("/supplier/set_supplier_as_featured", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const removeFeaturedSalon = async (data) => {
  try {
    const response = await Axios.post("/supplier/remove_supplier_as_featured", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getProfileCompletionStatus = async (supplier_id = null) => {
  try {
    if(!supplier_id) return
    const response = await Axios.get(`/supplier/profile_completion_status?supplier_id=${supplier_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
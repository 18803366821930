import React, { useState, useRef } from "react";
import { saveKeywords } from "../../core/Api/customer";
import { toast } from "react-toastify";
import UserInput from "../../components/Inputs";
import CheckBoxInput from "../../components/Inputs/CheckBox";
import { Button, Col, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";

const BlackListForm = (props) => {
  const { fetchKeywords, handleClose } = props;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [keywordDetails, setKeywordDetails] = useState(() => ({
    ...props?.selectedKeyWord,
  }));
  const [isLoading, setLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [status, setStatus] = useState(
    () => props?.selectedKeyWord?.status_id == "1"
  );

  const handleChange = (event) => {
    const value = event.target.value;
    console.log("value", value);
    setKeywordDetails({
      ...keywordDetails,
      word: value,
    });
  };

  const handleSaveOffer = async () => {
    toast.dismiss();
    setLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const result = await saveKeywords({
          blacklisted_word_id: keywordDetails.blacklisted_word_id,
          word: keywordDetails.word,
          is_active: status,
        });
        if (result.code == 200) {
          await fetchKeywords();
          handleClose();
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        forceUpdate(1);
        simpleValidator.current.showMessages();
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal.Body>
        <Col>
          <UserInput
            label="Word"
            name="keyword"
            placeholder="Enter word for blacklisting"
            value={keywordDetails.word}
            onChange={handleChange}
          />
          {simpleValidator.current.message(
            "word",
            keywordDetails.word,
            "required"
          )}
        </Col>
        <Col>
          <label>
            <CheckBoxInput
              checked={status}
              onChange={() => setStatus((prev) => !prev)}
            >
              Enable now?
            </CheckBoxInput>
          </label>
        </Col>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          disabled={isLoading}
          onClick={handleSaveOffer}
          className="save-btn"
        >
          Save changes
        </Button>
        <Button onClick={handleClose} variant="white">
          Close
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default BlackListForm;

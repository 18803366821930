import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, Overlay, Popover, PopoverContent } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import SearchInput from "../../../components/Inputs/Search";
import PrimaryButton from "../../../components/Buttons/Primary";

import { getAllCustomers } from "../../../core/Api/customer";
import menuIcon from "../../../assets/images/three-dots.svg";

const SelectClient = (props) => {
  const {
    history,
    selectedCustomer,
    setSelectedCustomer,
    closePopUp,
    getCustomers,
  } = props;
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [target, setTarget] = useState(null);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    getCustomerList();
  }, [getCustomers]);

  useEffect(() => {
    if (search.trim()) {
      debouncedGetCustomer.cancel();
      debouncedGetCustomer(search.trim());
    } else {
      setCustomerList([]);
    }
    // eslint-disable-next-line
  }, [search]);

  const getCustomerList = async (searchInput) => {
    const response = await getAllCustomers(searchInput);
    const customerList = response?.result?.data ?? [];
    setCustomerList(customerList);
  };

  const debouncedGetCustomer = useCallback(
    debounce((searchInput) => {
      getCustomerList(searchInput);
    }, 100),
    [props.customerId]
  );

  const openOverlay = () => {
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      openPopup: true,
    });
  };

  const renderSearchList = () => {
    if (selectedCustomer) {
      return (
        <div key={selectedCustomer.id} className="customer-details-wrapper">
          <Image
            src={selectedCustomer.profile_pic}
            data-holder-rendered="true"
          />
          <div className="customer-details">
            <p>{selectedCustomer.first_name}</p>
            <span>
              {selectedCustomer.email
                ? selectedCustomer.email
                : selectedCustomer.mobile}
            </span>
          </div>
        </div>
      );
    }
    return customerList.map((item) => {
      return (
        <div
          key={item.id}
          onClick={() => {
            closePopUp();
            setSelectedCustomer(item);
            setSearch("");
          }}
          className="customer-details-wrapper"
        >
          <Image src={item.profile_pic} data-holder-rendered="true" />
          <div className="customer-details">
            <p>{item.first_name}</p>
            <span>
              {item.email} {item.mobile}
            </span>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="select-panel">
      <div>
        <h2>Search Client</h2>
      </div>
      <div className="right-container">
        {!selectedCustomer && (
          <SearchInput
            placeholder={t("Search Client")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        {selectedCustomer && (
          <Image
            alt="menu-icon"
            className="three-dot-icon"
            onClick={(event) => setTarget(target ? null : event.target)}
            src={menuIcon}
          />
        )}
        <Overlay
          show={Boolean(target)}
          target={target}
          placement="bottom"
          container={menuRef.current}
        >
          <Popover bsPrefix="remove-selected-item">
            <PopoverContent
              onClick={() => {
                setSelectedCustomer("");
                setTarget(null);
              }}
            >
              {t("Remove Customer")}
            </PopoverContent>
          </Popover>
        </Overlay>
        <div
          className="bottom-sec"
          style={{ height: selectedCustomer ? "100%" : undefined }}
        >
          {customerList.length || selectedCustomer ? (
            <div className="search-list">{renderSearchList()}</div>
          ) : (
            <div className="empty-1">
              {Boolean(search.length) && Boolean(!customerList.length) && (
                <span className="px-3">{t(`${"No Clients Found"}`)}</span>
              )}
            </div>
          )}

          <PrimaryButton
            classNames="add-client"
            onClick={openOverlay}
            text={t("Add")}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectClient;

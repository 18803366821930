import React from "react";
import Hoc from "../Hoc";
import FeaturedSalonsList from "./FeaturedSalonsList";

const Advertisements = () => {
  return (
    <Hoc activeMenu="advertisements">
      <FeaturedSalonsList />
    </Hoc>
  );
};

export default Advertisements;

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Container, Col, Row, Image, Overlay, Popover, PopoverContent, PopoverTitle, Button } from "react-bootstrap";
import Hoc from "../Hoc";
import DashCard from "./DashCard";
import Card from "../../components/Card";
import CustomDropdown from "../../components/Dropdown";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import FlagIcon from "../../assets/images/flags.png";
import WaveIcon from "../../assets/images/line-chart.png";
import ReverseWaveIcon from "../../assets/images/line-chart-1.png";
import BoxIcon from "../../assets/images/order.png";
import CardIcon from "../../assets/images/credit-card-business-money-payment.png";
import TrophyIcon from "../../assets/images/trophy.png";
import { getAuthAttribute } from "../../utils/helper";
import "./Styles.scss";
import CalendarIcon from "../../assets/images/Group 94.svg";
import menuIcon from "../../assets/images/three-dots.svg";
import menuIconWhite from "../../assets/images/three-dots-white.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import {
	payoutSummaryDetail,
	servicesStatusSummary,
	getUpcommingAppointments,
	getServiceSummaryDetails,
	getMostBookedServices,
} from "../../core/Api/dashboard";
import {
	get3MonthsBookings,
	get3MonthsCompletedProfiles,
	get3MonthsRegistrations,
	get6MonthsBookings,
	get6MonthsCompletedProfiles,
	get6MonthsRegistrations,
	getAverageBookings,
	getAverageServicePrices,
} from "../../core/Api/Reports";
import GraphReports from "./graphReports";
import FullPageSpinner from "../../components/FullPageSpinner";

const Dashboard = () => {
	const { t } = useTranslation();
	const supplierName = getAuthAttribute("supplier_name");
	const supplierId = getAuthAttribute("supplier_id");
	const [statsData, setStatsData] = useState({
		total_payables: "0.00",
		total_earnings: "0.00",
		total_receivables: "0.00",
		last_payout: "0.00",
		total_payout: "0.00",
		payout_threshold: "0.00",
		target_reached: "0.00",
	});
	const defaultSummaryState = [0, 0, 0];
	const [serviceStatusSummary, setServiceStatusSummary] = useState(defaultSummaryState);
	const [upcommingAppointments, setUpcommingAppointments] = useState(null);
	const [filteredAppointments, setFilteredAppointments] = useState(null);
	const [todayAppointments, setTodayAppointments] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const [serviceSummary, setServiceSummary] = useState([]);
	const [mostBookedServices, setMostBookedServices] = useState([]);
	const [avgDailyDates, setAvgDailyDates] = useState({
		from_date: moment().format(),
		to_date: moment().format(),
	});
	const [avgWeeklyDates, setAvgWeeklyDates] = useState({
		from_date: moment().subtract(1, "week").format(),
		to_date: moment().format(),
	});
	const [graphReports, setGraphReports] = useState({
		avgDailyBookings: [],
		avgWeeklyBookings: [],
		avgPrices: [],
		last3MonthsBookings: [],
		last3MonthsReg: [],
		last6MonthsBookings: [],
		last6MonthsReg: [],
	});
	const [anchorEL, setAnchorEL] = useState(null);
	const options = [
		{
			label: t("Next 7 days"),
			value: 7,
		},
		{
			label: t("Next 30 days"),
			value: 30,
		},
	];
	const [selectedOption, setSelectedOption] = useState(options[0]);
	const initialValue = useRef(options[0]);
	const userTypeId = getAuthAttribute("user_type_id");
	const isUserAdmin = userTypeId == 4;

	useEffect(() => {
		if (upcommingAppointments) {
			const filteredAppointments = upcommingAppointments.filter((row) => {
				const today = moment();
				const appointment_date = moment(row.appointment_date);
				const rangeEnd = moment().add(selectedOption.value, "days");
				const include = appointment_date.isBetween(today, rangeEnd, "day", "[]");
				if (include) {
					return row;
				} else {
					return null;
				}
			});
			const todayAppointments = upcommingAppointments.filter((row) => {
				const today = moment();
				const appointment_date = moment(row.appointment_date);
				const rangeEnd = moment().add(1, "day");
				const include = appointment_date.isBetween(today, rangeEnd, "day", "[]");
				if (include) {
					return row;
				} else {
					return null;
				}
			});
			setFilteredAppointments(filteredAppointments);
			setTodayAppointments(todayAppointments);
		}
	}, [upcommingAppointments, selectedOption]);

	useEffect(() => {
		if (!isUserAdmin) {
			(async () => {
				const [stats, serviceStatus, upcommingAppointmentsDetails, serviceSummaryData] = await Promise.all([
					payoutSummaryDetail(supplierId),
					servicesStatusSummary(),
					getUpcommingAppointments(),
					getServiceSummaryDetails(supplierId),
				]);
				console.log(stats.result);
				if (stats && stats.code === 200) {
					setStatsData(stats.result);
				} else {
					toast.error(stats && stats.message);
				}
				if (serviceStatus && serviceStatus.code === 200) {
					setServiceStatusSummary([serviceStatus.result.completed, serviceStatus.result.pending, serviceStatus.result.cancelled]);
				} else {
					toast.error(serviceStatus && serviceStatus.message);
				}
				if (upcommingAppointmentsDetails && upcommingAppointmentsDetails.code === 200) {
					setUpcommingAppointments(upcommingAppointmentsDetails.result);
				} else {
					toast.error(upcommingAppointmentsDetails && upcommingAppointmentsDetails.message);
				}

				if (serviceSummaryData && serviceSummaryData.code === 200) {
					setServiceSummary(serviceSummaryData.result);
				} else {
					toast.error(serviceSummaryData && serviceSummaryData.message);
				}
			})();
		} else {
			fetchMostBookedServices();
		}
	}, [isUserAdmin, supplierId]);

	useEffect(() => {
		getReportsData();
	}, []);

	const getReportsData = async () => {
		const [avgDailyRes, avgWeeklyRes, avgServicePricesRes, book3Res, book6Res, reg3Res, reg6Res, completed3Months, completed6Months] =
			await Promise.all([
				getAvgDailyReportData(avgDailyDates?.from_date, avgDailyDates?.to_date),
				getAvgDailyReportData(avgWeeklyDates?.from_date, avgWeeklyDates?.to_date),
				getAverageServicePrices({
					from_date: avgDailyDates?.from_date,
					to_date: avgDailyDates?.to_date,
				}),
				get3MonthsBookings(),
				get6MonthsBookings(),
				get3MonthsRegistrations(),
				get6MonthsRegistrations(),
				// get3MonthsCompletedProfiles(),
				// get6MonthsCompletedProfiles()
			]);
		setGraphReports({
			avgDailyBookings: [
				{ name: "Average Daily Bookings", total_booking: avgDailyRes?.result?.result?.avg_daily_bookings },
				{ name: "Average Daily Bookings By Salon", total_booking: avgDailyRes?.result?.result?.avg_daily_bookings_by_salon },
				{ name: "Average Daily Bookings By Freelancer", total_booking: avgDailyRes?.result?.result?.avg_daily_bookings_by_freelancer },
			],
			avgWeeklyBookings: [
				{ name: "Average Weekly Bookings", total_booking: avgWeeklyRes?.result?.result?.avg_weekly_bookings },
				{ name: "Average Weekly Bookings By Salon", total_booking: avgWeeklyRes?.result?.result?.avg_weekly_bookings_by_salon },
				{ name: "Average Weekly Bookings By Freelancer", total_booking: avgWeeklyRes?.result?.result?.avg_weekly_bookings_by_freelancer },
			],
			avgPrices: [
				{ name: "Avg. price at Salon", total_booking: avgServicePricesRes?.result?.avg_service_price_all?.avg_price_at_salon },
				{ name: "Avg. price at Home", total_booking: avgServicePricesRes?.result?.avg_service_price_all?.avg_price_at_home },
				{ name: "Avg. Price at Salon by Salon", total_booking: avgServicePricesRes?.result?.avg_service_price_salon?.avg_price_at_salon },
				{ name: "Avg. Price at Salon by Freelancer", total_booking: avgServicePricesRes?.result?.avg_service_price_freelancer?.avg_price_at_salon },
				{ name: "Avg. Price at Home by Salon", total_booking: avgServicePricesRes?.result?.avg_service_price_salon?.avg_price_at_home },
				{
					name: "Avg. Price at Home by Freelancer Salon",
					total_booking: avgServicePricesRes?.result?.avg_service_price_freelancer?.avg_price_at_home,
				},
			],
			last3MonthsBookings: [
				{ name: "Last 3 months bookings", total_booking: book3Res?.result?.result?.total_bookings },
				{ name: "Last 3 months bookings by salon", total_booking: book3Res?.result?.result?.total_bookings_by_salon },
				{ name: "Last 3 months bookings by freelancer", total_booking: book3Res?.result?.result?.total_bookings_by_freelancer },
			],
			last6MonthsBookings: [
				{ name: "Last 3 months bookings", total_booking: book6Res?.result?.result?.total_bookings },
				{ name: "Last 3 months bookings by salon", total_booking: book6Res?.result?.result?.total_bookings_by_salon },
				{ name: "Last 3 months bookings by freelancer", total_booking: book6Res?.result?.result?.total_bookings_by_freelancer },
			],
			last3MonthsReg: [
				{ name: "Last 3 months registrations", total_booking: reg3Res?.result?.result?.total_registrations },
				{ name: "Last 3 months registrations by salon", total_booking: reg3Res?.result?.result?.total_registrations_salon },
				{ name: "Last 3 months registrations by freelancer", total_booking: reg3Res?.result?.result?.total_registrations_freelancer },
			],
			last6MonthsReg: [
				{ name: "Last 6 months registrations", total_booking: reg6Res?.result?.result?.total_registrations },
				{ name: "Last 6 months registrations by salon", total_booking: reg6Res?.result?.result?.total_registrations_salon },
				{ name: "Last 6 months registrations by freelancer", total_booking: reg6Res?.result?.result?.total_registrations_freelancer },
			],
			last3CompletedProfiles: [
				{ name: "Freelancers", total_booking: completed3Months?.result?.result?.total_profiles_freelancer || 0 },
				{ name: "Salons", total_booking: completed3Months?.result?.result?.total_profiles_salon || 0 },
			],
			last6CompletedProfiles: [
				{ name: "Freelancers", total_booking: completed6Months?.result?.result?.total_profiles_freelancer || 0 },
				{ name: "Salons", total_booking: completed6Months?.result?.result?.total_profiles_salon || 0 },
			],
		});
	};

	const getAvgDailyReportData = async (from, to) => {
		setIsloading(true);
		const result = await getAverageBookings({
			from_date: moment(from).format("YYYY-MM-DD"),
			to_date: moment(to).format("YYYY-MM-DD"),
		});
		setIsloading(false);
		return result;
	};

	const fetchMostBookedServices = async () => {
		try {
			const results = await getMostBookedServices({limit:10});
			if (results.code === 200) {
				const data = (results?.result || []).map((key) => ({
					name: key.name_en,
					total_booking: key.booked_count,
				}));
				setMostBookedServices(data);
			} else {
				throw new Error(results?.message ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
	};

	const handleClose = () => {
		setSelectedOption(initialValue.current);
		setAnchorEL(null);
	};

	const handleApply = () => {
		initialValue.current = selectedOption;
		setAnchorEL(null);
	};

	const renderPopoverContent = () => {
		return (
			<PopoverContent>
				<CustomDropdown value={selectedOption} options={options} onChange={(newValue) => setSelectedOption(newValue)} />
				<div className="button-wrapper">
					<Button className="close" onClick={handleClose}>
						{t("Close")}
					</Button>
					<Button className="apply" onClick={handleApply}>
						{t("Apply")}
					</Button>
				</div>
			</PopoverContent>
		);
	};

	const handleDateChange = async (type = "avgDaily", dates) => {
		const [start, end] = dates;
		if (type === "avgDaily") {
			setAvgDailyDates({
				from_date: start,
				to_date: end,
			});
			if (start && end) {
				const res = await getAvgDailyReportData(start, end);
				if (res?.code === 200) {
					setGraphReports({
						...graphReports,
						avgDailyBookings: [
							{ name: "Average Daily Bookings", total_booking: res?.result?.result?.avg_daily_bookings },
							{ name: "Average Daily Bookings By Salon", total_booking: res?.result?.result?.avg_daily_bookings_by_salon },
							{ name: "Average Daily Bookings By Freelancer", total_booking: res?.result?.result?.avg_daily_bookings_by_freelancer },
						],
					});
				}
			}
		} else if (type === "avgWeekly") {
			setAvgWeeklyDates({
				from_date: start,
				to_date: end,
			});
			if (start && end) {
				const res = await getAvgDailyReportData(start, end);
				if (res?.code === 200) {
					setGraphReports({
						...graphReports,
						avgWeeklyBookings: [
							{ name: "Average Weekly Bookings", total_booking: res?.result?.result?.avg_weekly_bookings },
							{ name: "Average Weekly Bookings By Salon", total_booking: res?.result?.result?.avg_weekly_bookings_by_salon },
							{ name: "Average Weekly Bookings By Freelancer", total_booking: res?.result?.result?.avg_weekly_bookings_by_freelancer },
						],
					});
				}
			}
		}
	};

	return (
		<Hoc activeMenu="backoffice_dashboard">
			{(isLoading && <FullPageSpinner />) || null}
			{userTypeId == 2 ? (
				<Container className="dashboard" fluid>
					<h2 className="page-name">
						{`${supplierName} `} | <small>{t("Control Panel")}</small>
					</h2>
					<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 950: 3, 1200: 6 }}>
						<Masonry gutter="10px">
							<div>
								<DashCard
									bgColor={"#FFCFA5"}
									iconBg={"#F6BC89"}
									countText={(statsData && statsData.target_reached) || 0}
									countSubText={`/ ${(statsData && statsData.payout_threshold) || 0}`}
									caption={"Payout target"}
									icon={FlagIcon}
								/>
							</div>
							<div>
								<DashCard icon={WaveIcon} iconBg={"#D4FFEB"} countText={(statsData && statsData.total_payout) || 0} caption={"Total payout"} />
							</div>
							<div>
								<DashCard icon={ReverseWaveIcon} iconBg={"#D4F2FF"} countText={(statsData && statsData.last_payout) || 0} caption={"Last payout"} />
							</div>
							<div>
								<DashCard icon={BoxIcon} iconBg={"#F9E6FF"} countText={(statsData && statsData.total_receivables) || 0} caption={"Receivables"} />
							</div>
							<div>
								<DashCard icon={CardIcon} iconBg={"#DFE8FF"} countText={(statsData && statsData.total_payables) || 0} caption={"Payable"} />
							</div>
							<div>
								<DashCard
									dark
									icon={TrophyIcon}
									bgColor={"#0B586D"}
									iconBg={"#2D6B7D"}
									countText={(statsData && statsData.total_earnings) || 0}
									caption={"Total earnings"}
								/>
							</div>
						</Masonry>
					</ResponsiveMasonry>
					<Row className="middle-block">
						<Col className={clsx("appointment", todayAppointments?.length === 0 && "no-upcoming-appointments")} md="4">
							<Card>
								<div className="upocoming-appointments-header">
									<h3>{t("Today Appointment")}</h3>
								</div>
								{todayAppointments?.length ? (
									<ul>
										{todayAppointments.map((row, index) => (
											<li key={index}>
												<Row className="top-deck">
													<Col className="left">{row.service_name}</Col>
													<Col className="right">{moment(row.appointment_date).format("D MMM")}</Col>
												</Row>
												<Row className="bottom-deck">
													<Col className="left">{`Walk-In, ${row.duration} with ${row.staff_name}`}</Col>
													<Col className="right">SAR {row.amount}</Col>
												</Row>
											</li>
										))}
									</ul>
								) : (
									<div className="no-data-container">
										<h5>{t("No Appointments for today.")}</h5>
										<Image alt="calendar-icon" src={CalendarIcon} />
									</div>
								)}
							</Card>
						</Col>
						<Col className={clsx("appointment", filteredAppointments?.length === 0 && "no-upcoming-appointments")} md="4">
							<Card>
								<div className="upocoming-appointments-header">
									<h3>{t("Upcoming Appointment")}</h3>
									{filteredAppointments && (
										<>
											<Image alt="menuIcon" src={filteredAppointments.length ? menuIcon : menuIconWhite} onClick={(e) => setAnchorEL(e.target)} />
											<Overlay show={Boolean(anchorEL)} target={anchorEL} placement="bottom-end">
												<Popover bsPrefix="upocoming-appointment-filter">
													<PopoverTitle>{t("Time Period")}</PopoverTitle>
													{renderPopoverContent()}
												</Popover>
											</Overlay>
										</>
									)}
								</div>
								{filteredAppointments?.length ? (
									<ul>
										{filteredAppointments.map((row, index) => (
											<li key={index}>
												<Row className="top-deck">
													<Col className="left">{row.service_name}</Col>
													<Col className="right">{moment(row.appointment_date).format("D MMM")}</Col>
												</Row>
												<Row className="bottom-deck">
													<Col className="left">{`Walk-In, ${row.duration} with ${row.staff_name}`}</Col>
													<Col className="right">SAR {row.amount}</Col>
												</Row>
											</li>
										))}
									</ul>
								) : (
									<div className="no-data-container">
										<h5>{t("No upcoming appointments")}</h5>
										<Image alt="calendar-icon" src={CalendarIcon} />
									</div>
								)}
							</Card>
						</Col>
						<Col
							className={clsx(
								"service-summary",
								serviceStatusSummary[0] === 0 && serviceStatusSummary[1] === 0 && serviceStatusSummary[2] === 0,
								"no-upcoming-appointments"
							)}
							md="4"
						>
							<Card>
								<h3>{t("Booking Services Summary")}</h3>
								{serviceStatusSummary[0] === 0 && serviceStatusSummary[1] === 0 && serviceStatusSummary[2] === 0 ? (
									<div className="no-data-container">
										<h5>{t("No summary ready, yet")}</h5>
										<Image alt="calendar-icon" src={CalendarIcon} />
									</div>
								) : (
									<div className="chart">
										<PieChart dataValue={serviceStatusSummary} />
									</div>
								)}
							</Card>
						</Col>
					</Row>
					<Row className="bottom-block">
						<Col lg={12} className="bar-chart">
							<Card>
								<h3>{t("Sales Analytics")}</h3>
								<div>
									<BarChart dataValue={serviceSummary} />
								</div>
							</Card>
						</Col>
						{/* <GraphReports
        graphReports={graphReports}
        handleDateChange={handleDateChange}
        avgDailyDates={avgDailyDates}
        avgWeeklyDates={avgWeeklyDates}
      /> */}
					</Row>
				</Container>
			) : (
				<>
					{((userTypeId == 3 || userTypeId == 4 || userTypeId == 8) && (
						<Container className="dashboard" fluid>
							<Row className="bottom-block">
								<Col lg={12} className="bar-chart">
									<Card>
										<h3>{t("Most Used Services")}</h3>
										<div>
											<BarChart dataValue={isUserAdmin ? mostBookedServices : serviceSummary} />
										</div>
									</Card>
								</Col>
								<GraphReports
									graphReports={graphReports}
									handleDateChange={handleDateChange}
									avgDailyDates={avgDailyDates}
									avgWeeklyDates={avgWeeklyDates}
								/>
							</Row>
						</Container>
					)) || <>Welcome </>}
				</>
			)}
		</Hoc>
	);
};
export default Dashboard;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/Primary';
import CustomDropdown from '../../../components/Dropdown';
import FullPageSpinner from '../../../components/FullPageSpinner';
import Hoc from '../../Hoc';
import './Styles.scss';
import PartnersGraph from './partnersGraph';
import {
	getSalesReport,
} from '../../../core/Api/Reports';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import moment from 'moment';
import { gteYearOptionsList } from '../../../utils/helper';

const SalesReport = () => {
	const yearOptions = gteYearOptionsList() ?? [];

	const { t } = useTranslation();
	const [data, setData] = useState({
		byDeviceTransactions: [],
		weeklyTransactions: {},
		monthlyTransactions: {},
	});
	const [filterSchema, setFilterSchema] = useState({
		year:  moment().format('yyyy'),
		quarter: null,
	});
	const [isLoading, setLoading] = useState(true);

	const quarterOptions = [
		{ label: t('Please Select'), value: null },
		{ label: t('Q1'), value: 'Q1' },
		{ label: t('Q2'), value: 'Q2' },
		{ label: t('Q3'), value: 'Q3' },
		{ label: t('Q4'), value: 'Q4' },
	];

	useEffect(() => {
		fetchBookings(filterSchema);
	}, []);

	const fetchBookings = async (filters) => {
		setLoading(true);
		const salesRes = await getSalesReport({
			year: filters?.year,
			quarter: filterSchema?.quarter,
		});

		if (salesRes.code == 200) {
			setData({
				totalCompletedThisMonth: salesRes?.result?.total_completed_this_month,
				totalConfirmedThisMonth: salesRes?.result?.total_confirmed_this_month,
				totalCancelledThisMonth: salesRes?.result?.total_cancelled_this_month,
				totalCompletedThisYear: salesRes?.result?.total_completed_this_year,
				totalConfirmedThisYear: salesRes?.result?.total_confirmed_this_year,
				totalCancelledThisYear: salesRes?.result?.total_cancelled_this_year,
				byDeviceTransactions: salesRes?.result?.by_device ?? [],
				weeklyTransactions: {
					// labels: _.map(salesRes?.result?.weekly ?? [], (r) => r.week),
					labels: _.map(salesRes?.result?.weekly ?? [], (r) => {

						const weekOfYear = r?.week?.split('-')[1];
						const year = r?.week?.split('-')[0];

						// Get the first day of the week based on the week number and year
						const firstDayOfWeek = moment().year(year).isoWeek(weekOfYear).startOf('isoweek');
						// Get the month name
						const monthName = firstDayOfWeek.format('MMM');
						// Calculate the week of the month
						const weekOfMonth = Math.ceil(firstDayOfWeek.date() / 7);

						return `${weekOfMonth}W-${monthName}`

					}),
					completedTrans: _.map(
						salesRes?.result?.weekly ?? [],
						(r) => r.Completed ?? 0
					),
					confirmedTrans: _.map(
						salesRes?.result?.weekly ?? [],
						(r) => r.Confirmed ?? 0
					),
					cancelledTrans: _.map(
						salesRes?.result?.weekly ?? [],
						(r) => r.Cancelled ?? 0
					),
				},
				monthlyTransactions: {
					labels: _.map(salesRes?.result?.monthly ?? [], (r) => r.month),
					completedTrans: _.map(
						salesRes?.result?.monthly ?? [],
						(r) => r.Completed ?? 0
					),
					confirmedTrans: _.map(
						salesRes?.result?.monthly ?? [],
						(r) => r.Confirmed ?? 0
					),
					cancelledTrans: _.map(
						salesRes?.result?.monthly ?? [],
						(r) => r.Cancelled ?? 0
					),
				},
			});
		} else {
			toast.error(salesRes && salesRes.message);
		}

		setLoading(false);
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleSearch = async () => {
		fetchBookings({ ...filterSchema });
	};

	const getMonthName = (params) =>{
		if(params){
			const values = params?.split('-');
			const mName = (values?.length && values?.length > 1 && values[0] ) || '';
			switch(mName){
				case 'January':
					return 'Jan';
				case 'February':
					return 'Feb';
				case 'March':
					return 'Mar';
				case 'April':
					return 'Apr';
				case 'May':
					return 'May';
				case 'June':
					return 'June';
				case 'July':
					return 'July';
				case 'August':
					return 'Aug';
				case 'September':
					return 'Sep';
				case 'October':
					return 'Oct';
				case 'November':
					return 'Nov';
				case 'December':
					return 'Dec';
				default:
					return mName;
			}
		}else{
			return ''
		}

	}
	return (
		// <Hoc activeMenu="salesReport">
			<div className="salesReport">
				<div className="table-wrapper pr-3">
					{isLoading && <FullPageSpinner />}
					<Row className="filtersCont">
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'year')}
								name="year"
								value={
									yearOptions?.find((r) => r.value == filterSchema?.year) ||
									null
								}
								options={yearOptions}
								label={t('Select Year')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'quarter')}
								name="quarter"
								value={
									quarterOptions?.find(
										(r) => r.value == filterSchema?.quarter
									) || null
								}
								options={quarterOptions}
								label={t('Select Quarter')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<PrimaryButton
								disabled={isLoading}
								onClick={handleSearch}
								text={t('Search')}
							/>
						</Col>
					</Row>

					<Container className="mainContent" fluid>
						<Row className="topRowCont">
							<Col lg={3} md={6} sm={12} className="headingCont">
								<div className="box-content">
									<h4 className="text">
										{t('Sales')} {filterSchema?.year} {filterSchema?.quarter || ''}
									</h4>
									<p className="text">{t('All Bookings Weekly')}</p>
								</div>
							</Col>
							<Col lg={3} md={6} sm={12} className="box-cont">
								<div className="box-content box1">
									<p className="num">{data?.totalCompletedThisMonth} </p>
									<p className="text">{t('Total Completed Bookings this Month')}</p>
								</div>
							</Col>
							<Col lg={3} md={6} sm={12} className="box-cont">
								<div className="box-content box2">
									<p className="num">{data?.totalConfirmedThisMonth} </p>
									<p className="text">{t('Total Confirmed Bookings this Month')}</p>
								</div>
							</Col>
							<Col lg={3} md={6} sm={12} className="box-cont">
								<div className="box-content box3">
									<p className="num">{data?.totalCancelledThisMonth} </p>
									<p className="text">{t('Total Cancelled Bookings this Month')}</p>
								</div>
							</Col>
						</Row>

						<Row className="graphsCont">
							<Col lg={12} md={12} sm={12}>
								<div className="multiGraphContent">
									<PartnersGraph graphsData={data?.weeklyTransactions ?? {}} />
								</div>
							</Col>
						</Row>
					</Container>

					<Container className="mainContent mt-5" fluid>
						<Row className="topRowCont">
							<Col lg={3} md={6} sm={12} className="headingCont">
								<div className="box-content">
									<h4 className="text">
										{t('Sales')} {filterSchema?.year} {filterSchema?.quarter || ''}
									</h4>
									<p className="text">{t('All Bookings Monthly')}</p>
								</div>
							</Col>
							<Col lg={3} md={6} sm={12} className="box-cont">
								<div className="box-content box1">
									<p className="num">{data?.totalCompletedThisYear} </p>
									<p className="text">{t('Total Completed Bookings this Year')}</p>
								</div>
							</Col>
							<Col lg={3} md={6} sm={12} className="box-cont">
								<div className="box-content box2">
									<p className="num">{data?.totalConfirmedThisYear} </p>
									<p className="text">{t('Total Confirmed Bookings this Year')}</p>
								</div>
							</Col>
							<Col lg={3} md={6} sm={12} className="box-cont">
								<div className="box-content box3">
									<p className="num">{data?.totalCancelledThisYear} </p>
									<p className="text">{t('Total Cancelled Bookings this Year')}</p>
								</div>
							</Col>
						</Row>

						<Row className="graphsCont">
							<Col lg={12} md={12} sm={12}>
								<div className="multiGraphContent">
									<PartnersGraph graphsData={data?.monthlyTransactions ?? {}} />
								</div>
							</Col>
						</Row>
					</Container>

					<Container className="mainContent mt-5" fluid>
						<div className="and-ios-data-cont">
							<div className="headings-cont">
								<div className="headings-content">
									<p>Android</p>
									<p>IOS</p>
								</div>
							</div>

							<div className="data-cont">
								<div className="data-content">
									{(data?.byDeviceTransactions ?? []).map((d, i) => (
										<div className="data-item-cont">
											<div className="left-cont">
												<p>{getMonthName(d?.device) ?? ''}</p>
											</div>
											<div className="right-cont">
												<p>{d['Customer/Android'] ?? 0}
												</p>
												<p>{d['Customer/iOS'] ?? 0}</p>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</Container>
				</div>
			</div>
		// </Hoc>
	);
};

export default SalesReport;




import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';

const PieChart = ({ graphsData = {} }) => {
	const { confirmedTransactions = 0, completedTransactions = 0 } = graphsData;
	const [data, setData] = useState({});
	useEffect(() => {
		setData({
			labels: ['Confirmed', 'Completed'],
			datasets: [
				{
					label: 'Transactions',
					data: [confirmedTransactions, completedTransactions],
					backgroundColor: ['#90D151', '#008A3D'],
					borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
					borderWidth: 1,
				},
			],
		});
	}, [graphsData]);

	return (
		<>
			{!_.isEmpty(data) && (
				<Pie
					data={data}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						elements: {
							bar: {
								borderWidth: 2,
							},
						},
						plugins: {
							legend: {
								display: true,
								position: 'bottom',
							},
							datalabels: {
								display: true, // Enable or disable data labels for all datasets
								anchor: 'auto', // Position of the label relative to the data point (center, start, end, auto)
								align: 'center', // Text alignment (center, start, end, left, right)
								color: 'white', // Label text color
								font: {
									weight: 'bold', // Label font weight
									size: '10px',
								},
								formatter: (value, context) => {
									return value;
								},
							},
						},
					}}
					plugins={[ChartDataLabels]}
				/>
			)}
		</>
	);
};

export default PieChart;

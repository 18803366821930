import React from "react";
import { Container, Table } from "react-bootstrap";
import "./Styles.scss";
import { useTranslation } from "react-i18next";

const TableData = ({
  data = [],
  pageNo = 1,
  pageSize = 10,
  statusFormatter,
  isMasterEdit = false,
  formatter
}) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="booking-table">
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>{t("Category")}</th>
            <th>{t("Service Name (ar)")}</th>
            <th>{t("Service Name (en)")}</th>
            <th>{t("New Service")}</th>
            <th>{t("Status")}</th>
            {isMasterEdit && <td>{t('Action')}</td>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{((pageNo - 1) * pageSize) + (index + 1)}</td>
              <td>{row?.category}</td>
              <td>{row?.name_ar}</td>
              <td>{row?.name_en}</td>
              <td>{row?.new_service_en}</td>
              <td>{statusFormatter(row?.status_id)}</td>
              {isMasterEdit && <td>{formatter(row)}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
export default TableData;

import Axios from "../Axios";

export const getSellers = async (params = {}) => {

  try {
    const { per_page = 10, offset = 0, search_phrase = '', status_id = 0 } = params;
    const response = await Axios.get(
      `/seller/index?per_page=${per_page}&offset=${offset}&search_phrase=${search_phrase}&status_id=${status_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSellerById = async (id) => {
  try {
    const response = await Axios.get(
      `/seller/index/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveSeller = async (data) => {
  try {
    const response = await Axios.post("/seller/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const activateSeller = async (data) => {
  try {
    const response = await Axios.post("/seller/activate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deactivateSeller = async (data) => {
  try {
    const response = await Axios.post("/seller/deactivate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteSeller = async (data) => {
  try {
    const response = await Axios.post("/seller/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getVouchers = async (params = {}) => {
  try {
    const { per_page = 10, offset = 0, search_phrase = '', seller_id = 0, status_id = 0 } = params;
    const response = await Axios.get(
      `/voucher/index?per_page=${per_page}&offset=${offset}&search_phrase=${search_phrase}&seller_id=${seller_id}&status_id=${status_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getVoucherById = async (id) => {
  try {
    const response = await Axios.get(
      `/voucher/index/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveVoucher = async (data) => {
  try {
    const response = await Axios.post("/voucher/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const activateVoucher = async (data) => {
  try {
    const response = await Axios.post("/voucher/activate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deactivateVoucher = async (data) => {
  try {
    const response = await Axios.post("/voucher/deactivate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteVoucher = async (data) => {
  try {
    const response = await Axios.post("/voucher/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const addVoucherPurchase = async (data) => {
  try {
    const response = await Axios.post("/voucher/add_purchase", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const redeemVoucher = async (data) => {
  try {
    const response = await Axios.post("/voucher/redeem", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getVoucherPurchasesByVoucherId = async (params) => {
  try {
    const { id = 0, per_page = 10, offset = 0 } = params;
    const response = await Axios.get(
      `/voucher/purchases/${id}?per_page${per_page}&&offset=${offset}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllVoucherSales = async (params) => {
  try {
    const { id = 0, per_page = 10, offset = 0, seller_id = 0, customer_id = 0, voucher_id = 0, v_sale_id = 0, status_id = 0 } = params;
    const response = await Axios.get(
      `/voucher/sales/${id}?per_page${per_page}&&offset=${offset}&status_id=${status_id}&seller_id=${seller_id}&customer_id=${customer_id}&voucher_id=${voucher_id}&v_sale_id=${v_sale_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
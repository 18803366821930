import "./Styles.scss";
import PrimaryButton from "../../components/Buttons/Primary";

const UnAuthorized = (props) => {
	const handleLogout = () => {
		localStorage.removeItem("boon-react-auth-result");
		localStorage.removeItem("permissions");
		props?.history.push({
			pathname: "/login",
		});
	};

	return (
		<div className="access-denied">
			<div className="access-denied__content">
				<div className="access-denied__icon">
					<span role="img" aria-label="lock">
						🔒
					</span>
				</div>
				<h1 className="access-denied__title">Access Denied</h1>
				<p className="access-denied__message">You don't have permissions to access this page.</p>
				<p className="access-denied__message">Contact an administrator to get permissions or go to the home page and browse other pages.</p>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<PrimaryButton
						className="access-denied__button"
						text="Go to home"
						onClick={() =>
							props?.history.push({
								pathname: "/",
							})
						}
						style={{ marginTop: 30 }}
					/>
					<PrimaryButton className="access-denied__button" text="Logout" onClick={() => handleLogout()} style={{ marginTop: 10 }} />
				</div>
			</div>
		</div>
	);
};

export default UnAuthorized;

import React, { useRef, useState } from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import DateInput from "../../../components/DateInput";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import CustomTextArea from "../../../components/Textarea";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { getDateOptions } from "../../../utils/helper";
import { saveUnavailability } from "../../../core/Api/supplier";
import moment from "moment";
import "./Styles.scss";
const defaultData = {
  supplier_unavailability_id: 0,
  supplier_id: "",
  start_time: null,
  end_time: null,
  all_day: 0,
  is_busy: 0,
  description: "",
  recurring_type: "weekly",
  recurring_days: "['monday']",
  is_active: 1,
  supplier_staff_id: "",
};

const BlockSlot = ({blockSlotData, handleClose}) => {
  const {selectedStaffId, startDate, endDate, supplier_id } = blockSlotData;
  const { t } = useTranslation();
  const [, forceUpdate] = useState();
  const dateOptions = [...getDateOptions()];
  const startdate = startDate ?? moment()._d;
  const [data, setData] = useState({
     ...defaultData,
     start_date: startdate,
     start_time:moment(startDate).format('hh:mm A'),
     end_time:moment(endDate).format('hh:mm A'),
     });
  const startDateRef = useRef(startdate);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        start_date: {
          message: t("Start date cannot be older than today"),
          rule: (startDate) => {
            if (!startDate) {
              return false;
            }
            return moment(startDate).isSameOrAfter(new Date(), "day");
          },
          required: true,
        },
        end_date: {
          message: t("End date must be greater than Start date"),
          rule: (endDate) => {
            if (!endDate || !startDateRef.current) {
              return false;
            }
            return moment(endDate).isAfter(moment(startDateRef.current));
          },
          required: true,
        },
      },
    })
  );

  const handleTimeChange = (value, name) => {
    if (name == "start_date") {
      startDateRef.current = value;
    }
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const payload = {
          supplier_unavailability_id: data.supplier_unavailability_id ?? 0,
          supplier_id: supplier_id,
          start_date: moment(data.start_date ?? startdate).format("YYYY-MM-DD"),
          end_date: moment(data.start_date ?? startdate).format("YYYY-MM-DD"),
          start_time: moment(data.start_time, "h:mm a").format("HH:mm"),
          end_time: moment(data.end_time, "h:mm a").format("HH:mm"),
          all_day: data.all_day,
          is_busy: data.is_busy,
          description: data.description,
          recurring_type: data.recurring_type,
          recurring_days: data.recurring_days,
          is_active: data.is_active,
          supplier_staff_id: selectedStaffId,
        };
        const result = await saveUnavailability(payload);
        if (result.code == 200) {
          toast.success("Saved Successfully");
          handleClose();
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };
  simpleValidator.current.purgeFields();

  return (
    <Container className="Block-Slot-form">
      <Modal.Body>
        <Row className="mt-4">
          <Col>
            <DateInput
              required
              label={t("Date")}
              value={data.start_date ? new Date(data.start_date) : startdate}
              onChange={(date) => {
                handleTimeChange(date, "start_date");
              }}
            />
            {simpleValidator.current.message(
              "start_date",
              data.start_date,
              "required|start_date"
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="6">
            <SearchableDropdown
              onChange={(value) => handleTimeChange(value.value, "start_time")}
              label={t("Start Time")}
              placeholder={t("Select Time")}
              name="start_time"
              options={dateOptions}
              value={dateOptions.find(
                (value) => value.value == data.start_time
              )}
            />
            {simpleValidator.current.message(
              "start_time",
              data.start_time,
              "required"
            )}
          </Col>
          <Col md="6">
            <SearchableDropdown
              required
              onChange={(value) => handleTimeChange(value.value, "end_time")}
              placeholder={t("Select Time")}
              label={t("End Time")}
              name="end_time"
              options={dateOptions}
              value={dateOptions.find((value) => value.value == data.end_time)}
            />
            {simpleValidator.current.message(
              "end_time",
              data.end_time,
              "required"
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="notes">
            <CustomTextArea
              value={data.description || blockSlotData.description}
              onChange={(event) =>
                handleTimeChange(event.target.value, "description")
              }
              label={t("Description (Optional)")}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="footer">
        <Button onClick={handleSave} className="save-btn">
          Save changes
        </Button>
        <Button onClick={handleClose} variant="white">
          Close
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default BlockSlot;

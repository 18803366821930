
import { Table } from 'react-bootstrap';
import './Styles.scss';

const CustomTableComponent = ({
  responsive = true,
  striped = false,
  bordered = false,
  hover = false,
  size = "sm",
  showSequence = false,
  pageSize = 0,
  pageNo = 0,
  data = [],
  columns = [{
    name: '',
    key: '',
    formatter: null,
  }],
  styles = {},
  onRowClick
}) => {

  const handleRowClick = (row, index) => {
    if (onRowClick) {
      onRowClick(row, index)
    } else {
      return false
    }
  }

  return (
    <div
      className='table-component'
      style={{ ...styles }}
    >
      <Table responsive={responsive} striped={striped} bordered={bordered} hover={hover} size={size} >
        <thead>
          <tr>
            {showSequence && <th>#</th>}
            {columns?.map((head, i) => (
              <th key={i}>{head?.name || ''}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, i) => (
            <tr 
            key={i} 
            onClick={() => handleRowClick(row, i)}
            className={`${onRowClick && 'cursor-pointer'}`}
             >
              {showSequence && <td>{((pageNo - 1) * pageSize) + (i + 1)}</td>}
              {columns?.map((col, colIndex) => (
                <td key={colIndex}>{col?.formatter ? col?.formatter(row, col?.key) : (row[col?.key] ?? 'N/A')}</td>
              ))}
            </tr>
          )
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default CustomTableComponent;
import moment from 'moment';
import { downloadExcel } from 'react-export-table-to-excel';

const formatDate = (format, date) => {
	const dateTemp = moment(date).format(format);
	const day = moment(date).format('ddd');
	return day + ', ' + dateTemp;
};
export { formatDate };

export const getAuthAttribute = (attributeName) => {
	const authResult = localStorage.getItem('boon-react-auth-result')
		? JSON.parse(localStorage.getItem('boon-react-auth-result'))
		: {};
	return authResult[attributeName] ?? '';
};

export const getPermissions = () => {
	const authResult = localStorage.getItem('boon-react-user-permissions')
		? JSON.parse(localStorage.getItem('boon-react-user-permissions'))
		: {};
		console.log("toRender ====> 0 ", authResult);
	return authResult?.permissions ?? [];
};

export const checkActionPermission = (key, type) => {
	let permissions = localStorage.getItem("boon-react-user-permissions") ? JSON.parse(localStorage.getItem("boon-react-user-permissions")) : {};
	permissions = permissions?.permissions ?? [];
	const currPermission = permissions?.find((permission) => permission?.key === key);
	console.log('checkActionPermission ===>', currPermission);
	if (currPermission) {
		switch (type) {
			case "canAdd":
			case "canEdit":
				return (currPermission["is_edit_allowed"] == 1 && true) || false;
			case "canDelete":
				return (currPermission["is_delete_allowed"] == 1 && true) || false;
			case "canView":
				return (currPermission["is_view_allowed"] == 1 && true) || false;
			default:
				return false
		}
	}else{
		return false
	}
};

export const formatAMPM = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

export const getDateOptions = () => {
	const x = 5;
	const times = [];
	let tt = 0;
	const ap = ['AM', 'PM'];

	for (let i = 1; tt < 24 * 60; i++) {
		const hh = Math.floor(tt / 60);
		const mm = tt % 60;
		const value =
			(hh % 12 == 0 ? '12' : '0' + (hh % 12)).slice(-2) +
			':' +
			('0' + mm).slice(-2) +
			' ' +
			ap[Math.floor(hh / 12)];
		times[i - 1] = { label: value, value };
		tt = tt + x;
	}
	times.push({
		label: '11:59 PM',
		value: '11:59 PM',
	});
	return times;
};

export const setAuthAttribute = (attributeName, newValue) => {
	const authResult = localStorage.getItem('boon-react-auth-result')
		? JSON.parse(localStorage.getItem('boon-react-auth-result'))
		: {};
	authResult[attributeName] = newValue;
	localStorage.setItem('boon-react-auth-result', JSON.stringify(authResult));
};

export const getDuration = (durationArray) => {
	console.log('durationArray', durationArray);
	let durationCount = 0;
	durationArray.forEach(
		(da) => (durationCount = durationCount + (!Number.isNaN(+da) ? +da : 0))
	);
	if (durationCount < 60) {
		return durationCount + ' mins';
	} else {
		let hours = durationCount / 60;
		return hours.toFixed(2) + ' h';
	}
};

export const getAmount = (serviceArray, isTotal = false) => {
	let amount = 0;
	if (isTotal) {
		serviceArray?.forEach(
			(serv) =>
				(amount =
					amount +
					(serv?.serviceData?.amount ? Number(serv?.serviceData?.amount) : 0))
		);
	} else {
		serviceArray?.forEach(
			(serv) =>
				(amount =
					amount + (serv?.original_amount ? Number(serv?.original_amount) : 0))
		);
	}
	return amount;
};

export const exportDataToExcel = async (params) => {
	try {
		const { data = [], header = [], fileName } = params;
		await downloadExcel({
			fileName,
			// sheet: ,
			tablePayload: {
				header,
				body: data,
			},
		});
		return true;
	} catch (error) {
		return false;
	}
};

export const gteYearOptionsList = (year = 2021) => {
	const yearOptions = [];
	for (let i = year; i <= moment().format('yyyy'); i++) {
		yearOptions.push({ label: i, value: i });
	}
	return yearOptions;
};

import React from "react";
import { Form, InputGroup, FormControl, Image } from "react-bootstrap";
import AssetsImg from "images-col";
import "./Styles.scss";
const UserInput = (props) => {
  const { label, placeholder, classNames } = props;
  return (
    <div className={`${classNames} custom-input-search`}>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <InputGroup.Text>
          <Image src={AssetsImg.ic_ionic_md_search} />
        </InputGroup.Text>
        <FormControl placeholder={placeholder} {...props} />
      </InputGroup>
    </div>
  );
};

UserInput.propTypes = {};

export default UserInput;

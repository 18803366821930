
import Axios from "../Axios";



export const getAllAdvertisements = async (params={}) => {
  try {
    const { status_id = 1 , per_page=10, offset=0} = params;
    const response = await Axios.get(
      `/settings/advert?status_id=${status_id}&per_page=${per_page}&offset=${offset}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAdvertById = async (id) => {
  try {
    const response = await Axios.get(
      `/settings/advert${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteAdvert = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/delete_advert",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveAdvert = async (data) => {
  try {
    const response = await Axios.post(
      "/settings/save_advert",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
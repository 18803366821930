import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./Styles.scss";
const CustomTextArea = (props) => {
  const {
    label,
    value,
    onChange,
    type,
    onBlur,
    disabled,
    required,
    placeholder,
  } = props;
  const [charaterLength, setCharaterLength] = useState(0);
  return (
    <div className="custom-textarea">
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
          <span className="count">{charaterLength}/1000 </span>
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          placeholder={placeholder && `${placeholder}`}
          as="textarea"
          disabled={disabled}
          rows={3}
          type={type}
          value={value}
          onChange={(e) => {
            setCharaterLength(e.target.value.length);
            if (e.target.value.length < 1000) {
              onChange(e);
            }
          }}
          onBlur={onBlur}
        />
      </InputGroup>
    </div>
  );
};

CustomTextArea.propTypes = {};

export default CustomTextArea;

import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { getUserPermissions, isSupplierMobilePresent, validateToken } from "../core/Api/Auth";
import { getAuthAttribute, getPermissions } from "../utils/helper";
import BoonContext from "../context";
import Constants from "../constants/index";
import _ from "lodash";
import PartnerMobileNumber from "../components/PartnerMobileNumber";
import moment from "moment";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
	const history = useHistory();
	const location = useLocation();
	const token = getAuthAttribute("auth_token");
	const userPermissions = getPermissions() ?? [];
	const [showPartnerMobileModal, setShowPartnerMobileModal] = useState(false);

	useEffect(() => {
		(async () => {
			const token = getAuthAttribute("auth_token");
			if (token) {
				const payload = {
					token: token,
				};
				const result = await validateToken(payload);
				if (result && result.code === 200) {
					if (result.result && result.result.is_valid) {
						const userTypeId = getAuthAttribute("user_type_id");
						if (userTypeId == 2) {
							const response = await isSupplierMobilePresent();
							if (response && response.code === 200) {
								console.log("isSupplierMobilePresent ===>", response);
								if (!response?.result?.is_present) {
									let savedTimeStamp = localStorage.getItem("partner_mobile_remind_later");
									if (savedTimeStamp) {
										savedTimeStamp = JSON.parse(savedTimeStamp)?.timeStamp ?? null;
										console.log("isSupplierMobilePresent savedTimeStamp ===>", savedTimeStamp);
										const hoursDifference = moment().diff(moment(savedTimeStamp, "MM DD YYYY HH:mm"), "hours");
										console.log("isSupplierMobilePresent savedTimeStamp ===> hoursDifference ", hoursDifference);
										if (hoursDifference >= 1) {
											setShowPartnerMobileModal(true);
										}
									} else {
										setShowPartnerMobileModal(true);
									}
								} else {
									console.log("isSupplierMobilePresent ===>", response?.result?.is_present);
								}
							}
						}
					} else {
						localStorage.removeItem("boon-react-auth-result");
						window.open("/login", "_self");
					}
				} else {
					localStorage.removeItem("boon-react-auth-result");
					window.open("/login", "_self");
					toast.error((result && result.message) || "Something wrong");
				}
			} else {
				if (location.pathname === "/signup" || location.pathname.indexOf("reset_password") >= 0) {
					history.push(location.pathname);
				} else {
					// history.push("/login");
					window.open("/login", "_self");
				}
			}
		})();
	}, [location.pathname, history]);

	const toRender = (props, pathname) => {
		const menuItems = _.flatMap(_.cloneDeep(Constants?.menuItems ?? []), (item) => {
			return [item, ...(item.options ?? [])];
		});

		console.log("toRender ====> 1", pathname, "userPermissions =>", userPermissions, "menuItems => ", menuItems);

		let currMenuItem = menuItems?.find((menuItem) => menuItem?.link === pathname);
		if (!currMenuItem) {
			currMenuItem = Constants?.subPages?.find((sp) => pathname?.startsWith(sp?.link));
		}

		console.log("toRender ====> 1.1", pathname, "currMenuItem => ", currMenuItem);

		if (currMenuItem) {
			const currPermission = userPermissions?.find((permission) => permission?.key === currMenuItem?.id);
			console.log("toRender ====> 2", pathname, currPermission);

			if (currPermission?.show_in_sidebar != "1" && !currMenuItem?.subPage) {
				console.log("toRender ====> 3", pathname, "currPermission =>", currPermission);

				if (pathname == "/dashboard") {
					const firstPermission = userPermissions?.find((permission) => permission?.show_in_sidebar == 1);
					if (firstPermission) {
						let firstMenuToShow = menuItems?.find((menuItem) => menuItem?.id === firstPermission?.key);
						if (firstMenuToShow) {
							return <Redirect to={firstMenuToShow?.link ?? "/"} />;
						}
					}
				}
				return <Redirect to={"/un-authorized"} />;
			}
		} else {
			console.log("toRender ====> 4", pathname, currMenuItem);
			return <Redirect to={"/not-found"} />;
		}
		console.log("toRender ====> 5", pathname);

		return <Component exact {...props} />;
	};

	return (
		<>
			<Route {...rest} render={(props) => (token ? toRender(props, path) : <Redirect to={"/login"} />)} />
			{showPartnerMobileModal && <PartnerMobileNumber onClose={() => setShowPartnerMobileModal(false)} />}
		</>
	);
};

export default PrivateRoute;

import React from "react";

import Card from "../../../components/Card";
import { Image } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import ic_cancel from "../../../assets/images/ic_cancel.svg";
import ic_edit from "../../../assets/images/Icon material-mode-edit.png";

const AddressSection = ({
  addressList,
  handleDeleteAddress,
  setIsEdit,
  setSelectedAddress,
}) => {
  const { t } = useTranslation();

  const handleEditClick = (key) => {
    setIsEdit(true);
    setSelectedAddress({
      ...key,
      isEdit:true,
      is_primary:key?.is_primary == '1' && true || false
    });
  };

  return (
    <div className="address-row">
      {addressList.length ? (
        addressList.map((key, i) => {
          return (
            <Card key={i}>
              <div className="address-data">
                <div>
                  {t("Location")}: <span>{key.location}</span>
                </div>
                <div>
                  {t("Street")}: <span>{key.street_name}</span>
                </div>
                <div>
                  {t("Neighbourhood")}: <span>{key.neighbourhood}</span>
                </div>
                <div>
                  {t("Zipcode")}: <span>{key.postal_zip_code}</span>
                </div>
              </div>
              <div className="icons-wrapper">
                <Image src={ic_edit} onClick={() => handleEditClick(key)} />
                <Image
                  src={ic_cancel}
                  onClick={() => handleDeleteAddress(key.address_id)}
                />
              </div>
            </Card>
          );
        })
      ) : (
        <h6>No Addresses available</h6>
      )}
    </div>
  );
};

export default AddressSection;

/* global google */
import Hoc from "../Hoc";
import React, { useEffect, useState } from "react";
import * as _ from 'lodash';
import { getAuthAttribute } from "../../utils/helper";
import { useRouteMatch } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/Primary";
import "./index.scss";
import { getFences, saveFence } from "../../core/Api/fencing";
import { toast } from 'react-toastify';
import FullPageSpinner from "../../components/FullPageSpinner";
import Map from "./Map";

const ServiceArea = () => {
	const match = useRouteMatch();
	const supplier_id = match?.params?.id ?
		match?.params?.id : getAuthAttribute("supplier_id");
	const [pathCoordinates, setPathCoordinates] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [centerCoordinates, setCenterCoords] = useState({ lat: 24.7024886, lng: 46.6707867 });

	useEffect(() => {
		getInitialData();
	}, []);

	async function getInitialData() {
		try {
			const response = await getFences(supplier_id);
			console.log('response?.result[0] :', response?.result[0]?.fence_coords);
			setPathCoordinates(response?.result[0]?.fence_coords || []);
			if (response?.result[0].fence_coords.length > 0) {
				if (response?.result[0].fence_coords[0].length > 0) {
					setCenterCoords(response?.result[0].fence_coords[0][0]);
				}
			}
		} catch (error) {
			return error;
		}
	}

	const saveServiceArea = async () => {
		try {
			setIsLoading(true);
			const data = {
				"supplier_id": supplier_id,
				"fence_coords": pathCoordinates || []
			};
			const response = await saveFence(data);
			if (response?.code < 400) {
				toast.success('Saved Successfully');
				getInitialData()
			} else {
				toast.error(response?.message)
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(error?.message)
		}
	}

	const deleteFence = async (paths) => {
		try {
			setPathCoordinates(paths || []);
			setIsLoading(true);
			const data = {
				"supplier_id": supplier_id,
				"fence_coords": paths || []
			};
			const response = await saveFence(data);
			if (response?.code < 400) {
				toast.success('Saved Successfully');
				getInitialData()
			} else {
				toast.error(response?.message)
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			toast.error(error?.message)
		}
	}


	return (
		<Hoc activeMenu="service_area">
			<div className="service-area-cont">
				{isLoading && <FullPageSpinner /> || (
					null
				)}
				<Map
					pathCoordinates={pathCoordinates}
					setPathCoordinates={setPathCoordinates}
					deleteFence={deleteFence}
					centerCoordinates={centerCoordinates}
				/>

				<PrimaryButton
					isLoading={isLoading}
					text="Save"
					onClick={() => saveServiceArea()}
					style={{
						float: 'right',
						width: '150px',
						marginTop: '20px'
					}} />
			</div>
		</Hoc>
	)
}

export default ServiceArea;

import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import PrimaryButton from '../../../../components/Buttons/Primary';
import FullPageSpinner from '../../../../components/FullPageSpinner';
import CustomTextArea from '../../../../components/Textarea';
import { redeemVoucher } from '../../../../core/Api/giftvouchers';
import './Styles.scss'

const SaleDetails = ({
  selectedSale,
  getBackgroundColor,
  getStatus,
  onRefreshList
}) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRedeemVoucher = async () => {
    setLoading(true)
    const res = await redeemVoucher({
      v_sale_id: selectedSale?.v_sale_id,
      notes
    });
    setLoading(false);
    if (res.code == 200) {
      toast.success(t('redeemedSuccessfully'));
      onRefreshList && onRefreshList()
    } else {
      toast.error(res?.message)
    }
  }

  

  return (
    <div className='sale-details'>
      {loading && <FullPageSpinner /> || null}
      <div className='header-cont '>
        <Card style={{
          backgroundColor: getBackgroundColor(selectedSale?.status_id),
          padding: '5px 30px'
        }}>
          <p>{t('ref.#')} - {selectedSale?.reference_no || ''}</p>
          <p><b>{getStatus(selectedSale?.status_id)}</b></p>
        </Card>
      </div>

      <div className='row'>
        <div className=' col-6'>
          <Card className='card-cont'>
            <div className='row'>
              <div className='col-6'><p className='header'>{t('voucherCode')}</p></div>
              <div className='col-6'><p>{selectedSale?.voucher_code || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p className='header'>{t('voucherName')}</p></div>
              <div className='col-6'><p>{selectedSale?.voucher_name || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p className='header'>{t('Category')}</p></div>
              <div className='col-6'><p>{selectedSale?.category || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p className='header'>{t('Type')}</p></div>
              <div className='col-6'><p>{selectedSale?.type || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'><p className='header'>{t('sellerName')}</p></div>
              <div className='col-6'><p>{selectedSale?.seller_name || ''}</p></div>
            </div>
          </Card>
        </div>
        <div className=' col-6'>
          <Card className='card-cont'>
            <div className='row'>
              <div className='col-6'> <p className='header'>{t('customerId')}</p></div>
              <div className='col-6'> <p>{selectedSale?.customer_id || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'> <p className='header'>{t('customerName')}</p></div>
              <div className='col-6'> <p>{`${selectedSale?.cust_first_name || ''} ${selectedSale?.cust_last_name || ''}` || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'> <p className='header'>{t('Mobile')}</p></div>
              <div className='col-6'> <p>{selectedSale?.cust_mobile || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-6'> <p className='header'>{t('Email')}</p></div>
              <div className='col-6'> <p>{selectedSale?.cust_email || 'N/A'}</p></div>
            </div>
          </Card>
        </div>
      </div>
      <div className='row'>
      <div className=' col-12'>
          <Card className='card-cont'>
            <div className='row'>
              <div className='col-4'> <p className='header'>{t('Gift from')}</p></div>
              <div className='col-6'> <p>{selectedSale?.gift_from || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-4'> <p className='header'>{t('Gift to')}</p></div>
              <div className='col-6'> <p>{`${selectedSale?.gift_to || ''} `}</p></div>
            </div>
            <div className='row'>
              <div className='col-4'> <p className='header'>{t('Receiver Mobile')}</p></div>
              <div className='col-6'> <p>{selectedSale?.gift_to_mobile || ''}</p></div>
            </div>
            <div className='row'>
              <div className='col-4'> <p className='header'>{t('Message')}</p></div>
              <div className='col-6'> <p>{selectedSale?.gift_message || ''}</p></div>
            </div>
          </Card>
        </div>
      </div>

      <div className='row m-0 mt-5'>
        <div className='col-12'>
          <CustomTextArea
            label={t("notes")}
            placeholder={t("notes")}
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>

      <div className='footer-cont row m-0 mt-3'>
        {selectedSale?.status_id != '23' && <PrimaryButton
          text={t('redeem')}
          onClick={handleRedeemVoucher}
        />}
      </div>
    </div>
  )
}

export default SaleDetails
import { useTranslation } from "react-i18next";
import CustomTableComponent from "../../../components/CustomTableComponent";

const TopCustomers = ({ data, type}) => {
  const { t } = useTranslation();

  const getColumnLabel = (type)=>{
    const name = {
      "top_customers":"Total Bookings",
      "top_completers":"Completed",
      "top_cancelers":"Canceled",
      "top_homers":"Home",
      "top_salon_visitors":"Salon"
    }
    return name[type];
  }
  const columns = [
    {
      name: t("CustomerId"),
      key: 'customer_id',
      formatter: null,
    },
    {
      name: t("Customer Name"),
      key: 'customer_name',
      formatter: null,
    },
    {
      name: t(getColumnLabel(type)),
      key: 'no_of_bookings',
    },
  ]

  return (
    <>
      <CustomTableComponent
        responsive={true}
        hover={true}
        showSequence={false}
        columns={columns || []}
        data={data || []}
      />
    </>
  )
}

export default TopCustomers;
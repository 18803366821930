import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";

import Cropper from "react-easy-crop";

const ImageCropper = (props) => {
  const { imageUrl, setCurrentImageURL } = props;
  const imageRef = useRef(imageUrl);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    imageRef.current = imageUrl;
  }, [imageUrl]);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(0);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return canvas.toDataURL();
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    if (
      imageRef.current &&
      croppedAreaPixels.width &&
      croppedAreaPixels.height
    ) {
      const croppedImage = await getCroppedImg(
        imageRef.current,
        croppedAreaPixels
      );
      setCurrentImageURL(croppedImage);
    }
  };

  return (
    <React.Fragment>
      <Col md="4" className="upload-msg">
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Col>
      <div className="range-slider">
        <div className="w-60 mx-auto mb-2">
          <Form.Control
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(event) => setZoom(+event.target.value)}
            type="range"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImageCropper;

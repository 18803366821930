import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserInput from "../../../components/Inputs";
import SimpleReactValidator from "simple-react-validator";
import { saveSupplierSettings } from "../../../core/Api/Settings";
import { toast } from "react-toastify";
import { checkActionPermission } from "../../../utils/helper";
const defaultSupplierData = {
  app_version: "",
  ios_app_version: "",
  records_per_page: "",
  is_update_required: "",
  vat_percent: "",
};

const SupplierSetting = (props) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [, forceUpdate] = useState();
  const [supplierData, setSupplierData] = useState(defaultSupplierData);

  useEffect(() => {
    if (props.supplierSettings) {
      setSupplierData(props.supplierSettings);
    }
  }, [props.supplierSettings]);

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          app_version: supplierData.app_version,
          ios_app_version: supplierData.ios_app_version,
          records_per_page: supplierData.records_per_page,
          is_update_required: supplierData.is_update_required,
          vat_percent: supplierData.vat_percent,
        };
        const result = await saveSupplierSettings(data);
        if (result?.code == 200) {
          toast.success("Supplier settings saved successfully");
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSupplierData({
      ...supplierData,
      [name]: value,
    });
  };

  return (
    <Container>
      <Card className="cards">
        <Row className="input-row">
          <Col md="6">
            <UserInput
              required
              label={t("VAT Percentage (%)")}
              placeholder={t("VAT Percentage (%)")}
              name="vat_percent"
              value={supplierData.vat_percent}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "vat_percent",
              supplierData.vat_percent,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("App Version")}
              placeholder={t("App Version")}
              name="app_version"
              value={supplierData.app_version}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "app_version",
              supplierData.app_version,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label={t("App Version(iOS)")}
              placeholder={t("App Version(iOS)")}
              name="ios_app_version"
              value={supplierData.ios_app_version}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "ios_app_version",
              supplierData.ios_app_version,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Records Per Page")}
              placeholder={t("Records Per Page")}
              name="records_per_page"
              value={supplierData.records_per_page}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "records_per_page",
              supplierData.records_per_page,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Is App Update Requried")}
              placeholder={t("Is App Update Requried")}
              name="is_update_required"
              value={supplierData.is_update_required}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "is_update_required",
              supplierData.is_update_required,
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col className="save-btn">
           {checkActionPermission("global_settings","canEdit") &&   <Button onClick={handleSave}>Save</Button>}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default SupplierSetting;

import React, { useState } from "react";
import RoundedButton from "../../components/Buttons/Rounded";
import { Container } from "react-bootstrap";
import "./Styles.scss";
import StaffListing from "./Listing";
import ClosedDates from "./ClosedDates";
import Members from "./Members";
import { useTranslation } from "react-i18next";

const MyStaff = (props) => {
  const { match, setUpdateProfileStatus } = props;
  const { t } = useTranslation();
  const buttonsList = ["Members", "Working hours", "Closed Dates"];
  const [compName, setCompName] = useState("Members");

  const renderComponent = () => {
    switch (compName) {
      case "Working hours":
        return <StaffListing supplierId={match.params.id} setUpdateProfileStatus={setUpdateProfileStatus}/>;
      case "Members":
        return <Members {...props} supplierId={match.params.id} setUpdateProfileStatus={setUpdateProfileStatus}/>;
      case "Closed Dates":
        return <ClosedDates supplierId={match.params.id} />;
      default:
        return null;
    }
  };
  return (
    <Container fluid className="staff">
      <div className="buttons-group">
        {buttonsList.map((item) => (
          <RoundedButton
            onClick={() => setCompName(item)}
            label={t(`${item}`)}
            isActive={item == compName ? true : false}
          />
        ))}
      </div>
      {renderComponent()}
    </Container>
  );
};

export default MyStaff;

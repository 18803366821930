import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Hoc from '../../Hoc';
import './Styles.scss';
import * as _ from 'lodash';
import PartnersSummaryReport from '../PartnersSummary';
import CustomersSummaryReport from '../CustomersSummary';
import ExpansionReport from '../ExpansionReport';
import SalesReport from '../Sales';
import GrossTransactionsReport from '../GrossTransactions';
import TransactiosPerDay from '../TransactionsPerDay';
import TransactionsPerWeek from '../TransactionsPerWeek';
import TransactiosPerMonth from '../TransactionsPerMonth';
import TransactiosServicesPerDay from '../TransactionsServicesPerDay';
import TransactiosServicesPerMonth from '../TransactionsServicesPerMonth';
import TransactiosByPartners from '../TransactionsByPartners';
import TransactiosByPartnerType from '../TransactionsByPartnersType';
import MostUsedServicesReport from '../MostUsedServices';
import ServicesPricingReport from '../ServicesPricingReport';
import IncompleteProfilePartners from '../IncompleteProfilePartners';
import AvgServicePriceReport from '../../AvgServicePriceReport';
import CumulativeCustomersReport from '../CumulativeCustomers/Index';
import TopCustomersReports from '../TopCustomersReports';
import BookingsIncome from '../BookingsIncome';

const GraphReports = () => {
	const [currTab, setCurrTab] = useState('transactionsPerDay');

	return (
		<Hoc activeMenu="master_reports">
			<div className="reportsTabsCont">
				<div className="table-wrapper pr-3">
					<Row>
						<Col>
							<Tabs
								defaultActiveKey="transactionsPerDay"
								id="uncontrolled-tab-example"
								className="mb-3"
								fill
								justify
								variant="pills"
								onSelect={(k) => setCurrTab(k)}
								// bsPrefix='tab-pane'
							>
								<Tab
									eventKey="transactionsPerDay"
									title="Trans. Per Day"
									onSelect={() => setCurrTab('transactionsPerDay')}
								>
									{(currTab == 'transactionsPerDay' && (
										<TransactiosPerDay />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="transactionsPerWeek"
									title="Trans. Per Week"
									onSelect={() => setCurrTab('transactionsPerWeek')}
								>
									{(currTab == 'transactionsPerWeek' && (
										<TransactionsPerWeek />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="transactionsPerMonth"
									title="Trans. Per Month"
									onSelect={() => setCurrTab('transactionsPerMonth')}
								>
									{(currTab == 'transactionsPerMonth' && (
										<TransactiosPerMonth />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="transactionsServicesPerDay"
									title="Trans. services Per Day"
									onSelect={() => setCurrTab('transactionsServicesPerDay')}
								>
									{(currTab == 'transactionsServicesPerDay' && (
										<TransactiosServicesPerDay />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="transactionsServicesPerMonth"
									title="Trans. services Per Month"
									onSelect={() => setCurrTab('transactionsServicesPerMonth')}
								>
									{(currTab == 'transactionsServicesPerMonth' && (
										<TransactiosServicesPerMonth />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="transactionsByPArtners"
									title="Trans. by Partners"
									onSelect={() => setCurrTab('transactionsByPArtners')}
								>
									{(currTab == 'transactionsByPArtners' && (
										<TransactiosByPartners />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="transactionsByPartnerType"
									title="Trans. by Partners Type"
									onSelect={() => setCurrTab('transactionsByPartnerType')}
								>
									{(currTab == 'transactionsByPartnerType' && (
										<TransactiosByPartnerType />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="mostUsedServices"
									title="Most Used Services"
									onSelect={() => setCurrTab('mostUsedServices')}
								>
									{(currTab == 'mostUsedServices' && (
										<MostUsedServicesReport />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="avgServicePricingReport"
									title="Avg Service Pricing"
									onSelect={() => setCurrTab('avgServicePricingReport')}
								>
									{(currTab == 'avgServicePricingReport' && (
										<AvgServicePriceReport />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="inCompleteProfilePartners"
									title="Incomplete Profile Partners"
									onSelect={() => setCurrTab('inCompleteProfilePartners')}
								>
									{(currTab == 'inCompleteProfilePartners' && (
										<IncompleteProfilePartners />
									)) ||
										null}
								</Tab>
                <Tab
									eventKey="servicePricingReport"
									title="Service Pricing"
									onSelect={() => setCurrTab('servicePricingReport')}
								>
									{(currTab == 'servicePricingReport' && (
										<ServicesPricingReport />
									)) ||
										null}
								</Tab>
                <Tab
									eventKey="cumulativeCustomersReport"
									title="Cumulative Customers"
									onSelect={() => setCurrTab('cumulativeCustomersReport')}
								>
									{(currTab == 'cumulativeCustomersReport' && (
										<CumulativeCustomersReport />
									)) ||
										null}
								</Tab>
                <Tab
									eventKey="topCustomersReport"
									title="Cumulative Customers"
									onSelect={() => setCurrTab('topCustomersReport')}
								>
									{(currTab == 'topCustomersReport' && (
										<TopCustomersReports />
									)) ||
										null}
								</Tab>
                <Tab
									eventKey="bookingIncomeReport"
									title="Bookings Income Report"
									onSelect={() => setCurrTab('bookingIncomeReport')}
								>
									{(currTab == 'bookingIncomeReport' && (
										<BookingsIncome />
									)) ||
										null}
								</Tab>

								{/* ================================= */}
								<Tab
									eventKey="partnersSummaryReport"
									title="Partner Summary"
									onSelect={() => setCurrTab('partnersSummaryReport')}
								>
									{(currTab == 'partnersSummaryReport' && (
										<PartnersSummaryReport />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="customersSummaryReport"
									title="Customer Summary"
									onSelect={() => setCurrTab('customersSummaryReport')}
								>
									{(currTab == 'customersSummaryReport' && (
										<CustomersSummaryReport />
									)) ||
										null}
								</Tab>
								<Tab
									eventKey="expansionReport"
									title="Expansion"
									onSelect={() => setCurrTab('expansionReport')}
								>
									{(currTab == 'expansionReport' && <ExpansionReport />) ||
										null}
								</Tab>
								<Tab
									eventKey="salesReport"
									title="Sales"
									onSelect={() => setCurrTab('salesReport')}
								>
									{(currTab == 'salesReport' && <SalesReport />) || null}
								</Tab>
								<Tab
									eventKey="GTVReport"
									title="GTV"
									onSelect={() => setCurrTab('GTVReport')}
								>
									{(currTab == 'GTVReport' && <GrossTransactionsReport />) ||
										null}
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</div>
			</div>
		</Hoc>
	);
};

export default GraphReports;

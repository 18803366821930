import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Tab, Tabs, } from "react-bootstrap";
import moment from "moment";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import * as _ from 'lodash';
import Hoc from "../../Hoc";
import FullPageSpinner from "../../../components/FullPageSpinner";
import AssetsImg from "images-col";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import UserInput from "../../../components/Inputs";
import CustomDropdown from "../../../components/Dropdown";
import { getCityByCountryCode, getCountryList } from "../../../core/Api/Settings";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import { getEmployees } from "../../../core/Api/employee";
import PrimaryButton from "../../../components/Buttons/Primary";
import { uploadImage } from "../../../core/Api/staff";
import { getCompanyId, registerBulkCustomers, saveCompany } from "../../../core/Api/company";
import { useHistory, useRouteMatch } from "react-router-dom";
import SystemAdministrators from "./Admins";
import { checkActionPermission, getAuthAttribute } from "../../../utils/helper";
import Groups from "../Groups";

const intialModel = {
  company_id: null,
  company_name_arb: null,
  company_name: null,
  company_cr_no: null,
  company_rep: null,
  contact_person_1: null,
  contact_number_1: null,
  status_id: "1",
  settlement_frequency: null,
  logo: null,
  vat_number: null,
  company_country: null,
  company_city: null,
  type: "",
  address: null
}
const initAddressModel = {
  address_id: null,
  location: null,
  lat_lng: '0,0',
  building_floor: null,
  street_name: null,
  neighbourhood: null,
  city: null,
  postal_zip_code: null,
  additional_numbers: null,
  landmark: null,
}

const AddCompany = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        time: {
          message: t("End time must be greater than Start time"),
          rule: (endTime, params) => {
            if (!endTime || params[0] == "") {
              return false;
            }
            const startTime = params.join(" ", ":").replace(" ", ":");
            return moment(endTime, "LT").isAfter(moment(startTime, "LT"));
          },
          required: true,
        },
        arabic: {
          message: t("Must be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return isArabic.test(val);
          },
          required: true,
        },
        english: {
          message: t("Must be english"),
          rule: (val) => {
            const isEnglish = /[a-zA-Z0-9]/;
            return isEnglish.test(val);
          },
          required: true,
        },
      },
    })
  );
  const isUserAdmin = getAuthAttribute("user_type_id") == 4;
  const supplierId = match?.params?.supplier_id || getAuthAttribute("supplier_id");
  const isDisabled = false;

  const [isLoading, setIsLoading] = useState(true);
  const [companyModel, setCompanyModel] = useState(_.cloneDeep(intialModel));
  const [addressModel, setAddressModel] = useState(_.cloneDeep(initAddressModel))
  const [link, setLink] = useState(null);
  const [employees, setEmployees] = useState([
    { value: null, label: 'Select Option' }
  ]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [, forceUpdate] = useState();
  const [imagePreviewURL, setImagePreviewURL] = useState({
    image: null,
    src: null,
  });
  const [fileName, setFileName] = useState(null);
  const [customersFileName, setCustomerFileName] = useState(null);
  const [regCutomrsRes, setRegCustomresRes] = useState(null);
  const settelmentOptions = [
    { label: t("Select Option"), value: null },
    { label: t("Weekly"), value: "Weekly" },
    { label: t("Monthly"), value: "Monthly" },
    { label: t("Yearly"), value: "Yearly" },
  ];

  useEffect(() => {
    laodInitialData();
  }, []);

  const laodInitialData = async () => {
    try {
      const promises = [
        getCountryList(),
        getEmployees({
          per_page: 99999,
          offset: 0,
          status_id: '1'
        })
      ]
      if (match?.params?.id) {
        promises.push(getCompanyId(match?.params?.id));
      }
      const results = await Promise.all([...promises]);
      setIsLoading(false)
      if (results?.length && results[0]?.code == 200) {
        const data = [];
        results?.length && results[0]?.result.forEach((row) => {
          data.push({
            label: row.name,
            value: row.code,
          });
        });
        setCountryList([...data]);
      } else {
        toast.error(results?.length && results[0]?.message || "Something went wrong");
      }
      if (results?.length && results[1].code == 200) {
        setEmployees([
          ...employees,
          ...(results?.length && results[1]?.result?.data || []).map(r => ({
            value: r?.emp_id,
            label: `${r?.first_name} ${r?.last_name}`
          }))
        ],
        );
      } else {
        toast.error(results?.length && results[1]?.message);
      }
      if (match?.params?.id) {
        if (results?.length && results[2].code == 200) {
          setCompanyModel({
            ...companyModel,
            ...results[2]?.result
          })
          setAddressModel({
            ...addressModel,
            ...results[2]?.result?.address
          })
          if (results[2]?.result?.logo) {
            setImagePreviewURL({
              src: results[2]?.result?.logo,
              image: results[2]?.result?.logo_filename,
            })
          }
          setLink(`https://alpha.boon.sa/p/${results[2]?.result?.company_unqid}`)
          if (results[2]?.result?.company_country) {
            await fetchCityByCountryCode(results[2]?.result?.company_country)
          }
        } else {
          toast.error(results?.length && results[2]?.message);
        }
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message || "Something went wrong");
    }
  }

  const handleShareLink = async (link) => {
    const theClipboard = navigator.clipboard;
    await theClipboard.writeText(link);
    toast.dismiss();
    toast.success("Link copied to the Clipboard");
  };

  const handleInputChange = (e, name) => {
    setCompanyModel({
      ...companyModel,
      [name]: e.target.value
    })
  }

  const handleAddInputChange = (value, name) => {
    setAddressModel({
      ...addressModel,
      [name]: value
    })
  }

  const handleDDChange = (value, name) => {
    setCompanyModel({
      ...companyModel,
      [name]: value,
    });
  };

  const handleCountryChange = (value, name) => {
    setCompanyModel({
      ...companyModel,
      [name]: value.value,
    });
    if (value?.value) {
      fetchCityByCountryCode(value?.value)
    }
  };

  const fetchCityByCountryCode = async (countryCode) => {
    try {
      setIsLoading(true);
      const result = await getCityByCountryCode(countryCode);
      setIsLoading(false);
      if (result && result.code == 200) {
        const data = result.result
          ? result.result.map((row) => ({
            label: row.name,
            value: row.city_id,
          }))
          : [];
        setCityList([...data]);
      } else {
        toast.error(result.message ?? "Something went wrong")
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setCompanyModel({
      ...companyModel,
      contact_number_1: `${value.countryCode}-${value.phoneNumber}`,
    });
  };

  const onImageSelect = async (event) => {
    const image = event.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePreviewURL({
          image: image,
          src: reader.result,
        });
      });
      reader.readAsDataURL(image);
    }
  };

  const handleSaveCompany = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      let data = {
        ...companyModel
      }
      if (imagePreviewURL.image) {
        const imageData = new FormData();
        imageData.append("file", imagePreviewURL.image);
        const imageUrl = await uploadImage(imageData);
        const logo = imageUrl?.result.file_name;
        data["logo"] = logo;
      }
      data = {
        ...data,
        address: {
          ...addressModel,
        }
      }
      const result = await saveCompany(data);
      setIsLoading(false);
      if (result?.code == 200) {
        toast.success('Saved successfully');
        history.push('/companies');
      } else {
        toast.error(result?.message || "Something wron.Please try again");
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      toast.error("Please enter valid details");
    }
  };

  const onFileSelect = async (event) => {
    const file = event.target.files[0];
    setFileName(file?.name|| null);
    const data = new FormData();
    data.append("file", file);
    event.target.value = null
    const fileRes = await uploadImage(data);
    if (fileRes?.code == 200) {
      toast.success('File uploaded successfully');
      setCustomerFileName(fileRes?.result?.file_name || null);
    } else {
      toast.error(fileRes?.message || "Something went wrong.Please try again");
    }
  }

  const handleSaveCustomers = async () => {
    try {
      setRegCustomresRes(null)
      setIsLoading(true);
      const res = await registerBulkCustomers({
        company_id: companyModel?.company_id,
        file_name: customersFileName
      });
      setIsLoading(false);
      if (res?.code == 200) {
        setRegCustomresRes(res?.result || null);
        setCustomerFileName(null);
        setFileName(null);
      } else {
        toast.error(res?.message || "Something went wrong.Please try again");
      }
    } catch (error) {

    }

  }

  return (
    <React.Fragment>
      <Hoc activeMenu={isUserAdmin ? "companies" : "companies"}>
        <Container fluid className="company">
          {isLoading && <FullPageSpinner /> || null}
          <Tabs defaultActiveKey="companyInfo" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="companyInfo" title={t('Company Info')}>
              <div className="company-info">
                <Row className="">
                  <Col xl="3" lg="3" md="3" sm="12" className="mt-4">
                    <div className="logo-cont">
                      <label className="profile-pic-label">
                        <img
                          src={imagePreviewURL?.src || AssetsImg?.img_default}
                          className="profile-pic"
                          alt="staff-member-pi"
                          onError={(e)=>{
                            e.target.src =AssetsImg?.img_default;
                          }}
                        />
                        <div className="overlay"></div>
                        <input
                          name='"profile-input'
                          visibility="hidden"
                          type="file"
                          id="profile-input"
                          accept=".png, .jpg, .jpeg"
                          onChange={onImageSelect}
                        />
                      </label>
                    </div>
                  </Col>
                  <Col xl="9" lg="9" md="9" sm="12" className="mt-4">
                    <Row className="mb-2">
                      <Col xl="12" lg="12" md="12" sm="12">
                        <div className="copy-link mb-4">
                          {match?.params?.id && <div className="input-group">
                            <input type="text" value={link} className="form-control" disabled={true} />
                            <span onClick={() => handleShareLink(link)} className="btn">
                              {t("Copy Link")}
                            </span>
                          </div> || null}
                        </div>
                      </Col>
                      <Col xl="6" lg="6" md="6" sm="12">
                        <UserInput
                          required
                          label={t("Name(eng)")}
                          disabled={isDisabled}
                          placeholder={t("Name(eng)")}
                          name="company_name"
                          value={companyModel?.company_name}
                          onChange={(event) =>
                            handleInputChange(event, "company_name")
                          }
                        />
                        {simpleValidator.current.message(
                          "company_name",
                          companyModel && companyModel.company_name,
                          "required"
                        )}
                      </Col>
                      <Col xl="6" lg="6" md="6" sm="12">
                        <UserInput
                          required
                          disabled={isDisabled}
                          label={t("Name(arb)")}
                          placeholder={t("Name(arb)")}
                          name="company_name_arb"
                          value={companyModel.company_name_arb}
                          onChange={(event) =>
                            handleInputChange(event, "company_name_arb")
                          }
                        />
                        {simpleValidator.current.message(
                          "company_name_arb",
                          companyModel && companyModel.company_name_arb,
                          "required"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      required
                      label={t("VAT Number")}
                      placeholder={t("VAT Number")}
                      name="vat_number"
                      disabled={isDisabled}
                      value={companyModel.vat_number}
                      onChange={(event) =>
                        handleInputChange(event, "vat_number")
                      }
                    />
                    {isUserAdmin &&
                      simpleValidator.current.message(
                        "vat_number",
                        companyModel && companyModel.vat_number,
                        "required"
                      )}
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      required
                      label={t("Registration Number")}
                      placeholder={t("Registration Number")}
                      name="company_cr_no"
                      disabled={isDisabled}
                      value={companyModel.company_cr_no}
                      onChange={(event) =>
                        handleInputChange(event, "company_cr_no")
                      }
                    />
                    {simpleValidator.current.message(
                      "company_cr_no",
                      companyModel && companyModel.company_cr_no,
                      "required"
                    )}
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <CustomDropdown
                      onChange={(value) =>
                        handleDDChange(value.value, "company_rep")
                      }
                      name="company_rep"
                      options={employees || []}
                      label={t("Company Representative")}
                      value={
                        employees.find(
                          (row) => row.value == +companyModel?.company_rep
                        ) || ""
                      }
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <CustomDropdown
                      required
                      onChange={(value) =>
                        handleDDChange(value.value, "settlement_frequency")
                      }
                      name="settlement_frequency"
                      options={settelmentOptions}
                      label={t("Settlement Frequency")}
                      value={
                        settelmentOptions.find(
                          (row) =>
                            row.label == (companyModel?.settlement_frequency)
                        ) || ""
                      }
                    />
                    {simpleValidator.current.message(
                      "settlement_frequency",
                      companyModel && companyModel.settlement_frequency,
                      "required"
                    )}
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <SearchableDropdown
                      onChange={(value) =>
                        handleCountryChange(value, "company_country")
                      }
                      label={t('Country')}
                      placeholder={t("Select Country")}
                      name="country"
                      options={countryList}
                      value={countryList.find(
                        (value) => value.value == companyModel?.company_country || ""
                      )}
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <SearchableDropdown
                      onChange={(value) =>
                        handleDDChange(value.value, "company_city")
                      }
                      label={t('City')}
                      placeholder={t("Select City")}
                      name="city"
                      options={cityList}
                      value={cityList.find(
                        (value) => value.value == companyModel.company_city || ""
                      )}
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      required
                      label={t("Contact Person Name")}
                      placeholder={t("Contact Person Name")}
                      name="contact_person_1"
                      disabled={isDisabled}
                      value={companyModel.contact_person_1}
                      onChange={(event) =>
                        handleInputChange(event, "contact_person_1")
                      }
                    />
                    {isUserAdmin &&
                      simpleValidator.current.message(
                        "contact_person_1",
                        companyModel && companyModel.contact_person_1,
                        "required"
                      )}
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <PhoneNumberInput
                      required
                      label={t("Mobile")}
                      placeholder="5xxxxxxx"
                      value={companyModel.contact_number_1}
                      selectedValues={(values) => handlePhoneNumberChange(values)}
                      maxLength="9"
                    />
                    {simpleValidator.current.message(
                      "contact_number_1",
                      companyModel.contact_number_1,
                      "required|checkMobileNumber"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <p className="font-bold pb-3 ">{t("Address Information")}</p>
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      label={t("Location")}
                      placeholder={t("Location")}
                      name="location"
                      value={addressModel.location}
                      onChange={(event) =>
                        handleAddInputChange(
                          event.target.value,
                          "location"
                        )
                      }
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      label={t("Street")}
                      placeholder={t("Street")}
                      name="street_name"
                      value={addressModel.street_name}
                      onChange={(event) =>
                        handleAddInputChange(
                          event.target.value,
                          "street_name"
                        )
                      }
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      label={t("Neighbourhood")}
                      placeholder={t("Neighbourhood")}
                      name="neighbourhood"
                      value={addressModel.neighbourhood}
                      onChange={(event) =>
                        handleAddInputChange(
                          event.target.value,
                          "neighbourhood"
                        )
                      }
                    />
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                    <UserInput
                      label={t("Zipcode")}
                      placeholder={t("Zipcode")}
                      name="postal_zip_code"
                      value={addressModel.postal_zip_code}
                      onChange={(event) =>
                        handleAddInputChange(
                          event.target.value,
                          "postal_zip_code"
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="pt-4">
                   {checkActionPermission("company","canAdd") && <PrimaryButton
                      text={t("Save")}
                      onClick={() => handleSaveCompany()}
                    /> || null}
                  </Col>
                </Row>
              </div>
            </Tab>
            {match?.params?.id &&
              <Tab eventKey="companyAdministrators" title={t('Administrators')}>
                <div className="administrators-info">
                  <SystemAdministrators companyId={match?.params?.id} />
                </div>
              </Tab> || null}
            {match?.params?.id &&
              <Tab eventKey="registerBulkCustomers" title={t('Register Bulk Customers')}>
                <Container fluid className="p-5 add-bulk-cont">
                  <Row className="m-0">
                    <Col xl={12} className="p-0 pb-4">
                      <div className="">
                        <div
                          className="select-file-cont"
                        >
                          <div >
                            <PrimaryButton
                              text={t("Select File")}
                            />
                            <p>{fileName}</p>
                          </div>
                          <input
                            className="customers-file-upload"
                            name='customers-input'
                            type="file"
                            id="profile-input"
                            accept=".xlsx"
                            onChange={onFileSelect}
                          />
                        </div>

                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="pt-4">
                      <PrimaryButton
                        text={t("Save")}
                        onClick={() => handleSaveCustomers()}
                        disabled={!customersFileName}
                      />
                    </Col>
                  </Row>
                  {regCutomrsRes && <>
                    <Row className="mt-5">
                      <Col xl="4" lg="4" md="5" sm="8" xs="12">
                        <table class="table" >
                          <tr>
                            <td>{t("Total Count")}</td>
                            <td></td>
                            <td>{regCutomrsRes?.total_count}</td>
                          </tr>
                          <tr>
                            <td>{t("Processed Count")}</td>
                            <td></td>
                            <td>{regCutomrsRes?.processed_count}</td>
                          </tr>
                          <tr>
                            <td>{t("Failed Count")}</td>
                            <td></td>
                            <td>{regCutomrsRes?.failed_count}</td>
                          </tr>
                          <tr>
                            <td>{t("Registered Count")}</td>
                            <td></td>
                            <td>{regCutomrsRes?.registered_count}</td>
                          </tr>
                          <tr>
                            <td>{t("Groups Assigned")}</td>
                            <td></td>
                            <td>{regCutomrsRes?.groups_assigned}</td>
                          </tr>
                        </table>
                      </Col>

                    </Row>
                    <Row>
                      <Col xl="12">
                        {regCutomrsRes?.result_data?.errors?.map((msg, i) => (
                          <div className='row'>
                            <p className="col-12 text-danger">{`${regCutomrsRes?.result_data?.error_details[i]?.mobile}  -  ${regCutomrsRes?.result_data?.error_details[i]?.first_name?.toUpperCase()} --> ${msg}`}</p>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </> || null}
                </Container>
              </Tab> || null}
            {false && match?.params?.id &&
              <Tab eventKey="groups" title="Groups">
                <div className="groups">
                  <Groups companyId={match?.params?.id} />
                </div>
              </Tab> || null}
          </Tabs>
        </Container>
      </Hoc>
    </React.Fragment >
  );
};

export default AddCompany;

import React from "react";
import moment from "moment";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Card from "../../../components/Card";
import "./Styles.scss";

const AppointmentList = ({ appointments }) => {
  const { t } = useTranslation();
  return Object.keys(appointments).map(
    (key) =>
      appointments[key].bookings &&
      appointments[key].bookings.length && (
        <Container className="client-profile">
          <Card className={"custom-card past-section"}>
            <React.Fragment key={key}>
              <div className="heading">{`${t(key)} (${
                appointments[key].bookings.length
              })`}</div>
              <ul>
                {appointments[key].bookings.map((booking, i) => (
                  <li key={i}>
                    <div className="timing">
                      <div className="date">
                        {moment(booking.appointment_date).format("DD")}
                      </div>
                      <div className="month">
                        {moment(booking.appointment_date).format("MMM")}
                      </div>
                    </div>
                    <div className="name">
                      <div className="slot">
                        {`${moment(booking.appointment_date).format(
                          "ddd"
                        )} ${moment(booking.appointment_date).format(
                          "hh:mm A"
                        )} `}
                        <span className="status confirmed">
                          {t(`${booking.status}`)}
                        </span>
                      </div>
                      <div className="what">
                        {booking.services &&
                          booking.services.length &&
                          booking.services[0].service_name}
                      </div>
                      <div className="with">
                        {booking.services &&
                          booking.services.length &&
                          `${booking.services[0].duration} with ${booking.services[0].staff_name}`}
                      </div>
                    </div>
                    <div className="sar">
                      {t("SAR")} {booking.total_amount}
                    </div>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          </Card>
        </Container>
      )
  );
};

export default AppointmentList;

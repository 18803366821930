import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import CatImage from "../../../assets/images/Icon feather-menu.png";
import menuIcon from "../../../assets/images/Icon awesome-ellipsis-h.png";
import trash from "../../../assets/images/trash.svg";
import editIcon from "../../../assets/images/Icon material-mode-edit.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { deleteService, saveServiceSorting } from "../../../core/Api/services";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useTranslation } from "react-i18next";
import { checkActionPermission } from "../../../utils/helper";

export default function CatList(props) {
  const { t } = useTranslation();
  const { catId, catName, data, handleRemoveService, onClick, setOpen } = props;
  const [services, setServices] = useState([]);
  const [showDetails, setShowDetails] = useState(setOpen);

  useEffect(() => {
    setShowDetails(setOpen);
  }, [setOpen]);

  useEffect(() => {
    if (data) {
      const services = data.map((item) => ({
        ...item,
        id: item.supplier_service_id,
      }));
      setServices(services);
    }
  }, [data]);

  const handleDeleteService = async (supplier_id, supplier_service_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this service? This action cannot be undone."
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteService(supplier_id, supplier_service_id);
        if (result && result.code == 200) {
          Swal.fire(t("Deleted Successfully"), "", "success");
          console.log("result", result);
          handleRemoveService(catId, supplier_service_id);
        } else {
          Swal.fire(
            (result && result.message) || t("Something wrong"),
            "",
            "error"
          );
        }
      }
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newState = [...services];
    const temp = newState[oldIndex];
    newState[oldIndex] = newState[newIndex];
    newState[newIndex] = temp;
    setServices(newState);
    const data = newState.map((item, index) => ({
      supplier_service_id: item.id,
      ss_sort_order: index,
    }));
    saveServiceSorting({ sorted_supplier_services: data });
  };

  const ListElement = ({ item }) => {
    return (
      <li key={item.supplier_id} style={{ listStyle: "none" }}>
        <Row
          className="detailed"
          style={{
            justifyContent: "space-between",
            padding: "15px 20px",
            backgroundColor: "#ffffff",
          }}
        >
          <Col md="6" className="name d-flex align-items-center">
            <Image src={CatImage} className="ser-image mr-2" />
            <span>{`${item.service_label}`}</span>
          </Col>
          <Col md="4" className="duration">
            {`${item.duration ? item.duration : 0} min`}
          </Col>
          <Col md="2"></Col>
        </Row>
      </li>
    );
  };

  const SortableListElement = SortableElement(({ item }) => (
    <ListElement item={item} />
  ));

  const SortableItem = (props) => {
    const { item, index } = props;
    return (
      <div className="position-relative">
        <SortableListElement key={`${item.id}`} index={index} item={item} />
        <div className="delete">
         {checkActionPermission("supplier_services","canDelete") && (
           <Image
           style={{ marginLeft: "10px" }}
           src={trash}
           alt="delete"
           onClick={() =>
             handleDeleteService(item.supplier_id, item.supplier_service_id)
           }
         />
         )||null}
          {checkActionPermission("supplier_services","canEdit") && (
            <Image
            style={{ marginLeft: "10px" }}
            src={editIcon}
            alt="edit"
            onClick={() => onClick(item.supplier_service_id)}
          />
          )}
        </div>
      </div>
    );
  };

  const SortableList = SortableContainer(({ items }) => (
    <div>
      {items.map((item, index) => (
        <SortableItem item={item} index={index} />
      ))}
    </div>
  ));

  return (
    <React.Fragment>
      <li className="cat-list">
        <div>
          <Row onClick={() => setShowDetails(!showDetails)}>
            <Col md="4" className="name">
              <Image src={CatImage} className="cat-image mr-2" />
              {catName}
            </Col>
            <Col md="auto">
              <Image src={menuIcon} className="icon" />
            </Col>
          </Row>
        </div>
      </li>
      <div>
        {showDetails && (
          <SortableList items={services} onSortEnd={onSortEnd} axis="y" />
        )}
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext } from "react";
import AssetsImg from "images-col";
import { Link, useHistory } from "react-router-dom";
import { Col, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Constants from "../../constants";
import { getAuthAttribute, getPermissions } from "../../utils/helper";

import "./Styles.scss";
import { useTranslation } from "react-i18next";
import BoonContext from "../../context";

const NavigationItem = (props) => {
	const { t } = useTranslation();
	const { selected, item } = props;
	const isUserAdmin = getAuthAttribute("user_type_id");
	const [showSubOptions, setShowSubOptions] = useState(() => Boolean(item?.options?.find((item) => item.id === selected)));
	const currPermission = props?.userPermissions?.find((permission) => permission?.key === props?.item?.id);
	if (currPermission?.show_in_sidebar != "1") {
		return <></>;
	}

	const isShowStaric = (key = "") => {
		if (key === "my_businesses" && props?.profileStatus?.has_working_hours === "0") {
			return "*";
		} else if (key === "staff" && props?.profileStatus?.has_staff === "0") {
			return "*";
		} else if (key === "staff" && props?.profileStatus?.has_staff_shifts === "0") {
			return "*";
		} else if (key === "service" && props?.profileStatus?.has_services === "0") {
			return "*";
		} else {
			return "";
		}
	};

	return (
		<li className={selected === item.id ? "active text-danger" : "text-danger"}>
			{item.hasSuboptions ? (
				<div className="sub-links-section" onClick={() => setShowSubOptions((prev) => !prev)}>
					<div className="setting-header">
						<OverlayTrigger
							placement="right"
							delay={{ show: 200, hide: 10 }}
							overlay={
								<Tooltip id="button-tooltip" {...props}>
									<span className="nav-text">{t(`${item.name}`)}</span>
								</Tooltip>
							}
						>
							<Image src={selected === item.id ? item.activeIcon : item.staticIcon} className="setting-icon" alt="partners" />
							{/* <span className="nav-text">{t(`${item.name}`)}</span> */}
						</OverlayTrigger>
					</div>
					{showSubOptions && (
						<div className="sub-links-wrap">
							{item.options.map((item) => {
								const currPermission = props?.userPermissions?.find((permission) => permission?.key === item?.id);
								if (currPermission?.show_in_sidebar != "1") {
									return <></>;
								} else {
									return (
										<OverlayTrigger
											placement="right"
											delay={{ show: 200, hide: 10 }}
											overlay={
												<Tooltip id="button-tooltip" {...props}>
													<span className="nav-text">{t(`${item.name}`)}</span>
												</Tooltip>
											}
										>
											<Link className={selected === item.id ? "active-link" : undefined} key={item.name} to={item.link} id={item?.id}>
												<Image src={selected === item.id ? item.activeIcon : item.staticIcon} className="nav-icon" />
												{/* <span className="nav-text">{t(`${item.name}`)}</span> */}
											</Link>
										</OverlayTrigger>
									);
								}
							})}
						</div>
					)}
				</div>
			) : (
				<OverlayTrigger
					placement="right"
					// delay={{ show: 250, hide: 250 }}
					// trigger="click"
					overlay={
						<Tooltip id="button-tooltip" {...props}>
							<span className="nav-text">{t(`${item.name}`)}</span>
						</Tooltip>
					}
				>
					<Link to={item.link} id={item?.id}>
						<Image src={selected === item.id ? item.activeIcon : item.staticIcon} className="nav-icon" />
						{/* <span className="nav-text">{t(`${item.name}`)}</span> */}
					</Link>
				</OverlayTrigger>
			)}
			{isShowStaric(item?.id)}
		</li>
	);
};

const SideMenu = (props) => {
	const history = useHistory();
	const { selected } = props;
	const isUserAdmin = getAuthAttribute("user_type_id");
	const userPermissions = getPermissions() ?? [];

	useEffect(() => {
		const element = document.getElementById(selected);
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "end" });
		}
	}, [selected]);

	if (!userPermissions?.length) return <></>;
  
	return (
		<>
			<div className="new-sidear">
				<div className="logo-cont">
					<Image
						src={Logo}
						className="logo"
						onClick={() => {
							isUserAdmin === 7 ? history.replace("/companies") : history.push("/dashboard");
						}}
					/>
				</div>
				<div className="logo-border" />
				<div className="menu-cont">
					<ul>
						{Constants?.menuItems.map((item, i) => {
							return (
								<NavigationItem
									key={i}
									selected={selected}
									item={item}
									profileStatus={props?.profileStatus}
									userPermissions={userPermissions ?? []}
								/>
							);
						})}
					</ul>
					<div className="footer-cont">
						<footer>
							<span>v1.1.1</span>
						</footer>
					</div>
				</div>
			</div>
		</>
	);
};

export default SideMenu;

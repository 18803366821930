import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import { getComplains } from "../../../core/Api/partner";
import SearchInput from "../../../components/Inputs/Search";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";

const ComplaintTable = () => {
  const { t } = useTranslation();
  const [complains, setComplains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const filteredComplaints = complains.filter((item) =>
    `${item.ticket_title.toLowerCase()} ${item.description.toLowerCase()} ${item.solution.toLowerCase()}`.includes(
      searchInput.toLowerCase()
    )
  );
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const totalResults = complains.length;
  const limit = 10;
  const offset = (pageNo - 1) * limit;
  const output = filteredComplaints.slice(offset, offset + limit);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (filteredComplaints.length > limit) {
      setTotalPages(Math.ceil(filteredComplaints.length / limit));
    }
  }, [filteredComplaints]);

  const loadData = async () => {
    setIsLoading(true);
    const result = await getComplains();
    if (result && result.code == 200) {
      setComplains(result.result);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const handleSearchChange = (event) => setSearchInput(event.target.value);

  const statusFormatter = (status) => {
    const style = ((status_id) => {
      switch (status_id) {
        case "Open":
          return { color: "#00a65a" };
        case "Closed":
          return { color: "#dd3333" };
        default:
          break;
      }
    })(status);
    return (
      <div className="status-formatter" style={style}>
        {t(status)}
      </div>
    );
  };

  return (
    <Container fluid className="complains-table">
      <Row className="heading-row">
        <Col xl="3" lg="4" md="6" sm="12">
          <SearchInput
            classNames="pt-0"
            onChange={handleSearchChange}
            placeholder={t("Search")}
          />
        </Col>
      </Row>
      {output?.length ? (
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("Complain No")}</th>
                  <th>{t("Title")}</th>
                  <th>{t("Description")}</th>
                  <th>{t("Solution")}</th>
                  <th>{t("Created Date")}</th>
                  <th>{t("Modified Date")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {output.map((row) => (
                  <tr key={row.ticket_no}>
                    <td className="text-nowrap">{row.ticket_no}</td>
                    <td className="text-nowrap">{row.ticket_title}</td>
                    <td className="text-nowrap">{row.description}</td>
                    <td className="text-nowrap">{row.solution}</td>
                    <td className="text-nowrap">{row.createdtime}</td>
                    <td className="text-nowrap">{row.modifiedtime}</td>
                    <td>{statusFormatter(row.ticketstatus)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : isLoading ? (
        <div className="spinner-wrap">
          <Spinner animation="border" />
          <span>{t("Please wait, we're loading complains")}</span>
        </div>
      ) : (
        <div className="no-data-container">
          <h4>{t("No data available")}</h4>
        </div>
      )}
      {!isLoading && Boolean(output.length) && (
        <Row className="pagination">
          <Col md="3 result-text">
            {output.length
              ? t(`Showing ${output.length} out of ${totalResults} Results`)
              : t("No Resuts found")}
          </Col>
          <Col md="5" className="count">
            <div
              className="previous"
              onClick={() =>
                setPageNo((prev) => (prev - 1 > 0 ? prev - 1 : prev))
              }
            >
              <img src={leftArrow} alt="left-arrow"></img>
            </div>
            <div className="pages">
              <span className={`cursor-pointer active`}>{pageNo}</span>
            </div>
            <div
              className="next"
              onClick={() =>
                setPageNo((prev) => (prev + 1 <= totalPages ? prev + 1 : prev))
              }
            >
              <img src={rightArrow} alt="right-arrow"></img>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};
export default ComplaintTable;

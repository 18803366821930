import React, { useState } from "react";

import { Container, Col, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Card from "../../../components/Card";
import AddSingleServiceForm from "./AddSingleServiceForm";
import "./Styles.scss";
const AddService = ({ edit, onClose, supplierId }) => {
  const { t } = useTranslation();
  const [compName, setCompName] = useState(3);
  const renderComponent = () => {
    switch (compName) {
      case 1:
        return (
          <Container className="step-one">
            <Row className="heading">
              <Col md="5" className="title">
                {t("Add an offering to your service menu")}
              </Col>
              <Col md="auto" className="step-count">
                {t("Step 1 of 2")}
              </Col>
            </Row>

            <Row>
              <Col>
                <Card onClick={() => setCompName(2)}>
                  <Image src=" " className="illu" />
                  <div className="details">
                    <div className="name">Service</div>
                    <div className="disc">
                      {t(
                        "Service Services booked by one client in a single visit"
                      )}
                    </div>
                  </div>
                  <Image src=" " className="arrow" />
                </Card>
              </Col>
            </Row>
          </Container>
        );
      case 2:
        return (
          <Container className="step-two">
            <Row className="heading">
              <Col md="5" className="title">
                {t("Choose a service type")}
              </Col>
              <Col md="auto" className="step-count">
                Step 2 of 2
              </Col>
            </Row>
            <Row className="card-row">
              <Col md="4">
                <Card onClick={() => setCompName(3)}>
                  <div>
                    <Image src="" />
                  </div>
                  <div className="title">{t("Service")}</div>
                  <div className="subtitle">
                    {t("Services booked by one client in a single visit")}
                  </div>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <div>
                    <Image src="" />
                  </div>
                  <div className="title">{t("Packages")} </div>
                  <div className="subtitle">
                    {t("Multiple services booked together in one appointment")}
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        );
      case 3:
        return (
          <Container className="step-three">
            <Row className="heading">
              <Col md="5" className="title">
                {t(`${(edit && "Edit") || "Add"} a new single service`)}
              </Col>
            </Row>
            <AddSingleServiceForm
              supplierId={supplierId}
              edit={edit}
              onClose={onClose}
            />
          </Container>
        );
      default:
        return <div></div>;
    }
  };
  return <div className="add-service">{renderComponent()}</div>;
};

export default AddService;

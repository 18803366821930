import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { sendOtp, verifyOtp } from "../../../core/Api/Auth";
import FullPageSpinner from "../../../components/FullPageSpinner";
import UserInput from "../../../components/Inputs";
import { toast } from "react-toastify";


const VerifyMobileNumber = ({ onClose, mobile }) => {
	const { t } = useTranslation();

  const otpVaildator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
	const [isLoading, setIsLoading] = useState(false);
	const [, forceUpdate] = useState();
	const [otp, setOTP] = useState(null);


  useEffect(()=>{
    handleSendOTP(mobile)
  }, [])
  
	const handleSendOTP = async (mobile) => {
		setIsLoading(true);
		try {
				const data = {
					mobile: mobile,
					action: "supplier_mobile",
				};
				const response = await sendOtp(data);
				if (response?.code === 200) {
					toast.success(response.message);
				} else {
					throw new Error(response?.message ?? "Something went wrong");
				}
			
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
		setIsLoading(false);
	};

	const handleVerifyOTP = async () => {
		setIsLoading(true);
		try {
			if (otpVaildator.current.allValid()) {
				const data = {
					mobile: mobile,
					code: otp,
				};
				const response = await verifyOtp(data);
				if (response?.code === 200) {
					toast.success(response.message);
          onClose(true)
				} else {
					throw new Error(response?.message ?? "Something went wrong");
				}
			} else {
				otpVaildator.current.showMessages();
				forceUpdate(1);
				throw new Error("Please enter valid details" ?? "Something went wrong");
			}
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message ?? "Something went wrong");
		}
		setIsLoading(false);
	};

	return (
		<Modal show={true} onHide={()=>onClose(false)} backdrop="static" keyboard={false} >
			<Modal.Header closeButton>
				<Modal.Title>{t("Verify Mobile Number")}</Modal.Title>
			</Modal.Header>

			<Container>
				<Modal.Body>
					{(isLoading && <FullPageSpinner />) || null}
					<Row>
						<Col xl="12" lg="12" md="12" sm="12" className="mb-2">
							<h3>Verify OTP</h3>
							<p>Please enter otp sent to this mobile number</p>
							<p>{mobile}</p>
						</Col>
						<Col xl="12" lg="12" md="12" sm="12" className="mb-2 mt-3">
							<UserInput label={t("OTP")} name="otp" placeholder="Enter OTP" value={otp} onChange={(event) => setOTP(event.target.value)} disabled={isLoading}/>
							{otpVaildator.current.message("mobile", otp, "required")}
						</Col>
					</Row>
				</Modal.Body>

				<Modal.Footer className="footer">
					<Button disabled={isLoading} onClick={handleVerifyOTP} variant="primary" className="save-btn">
						{t("Verify")}
					</Button>
				</Modal.Footer>
			</Container>
		</Modal>
	);
};

export default VerifyMobileNumber;

import React from "react";
import "./Styles.scss";
const Panel = (props) => {
  const { title, subtitle } = props;
  return (
    <div className={`custom-panel ${props?.classNames}`}>
      <div className="heading">
        <div className="title">{title} </div>
        {subtitle && <div className="subtitle">{subtitle} </div>}
      </div>
      <div className="body">{props.children}</div>
    </div>
  );
};

export default Panel;

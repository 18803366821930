import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserInput from "../../../components/Inputs";
import SimpleReactValidator from "simple-react-validator";
import { saveGeneralSettings } from "../../../core/Api/Settings";
import { toast } from "react-toastify";
import { checkActionPermission } from "../../../utils/helper";

const defaultGeneralSettings = {
  play_store_url: "",
  app_store_url: "",
  about_us_url: "",
  help_us_url: "",
  privacy_policy_url: "",
  customer_care_phone: "",
  customer_care_email: "",
  vat_reg_number: "",
  award_points_factor: 0,
  redeem_points_factor: 0
};

const GeneralSettings = (props) => {
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [, forceUpdate] = useState();
  const { t } = useTranslation();
  const [generalData, setGeneralData] = useState(defaultGeneralSettings);

  useEffect(() => {
    if (props.generalSettings) setGeneralData(props.generalSettings);
  }, [props.generalSettings]);

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          play_store_url: generalData.play_store_url,
          app_store_url: generalData.app_store_url,
          about_us_url: generalData.about_us_url,
          help_us_url: generalData.help_us_url,
          privacy_policy_url: generalData.privacy_policy_url,
          customer_care_phone: generalData.customer_care_phone,
          customer_care_email: generalData.customer_care_email,
          vat_reg_number: generalData.vat_reg_number ?? "",
          award_points_factor: generalData?.award_points_factor ||0,
          redeem_points_factor: generalData?.redeem_points_factor || 0
        };
        const result = await saveGeneralSettings(data);
        if (result?.code == 200) {
          toast.success("General settings saved successfully");
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setGeneralData({
      ...generalData,
      [name]: value,
    });
  };

  return (
    <Container>
      <Card className="cards">
        <Row className="input-row">
          <Col md="6">
            <UserInput
              required
              label={t("App Store URL (iOS)")}
              placeholder={t("App Store URL (iOS)")}
              name="app_store_url"
              value={generalData.app_store_url}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "app_store_url",
              generalData.app_store_url,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Play Store URL (Android)")}
              placeholder={t("Play Store URL (Android)")}
              name="play_store_url"
              value={generalData.play_store_url}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "play_store_url",
              generalData.play_store_url,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("About Us URL")}
              placeholder={t("About Us URL")}
              name="about_us_url"
              value={generalData.about_us_url}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "about_us_url",
              generalData.about_us_url,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Help Us URL")}
              placeholder={t("Help Us URL")}
              name="help_us_url"
              value={generalData.help_us_url}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "help_us_url",
              generalData.help_us_url,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Privacy Policy URL")}
              placeholder={t("Privacy Policy URL")}
              name="privacy_policy_url"
              value={generalData.privacy_policy_url}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "privacy_policy_url",
              generalData.privacy_policy_url,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Customer Care Phone")}
              placeholder="5xxxxxxx"
              name="customer_care_phone"
              value={generalData.customer_care_phone}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "customer_care_phone",
              generalData.customer_care_phone,
              "required|numeric"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Customer Care Email")}
              placeholder={t("Customer Care Email")}
              name="customer_care_email"
              value={generalData.customer_care_email}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "customer_care_email",
              generalData.customer_care_email,
              "required|email"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label={t("VAT Number")}
              placeholder={t("VAT Number")}
              name="vat_reg_number"
              value={generalData.vat_reg_number}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "about_us_url",
              generalData.about_us_url,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label={t("Award Points")}
              placeholder={t("Award Points")}
              name="award_points_factor"
              value={generalData.award_points_factor}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "award_points_factor",
              generalData.award_points_factor,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label={t("Radeem Points")}
              placeholder={t("Radeem Points")}
              name="redeem_points_factor"
              value={generalData.redeem_points_factor}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "redeem_points_factor",
              generalData.redeem_points_factor,
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col className="save-btn">
            {checkActionPermission("global_settings","canEdit") &&  <Button onClick={handleSave}>Save</Button>}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default GeneralSettings;

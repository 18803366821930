import React from "react";

import { Pie } from "react-chartjs-2";

const PieChart = (props) => {
  const { dataValue } = props;
  const data = {
    labels: ["Completed", "Pending", "Cancelled"],
    datasets: [
      {
        data: dataValue,
        backgroundColor: ["#88CADC", "#FFC78E", "#FFBEBE"],
        cutout: "90%",
        borderRadius: 50,
      },
    ],
  };
  return <Pie data={data} />;
};

export default PieChart;

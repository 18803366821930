import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Image,
  Overlay,
  Popover,
  PopoverContent,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import SearchInput from "../../../components/Inputs/Search";
import PrimaryButton from "../../../components/Buttons/Primary";
import InvoiceTable from "../../Client/Profile/InvoiceTable";
import AppointmentList from "../../Client/Profile/appointment";
import { getAllCustomers } from "../../../core/Api/customer";
import { getAllBookingsByCustomerId } from "../../../core/Api/bookings";
import { getInvoicesByCustomerId } from "../../../core/Api/customer";
import menuIcon from "../../../assets/images/three-dots.svg";
import searchIcon from "../../../assets/images/Icon ionic-ios-search.svg";
import noClientFound from "../../../assets/images/Group 395.png";
import billIcon from "../../../assets/images/bill.png";
import AssetsImg from "images-col";
import { isEmpty } from "lodash";
import { getAuthAttribute, getDuration } from "../../../utils/helper";
import { map } from 'lodash'

const SearchPanel = (props) => {
  const {
    history,
    selectedCustomer,
    setSelectedCustomer,
    supplierId,
    totalAmount,
    handleSave,
    isEdit = false,
    bookingDetails,
    bookingInfo,
    deliveryFee=0
  } = props;
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [target, setTarget] = useState(null);
  const tabs = ["Appointments", "Products", "Invoices", "Info"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [appointments, setAppointments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (search.trim()) {
      debouncedGetCustomer.cancel();
      debouncedGetCustomer(search.trim());
    } else {
      setCustomerList([]);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (selectedCustomer?.customer_id && supplierId) {
      fetchCustomerData(supplierId, selectedCustomer.customer_id);
    }
  }, [selectedCustomer, supplierId]);

  useEffect(() => {
    setDuration(getDuration(map(bookingInfo, 'duration')))
  }, [bookingInfo]);

  const getCustomerList = async (searchInput) => {
    const response = await getAllCustomers(searchInput, supplierId);
    const customerList = response?.result?.data ?? [];
    setCustomerList(customerList);
  };

  const debouncedGetCustomer = useCallback(
    debounce((searchInput) => {
      getCustomerList(searchInput);
    }, 100),
    [props.customerId]
  );

  async function fetchCustomerData(supplier_id, customer_id) {
    const [invoicesResult, bookingsResult] = await Promise.all([
      getInvoicesByCustomerId(customer_id),
      getAllBookingsByCustomerId(supplier_id, customer_id),
    ]);
    if (invoicesResult && invoicesResult.code == 200) {
      setInvoices(invoicesResult.result);
    } else {
      toast.error(invoicesResult && invoicesResult.message);
    }
    if (bookingsResult && bookingsResult.code == 200) {
      setAppointments(bookingsResult.result);
    }
  }

  const openOverlay = () => {
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      openPopup: true,
    });
  };

  const renderTabContent = (key) => {
    switch (key) {
      case "Info":
        return (
          <div className="filled-data">
            <label>{t("Name")}</label>
            <div className="response">
              {selectedCustomer?.first_name + " " + selectedCustomer?.last_name}
            </div>
            <label>{t("Email")}</label>
            <div className="response">
              {selectedCustomer?.email ? selectedCustomer.email : "NA"}
            </div>
            <label>{t("Marketing notifications")} </label>
            <div className="response">
              {t(
                `${selectedCustomer?.accept_marketing_notification == "1"
                  ? " Accepts marketing notifications"
                  : "NA"
                }`
              )}
            </div>
          </div>
        );
      case "Invoices":
        if (invoices.length) {
          return <InvoiceTable history={history} invoices={invoices} />;
        } else {
          return (
            <>
              <Image
                className="no-history-image"
                alt="box-icon"
                src={billIcon}
              />
              <h3>{t("No Sales History")}</h3>
              <span>
                {t(
                  "Use the checkout feature to create a new sale for this client"
                )}
              </span>
            </>
          );
        }
      case "Appointments":
        if (appointments?.status_list?.length) {
          return <AppointmentList appointments={appointments} />;
        } else {
          return (
            <>
              <Image
                className="no-history-image"
                alt="box-icon"
                src={billIcon}
              />
              <h3>{t("No Sales History")}</h3>
              <span>
                {t(
                  "Use the checkout feature to create a new sale for this client"
                )}
              </span>
            </>
          );
        }
      default:
        return (
          <>
            <Image className="no-history-image" alt="box-icon" src={billIcon} />
            <h3>{t("No Sales History")}</h3>
            <span>
              {t(
                "Use the checkout feature to create a new sale for this client"
              )}
            </span>
          </>
        );
    }
  };

  const handleCustomerRedirect = (id) => {
    history.push(`/clients/${id}`)
  }

  const renderCustomerDetails = () => {
    const customerInfo = !isEmpty(selectedCustomer) && selectedCustomer || bookingDetails?.customer;
    return (
      <div key={customerInfo?.id} className="customer-details-wrapper" onClick={() => handleCustomerRedirect(customerInfo?.id)}>
        <Image
          src={customerInfo?.profile_pic || AssetsImg?.img_default}
          data-holder-rendered="true"
        />
        <div className="customer-details">
          <p>{`${customerInfo?.first_name} ${customerInfo?.last_name}` || 'N/A'}</p>
          <span>
            {customerInfo?.mobile}
          </span>
        </div>
      </div>
    );
  };

  const renderCustomerList = () => {
    if (customerList?.length) {
      return customerList.map((item) => {
        return (
          <div
            key={item.id}
            onClick={() => {
              setCustomerList([]);
              setSelectedCustomer(item);
              setSearch("");
            }}
            className="customer-details-wrapper"
          >
            <Image src={item.profile_pic} data-holder-rendered="true" />
            <div className="customer-details">
              <p>{item.first_name}</p>
              <span>
                {item.email} {item.mobile}
              </span>
            </div>
          </div>
        );
      });
    } else {
      return null
    }
  }

  return (
    <div className="search-panel">
      <div className="right-container">
        {!selectedCustomer && (
          <SearchInput
            placeholder={t("Search Client")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        ) || null}
        {search && (
          <div
            style={{
              color: "#0B586D",
              textDecoration: "underline",
              textAlign: "center",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={openOverlay}
          >
            Add new
          </div>
        )}
        {(!isEdit && selectedCustomer) && (
          <Image
            alt="menu-icon"
            className="three-dot-icon"
            onClick={(event) => setTarget(target ? null : event.target)}
            src={menuIcon}
          />
        )}
        <Overlay
          show={Boolean(target)}
          target={target}
          placement="bottom"
          container={menuRef.current}
        >
          <Popover bsPrefix="remove-selected-item">
            <PopoverContent
              onClick={() => {
                setSelectedCustomer("");
                setTarget(null);
              }}
            >
              {t("Remove Customer")}
            </PopoverContent>
          </Popover>
        </Overlay>
        <div
          className="bottom-sec"
          style={{ height: selectedCustomer ? "100%" : undefined }}
        >
          {(customerList.length || selectedCustomer) ? (
            <div className="search-list">
              {selectedCustomer && renderCustomerDetails()}
              {renderCustomerList()}
              {(!isEdit && selectedCustomer) && (
                <div className="client-info-block">
                  <div className="new-client-btn">
                    <Button onClick={openOverlay}>{t("New Client")}</Button>
                  </div>
                  <div className="summary-block">
                    <div>
                      <h5>0</h5>
                      <span>{t("Total Bookings")}</span>
                    </div>
                    <div>
                      <h5>{`${t("SAR")} ${totalAmount}`}</h5>
                      <span>{t("Total Sales")}</span>
                    </div>
                  </div>
                  <div className="info-tabs">
                    {tabs.map((value) => (
                      <span
                        key={value}
                        className={
                          value == selectedTab ? "selected-tab" : undefined
                        }
                        onClick={() => setSelectedTab(value)}
                      >
                        {t(`${value}`)}
                      </span>
                    ))}
                  </div>
                  <div className="tab-content">
                    {renderTabContent(selectedTab)}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="empty-1">
              <div>
                <Image
                  src={
                    search.length && !customerList.length
                      ? noClientFound
                      : searchIcon
                  }
                  className="py-2"
                />
              </div>
              <div style={{ maxWidth: "90%", margin: "auto" }}>
                <span>
                  {t(
                    `${search.length && !customerList.length
                      ? "No Clients Found"
                      : "Use the search to add a client, or keep empty to save as walk-in."
                    }`
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="empty-2">
            <div className="total">
              {`${t("Total")}: ${t("SAR")} ${Number(totalAmount) + Number(deliveryFee)} (
            ${duration} )`}
            </div>
            <PrimaryButton
              classNames="save-button-side-panel"
              onClick={handleSave}
              text={t("Save")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPanel;

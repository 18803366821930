import React, { useState } from "react";
import BoonContext from "./index";

const BoonProvider = (props) => {
	const [supplierName, setSupplierName] = useState("");
	const [iWillDoLater, setIWillDoLater] = useState(false);
	const [profileStatusInfo, setProfileStatusInfo] = useState(null);
	const [userPermissions, setUserPermissions] = useState([]);
	return (
		<BoonContext.Provider
			value={{
				supplierName,
				setSupplierNameContext: (name) => {
					setSupplierName(name);
				},
				iWillDoLater,
				setIWillDoLater: (value) => {
					setIWillDoLater(value);
				},
				profileStatusInfo,
				setProfileStatusInfo: (value) => {
					setProfileStatusInfo(value);
				},
				userPermissions: userPermissions ,
				setUserPermissions: (value) => {
					setUserPermissions(value);
				},
			}}
		>
			{props.children}
		</BoonContext.Provider>
	);
};

export default BoonProvider;

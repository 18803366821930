import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';


const PartnersGraph = ({ graphsData, title=null, barGraphLabel='',lineGraphLabel='' }) => {
	const { labels=[],partners=[], cumulatedPartners=[], } = graphsData;
	const [data, setData] = useState({});
	useEffect(() => {
		if (!_.isEmpty(graphsData)) {
			setData({
				labels: (labels && [...labels]) || [],
				datasets: [
					{
						type: 'bar',
						label: barGraphLabel,
						backgroundColor: '#00af50',
						data:
							(partners && [
								...partners,
							]) ||
							[],
						borderColor: 'white',
						borderWidth: 2,
					},
					{
						type: 'line',
						label: lineGraphLabel,
						borderColor: '#2d4d5a',
						borderWidth: 2,
						data:
							(cumulatedPartners && [
								...cumulatedPartners,
							]) ||
							[],
					},
				],
			});
		}
	}, [graphsData]);

	return (
		<>
			{!_.isEmpty(data) && (
				<Line
					type="bar"
					data={data}
					options={{
						scales: {
							y: {
								display: false,
								// min: 10,
								// max:400,
								// suggestedMax: 50,
								ticks: {
									// stepSize: 50,
								},
								title: {
									display: false,
									text: 'Value',
								},
							},
							x: {
								title: {
									display: false,
									text: 'Month',
								},
							},
						},
						plugins: {
							tooltip: {
								usePointStyle: true,
							},
							title: {
								display: title && true || false,
								text: title,
							},
							legend: {
								display: true,
								position: 'bottom',
							},
							datalabels: {
								display: true, // Enable or disable data labels for all datasets
								anchor: 'center', // Position of the label relative to the data point (center, start, end, auto)
								align: 'center', // Text alignment (center, start, end, left, right)
								color: 'white', // Label text color
								offset: 20,
								font: {
									weight: 'bold', // Label font weight
									size: '20px',
								},
								formatter: (value, context) => {
									// Custom function to format the label text
									return value;
								},
							},
						},
						responsive: true,
						maintainAspectRatio: false,
					}}
					plugins={[ChartDataLabels]}
					redraw={true}
				/>
			)}
		</>
	);
};

export default PartnersGraph;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class PageNotFound extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={"text-center mt-5"}>
        <h1>{t("Page not found")}</h1>
        <Link to={"/"}>{t("Back to home")}</Link>
      </div>
    );
  }
}

export default withTranslation()(PageNotFound);

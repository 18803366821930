import React from "react";
import { Form } from "react-bootstrap";
import "./Styles.scss";
import Select from "react-select";
import dropDownArrow from "../../../src/assets/images/chevron-down.svg";

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: state.hasValue ? "none" : "none",
  }),
  container: (provided) => ({
    ...provided,
    height: "100%",
  }),
  control: (provided) => ({
    ...provided,
    minWidth: 70,
    height: "100%",
    "hover":{
      border: "1px solid #0b586d !important",
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
};

const SearchableDropdown = (props) => {
  const {
    className,
    disabled,
    isMulti,
    label,
    onChange,
    options,
    placeholder,
    sublabel,
    value,
    required,
    customIcon,
  } = props;
  return (
    <div className={`custom-dropdown ${className ? className : ""}`}>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
          {sublabel && <div className="subtitle">{sublabel}</div>}
        </Form.Label>
      )}
      <div>
        <Select
          styles={customStyles}
          classNamePrefix="custom-dropdown-select"
          value={value}
          isMulti={isMulti}
          isDisabled={disabled}
          isClearable={false}
          isSearchable={true}
          onChange={(value) => {
            onChange && onChange(value);
          }}
          placeholder={placeholder}
          options={options}
          {...props}
        />
        {customIcon && <img alt="arrow" src={dropDownArrow} />}
      </div>
    </div>
  );
};

SearchableDropdown.propTypes = {};

export default SearchableDropdown;

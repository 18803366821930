import React from "react";
import Hoc from "../Hoc";
import Logs from "./Logs";
import "./Styles.scss";

const LogsScreen = () => {
  return (
    <Hoc activeMenu="logs">
      <Logs />
    </Hoc>
  );
};
export default LogsScreen;

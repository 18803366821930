import Axios from "../Axios";

export const payoutSummaryDetail = async (id) => {
  try {
    const response = await Axios.get(`/supplier/payout_summary/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const servicesStatusSummary = async (id) => {
  try {
    const response = await Axios.get(
      `/booking/get_service_status_summary?supplier_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUpcommingAppointments = async () => {
  try {
    const response = await Axios.get("/booking/upcoming_services");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServiceSummaryDetails = async (id) => {
  try {
    const response = await Axios.get(`/booking/service_summary/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMostBookedServices = async (params={}) => {
  try {
    const response = await Axios.post(`/report/most_booked_services`, params);
    return response.data;
  } catch (error) {
    return error;
  }
};

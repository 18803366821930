import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Table, Image, Spinner } from "react-bootstrap";
import "./Styles.scss";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import deactivateIcon from "../../../assets/images/eye-slash-fill.svg";
import activateIcon from "../../../assets/images/eye-fill.svg";
import trash from "../../../assets/images/trash.svg";
import { deleteCustomer, getCustomers, blockCustomer, activeCustomer } from "../../../core/Api/customer";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { checkActionPermission, getAuthAttribute } from "../../../utils/helper";
import CustomTableComponent from "../../../components/CustomTableComponent";
import Pagination from "../../../components/Pagination";
import { useHistory } from "react-router-dom";

const ClientListing = ({ searchInput, handleReload, reloadClients }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const pageSize = 10;
	const isUserAdmin = getAuthAttribute("user_type_id") == 44;

	const [pageNo, setPageNo] = useState(1);
	const [count, setCount] = useState(0);
	const [customers, setCustomers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const deleteClickRef = useRef(false);
	const timeoutRef = useRef(null);

	const debouncedLoadData = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			loadData(searchInput);
		}, 500);
	};

	useEffect(() => {
		if (searchInput?.trim()) {
			debouncedLoadData(searchInput);
		} else {
			loadData(searchInput);
		}
		// eslint-disable-next-line
	}, [searchInput]);

	useEffect(() => {
		if (reloadClients) loadData(searchInput);
		// eslint-disable-next-line
	}, [reloadClients]);

	const loadData = async (value = null) => {
		setIsLoading(true);
		const result = await getCustomers(pageNo, pageSize, value);
		if (result && result.code == 200) {
			setCustomers(result.result.data);
			setCount(value ? result.result.total_filtered : result.result.total_records);
		} else {
			toast.error(result && result.message);
		}
		reloadClients && handleReload(false);
		setIsLoading(false);
	};

	const handlePageChange = async (pageNo, pageSize) => {
		setPageNo(pageNo);

		setIsLoading(true);
		const result = await getCustomers(pageNo, pageSize, searchInput);
		if (result && result.code == 200) {
			setCustomers(result.result.data);
			setCount(searchInput ? result.result.total_filtered : result.result.total_records);
		} else {
			toast.error(result && result.message);
		}
		setIsLoading(false);
	};

	const handleDeleteClient = async (customer_id) => {
		toast.dismiss();
		Swal.fire({
			text: t("Are you sure you want to delete this client? This action cannot be undone."),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#D11A2A",
			cancelButtonColor: "#ffffff",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Delete"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				const result = await deleteCustomer(customer_id);
				if (result && result.code == 200) {
					Swal.fire("Deleted Successfully", "", "success");
					handleReload(true);
				} else {
					Swal.fire((result && result.message) || "Something wrong", "", "error");
				}
			}
			deleteClickRef.current = false;
		});
	};

	const handleToggleActive = (id, status) => {
		toast.dismiss();
		Swal.fire({
			text: t(`Are you sure you want to ${(status == "22" && "activate") || "block"} this client?`),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#D11A2A",
			cancelButtonColor: "#ffffff",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Ok"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				let result = {};
				if (status == "22") {
					result = await activeCustomer(id);
				} else {
					result = await blockCustomer(id);
				}
				if (result && result.code == 200) {
					Swal.fire(`${(status == "22" && "Activated") || "Blocked"} Successfully`, "", "success");
					handleReload(true);
				} else {
					Swal.fire((result && result.message) || "Something wrong", "", "error");
				}
			}
			deleteClickRef.current = false;
		});
	};

	const handleOnError = (e) => {
		e.target.src = "https://via.placeholder.com/140x100";
	};

	const getClientImage = (row, key) => {
		return (
			<span>
				<img alt="client-img" src={row.profile_pic} className="profile-pic" onError={handleOnError} />
				{`${row.first_name} ${row.last_name} `}
			</span>
		);
	};

	const getActionButtons = (row = {}, key) => {
		return (
			<div className="actions-cont">
				{(checkActionPermission("client", "canDelete") && (
					<Image
						className="delete"
						src={trash}
						alt="delete"
						onClick={() => {
							deleteClickRef.current = true;
							handleDeleteClient(row.customer_id);
						}}
					/>
				)) ||
					null}
				{(isUserAdmin && (
					<>
						{checkActionPermission("client", "canDelete") && (
							<>
								{(row.status_id == "22" && (
									<Image
										className="delete"
										src={deactivateIcon}
										alt="delete"
										onClick={() => {
											deleteClickRef.current = true;
											handleToggleActive(row.customer_id, row.status_id);
										}}
									/>
								)) || (
									<Image
										className="delete"
										src={activateIcon}
										alt="delete"
										onClick={() => {
											deleteClickRef.current = true;
											handleToggleActive(row.customer_id, row.status_id);
										}}
									/>
								)}
							</>
						)}
					</>
				)) ||
					null}
			</div>
		);
	};

	const handleRowClick = (row, index) => {
		if (!deleteClickRef?.current) {
			if (checkActionPermission("client", "canEdit")) {
				history.push(`/profile/${row?.customer_id}`);
			}
		} else {
			return false;
		}
	};

	const columns = [
		{
			name: t("Name"),
			key: "first_name",
			formatter: getClientImage,
		},
		{
			name: t("Mobile Number"),
			key: "mobile",
			formatter: null,
		},
		{
			name: t("Email"),
			key: "email",
			formatter: null,
		},
		{
			name: t("Registration Platform"),
			key: "registration_platform",
			formatter: null,
		},
		{
			name: t("Gender"),
			key: "gender",
			formatter: null,
		},
		{
			name: t("Actions"),
			key: "actions",
			formatter: getActionButtons,
		},
	];

	console.log("deleteClickRef.current :", deleteClickRef.current);
	return customers.length ? (
		<Container fluid className="client-table">
			<Row className="m-0 client-table-cont">
				<CustomTableComponent
					responsive={true}
					striped={false}
					bordered={false}
					hover={true}
					size="sm"
					columns={columns || []}
					data={customers || []}
					styles={{ maxHeight: `${window.innerHeight - 240}px` }}
					onRowClick={handleRowClick}
				/>
			</Row>

			<Row>
				<Pagination totalCount={count || 0} viewCount={customers?.length || 0} onChangePage={handlePageChange} pageNo={pageNo} />
			</Row>
		</Container>
	) : isLoading ? (
		<div className="spinner-wrap">
			<Spinner animation="border" />
			<span>{t(`${pageNo > 1 ? `Fecthing data for Page ${pageNo}` : t("Please wait, we're loading client data")}`)}</span>
		</div>
	) : (
		<div className="no-data-container mt-4">
			<h4>{t("No Clients Available")}</h4>
		</div>
	);
};
export default ClientListing;

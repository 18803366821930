import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Modal } from "react-bootstrap";
import { getSupplierDriver } from "../../core/Api/supplier";
import CustomDropdown from "../../components/Dropdown";
import { requestAssignDriver } from "../../core/Api/Driver";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const DriverModal = ({ driverInfo, handleClose }) => {
  const { t } = useTranslation();
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [driverOptions, setDriverOptions] = useState([
    { label: t("Please Select"), value: null },
  ]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [centerCords, setCenterCords] = useState({
    lat: 21.3890824,
    lng: 39.8579118,
  });
  const [markerCords, setMarkerCords] = useState(null);
  const [driverCords, setDriverCords] = useState(null);
  const [direction, setDirection] = useState(null);

  const directionsCallback = useCallback((res) => {
    if (res != null) {
      if (res.status == "OK") {
        setDirection(res || {});
      } else {
        console.log("response: ", res);
      }
    }
  }, []);

  const directionsServiceOptions = useMemo(() => {
    return {
      origin: markerCords,
      destination: driverCords,
      travelMode: "DRIVING",
    };
  }, [markerCords, driverCords]);

  const directionsRendererOptions = useMemo(() => {
    return {
      directions: direction,
    };
  }, [direction]);

  const handleAssignDriver = async () => {
    try {
      if (validator.current.allValid()) {
        const response = await requestAssignDriver(
          driverInfo.booking_id,
          selectedDriver
        );
        if (response.code == 200) {
          toast.success(response.message);
        } else {
          throw new Error(response.message);
        }
        handleClose && handleClose();
      } else {
        throw new Error("Please select a driver first");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    fetchDriverInfo();
    if (driverInfo?.driver_location) {
      const lat_lng = driverInfo.driver_location.split(",");
      const lat = +lat_lng[0];
      const lng = +lat_lng[1];
      setDriverCords({
        lat,
        lng,
      });
    }
    if (driverInfo?.lat_lng) {
      const lat_lng = driverInfo.lat_lng.split(",");
      const lat = +lat_lng[0];
      const lng = +lat_lng[1];
      setCenterCords({
        lat,
        lng,
      });
      setMarkerCords({
        lat,
        lng,
      });
    }
    // eslint-disable-next-line
  }, [driverInfo]);

  const fetchDriverInfo = async () => {
    if (!driverInfo) return;
    const response = await getSupplierDriver(driverInfo.supplier_id);
    if (response?.code == 200) {
      const options = response.result.map((item) => ({
        ...item,
        label: item.name,
        value: item.supplier_driver_id,
      }));
      if (driverInfo.supplier_driver_id)
        setSelectedDriver(driverInfo.supplier_driver_id);
      setDriverOptions([...driverOptions, ...options]);
    }
  };
  return (
    <React.Fragment>
      <Modal.Body>
        <CustomDropdown
          onChange={(value) => setSelectedDriver(value.value)}
          options={driverOptions}
          label={"Select Driver"}
          value={driverOptions.find((row) => row.value == selectedDriver)}
        />
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I"
          libraries={["places", "drawing"]}
        >
          <GoogleMap
            id="map_canvas"
            mapContainerStyle={{
              width: "100%",
              height: "400px",
            }}
            center={centerCords}
            zoom={15}
            mapTypeId="terrain"
          >
            <Marker position={markerCords} title={t("Your current location")} />
            <Marker
              position={driverCords}
              title={t("Driver's current location")}
            />
            <DirectionsService
              options={directionsServiceOptions}
              callback={directionsCallback}
            />
            <DirectionsRenderer options={directionsRendererOptions} />
          </GoogleMap>
        </LoadScript>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button className="save-btn" onClick={handleAssignDriver}>
          Save changes
        </Button>
        <Button onClick={handleClose} variant="white">
          Close
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default DriverModal;

import Axios from "../Axios";

export const getAverageBookings = async (params = {}) => {
  try {
    const { from_date = null, to_date = null } = params;
    const response = await Axios.get(
      `/report/dsb_average_bookings?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAverageServicePrices = async (params = {}) => {
  try {
    const { from_date = null, to_date = null } = params;
    const response = await Axios.get(
      `/report/dsb_average_service_price?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const get3MonthsBookings = async (params = {}) => {
  try {
    const response = await Axios.get(
      `/report/dsb_bookings_last_3_months`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const get3MonthsRegistrations = async (params = {}) => {
  try {
    const response = await Axios.get(
      `/report/dsb_registrations_last_3_months`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const get6MonthsBookings = async (params = {}) => {
  try {
    const response = await Axios.get(
      `/report/dsb_bookings_last_6_months`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const get6MonthsRegistrations = async (params = {}) => {
  try {
    const response = await Axios.get(
      `/report/dsb_registrations_last_6_months`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const get3MonthsCompletedProfiles = async (params = {}) => {
  try {
    const response = await Axios.get(
      `/report/dsb_complete_profiles_last_3_months`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const get6MonthsCompletedProfiles = async (params = {}) => {
  try {
    const response = await Axios.get(
      `/report/dsb_complete_profiles_last_6_months`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
//Supplier Average Service Price Report
export const getSupAvgSPReport = async () => {
  try {
    const response = await Axios.get(
      `/report/rpt_average_service_price`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPartnersSummaryReport = async (params = {}) => {
  try {
    const { year = null, quarter = null} = params;
    const response = await Axios.get(
      `/report/rpt_partnerships?year=${year}&quarter=${quarter}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}



export const getCustomersSummaryReport = async (params = {}) => {
  try {
    const { year = null, quarter = null} = params;
    const response = await Axios.get(
      `/report/rpt_cumulative_customers_by_quarter?year=${year}&quarter=${quarter}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export const getSalesReport = async (params = {}) => {
  try {
    const { year = null, quarter = null} = params;
    const response = await Axios.get(
      `/report/rpt_booking_transactions?year=${year}${quarter && `&quarter=${quarter}` || ''}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getGTVReport = async (params = {}) => {
  try {
    const { year = null, quarter = null} = params;
    const response = await Axios.get(
      `/report/rpt_gtv?year=${year}${quarter && `&quarter=${quarter}` || ''}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getBookingsIncomeReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0} = params;
    const response = await Axios.get(
      `/report/booking_income?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getPerDayTransactionsReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_per_day?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getPerWeekTransactionsReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_per_week?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getPerMonthTransactionsReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_per_month?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPerDayTransactionsServicesReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_by_service_per_day?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPerMonthTransactionsServicesReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_by_service_per_month?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTransactionsByPartnersReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_by_partner?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTransactionsByPartnerTypeReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, rpt_type = 'flash', per_page = 10, offset = 0, supplier_id = null } = params;
    const response = await Axios.get(
      `/report/rpt_trx_by_partner_type?from_date=${from_date}&to_date=${to_date}&rpt_type=${rpt_type}&per_page=${per_page}&offset=${offset}&supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getIncompleteProfilePartnersReport = async (params = {}) => {
  try {
    const { from_date = null, to_date = null, supplier_type = null, per_page = 10, offset = 0, supplier_rep = null } = params;
    const response = await Axios.get(
      `/report/rpt_suppliers_with_incomplete_info?from_date=${from_date}&to_date=${to_date}&supplier_type=${supplier_type}&per_page=${per_page}&offset=${offset}&supplier_rep=${supplier_rep}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServicesPricingReport = async (params = {}) => {
  try {
    const { per_page = 10, offset = 0 } = params;
    const response = await Axios.get(
      `/report/rpt_services_list?per_page=${per_page}&offset=${offset}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCumulativeCustomersReport = async (params = {}) => {
  try {
    const { from_date = '', to_date = '' } = params;
    const response = await Axios.get(
      `/report/rpt_cumulative_customers?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getExpansionReport = async (params = {}) => {
  try {
    const { year = null} = params;
    const response = await Axios.get(
      `/report/rpt_expansions?year=${year}&quarter='Q1`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTopCustomersReport = async (params = {}) => {
  try {
    const { from_date = '', to_date = '' } = params;
    const response = await Axios.get(
      `/report/rpt_top_customers?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getTopCompletersReport = async (params = {}) => {
  try {
    const { from_date = '', to_date = '' } = params;
    const response = await Axios.get(
      `/report/rpt_top_completors?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getTopCancelersReport = async (params = {}) => {
  try {
    const { from_date = '', to_date = '' } = params;
    const response = await Axios.get(
      `/report/rpt_top_cancellers?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getTopHomersReport = async (params = {}) => {
  try {
    const { from_date = '', to_date = '' } = params;
    const response = await Axios.get(
      `/report/rpt_top_homers?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getTopSalonVisitorsReport = async (params = {}) => {
  try {
    const { from_date = '', to_date = '' } = params;
    const response = await Axios.get(
      `/report/rpt_top_salon_visitors?from_date=${from_date}&to_date=${to_date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
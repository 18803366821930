import Axios from "../Axios";

export const getBusinessData = async (id) => {
  try {
    const response = await Axios.get(`/supplier/index/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

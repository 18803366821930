import React, { useState, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Card from "../../../components/Card";
import UserInput from "../../../components/Inputs";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/Primary";
import SimpleReactValidator from "simple-react-validator";
import Logo from "../../../assets/images/logo.png";
import { resetPassword } from "../../../core/Api/Auth";

import { toast } from "react-toastify";
import "./Styles.scss";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const route = useRouteMatch();
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        customPassword: {
          message: t(
            "Must contain at least one number, one uppercase and lowercase letter,one special character and at least 5 or more characters"
          ),
          rule: (val) => {
            const isPassword =
              /^.*(?=.{5,})(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).*$/;
            return isPassword.test(val);
          },
          required: true,
        },
        spaces: {
          message: t("Must not contain spaces"),
          rule: (val) => {
            const isSpace = /[^\S]/;
            return !isSpace.test(val);
          },
          required: true,
        },
        arabic: {
          message: t("Must not be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return !isArabic.test(val);
          },
          required: true,
        },
      },
    })
  );
  const [, forceUpdate] = useState(0);

  const submitForm = async () => {
    if (simpleValidator.current.allValid()) {
      if (password != ConfirmPassword) {
        toast.error("Password did not match");
        return;
      }
      const payload = {
        code: route && route.params && route.params.token,
        password,
      };
      setIsLoading(true);
      const result = await resetPassword(payload);
      setIsLoading(false);
      if (result && result.code == 200) {
        // history.push("/login");
        window.open('/login',  '_self')
        toast.success("Password reset successfully");
      } else {
        toast.error((result && result.message) || "Something wrong");
      }
    } else {
      simpleValidator.current.showMessages();
      toast.error("Please enter valid details");
      forceUpdate(1);
    }
  };

  const onKeyDown = (event) => {
    if (event.code == "Enter") {
      submitForm();
    }
  };

  return (
    <Container className=" reset-pass">
      <Row className="h-100 justify-content-center align-items-center">
        <Col lg="auto">
          <Image src={Logo} className="logo" />
          <Card>
            <h2>{t("Welcome to Boon.Set your password below")}</h2>
            <Row>
              <Col>
                <UserInput
                  required
                  value={password}
                  type="password"
                  placeholder="********"
                  label={t("Password")}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onKeyDown}
                />
                {simpleValidator.current.message(
                  "password",
                  password,
                  "required|arabic|spaces|customPassword"
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <UserInput
                  required
                  value={ConfirmPassword}
                  type="password"
                  placeholder="********"
                  label={t("Confirm Password")}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyDown={onKeyDown}
                />
                {simpleValidator.current.message(
                  "ConfirmPassword",
                  ConfirmPassword,
                  "required|arabic|spaces|customPassword"
                )}
              </Col>
            </Row>
            <PrimaryButton
              text={t("Submit")}
              onKeyDown={onKeyDown}
              isLoading={isLoading}
              onClick={submitForm}
              style={{ marginTop: 30, width: "100%" }}
            />
            <div className="signup-link">
              {t("Already have an account?")}
              <Link to="/login">{t("Sign In")} </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;

import React, { useRef, useState, useEffect, forwardRef } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Image as BSImage,
  Container,
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import UserInput from "../../components/Inputs";
import DateInput from "../../components/DateInput/index";
import CheckBoxInput from "../../components/Inputs/CheckBox";
import CustomDropdown from "../../components/Dropdown";
import { useTranslation } from "react-i18next";
import { getOfferByd, saveOffer } from "../../core/Api/supplier";
import { getServicesList, uploadImage } from "../../core/Api/staff";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { getPartnersList } from "../../core/Api/partner";
import { getCategories, getServices } from "../../core/Api/services";
import moment from "moment";
import SearchableDropdown from "../../components/Dropdown/SearchableDropdown";
import "./Styles.scss";
import { getAuthAttribute } from "../../utils/helper";
import infoIco from "../../assets/images/info-ico.svg";
import SecondaryButton from "../../components/Buttons/Secondary";
import PrimaryButton from "../../components/Buttons/Primary";
import { getGroups } from "../../core/Api/groups";
import FullPageSpinner from "../../components/FullPageSpinner";
import { useParams } from "react-router-dom";
const defaultImage =
  "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg?v=1628698285";


const OfferForm = (props) => {
  const { fetchOffers, handleClose, supplier_id, offerDetails = {}, discountType = 'offer', checkImgRes } = props;
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [offer, setOffer] = useState(() => ({
    ...offerDetails,
    generate_unique_codes: offerDetails?.coupon_code && '0' || '1',
    limit_per_user: offerDetails?.limit_per_user || 1
  }));
  const [isLoading, setLoading] = useState(false);
  const [, forceUpdate] = useState();
  const [servicesList, setServicesList] = useState([]);
  const startDateRef = useRef(offer.start_date);
  const [showSSModal, setShowSSModal] = useState(false);
  const [allServicesSelected, setAllServicesSelected] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [groups, setGroups] = useState([{
    value: null, label: 'Select Group'
  }]);
  const [imagePreview, setImagePreview] = useState(null);
  const [serviceCategories, setServiceCategories] = useState([]);

  const isUserAdmin = getAuthAttribute("user_type_id") == 4;
  const partnerId = !isUserAdmin
    ? getAuthAttribute("supplier_id")
    : params?.id;
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        start_date: {
          message: t("Start date cannot be older than today"),
          rule: (startDate) => {
            if (!startDate) {
              return false;
            }
            return moment(startDate).isSameOrAfter(new Date(), "day");
          },
          required: true,
        },
        expiry_date: {
          message: t("Expiry date must be greater than Start date"),
          rule: (expiryDate) => {
            if (!expiryDate || !startDateRef.current) {
              return false;
            }
            return moment(expiryDate).isAfter(moment(startDateRef.current));
          },
          required: true,
        },
      },
    })
  );
  simpleValidator.current.purgeFields();

  useEffect(() => {
    loadInitData();
    // eslint-disable-next-line
  }, []);

  const loadInitData = async () => {
    setLoading(true);
    const promises = [
      getCategories(),
      getGroups(),
    ];
    if(supplier_id){
      promises.push(getServicesList(supplier_id))
    }
    if (supplier_id && offerDetails?.offer_id) {
      promises.push(getOfferByd({
        supplier_id: supplier_id,
        exclude_services: 0,
        offer_id: offerDetails?.offer_id
      }))
    }

    const [result, groupsRes, serviceList, offerRes] = await Promise.all([...promises])

    setLoading(false);

    if (supplier_id && offerDetails?.offer_id) {
      if (offerRes && offerRes.code == 200) {
        const offer = offerRes?.result?.length && offerRes?.result[0] || null
        if(offer){
          setOffer({
            ...offer,
            generate_unique_codes: offer?.coupon_code && '0' || '1',
            limit_per_user: offer?.limit_per_user || 1
          })
        }
      } else {
        toast.error(t('Something went wrong.Please try again'))
      }
    } else {
      setOffer({
        ...offerDetails,
        generate_unique_codes: offerDetails?.coupon_code && '0' || '1',
        limit_per_user: offerDetails?.limit_per_user || 1
      })
    }


    if (supplier_id && serviceList && serviceList.code == 200) {
      setServicesList(serviceList?.result || []);
      if (offerDetails?.offer_id) {
        if (offerRes && offerRes.code == 200) {
          const offer = offerRes?.result?.length && offerRes?.result[0] || null
          if (offer?.service) {
            setSelectedServices(offer.service.map(s => s.supplier_service_id));
            setAllServicesSelected(offer.service.length == serviceList?.result.length || false)
          }
        }
      }

    }

    if (groupsRes?.code == 200) {
      setGroups([
        ...groups,
        ...(groupsRes?.result || []).map(r => ({
          label: r?.grp_name,
          value: r?.grp_id
        }))
      ])
    }
    if (result && result.code == 200) {
      const data = [];
      result.result.forEach((row) => {
        data.push({
          label: i18n.language == "ar" ? row.category_ar : row.category,
          value: row.category_id,
          service_id: row.category_id,
        });
      });
      setServiceCategories(data);
    } else {
      toast.error(result && result.message);
    }
  }
  const handleChange = (event) => {
    setOffer({
      ...offer,
      [event.target.name]: event.target.value,
    });
  };

  const handleDDChange = (value, name) => {
    if (name == "start_date") {
      startDateRef.current = value;
    }
    setOffer({
      ...offer,
      [name]: value,
    });
  };


  const handleGetServiceList = async () => {
    toast.dismiss();
    try {
      const result = await getServicesList(supplier_id);
      if (result && result.code == 200) {
        setServicesList(result?.result);
        if (offerDetails.service) {
          setSelectedServices(offerDetails.service.map(s => s.supplier_service_id));
          setAllServicesSelected(offerDetails.service.length == result?.result.length || false)
        }
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleSaveOffer = async () => {
    toast.dismiss();
    setLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        if (offer?.is_continuous == '0') {
          if (moment(offer.expiry_date).format("HH:mm:ss") <= moment(offer.start_date).format("HH:mm:ss")) {
            toast.error(t('Offer expiry time must be greater then start time'));
            setLoading(false);
            return;
          }
        }
        const data = {
          offer_id: offer?.offer_id || 0,
          supplier_id: supplier_id || "0",
          customer_id: offer.customer_id || "0",
          supplier_service_id: selectedServices.length && selectedServices.toString() || "",
          name: offer.name,
          coupon_code: offer?.generate_unique_codes == '1' ? null : offer.coupon_code,
          value_type: offer.value_type,
          value: offer.amount,
          max_amount: offer.max_amount || "0",
          limit: offer.limit || "",
          limit_per_user: offer.limit_per_user || "",
          start_date: moment(offer.start_date).format("YYYY-MM-DD HH:mm:ss"),
          expiry_date: moment(offer.expiry_date).format("YYYY-MM-DD HH:mm:ss"),
          type: discountType,
          is_displayed: offer.is_displayed,
          is_published: offer.is_published || "0",
          is_continuous: offer.is_continuous,
          offer_image: "",
          group_id: offer?.group_id || "0",
          category_id: offer?.category_id || "0",
          generate_unique_codes: offer?.generate_unique_codes || '0'
        };
        if (imagePreview?.image) {
          const imageData = new FormData();
          imageData.append("file", imagePreview.image);
          const offerImage = await uploadImage(imageData);
          data.offer_image = offerImage.result.file_name;
        }

        const offers = await saveOffer(data);
        if (offers.code == 200) {
          toast.success(offers?.result?.message || "Offer Saved Successfully")
          handleClose();
          await fetchOffers();
        } else {
          throw new Error(offers?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const handleDateChange = (name, date) => {
    setOffer({
      ...offer,
      [name]: date
    })
  }

  const setIsDisplayed = (value, name) => {
    setOffer({
      ...offer,
      [name]: value.target.checked ? "1" : "0",
      limit_per_user: (name == 'generate_unique_codes' && value?.target?.checked == true) && 1 || offer?.limit_per_user,
      coupon_code: (name == 'generate_unique_codes' && value?.target?.checked == true) ? '' : offer?.coupon_code
    })
  }

  const CustomInput111 = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {`${moment(offer.start_date).format('DD/MM/YYYY')} - ${offer.expiry_date && moment(offer.expiry_date).format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')}`}
    </button>
  ));

  const handleSelectAllServiceChange = (value) => {
    setAllServicesSelected(value);
    if (value) {
      setSelectedServices(servicesList.map((row) => row.supplier_service_id));
    } else {
      setSelectedServices([]);
    }
  };

  const handleServiceSelectionChange = (value, data) => {
    let newList = [];
    if (value) {
      newList = [...selectedServices, data.supplier_service_id]
    } else {
      newList = selectedServices.filter((id) => id != data.supplier_service_id)
    }
    setAllServicesSelected(newList.length == selectedServices.length && true || false)
    setSelectedServices(newList);
  };

  const getServicesTitle = () => {
    if (allServicesSelected) {
      return t("All services");
    } else if (!selectedServices.length) {
      return t("Press to select services");
    } else {
      let serviceTitle = "";
      selectedServices.forEach((id) => {
        const service = servicesList.find(
          (item) => item.supplier_service_id == id
        );
        if (service) {
          serviceTitle = serviceTitle
            ? serviceTitle + ", " + (service?.service_label ?? "")
            : service?.service_label ?? "";
        }
      });
      return serviceTitle;
    }
  };

  const onImageSelect = async (event) => {
    if (true) {
      const image = event.target.files[0];
      if (image) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            var width = img.naturalWidth,
              height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            if (width >= 400 && height >= 400) {
              setImagePreview({
                image: image,
              });
              setOffer({
                ...offer,
                image: reader.result,
              });
            } else {
              toast.error("Image resolution must be greater then 400X400");
              event.preventDefault();
            }
          };

        });
        reader.readAsDataURL(image);
      }
    }
  };


  const CustomInputStartDate = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {`${moment(offer.start_date).format('DD/MM/YYYY - HH:mm')}`}
    </button>
  ));
  const CustomInputEndDate = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {`${moment(offer.expiry_date).format('DD/MM/YYYY - HH:mm')}`}
    </button>
  ));

  return (
    <Container className="offers-form">
      <Modal.Body>
        <Row className="m-0">
          {isLoading && <FullPageSpinner /> || null}
          <Col xl={(discountType == 'offer' && '12') || '12'} lg={(discountType == 'offer' && '12') || '12'} md={(discountType == 'offer' && '12') || '6'} sm="12" className="m-0">
            <UserInput
              required
              label={t("Name")}
              name="name"
              placeholder="eg: Mothers Day"
              value={offer.name}
              onChange={handleChange}
            />
            {simpleValidator.current.message("name", offer.name, "required")}
          </Col>
        </Row>
        <Row className="m-0 mt-3">
          <Col xl="3" lg="3" md="4" sm="4" className="mb-3">
            <div className="value-type-cont">
              <div className="label-cont">
                <p>{t("Value Type")}</p>
              </div>
              <div className="items-cont">
                <p onClick={() => handleDDChange('value', 'value_type')} className={`value-type-item ${offer.value_type == 'value' && 'value-type-active'}`}>SAR</p>
                <p onClick={() => handleDDChange('percentage', 'value_type')} className={`value-type-item ${offer.value_type == 'percentage' && 'value-type-active'}`}>%</p>
              </div>
            </div>
            {simpleValidator.current.message(
              "value type",
              offer.value_type,
              "required"
            )}
          </Col>
          <Col xl={offer.value_type == 'percentage' && "4" || "8"} lg={offer.value_type == 'percentage' && "4" || "8"} md="8" sm="8" className="mb-3">
            <UserInput
              required
              label={t("Value")}
              name="amount"
              placeholder={t("Value")}
              value={offer.amount}
              onChange={handleChange}
            />
            {simpleValidator.current.message("value", offer.amount, "required")}
          </Col>
          {offer.value_type == 'percentage' && <Col xl="5" lg="5" md="12" sm="12" className="mb-3">
            <UserInput
              required
              label={t("Maximum Amount")}
              name="max_amount"
              placeholder={t("Max Amount")}
              value={offer.max_amount}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "max amount",
              offer.max_amount,
              "required"
            )}
          </Col> || null}
          {discountType == 'promotion' && (
            <>
              <Col xl='6' lg='6' md='6' sm='12'>
                <UserInput
                  required={offer?.generate_unique_codes == '0' && true || false}
                  disabled={(offer.offer_id || offer?.generate_unique_codes == '1') || false}
                  label="Coupon Code"
                  name="coupon_code"
                  placeholder="eg: mother50off"
                  value={offer.coupon_code}
                  onChange={handleChange}
                />
                {offer?.generate_unique_codes == '0' && simpleValidator.current.message(
                  "coupon_code",
                  offer.coupon_code,
                  "required"
                )}
              </Col>
              <Col md="6" sm="12" className="mb-3">
                <div className="display-cb-cont">
                  {(true) && <div className="label-cont">
                  </div> || null}
                  <div className="checkbox-label display">
                    <CheckBoxInput
                      checked={offer?.generate_unique_codes == "1" && true || false}
                      onChange={(e) => setIsDisplayed(e, 'generate_unique_codes')}
                    />
                    <Form.Label>{`${t("Auto Create")} `}&nbsp; </Form.Label>
                  </div>
                </div>
              </Col>
            </>
          ) || null}
          {discountType == "promotion" && (
            <Col xl="6" lg="6" md="6" sm="12" className="mb-3">
              <UserInput
                required
                label={t("Total Coupons Available")}
                name="limit"
                placeholder={t("Total Coupons Available")}
                value={offer.limit}
                onChange={handleChange}
              />
              {simpleValidator.current.message(
                "limit",
                offer.limit,
                "required"
              )}
            </Col>
          )}
          {discountType == "promotion" && (
            <Col xl="6" lg="6" md="6" sm="12" className="mb-3">
              <UserInput
                disabled={offer?.generate_unique_codes == '1' && true || false}
                required={offer?.generate_unique_codes == '0' && true || false}
                label="Max Coupons per User"
                name="limit_per_user"
                placeholder="eg: 2 coupons per user"
                value={offer.limit_per_user}
                onChange={handleChange}
              />
              {offer?.generate_unique_codes == '0' && simpleValidator.current.message(
                "limit_per_user",
                offer.limit_per_user,
                "required"
              )}
            </Col>
          )}
          <Col xl="8" lg="8" md="12" sm="12" className="mb-3">
            <Row>
              <Col xl='6' lg='6' md='6' sm='12'>
                <div className="validity-cont">
                  <div className="label-cont">
                    <p>{t('Discount validity from')}</p>
                  </div>
                  <div className="date-cont">
                    <DatePicker
                      selected={new Date(offer.start_date)}
                      onChange={(date) => handleDateChange('start_date', date)}
                      customInput={<CustomInputStartDate />}
                      dateFormat="d MMM yyyy h:mm aa"
                      startDate={new Date(offer.start_date)}
                      endDate={offer.expiry_date && new Date(offer.expiry_date) || null}
                      // selectsRange
                      showTimeInput
                      selectsStart
                    />
                  </div>
                </div>
                {!offer.offer_id && simpleValidator.current.message(
                  "start_date",
                  offer.start_date,
                  "required|start_date"
                )}
              </Col>
              <Col xl='6' lg='6' md='6' sm='12'>
                <div className="validity-cont">
                  <div className="label-cont">
                    <p>{t('Discount validity expiry')}</p>
                  </div>
                  <div className="date-cont">
                    <DatePicker
                      selected={new Date(offer.expiry_date)}
                      onChange={(date) => handleDateChange('expiry_date', date)}
                      customInput={<CustomInputEndDate />}
                      dateFormat="d MMM yyyy h:mm aa"
                      startDate={new Date(offer.start_date)}
                      endDate={offer.expiry_date && new Date(offer.expiry_date) || null}
                      // selectsRange
                      showTimeInput
                      selectsEnd
                    />
                  </div>
                </div>
              </Col>
              {simpleValidator.current.message(
                "expiry_date",
                offer.expiry_date,
                "required|expiry_date"
              )}
            </Row>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12" className="mb-3">
            <div className="display-cb-cont">
              {(window?.innerWidth > 992) && <div className="label-cont">
              </div> || null}
              <div className="checkbox-label display">
                <CheckBoxInput
                  checked={offer?.is_continuous == "1" && true || false}
                  onChange={(e) => setIsDisplayed(e, 'is_continuous')}
                />
                <Form.Label>{`${t("Is Continuous")} `}&nbsp; </Form.Label>
              </div>
            </div>
          </Col>
          {isUserAdmin && <Col xl="4" lg="4" md="6" sm="12" className="mb-3">
            <SearchableDropdown
              onChange={(value) => handleDDChange(value?.value, 'category_id')}
              name="category_id"
              value={
                serviceCategories.find(
                  (row) =>
                    row.value == (offer && offer.category_id)
                ) || ""
              }
              options={serviceCategories}
              placeholder={t("Select Category")}
              label={t("Category")}
            />
          </Col> || null}
          {isUserAdmin && (
            <Col xl="4" lg="4" md="6" sm="12" className="mb-3">
              <CustomDropdown
                onChange={(value) =>
                  handleDDChange(value?.value, "group_id")
                }
                name="group_id"
                options={groups || []}
                label={t("Group")}
                value={
                  groups.find(
                    (row) => row.value == +offer?.group_id
                  ) || ""
                }
              />
            </Col>
          )}
          <Col xl="4" lg="4" md="6" sm="6" className="mb-3">
            <div className="display-cb-cont">
              {isUserAdmin && <div className="label-cont">
              </div> || null}
              <div className="checkbox-label display">
                <CheckBoxInput
                  checked={offer?.is_displayed == "1" && true || false}
                  onChange={(e) => setIsDisplayed(e, 'is_displayed')}
                />
                <Form.Label>{`${t("Display")} `}&nbsp; </Form.Label>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-2">{t('Check to DISPLAY this in Customer app banners')}</Tooltip>}
                >
                  <img
                    src={infoIco} alt="infoIco"
                    className="info-ico-cont"
                  />
                </OverlayTrigger>
              </div>
            </div>
          </Col>
          {partnerId && (
            <Col xl="12" lg="12" md="12" sm="12" className="mb-3" onClick={() => setShowSSModal(true)}>
              <UserInput value={getServicesTitle()} disabled />
            </Col>
          ) || null}
        </Row>
        <Row className="m-0">
          <Col md="12" className="image-wrapper">
            <div>
              <input
                type="file"
                name="myfile"
                accept=".png, .jpg, .jpeg"
                onChange={onImageSelect}
              />
            </div>
            <BSImage
              src={offer?.image}
              alt="Select banner image."
              style={{
                width: '500px',
                height: '200px',
                objectPosition: 'left',
                objectFit: 'contain'
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="footer">
        <div className="checkbox-label">
          <CheckBoxInput
            checked={offer?.is_published == "1" && true || false}
            onChange={(e) => setIsDisplayed(e, 'is_published')}
            disabled={offer.status == "Published" || false}
          >
            {t("Publish offer now?")}
          </CheckBoxInput>
          <small>{t("No changes can be done once published")}</small>
        </div>
        <Button
          disabled={isLoading || offer.status == "Published"}
          onClick={handleSaveOffer}
          className="save-btn"
        >
          {t("Save")}
        </Button>
      </Modal.Footer>


      {/* select services modal */}
      <Modal
        className="staff-select-service-popup"
        centered
        size="md"
        show={showSSModal}
        onHide={() => {
          setShowSSModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Select Services")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                {/* <SearchInput placeholder={"Search Services"} /> */}
                <ul>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedServices.length == servicesList.length && true || false}
                        onChange={(event) =>
                          handleSelectAllServiceChange(event.target.checked)
                        }
                      />
                      {t("All Services")}{" "}
                      <small>({selectedServices.length})</small>
                      <br />
                      {/* <small className="second"></small> */}
                    </label>
                  </li>
                  {servicesList?.length ? (
                    servicesList.map((row, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            checked={
                              allServicesSelected
                                ? true
                                : selectedServices.find(
                                  (id) => id == row.supplier_service_id
                                )
                                  ? true
                                  : false
                            }
                            onChange={(event) =>
                              handleServiceSelectionChange(
                                event.target.checked,
                                row
                              )
                            }
                          />
                          {row.service_label}
                        </label>
                      </li>
                    ))
                  ) : (
                    <div className="no-service-container">
                      <h4>{t("No services available")}</h4>
                    </div>
                  )}
                </ul>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            text={t("Cancel")}
            onClick={() => {
              setShowSSModal(false);
            }}
          />
          <PrimaryButton text={t("OK")} onClick={() => setShowSSModal(false)} />
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default OfferForm;

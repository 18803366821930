import React from "react";
import { Form } from "react-bootstrap";
import "./Styles.scss";
const CheckBoxInput = (props) => {
  const { onChange, checked, classNames, onClick, disabled } = props;
  return (
    <Form.Group
      onClick={onClick ? onClick : () => {}}
      className={`custom-checkbox ${classNames}`}
    >
      <Form.Check
        disabled={disabled}
        type="checkbox"
        label={props.children}
        onChange={onChange}
        checked={checked}
      />
    </Form.Group>
  );
};

export default CheckBoxInput;

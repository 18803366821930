import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/Primary';
import CustomDropdown from '../../../components/Dropdown';
import FullPageSpinner from '../../../components/FullPageSpinner';
import Hoc from '../../Hoc';
import './Styles.scss';
import PartnersGraph from './partnersGraph';
import {
	getGTVReport,
	getPartnersSummaryReport,
} from '../../../core/Api/Reports';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import HorizontalBarGraph from './HorizontolBarGraph';
import BarGraph from './BarGraph';
import VouchersSalesGraph from './VouchersGraph';
import moment from 'moment';
import { gteYearOptionsList } from '../../../utils/helper';

const GrossTransactionsReport = () => {
	const yearOptions = gteYearOptionsList() ?? [];
	const { t } = useTranslation();
	const [data, setData] = useState({});
	const [filterSchema, setFilterSchema] = useState({
		year: moment().format('yyyy'),
		quarter: null,
	});
	const [isLoading, setLoading] = useState(true);

	const quarterOptions = [
		{ label: t('Please Select'), value: null },
		{ label: t('Q1'), value: 'Q1' },
		{ label: t('Q2'), value: 'Q2' },
		{ label: t('Q3'), value: 'Q3' },
		{ label: t('Q4'), value: 'Q4' },
	];

	useEffect(() => {
		fetchBookings(filterSchema);
	}, []);

	const getMonthName = (params) => {
		if (params) {
			const values = params?.split('-');
			const mName = (values?.length && values?.length > 1 && values[0]) || '';
			switch (mName) {
				case 'January':
					return 'Jan';
				case 'February':
					return 'Feb';
				case 'March':
					return 'Mar';
				case 'April':
					return 'Apr';
				case 'May':
					return 'May';
				case 'June':
					return 'June';
				case 'July':
					return 'July';
				case 'August':
					return 'Aug';
				case 'September':
					return 'Sep';
				case 'October':
					return 'Oct';
				case 'November':
					return 'Nov';
				case 'December':
					return 'Dec';
				default:
					return mName;
			}
		} else {
			return '';
		}
	};

	const fetchBookings = async (filters) => {
		setLoading(true);

		const gtvRes = await getGTVReport({
			year: filters?.year,
			quarter: filters?.quarter,
		});
		if (gtvRes.code == 200) {
			const data = {
				totalGTV: parseFloat(
					gtvRes?.result?.total_gtv_since_beginning ?? 0
				).toFixed(0),
				totalGTVThisYear: parseFloat(
					gtvRes?.result?.total_gtv_of_year ?? 0
				).toFixed(0),
				numberOfSalesThisYear: gtvRes?.result?.total_sales_this_year_with_offer,
				totalGiftsSoldThisYear: gtvRes?.result?.total_sold_gifts,
				avgDailyBookings: {
					salon: parseFloat(
						gtvRes?.result?.average_daily_bookings?.salon ?? 0
					).toFixed(0),
					freelancer: parseFloat(
						gtvRes?.result?.average_daily_bookings?.freelancer ?? 0
					).toFixed(0),
				},
				avgMonthlyBookings: {
					salon: parseFloat(
						gtvRes?.result?.average_monthly_bookings?.salon ?? 0
					).toFixed(0),
					freelancer: parseFloat(
						gtvRes?.result?.average_monthly_bookings?.freelancer ?? 0
					).toFixed(0),
				},
				monthlyRevenue: {
					labels: _.map(gtvRes?.result?.monthly_revenue, (r) => {
						return getMonthName(r?.month || '-');
					}).reverse(),
					revenue: _.map(gtvRes?.result?.monthly_revenue, (r) =>
					(r?.total_amount && parseFloat(r?.total_amount??0).toFixed(2)) || 0
					).reverse(),
				},
				numberOfCodesSold: _.map(
					gtvRes?.result?.weekly_sales_with_code,
					(sale) => {
						return {
							title: sale?.offer ?? '',
							labels: _.map(sale?.data, (r) => {
								const weekOfYear = r?.week?.split('-')[1];
								const year = r?.week?.split('-')[0];
								// Get the first day of the week based on the week number and year
								const firstDayOfWeek = moment()
									.year(year)
									.isoWeek(weekOfYear)
									.startOf('isoweek');
								// Get the month name
								const monthName = firstDayOfWeek.format('MMM');
								// Calculate the week of the month
								const weekOfMonth = Math.ceil(firstDayOfWeek.date() / 7);
								return `${weekOfMonth}W-${monthName}`;
							}),
							completedTrans: _.map(sale?.data, (r) => r?.Completed ?? 0),
							confirmedTrans: _.map(sale?.data, (r) => r?.Confirmed ?? 0),
						};
					}
				),
				numberOfVouchersSold: {
					labels: _.map(gtvRes?.result?.weekly_sales_gift_vouchers, (r) => {
						const weekOfYear = r?.week?.split('-')[1];
						const year = r?.week?.split('-')[0];
						// Get the first day of the week based on the week number and year
						const firstDayOfWeek = moment()
							.year(year)
							.isoWeek(weekOfYear)
							.startOf('isoweek');
						// Get the month name
						const monthName = firstDayOfWeek.format('MMM');
						// Calculate the week of the month
						const weekOfMonth = Math.ceil(firstDayOfWeek.date() / 7);
						return `${weekOfMonth}W-${monthName}`;
					}),
					totalSales: _.map(
						gtvRes?.result?.weekly_sales_gift_vouchers,
						(r) => r?.total_sales ?? 0
					),
				},
			};
			setData({ ...data });
		} else {
			toast.error(gtvRes && gtvRes.message);
		}

		setLoading(false);
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleSearch = async () => {
		fetchBookings({ ...filterSchema });
	};

	return (
		// <Hoc activeMenu="grossTransactionsReport">
			<div className="grossTransactionsReport">
				<div className="table-wrapper pr-3">
					{isLoading && <FullPageSpinner />}
					<Row className="filtersCont">
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'year')}
								name="year"
								value={
									yearOptions?.find((r) => r.value == filterSchema?.year) ||
									null
								}
								options={yearOptions}
								label={t('Select Year')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'quarter')}
								name="quarter"
								value={
									quarterOptions?.find(
										(r) => r.value == filterSchema?.quarter
									) || null
								}
								options={quarterOptions}
								label={t('Select Quarter')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<PrimaryButton
								disabled={isLoading}
								onClick={handleSearch}
								text={t('Search')}
							/>
						</Col>
					</Row>

					<Container className="mainContent" fluid>
						<Row className="topRowCont">
							<Col lg={4} md={6} sm={12} className="headingCont">
								<div className="box-content">
									<h4 className="text">{t('Total GTV')}</h4>
									<p className="text">{t('Gross Transaction Value')}</p>
								</div>
							</Col>
							<Col lg={4} md={6} sm={12} className="box-cont">
								<div className="box-content box1">
									<p className="num">{data?.totalGTV}</p>
									<p className="text">{t('Total GTV since beginning')}</p>
								</div>
							</Col>
							<Col lg={4} md={6} sm={12} className="box-cont">
								<div className="box-content box2">
									<p className="num">{data?.totalGTVThisYear} </p>
									<p className="text">
										{t('Total GTV')} {filterSchema?.year}
									</p>
								</div>
							</Col>
						</Row>

						{true && (
							<Row className="horizontal-bar0graph-cont">
								<Col lg={8} md={8} sm={12}>
									<div className="multiGraphContent">
										<HorizontalBarGraph
											graphsData={data?.monthlyRevenue ?? {}}
										/>
									</div>
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className="barGraphContent">
										<div className="bottomSecContent">
											<p>{t('')}</p>
											<div className="barGraphCont">
												<BarGraph
													graphsData={data?.avgDailyBookings ?? {}}
													title={t('Average Daily Bookings')}
												/>
											</div>
											<div className="barGraphCont">
												<BarGraph
													graphsData={data?.avgMonthlyBookings ?? {}}
													title={t('Average Monthly Bookings')}
												/>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						)}
					</Container>

					{true && (
						<Container className="mainContent mt-5" fluid>
							<Row className="topRowCont">
								<Col lg={3} md={6} sm={12} className="headingCont">
									<div className="box-content">
										<h4 className="text">{t('Sales')}</h4>
										<p className="text">{t('Code Discount')}</p>
									</div>
								</Col>
								<Col lg={9} md={6} sm={12} className="box-cont">
									<div className="box-content discount-box">
										<div>
											<p className="num">{data?.numberOfSalesThisYear}</p>
											<span>{t('From 1Jan till now')}</span>
										</div>
										<div>
											<p className="text">{t('Total Sold')}</p>
											<span>(t{t('Completed')})</span>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					)}

					{true && (
						<Container className="mainContent discount-codes-cont mt-3" fluid>
							<div className="discount-codes-content">
								{data?.numberOfCodesSold?.map((r, i) => (
									<Row className="graphsCont" key={i}>
										<Col lg={12} md={12} sm={12}>
											<div className="multiGraphContent">
												<PartnersGraph graphsData={r} showLegend={true} />
											</div>
										</Col>
									</Row>
								))}
							</div>
						</Container>
					)}

					<Container className="mainContent mt-5" fluid>
						{true && (
							<Row className="topRowCont">
								<Col lg={4} md={6} sm={12} className="headingCont">
									<div className="box-content">
										<h4 className="text">{t('Sales')} </h4>
										<p className="text">{t('Gift Vouchers')}</p>
									</div>
								</Col>
								<Col lg={8} md={6} sm={12} className="box-cont">
									<div className="box-content box3">
										<p className="num">{data?.totalGiftsSoldThisYear} </p>
										<p className="text">{t('Total Sold Gifts')}</p>
									</div>
								</Col>
								{/* <Col lg={4} md={6} sm={12} className="box-cont">
									<div className="box-content box4">
										<p className="num">{data?.totalGiftsSoldThisYear} </p>
										<p className="text">Total Target Gifts</p>
									</div>
								</Col> */}
							</Row>
						)}

						{true && (
							<Row className="graphsCont">
								<Col lg={12} md={12} sm={12}>
									<div className="voucherGraphContent">
										<VouchersSalesGraph
											graphsData={data?.numberOfVouchersSold ?? {}}
											showLegend={true}
										/>
									</div>
								</Col>
							</Row>
						)}
					</Container>
				</div>
			</div>
		// </Hoc>
	);
};

export default GrossTransactionsReport;

import React, { useRef, useState } from "react";
import UserInput from "../../../../components/Inputs";
import { Row, Col, Modal, Button, Container } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { saveSupplierAdmin } from "../../../../core/Api/supplier";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { saveCompanyAdmin } from "../../../../core/Api/company";
import * as _ from 'lodash';
import FullPageSpinner from "../../../../components/FullPageSpinner";

const Adminform = ({ getSupplierAdminslist, handleClose, selectedAdmin }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [adminDetails, setAdminDetails] = useState(() => ({
    ...selectedAdmin,
  }));
  const [, forceUpdate] = useState();
  const handleSaveAdmin = async () => {
    setIsLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          company_id: adminDetails.company_id || 0,
          company_admin_id: adminDetails.company_admin_id || "0",
          first_name: adminDetails.first_name,
          last_name: adminDetails.last_name,
          username: adminDetails.username,
          email: adminDetails.email,
        };
        // const response = await saveCompanyAdmin(_.pickBy(data, _.identity));
        const response = await saveCompanyAdmin(data);
        if (response?.code == 200) {
          toast.success(response.message);
          await getSupplierAdminslist();
          handleClose();
        } else {
          throw new Error(response?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAdminDetails({
      ...adminDetails,
      [name]: value,
    });
  };

  return (
    <Container>
      <Modal.Body>
        {isLoading && <FullPageSpinner /> || null }
        <Row>
          <Col md="6" className="mb-3">
            <UserInput
              label={t("First Name")}
              name="first_name"
              placeholder="Enter First Name"
              value={adminDetails.first_name}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "first_name",
              adminDetails.first_name,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              label={t("Last Name")}
              name="last_name"
              placeholder="Enter Last Name"
              value={adminDetails.last_name}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "last_name",
              adminDetails.last_name,
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-3">
            <UserInput
              label={t("Email")}
              name="email"
              placeholder="Enter Email"
              value={adminDetails.email}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "email",
              adminDetails.email,
              "required|email"
            )}
          </Col>
          <Col md="6">
            <UserInput
              label={t("Username")}
              name="username"
              placeholder="Enter Username"
              value={adminDetails.username}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "username",
              adminDetails.username,
              "required|min:5"
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="footer">
        <Button
          disabled={isLoading}
          onClick={handleSaveAdmin}
          variant="primary"
          className="save-btn"
        >
          Save changes
        </Button>
        <Button onClick={handleClose} variant="white">
          Close
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default Adminform;

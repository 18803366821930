import React from "react";
import PushNotificationForm from "./Form";
import Hoc from "../Hoc";
import './Styles.scss'

const PushNotifications = () => {
  return (
    <Hoc activeMenu="push_notifications">
      <PushNotificationForm />
    </Hoc>
  );
};

export default PushNotifications;

import React from "react";
import PropTypes from "prop-types";
import "./Styles.scss";
import { Spinner, Button } from "react-bootstrap";

const DangerButton = (props) => {
  const { style, text, textStyle, isLoading,classNames, ...rest } = props;
  return (
    <Button className={`${classNames} danger-button-container`} style={style} {...rest}>
      <div className={"button-text"} style={textStyle}>
        {isLoading ? <Spinner animation="border" /> : text}
      </div>
    </Button>
  );
};

DangerButton.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  textStyle: PropTypes.object,
};

export default DangerButton;

import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner, Image, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { capitalCase } from "capital-case";
import moment from "moment";
import SearchInput from "../../components/Inputs/Search";
import { toast } from "react-toastify";
import locationIcon from "../../assets/images/geo-alt-fill.svg";
import carIcon from "../../assets/images/car.png";
import menuIcon from "../../assets/images/menu-button-wide.svg";
import receiptIcon from "../../assets/images/receipt.svg";
import playIcon from "../../assets/images/play-circle.svg";
import stopIcon from "../../assets/images/stop-circle.svg";
import DriverModal from "./DriverModal";
import PrimaryButton from "../../components/Buttons/Primary";
import FullScreenPopup from "../../components/FullScreenPopup";
import ViewAppointment from "../Client/Profile/ViewAppointment";
import DateInput from "../../components/DateInput";
import { getAllBookings } from "../../core/Api/bookings";
import { checkActionPermission, getAuthAttribute } from "../../utils/helper";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Styles.scss";
import {
  endJourneyRequest,
  startJourneyRequest,
  updatedCurrentLocation,
} from "../../core/Api/Driver";
import Pagination from "../../components/Pagination";
import FullPageSpinner from "../../components/FullPageSpinner";
import { useRouteMatch } from "react-router-dom";
import CustomTableComponent from "../../components/CustomTableComponent";

const BookingsTable = ({ history }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const supplier_id = match?.params?.id || getAuthAttribute("supplier_id");
  const pageSize = 10;
  const offset = 0;
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSchema, setFilterSchema] = useState({
    date_from: null,
    date_to: null,
    search_phrase: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [csvLoader, setCSVLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [driverInfo, setDriverInfo] = useState(null);
  const [intervals, setIntervals] = useState({});
  const isDriverScreen = getAuthAttribute("sub_user_type_id") == "6";


  useEffect(() => {
    const state = history.location.state;
    if (state?.viewAppointment) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [history.location.state]);

  useEffect(() => {
    fetchBookings(10, 0);
    setCurrentPage(1)
    // eslint-disable-next-line
  }, [filterSchema]);

  //un-used
  useEffect(() => {
    if (isDriverScreen) {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state == "granted") {
              navigator.geolocation.getCurrentPosition(function (position) {
                console.log("position", { position });
              });
            } else if (result.state == "prompt") {
              navigator.geolocation.getCurrentPosition(function (position) {
                console.log("position", { position });
              });
            } else if (result.state == "denied") {
              alert("Please enable location access");
            }
          });
      } else {
        alert("Location not available, please switch to a different browser");
      }
    }
  }, [isDriverScreen]);

  const handleSearchChange = (e) => {
    setFilterSchema({
      ...filterSchema,
      search_phrase: e.target.value,
    })
  };

  const handleDateChange = (date, name) => {
    setFilterSchema({
      ...filterSchema,
      [name]: date && new Date(date) || null,
    });
  };

  const fetchBookings = async (pageSize, offset) => {
    setLoading(true);
    const response = await getAllBookings(
      pageSize,
      offset,
      filterSchema?.search_phrase?.trim(),
      filterSchema.date_from,
      filterSchema.date_to,
      true,
      supplier_id
    );
    if (response.code == 200) {
      setData(response?.result?.data || []);
      setTotalCount(response?.result?.total_count || 0);
    } else {
      toast.error(response && response.message);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNo, pageSize) => {
    setCurrentPage(pageNo);
    fetchBookings(pageSize, (pageNo - 1) * pageSize)
  };

  const handleCSVExport = async () => {
    try {
      setCSVLoader(true);
      const response = await getAllBookings(
        totalCount,
        0,
        filterSchema?.search_phrase?.trim() || null,
        filterSchema?.date_from || null,
        filterSchema?.date_to || null,
        false,
        supplier_id
      );
      if (response.code == 200) {
        const bookings = response?.result || [];
        let csv =
          "Supplier Id, Reference Number, Customer, Supplier, Booking Type,Booking From,  Special Insctructions, Amount, Payment Mode, Promo Code, Guest Booking, Address, Created At, Appointment Date, Status\r\n";
        const rows = [];
        for (let i = 0; i < bookings?.length; i++) {
          const item = bookings[i];
          rows.push(`${item.supplier_id},${item.reference_number
            },${item?.first_name || ''} ${item?.last_name || ''},${capitalCase(
              item.name
            )},${capitalCase(item.location_type)},${formatBookingFrom(item, 'application_type')},"${item.notes ? item.notes : "NA"
            }",${item.total_amount} SAR,${getPaymentModes(item, 'payment_mode' || [])},${item?.offer_details?.coupon_code||''},${item.is_guest_booking == "1" ? "Yes" : "No"
            },"${item.location}",${moment(item.date).format(
              "YYYY-MM-DD"
            )},${moment(item.appointment_date).format(
              "YYYY-MM-DD"
            )},${item.status}\r\n`)
        }
        rows.forEach((row) => {
          csv += row;
        });
        const generationDate = new Date().toLocaleDateString("en-US");
        const link = document.createElement("a");
        link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        link.target = "_blank";
        link.download = `Bookings_${generationDate}.csv`;
        link.click();
        setCSVLoader(false);
      } else {
        toast.error(response && response.message);
        setCSVLoader(false);
      }
    } catch (error) {
      toast.error(error && error.message);
      setCSVLoader(false);
    }
  };

  const handleRedirectToLocation = (lat_lng) => {
    lat_lng = lat_lng.split(",").map((val) => val?.trim());
    window.open(
      `https://maps.google.com/maps?z=12&t=m&q=${lat_lng[0]}+${lat_lng[1]}`,
      "_blank"
    );
  };

  const addressFormatter = (row) => {
    if (!row.lat_lng) {
      return "";
    }
    if (row?.is_home == "1") {
      return (
        <div
          className="addressWrapper"
          onClick={() => handleRedirectToLocation(row.lat_lng)}
        >
          <img src={locationIcon} alt="location" />
          <span>Map View</span>
        </div>
      );
    }else{
      return 'N/A'
    }
  };

  const formatCustomer = (row) => {
    return `${row?.first_name} ${row?.last_name}`
  };

  const formatBookingFrom = (row, key) => {
    switch (row?.application_type?.toLowerCase()) {
      case "customer/android":
        return "Android";
      case "customer/ios":
        return "iOS";
      case "backoffice":
        return "Backoffice";
      default:
        return "N/A";
    }
  };

  const statusFormatter = (row, key) => {
    const style = ((status) => {
      switch (status) {
        case "In Progress":
          return {
            color: "#7effd7",
            fontFamily: "Nunito Sans-extrabols",
            fontSize: "16px",
          };
        case "Cancelled":
          return {
            color: "#dd3333",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
        case "Completed":
          return {
            color: "#097969",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
        default:
          return {
            color: "#ffa470",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
      }
    })(row?.status);
    return (
      <div className="status-formatter" style={style}>
        {t(row?.status)}
      </div>
    );
  };

  const openOverlay = (bookingId) => {
    let pathname = history.location.pathname + history.location.search;
    if (bookingId) {
      pathname += `?booking_id=${bookingId}`;
    }
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      viewAppointment: true,
    });
  };

  const handleUpdateLocation = (booking_id, supplier_id) => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords || {};
      if (latitude && longitude) {
        try {
          await updatedCurrentLocation(
            supplier_id,
            booking_id,
            latitude,
            longitude
          );
        } catch (error) {
          console.log("ERROR:", error);
        }
      }
    });
  };

  const startTracking = (booking_id, supplier_id) => {
    const updatedIntervals = { ...intervals };
    if (updatedIntervals[booking_id]) {
      clearInterval(updatedIntervals[booking_id]);
    }
    handleUpdateLocation(booking_id, supplier_id);
    updatedIntervals[booking_id] = setInterval(
      () => handleUpdateLocation(booking_id, supplier_id),
      15000
    );
    setIntervals(updatedIntervals);
  };

  const startJourney = async (booking_id, supplier_id) => {
    try {
      const response = await startJourneyRequest(booking_id, supplier_id);
      if (response.code == 200) {
        toast.success("Journey Started");
        const updatedData = [...data];
        const updatedPageData = [...updatedData[currentPage - 1]].map(
          (item) => ({
            ...item,
            journey_status:
              item.booking_id == booking_id
                ? item.journey_status == "1"
                  ? "0"
                  : "1"
                : item.journey_status,
          })
        );
        startTracking(booking_id, supplier_id);
        updatedData[currentPage - 1] = updatedPageData;
        setData(updatedData);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error?.message ?? "Something went wrong!");
    }
  };

  const stopJourney = async (booking_id, supplier_id) => {
    try {
      const response = await endJourneyRequest(booking_id, supplier_id);
      if (response.code == 200) {
        toast.success("Journey Ended");
        const updatedData = [...data];
        clearInterval(intervals[booking_id]);
        const updatedIntervals = { ...intervals };
        delete updatedIntervals[booking_id];
        setIntervals(updatedIntervals);
        const updatedPageData = [...updatedData[currentPage - 1]].map(
          (item) => ({
            ...item,
            journey_status:
              item.booking_id == booking_id
                ? item.journey_status == "1"
                  ? "0"
                  : "1"
                : item.journey_status,
          })
        );
        updatedData[currentPage - 1] = updatedPageData;
        setData(updatedData);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error?.message ?? "Something went wrong!");
    }
  };

  const formatter = (row) => {
    return isDriverScreen ? (
      <div className="driverIcon">
        {row.journey_status == "1" ? (
          <Image
            src={stopIcon}
            onClick={() => stopJourney(row.booking_id, row.supplier_id)}
            onLoad={() => startTracking(row.booking_id, row.supplier_id)}
            alt="end-journey"
          />
        ) : (
          <Image
            src={playIcon}
            onClick={() => startJourney(row.booking_id, row.supplier_id)}
            alt="start-journey"
          />
        )}
      </div>
    ) : (
      <div className="iconsWrapper">
       {checkActionPermission("bookings","canView")&&(
        <>
         <Image
          onClick={() => window.open(`/invoice/${row.booking_id}`)}
          src={receiptIcon}
          alt="invoice"
        />
         <Image
          src={menuIcon}
          onClick={() => openOverlay(row.booking_id)}
          alt="appointment"
        />
        </>
       )||null}
        {(row?.is_home === "1" && (row?.status !== 'Completed' && row?.status !== 'Cancelled')) && (<Image
          onClick={() => setDriverInfo(row)}
          src={carIcon}
          alt="driver-location"
        />) || null}
      </div>
    );
  };

  const getPaymentModes = (row) => {
    let mode = ''
    if (Array.isArray(row?.payment_mode) && row?.payment_mode.length) {
      row?.payment_mode?.forEach((r, i) => {
        mode += `${i > 0 && ' - ' || ''}${r?.pay_mode?.toUpperCase()}`
      })
    } else {
      mode = row?.payment_mode;
    }
    return mode;
  }

  const amountFormatter = (row, key) => {
    return <span>{`${row[key]} SAR`}</span>
  }

  const renderGuestInfo = (props, row) => {
    return (
      <Tooltip {...props}>
        <div className="p-2"
        >
          <div className="row">
            <div className="col-4 text-left text-nowrap">{t('Name')}</div>
            <div className="col-8 text-left text-nowrap">{row?.guest_name}</div>
          </div>
          <div className="row">
            <div className="col-4 text-left text-nowrap">{t('Mobile#')}</div>
            <div className="col-8 text-left text-nowrap">{row?.guest_mobile}</div>
          </div>
          <div className="row">
            <div className="col-4 text-left text-nowrap">{t('Email')}</div>
            <div className="col-8 text-left text-nowrap">{row?.guest_email}</div>
          </div>
        </div>
      </Tooltip>
    )
  };

  const guestInfoFormatter = (row) => {
    if (row?.is_guest_booking == "1") {
      return <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 1 }}
        overlay={(props) => renderGuestInfo(props, row)}
      >
        <p style={{ width: 'fit-content' }}>{t(`Yes`)}</p>
      </OverlayTrigger>
    } else {
      return <p style={{ width: 'fit-content' }}>{t(`No`)}</p>
    }
  }

  const dateFormatter = (row, key) => {
    return <span className="text-nowrap">{moment(row[key]).format("YYYY-MM-DD")}</span>
  }

  const columns = [
    {
      name: t("Reference #"),
      key: 'reference_number',
      formatter: null,
    },
    {
      name: t("Customer"),
      key: 'first_name',
      formatter: formatCustomer,
    },
    {
      name: t("Supplier"),
      key: 'name',
      formatter: null,
    },
    {
      name: t("Booking Type"),
      key: 'location_type',
      formatter: null,
    },
    {
      name: t("Special Instructions"),
      key: 'notes',
      formatter: (row, key) => {
        return <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">{row[key]}</Tooltip>}
        >
          <span style={{
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: "inline-block",
            cursor: 'pointer'
          }}>{row[key]}</span>
        </OverlayTrigger>
      },
    },
    {
      name: t("Booking From"),
      key: 'application_type',
      // formatter: formatBookingFrom,
    },
    {
      name: t("Amount"),
      key: 'total_amount',
      formatter: amountFormatter,
    },
    {
      name: t("Payment Mode"),
      key: 'payment_mode',
      formatter: getPaymentModes,
    },
    {
      name: t("Promo Code"),
      key: 'promo_code',
      formatter: (row)=>{
        return <span>{row?.offer_details?.coupon_code}</span>
      },
    },
    {
      name: t("Guest Booking"),
      key: 'is_guest_booking',
      formatter: guestInfoFormatter,
    },
    {
      name: t("Address"),
      key: 'lat_lng',
      formatter: addressFormatter,
    },
    {
      name: t("Date Created"),
      key: 'date',
      formatter: dateFormatter,
    },
    {
      name: t("Appointment Date"),
      key: 'appointment_date',
      formatter: dateFormatter,
    },
    {
      name: t("Status"),
      key: 'status',
      formatter: statusFormatter,
    },
    {
      name: t("Other"),
      key: 'actions',
      formatter: formatter,
    },
  ]



  return (
    <div className="bookings">
      {csvLoader && <FullPageSpinner /> || null}
      <div className="table-wrapper">
        <Row className="search-wrap align-items-end">
          <Col lg="3" md="6" sm="12">
            <DateInput
              label={t("From date")}
              value={filterSchema.date_from}
              onChange={(date) => handleDateChange(date, "date_from")}
              isClearable={true}
            />
          </Col>
          <Col lg="3" md="6" sm="12">
            <DateInput
              label={t("To date")}
              className="ml-3"
              value={filterSchema.date_to}
              onChange={(date) => handleDateChange(date, "date_to")}
              isClearable={true}
            />
          </Col>
          <Col className="mt-3" lg="3" md="6" sm="12">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
          <Col className="mt-3" lg="3" md="6" sm="12">
            <PrimaryButton
              disabled={isLoading || csvLoader}
              onClick={handleCSVExport}
              text={t('Export as CSV')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoading && (
            <div className="spinner-wrap">
                <FullPageSpinner animation="border" />
                <span>
                  {t("Please wait, we're loading data")}
                </span>
              </div>
              ) || null}
            {!isLoading && data?.length == 0 && (
              <div className="no-data-text text-center mt-2">
                {t("No data available")}
              </div>
            ) || null}
          </Col>
        </Row>
        <Row>
          {data.length &&
            <>
              <CustomTableComponent
                responsive={true}
                hover={true}
                showSequence={true}
                pageSize={pageSize}
                pageNo={currentPage}
                columns={columns || []}
                data={data || []}
              // styles={{ maxHeight: `${window.innerHeight - 300}px` }}

              />
            </>}
        </Row>
      </div>

      <Row>
        <Pagination
          totalCount={totalCount}
          viewCount={data?.length || 0}
          onChangePage={handlePageChange}
          pageNo={currentPage}
        />
      </Row>

      <FullScreenPopup open={open} onClose={() => history.goBack()}>
        <ViewAppointment history={history} onClose={() => {
          history.goBack();
          fetchBookings(10, 0);
          setCurrentPage(1);
        }} />
      </FullScreenPopup>
      <Modal show={driverInfo} onHide={() => setDriverInfo(null)}>
        <Modal.Header>Assign driver</Modal.Header>
        <DriverModal
          driverInfo={driverInfo}
          handleClose={() => setDriverInfo(null)}
        />
      </Modal>
    </div>
  );
};

export default BookingsTable;

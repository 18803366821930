import axios from "axios";
import config from "../../config";
import { getAuthAttribute } from "../../utils/helper";

const Axios = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    "AUTH-API-KEY": getAuthAttribute("auth_token"),
  },
});

Axios.interceptors.request.use(async (config) => {
  const token =
    getAuthAttribute("auth_token") || localStorage.getItem("boon-temp-token");
  try {
    if (token) {
      config.headers["AUTH-API-KEY"] = token;
    }
  } catch (e) {
    console.error(e);
  }
  config.Accept = "application/json";
  config["Content-Type"] = "application/json";
  config["Access-Control-Allow-Credentials"] = true;
  return config;
});

Axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err && err.response && err.response.status == 404) {
      // window.location.href='/'
    }
    throw err && err.response && err.response.data;
  }
);

export default Axios;

import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';

const BarGraph = ({ graphsData = {} }) => {
	const { activePartners = 0, inactivePartners = 0 } = graphsData;
	const [data, setData] = useState({});
	useEffect(() => {
		setData({
			labels: ['Active Partners', 'In-active Partners'],
			datasets: [
				{
					label: 'Active Partners',
					data: [activePartners],
					backgroundColor: '#a5aeb5',
					barPercentage: 1,
					// barThickness:500
				},
				{
					label: 'In-active Partners',
					data: [inactivePartners],
					backgroundColor: '#3a5f71',
					barPercentage: 1,
				},
			],
		});
	}, [graphsData]);

	return (
		<>
			{!_.isEmpty(data) && (
				<Bar
					options={{
						responsive: true,
						maintainAspectRatio: false,
						indexAxis: 'y',
						elements: {
							bar: {
								borderWidth: 2,
							},
						},
						plugins: {
							legend: {
								display: false,
								position: 'bottom',
							},
							title: {
								display: false,
								text: 'Partners',
								position: 'bottom',
							},
							datalabels: {
								display: true, // Enable or disable data labels for all datasets
								anchor: 'auto', // Position of the label relative to the data point (center, start, end, auto)
								align: 'center', // Text alignment (center, start, end, left, right)
								color: 'white', // Label text color
								font: {
									weight: 'bold', // Label font weight
									size: '10px',
								},
								formatter: (value, context) => {
									return value;
								},
							},
						},
						scales: {
							x: {
								// stacked: true,
								display: false,
								barPercentage: 1,
							},
							y: {
								// stacked: true,
								display: false,
							},
							xAxes: [
								{
									barPercentage: 1,
									// barThickness: 100,
								},
							],
						},
					}}
					data={data}
					plugins={[ChartDataLabels]}
				/>
			)}
		</>
	);
};

export default BarGraph;

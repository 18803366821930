import React, { forwardRef, useState } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import './Styles.scss'

const MapSearch = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [searchBox, setSearchBox] = useState();

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    let res = searchBox.getPlaces();
    props.handleCenterChange(res[0]);
  };

  return (
    <div className="map-search-cont">
      <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onPlacesChanged} className=''>
        <input
          ref={ref}
          className="search-location"
          disabled={props.isDisabled}
          type="text"
          name="searchMap"
          value={props.value}
          placeholder={t("Search here")}
          onChange={props.onChange}
          style={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            width: "180px",
            height: "40px",
            padding: "0 12px",
            borderRadius: "3px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            fontSize: "14px",
            outline: "none",
            textOverflow: "ellipses",
            position: "absolute",
            // left: "50%",
            top: "10px",
            marginLeft: "-120px",
            ...props?.styles
          }}
        />
      </StandaloneSearchBox>
    </div>
  );
});

export default MapSearch;

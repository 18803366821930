import React from "react";
import ComplaintsTable from "./ComplainsList";
import { Container } from "react-bootstrap";
import Hoc from "../Hoc";
import "./Styles.scss";

const Partners = () => {
  return (
    <React.Fragment>
      <Hoc activeMenu="crm_complains">
        <Container fluid className="complains">
          <ComplaintsTable />
        </Container>
      </Hoc>
    </React.Fragment>
  );
};
export default Partners;

import Axios from "../Axios";

export const getAllCustomers = async (search=null, supplier_id=null) => {
  try {
    const response = await Axios.get(`/customer?search_phrase=${search}&supplier_id=${supplier_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSelectedCustomer = async (id) => {
  try {
    const response = await Axios.get(`/customer/index/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCustomers = async (pageNo, pageSize, search = "") => {
  try {
    const response = await Axios.get(
      `/customer?page_number=${pageNo || 1}&page_length=${
        pageSize || 10
      }&&search_phrase=${search}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCustomerById = async (id) => {
  try {
    const response = await Axios.get(`/customer/index/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getInvoicesByCustomerId = async (id) => {
  try {
    const response = await Axios.get(`/booking/invoices?customer_id=${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getInvoiceById = async (id) => {
  try {
    const response = await Axios.get(`/booking/invoice/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateAddress = async (data) => {
  try {
    const response = await Axios.post("/customer/address", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCustomer = async (data) => {
  try {
    const response = await Axios.post("/customer/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCustomerAddress = async (data) => {
  try {
    const response = await Axios.post("/customer/save_address", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCustomerAddress = async (customer_id, address_id) => {
  try {
    const response = await Axios.get(`/customer/delete_address?customer_id=${customer_id}&address_id=${address_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCustomer = async (id) => {
  const data = {
    customer_id: id,
  };
  try {
    const response = await Axios.post("/customer/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const blockCustomer = async (id) => {
  const data = {
    customer_id: id,
  };
  try {
    const response = await Axios.post("/customer/block", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const activeCustomer = async (id) => {
  const data = {
    customer_id: id,
  };
  try {
    const response = await Axios.post("/customer/activate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const CustomerList = async () => {
  try {
    const response = await Axios.get("/customer");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCustomersList = async (params={}) => {
  try {
    const { per_page = 10, offset = 0 } = params;
    const response = await Axios.get(`/customer/list?per_page=${per_page}&offset=${offset}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getBacklistedKeywords = async () => {
  try {
    const response = await Axios.get("/settings/blacklisted_keywords");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteKeywords = async (data) => {
  try {
    const response = await Axios.post(
      "settings/delete_blacklisted_keyword/500",
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveKeywords = async (data) => {
  try {
    const response = await Axios.post(
      `settings/save_blacklisted_keyword`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

import BarGraph from './barGraph';
import PieChart from './pie-chart';

const CardContainer = ({data={}}) => {
	return (
		<div className="card-cont">
			<div className="card-content">
				<div className="header-cont">
					<p>{data?.cityName}</p>
				</div>
				<div className="graphs-cont">
					<div className="graphs-content">
						<div className="bar-graph-cont">
							<BarGraph graphsData={data}/>
						</div>
						<div className="graph-legend-cont">
							<p className="partners-heading">Partners</p>
							<div className="graph-legend-content">
								<div className="left-cont">
									<span className="active" />
									<p>Active</p>
								</div>
								<div className="right-cont">
									<p>{data?.activePartners}</p>
								</div>
							</div>
							<div className="graph-legend-content">
								<div className="left-cont">
									<span className="in-active" />
									<p>In Active</p>
								</div>
								<div className="right-cont">
									<p>{data?.inactivePartners}</p>
								</div>
							</div>
						</div>
						<div className="trans-graph-cont">
							<div className="trans-heading">
								<p>Transactions</p>
							</div>
							<div className="pie-chart-cont">
								<PieChart graphsData={data}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardContainer;

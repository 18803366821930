import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SideMenu from "../SideMenu";
import TopMenu from "../TopMenu";
import Notification from "../Notification";
import "./Styles.scss";

const Hoc = (props) => {
  const [showNotification, setShowNotification] = useState(null);
  const [updateNotificationCount, setUpdateNotificationsCount] = useState(false);

  const toggleNotification = (event) => {
    setShowNotification(event);
  };
  const [profileStatus, setCustProfileStats] = useState(null)

  return (
    <>
      <Container fluid>
        <Row className="mt-0">
          <SideMenu selected={props.activeMenu} profileStatus={profileStatus}/>
          <Col className="main-side">
            <TopMenu
              selected={props.activeMenu}
              handleImageUpload={props.handleImageUpload}
              show={showNotification}
              showNotification={(event) => setShowNotification(event)}
              selectedView={props.selectedView}
              handleViewChange={props.handleViewChange}
              updateProfileStatus={props?.updateProfileStatus || false}
              setCustProfileStats={setCustProfileStats}
              updateNotificationCount={updateNotificationCount}
            />
            <div className="main-container">{props.children}</div>
          </Col>
        </Row>
      </Container>
      <Notification
        show={showNotification}
        toggleNotification={toggleNotification}
        setShowNotification={setShowNotification}
        updateNotificationCount={updateNotificationCount}
        setUpdateNotificationsCount={setUpdateNotificationsCount}
      />
    </>
  );
};
export default Hoc;

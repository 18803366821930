import React from "react";
import { Bar } from "react-chartjs-2";
const BarChart = (props) => {
  const { dataValue } = props;
  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  const data = {
    labels: [],
    datasets: [
      {
        data: [],
        label: "Data",
        backgroundColor: ["#FD941A", "#D4FFEB", "#DFE8FF", "#F9E6FF"],
        borderRadius: 5,
        borderWidth: 1,
      },
    ],
  };
  dataValue.map((row) => data.labels.push(row.name));
  dataValue.map((row) => data.datasets[0].data.push(Number(row.total_booking)));

  return <Bar height={"100%"} data={data} options={options} />;
};

export default BarChart;

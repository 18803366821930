
import { forwardRef } from 'react';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Card from "../../components/Card";
import BarChart from "./BarChart";

const GraphReports = ({
  graphReports = {},
  handleDateChange,
  avgDailyDates = {},
  avgWeeklyDates = {}
}) => {
  const { t } = useTranslation();

  const CustomInputDaily = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {`${moment(avgDailyDates.from_date).format('DD/MM/YYYY')} - ${avgDailyDates.to_date && moment(avgDailyDates.to_date).format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')}`}
    </button>
  ));

  const CustomInputWeekly = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {`${moment(avgWeeklyDates.from_date).format('DD/MM/YYYY')} - ${avgWeeklyDates.to_date && moment(avgWeeklyDates.to_date).format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')}`}
    </button>
  ));

  
  return (
    <>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Average Daily Bookings")}</h3>
            <div>
              <div className="date-cont">
                <DatePicker
                  selected={new Date(avgDailyDates.from_date)}
                  onChange={(date) => handleDateChange('avgDaily', date)}
                  customInput={<CustomInputDaily />}
                  dateFormat="d MMM yyyy"
                  startDate={new Date(avgDailyDates.from_date)}
                  endDate={avgDailyDates.to_date && new Date(avgDailyDates.to_date) || null}
                  selectsRange
                />
              </div>
            </div>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.avgDailyBookings || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Average Weekly Bookings")}</h3>
            <div>
              <div className="date-cont">
                <DatePicker
                  selected={new Date(avgWeeklyDates.from_date)}
                  onChange={(date) => handleDateChange('avgWeekly', date)}
                  customInput={<CustomInputWeekly />}
                  dateFormat="d MMM yyyy"
                  startDate={new Date(avgWeeklyDates.from_date)}
                  endDate={avgWeeklyDates.to_date && new Date(avgWeeklyDates.to_date) || null}
                  selectsRange
                />
              </div>
            </div>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.avgWeeklyBookings || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={12} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Average Prices")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.avgPrices || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Last 3 Months Bookings")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.last3MonthsBookings || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Last 6 Months Bookings")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.last6MonthsBookings || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Last 3 Months Registrations")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.last3MonthsReg || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Last 6 Months Registrations")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.last6MonthsReg || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Last 3 Months Completed Profiles")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.last3CompletedProfiles || []}
            />
          </div>
        </Card>
      </Col>
      <Col lg={6} className="bar-chart mt-4">
        <Card >
          <div className="header-cont">
            <h3>{t("Last 6 Months Completed Profiles")}</h3>
          </div>
          <div>
            <BarChart
              dataValue={graphReports?.last6CompletedProfiles || []}
            />
          </div>
        </Card>
      </Col>
    </>
  )
}

export default GraphReports;
import React, { useState, useEffect, useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "./Styles.scss";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import editIcon from "../../assets/images/Icon material-mode-edit.png";
import deleteIcon from "../../assets/images/trash.svg";
import { Container, Image, Modal, Spinner, Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Buttons/Primary";
import { getBacklistedKeywords, deleteKeywords } from "../../core/Api/customer";
import BlackListForm from "./BlackListForm";
import SearchInput from "../../components/Inputs/Search";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";

const defaultKeyword = {
  blacklisted_word_id: "0",
  word: "",
  status_id: "",
};

const BlacklistedTable = () => {
  const [selectedKeyWord, setSelectedKeyWord] = useState(null);
  const [data, setData] = useState([]);
  const pageSize = 10;
  const totalResults = data.length;
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const filteredData = data.filter((item) =>
    `${item.word.toLowerCase()}`.includes(searchInput.toLowerCase())
  );
  const offset = pageSize * (selectedPage - 1);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const startPageRef = useRef(selectedPage);
  const outputData = filteredData.slice(offset, offset + pageSize);

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handlePageChange = (target) => {
    let newPage = selectedPage;
    if (target == "next") {
      newPage = newPage + 1 <= pageCount ? newPage + 1 : newPage;
    } else if (target == "prev") {
      newPage = newPage - 1 > 0 ? newPage - 1 : newPage;
    } else {
      newPage = target;
    }
    setSelectedPage(newPage);
    if (pageCount > 5) {
      if (target == "next" && newPage % 5 == 1) {
        startPageRef.current += 5;
      } else if (target == "prev" && newPage % 5 == 0) {
        startPageRef.current -= 5;
      }
    }
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / pageSize));
  }, [filteredData]);

  const fetchKeywords = async () => {
    toast.dismiss();
    setLoading(true);
    try {
      const result = await getBacklistedKeywords();
      if (result.code == 200) {
        setData(result?.result ?? []);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const handleConfirm = async (data) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this blacklist? This action cannot be undone."
      ),

      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteKeywords(data);
        if (result && result.code == 200) {
          await fetchKeywords();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="otherWrap">
        <Image
          className="edit"
          src={editIcon}
          alt="edit"
          onClick={() => setSelectedKeyWord(row)}
        />
        <Image
          onClick={() => handleConfirm(row.offer_id, false)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    const status = ((status_id) => {
      switch (status_id) {
        case "1":
          return "Active";
        case "2":
          return "Inactive";
        case "3":
          return "Deleted";
        default:
          break;
      }
    })(row.status_id);
    let style = ((status_id) => {
      switch (status_id) {
        case "1":
          return { color: "#00a65a" };
        case "2":
          return { color: "#dd3333"};
        case "3":
          return { color: "#ffa470" };
        default:
          break;
      }
    })(row.status_id);
    return (
      <div className="status-formatter" style={style}>
        {status}
      </div>
    );
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order == "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const columns = [
    {
      dataField: "blacklisted_word_id",
      text: "#",
      sort: true,
      sortCaret,
    },
    {
      dataField: "word",
      text: "Keywords",
      sort: true,
      sortCaret,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret,
      formatter: statusFormatter,
    },
    {
      dataField: "other",
      text: "",
      sort: false,
      formatter: formatter,
    },
  ];

  const handleClose = () => setSelectedKeyWord(null);

  return (
    <Container className="keywords-table">
      <div className="table-wrapper">
        <Row className="search-wrap">
          <Col xl="4" lg="5" md="6">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
          <Col xl="6" lg="6" md="5" sm="12" className="cta-col">
            <PrimaryButton
              onClick={() => setSelectedKeyWord({ ...defaultKeyword })}
              classNames="add-new-offer"
              text={t("Add New")}
            />
          </Col>
        </Row>
        {outputData.length ? (
          <BootstrapTable
            keyField={columns[0]}
            data={outputData}
            columns={columns}
          />
        ) : isLoading ? (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t("Please wait, we're loading blacklisted keywords")}</span>
          </div>
        ) : (
          <div className="no-data-container">
            <h4>
              {searchInput
                ? t("No matching keywords found")
                : t("No Keywords Available")}
            </h4>
          </div>
        )}
      </div>
      {isLoading && Boolean(outputData.length) && (
        <Row className="pagination">
          <Col md="3 result-text">
            {t("Showing")}{" "}
            {filteredData.length ? offset + 1 : filteredData.length} {t("to")}
            &nbsp;
            {offset + outputData.length} {t("of")} {totalResults} {t("Results")}
          </Col>
          <Col md="5" className="count">
            <div className="previous" onClick={() => handlePageChange("prev")}>
              <img src={leftArrow} alt="left-arrow"></img>
            </div>
            <div className="pages">
              {Array.from({ length: pageCount > 5 ? 5 : pageCount }).map(
                (_, index) => {
                  const pageNumber = startPageRef.current + index;
                  return (
                    <span
                      key={index}
                      className={` cursor-pointer ${
                        selectedPage == pageNumber && "active"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </span>
                  );
                }
              )}
            </div>
            <div className="next" onClick={() => handlePageChange("next")}>
              <img src={rightArrow} alt="right-arrow"></img>
            </div>
          </Col>
        </Row>
      )}
      <Modal show={selectedKeyWord} onHide={handleClose}>
        <BlackListForm
          selectedKeyWord={selectedKeyWord}
          fetchKeywords={fetchKeywords}
          handleClose={handleClose}
        />
      </Modal>
    </Container>
  );
};

export default BlacklistedTable;

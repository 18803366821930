import React, { useRef, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";

const ReviewRepliesList = ({ reviewReplies = [] }) => {

	return (
		<Row>
			<Col md="12" className="mt-4">
				<strong>Review Replies</strong>
				<hr />
				<ul style={{ listStyleType: "none" }}>
					{reviewReplies?.length && reviewReplies?.map(rr => (
						<li style={{ marginBottom: "5px" }}><u>{rr.first_name}</u><br />{rr.review}</li>
					)) || <p class='error text-center'>No replies to show</p>}
				</ul>
				<hr />
			</Col>
		</Row>
	);
};

export default ReviewRepliesList;

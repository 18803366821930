import React, { useEffect, useState } from "react";
import Hoc from "../Hoc";
import {
  Col,
  Container,
  Row,
  Button,
  Card,
  Accordion,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "./Styles.scss";
import AddMyBusinesses from "./AddMyBusiness";
import { checkActionPermission, getAuthAttribute } from "../../utils/helper";
import { getStaffAndServiceCount } from "../../core/Api/staff";
import AdminsData from "../Administrators/index";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import BankAccount from "../BankAccount";
import { Link } from "react-router-dom";

const MyBusinesses = (props) => {
  const { match } = props;
  const { t } = useTranslation();
  const isUserAdmin = getAuthAttribute("user_type_id") == 4;
  const [disabled, setDisabled] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const supplierId = match?.params?.supplier_id;
  const [selectedOption, setSelectedOption] = useState(null);
  const [updateProfileStatus, setUpdateProfileStatus] = useState(false);
  const menuOptions = [
    { label: t("Offers"), value: `/offers/${supplierId}` },
    { label: t("Services"), value: `/service/${supplierId}` },
    { label: t("Staff and Closed Dates"), value: `/staff/${supplierId}` },
    { label: t("Image Gallery"), value: `/images/${supplierId}` },
    { label: t("Bookings and Schedules"), value: `/calendar/${supplierId}` },
    { label: t("Delivery Fee"), value: `/delivery-fee/${supplierId}` },
    { label: t("Payouts"), value: `/payouts/${supplierId}` },
    { label: t("Supplier Driver"), value: `/supplier-driver/${supplierId}` },
    { label: t("Reviews"), value: `/reviews/${supplierId}` },
    { label: t("Bookings"), value: `/bookings/${supplierId}` },
    { label: t("Service Area"), value: `/service-area/${supplierId}` },
  ];
  const [activeKey, setActiveKey] = useState(1);
  const [count, setCount] = useState({});
 
  useEffect(() => {
    (async () => {
      if (!isUserAdmin) {
        const supplierId = getAuthAttribute("supplier_id");
        const result = await getStaffAndServiceCount(supplierId);
        if (result && result.code == 200) {
          setCount(result.result);
        } else {
          // throw new Error(result?.message ?? "Something went wrong");
        }
      }
    })();
  }, [isUserAdmin]);

  const warningMessage = (profileStats) => {
    if (isUserAdmin) {
      return null;
    } else if (profileStats?.has_staff == "0"  ||  profileStats?.has_services == "0" || profileStats?.has_working_hours == "0" || profileStats?.has_staff_shifts == "0") {
      return (
        <>
          {profileStats?.has_staff == "0" && <span>
            No staff added. Please add staff to make your salon visible on the
            portal.&nbsp; <Link to="/staff">Add Staff</Link>
          </span> || <></>}
          {profileStats?.has_staff_shifts == "0" && <span>
            No staff shifts added. Please add staff shifts to make your salon visible on the
            portal.&nbsp; <Link to="/staff">Add Staff Shifts</Link>
          </span> || <></>}
          {profileStats?.has_services == "0" && <span>
            No services added. Please add service to make your salon visible on
            the portal.&nbsp; <Link to="/service"> Add Service </Link>
          </span> || <></>}
          {profileStats?.has_working_hours == "0" && <span>
            No working housrs added. Please add working hous to make your salon visible on
            the portal.&nbsp; 
          </span> || <></>}
        </>
      );
    } else {
      return null;
    }
  };

  const handleChangeSelectedOption = (value) => {
    window.open(value, "_blank");
  }

  const switchToEditMode = () => {
    setShowEditButton(true);
    setDisabled(true);
  };

  const confirmDiscard = async () => {
    Swal.fire({
      text: t(
        "Are you sure you want to discard the changes? This action cannot be undone."
      ),

      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Discard"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDisabled(true);
      }
    });
  };

  const toggleDisabled = (skipConfirm = false) => {
    if (disabled) {
      setDisabled(false);
    } else if (skipConfirm == true) {
      setDisabled(true);
    } else {
      confirmDiscard();
    }
  };

  const tabs = [
    {
      id: 1,
      label: t("Administrators"),
      components: <AdminsData supplierId={supplierId} />,
    },
    {
      id: 2,
      label: t("Bank Account Details"),
      components: <BankAccount supplierId={supplierId} />,
    },
  ];

  return (
    <React.Fragment>
      <Hoc activeMenu={isUserAdmin ? "suppliers" : "my_businesses"} updateProfileStatus={updateProfileStatus}>
        <Container fluid className="all-service">
          {showEditButton && (
            <Row className="edit-btn">
              {supplierId ? (
                <DropdownButton variant={"dark"} title={t("More Options")}>
                  {menuOptions.map((item, index) => (
                    <Dropdown.Item
                      onClick={() => handleChangeSelectedOption(item.value)}
                      key={item.label}
                      eventKey={item.value ?? index}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              ) : null}
              <Col md="auto" className="d-flex">
                {checkActionPermission("my_businesses","canEdit") && (
                  <Button
                  onClick={toggleDisabled}
                  variant={disabled ? "dark" : "secondary "}
                >
                  {disabled ? t("Edit") : t("Discard Changes")}
                </Button>
                )||null}
              </Col>
            </Row>
          )}
          <AddMyBusinesses
            setUpdateProfileStatus={setUpdateProfileStatus}
            {...props}
            disabled={disabled}
            hideTitle={true}
            switchToEditMode={switchToEditMode}
            toggleDisabled={toggleDisabled}
            warningMessage={warningMessage}
          />
          {disabled
            ? tabs.map((tab) => (
                <Accordion
                  defaultActiveKey={activeKey}
                  activeKey={activeKey}
                  key={tab.id}
                >
                  <Card className="admin-cards">
                    <Card.Header>
                      <Accordion.Toggle
                        variant="link"
                        as={Button}
                        eventKey={tab.id}
                        onClick={() =>
                          setActiveKey((prev) => (prev == tab.id ? 0 : tab.id))
                        }
                      >
                        {t(`${tab.label}`)}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse activeMenu={true} eventKey={tab.id}>
                      {tab.components}
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))
            : null}
        </Container>
      </Hoc>
    </React.Fragment>
  );
};

export default MyBusinesses;

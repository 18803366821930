import React from "react";
import PropTypes from "prop-types";
import "./Styles.scss";
import { Spinner, Button, Image } from "react-bootstrap";

const PrimaryButton = (props) => {
  const { style, text, textStyle, isLoading, classNames, src, ...rest } = props;
  return (
    <Button
      className={`${classNames} primary-button-container text-nowrap`}
      style={style}
      {...rest}
    >
      {/* <div className={"button-text"} style={textStyle}> */}
        {isLoading ? <Spinner animation="border" /> : text}
        {((!isLoading && src) && <Image  alt="button-icon" className="primary-tm-image" src={src} />) || null}
      {/* </div> */}
      {/* {isLoading ? (
        <Spinner animation="border" />
      ) : src ? (
        <Image style={{ margin: "auto" }} alt="button-icon" src={src} />
      ) : (
        <div className={"button-text"} style={textStyle}>
          {text ?? "Secondary Button"}
        </div>
      )} */}
    </Button>
  );
};

PrimaryButton.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  textStyle: PropTypes.object,
};

export default PrimaryButton;

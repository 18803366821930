const hostname = window && window.location && window.location.hostname;

const HOST_ROOT = process.env.REACT_APP_HOST ?? "https://testapi.boon.sa";

const config = {
  BASE_URL: HOST_ROOT,
  hostname
};

export default config;

import React from "react";
import PropTypes from "prop-types";
import "./Styles.scss";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import clsx from "clsx";

const SecondaryButton = (props) => {
  const { style, text, textStyle, onClick, src, isLoading } = props;
  return (
    <div
      className={clsx("secondary-button-container", isLoading && "loader")}
      onClick={onClick}
      style={style}
      {...props}
    >
      {isLoading ? (
        <Spinner animation="border" />
      ) : src ? (
        <Image style={{ margin: "auto" }} alt="button-icon" src={src} />
      ) : (
        <div className={"button-text"} style={textStyle}>
          {text ?? "Secondary Button"}
        </div>
      )}
    </div>
  );
};

SecondaryButton.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  textStyle: PropTypes.object,
};

export default SecondaryButton;

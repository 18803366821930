import React from "react";
import PropTypes from "prop-types";
import "./Styles.scss";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const DashCard = (props) => {
  const { t } = useTranslation();
  const { bgColor, iconBg, countSubText, countText, icon, caption, dark } =
    props;
  return (
    <div
      className="dash-card"
      style={{
        backgroundColor: bgColor ? bgColor : "#fff",
        color: dark ? "#fff" : "#000",
      }}
    >
      <div
        className="icon-cont"
        style={{ backgroundColor: iconBg ? iconBg : "#DFE8FF" }}
      >
        <Image src={icon} />
      </div>
      <div className="count">
        {countText}
        <span className="sub">{countSubText}</span>
      </div>
      <p>{t(`${caption}`)}</p>
    </div>
  );
};

DashCard.propTypes = {
  BgColor: PropTypes.string,
  IconBg: PropTypes.string,
  countSubText: PropTypes.string,
  countText: PropTypes.string,
  icon: PropTypes.string,
};

export default DashCard;

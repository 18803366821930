import Axios from "../Axios";

export const getCompanies = async (params = {}) => {

  try {
    const { per_page = 10, offset = 0, search_phrase = '' } = params;
    const response = await Axios.get(
      `/company/index?per_page=${per_page}&offset=${offset}&search_phrase=${search_phrase}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCompany = async (data) => {
  try {
    const response = await Axios.post("/company/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCompanyId = async (id) => {
  try {
    const response = await Axios.get(`/company/index/${id}`)
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAdminsCompanyId = async (id) => {
  try {
    const response = await Axios.get(`/company/administrators/${id}`)
    return response.data;
  } catch (error) { 
    return error;
  }
};

export const saveCompanyAdmin = async (data) => {
  try {
    const response = await Axios.post("/company/save_administrator", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCompanyAdmin = async (data) => {
  try {
    const response = await Axios.post("/company/delete_administrator", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const activateCompany = async (data) => {
  try {
    const response = await Axios.post("/company/activate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deactivateCompany = async (data) => {
  try {
    const response = await Axios.post("/company/deactivate", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const registerBulkCustomers = async (data) => {
  try {
    const response = await Axios.post("/customer/register_bulk_customers", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
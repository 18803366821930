import Axios from "../Axios";

export const getServices = async (id, locationType) => {
  let endPoint = `/supplier/services/${id}`;
  if (locationType) {
    endPoint += `?location_type=${locationType}`;
  }
  try {
    const response = await Axios.get(endPoint);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServiceById = async (id, supplier_id = 0) => {
  try {
    const response = await Axios.get(
      `supplier/service/${id}?supplier_id=${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCategories = async () => {
  try {
    const response = await Axios.get("/category");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServicesListByCategory = async (category_id) => {
  try {
    const response = await Axios.get(
      `/settings/service_list?category_id=${category_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveService = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_service", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteService = async (supplier_id, supplier_service_id) => {
  try {
    const response = await Axios.get(
      `/supplier/delete_service?supplier_id=${supplier_id}&supplier_service_id=${supplier_service_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStartTimebyStaff = async (service_id, duration, date) => {
  try {
    const response = await Axios.get(
      `supplier/service_timeslots_by_staff?service_id=${service_id}&duration=${duration}&date=${date}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServicesByStatusId = async (id = 0) => {
  try {
    const response = await Axios.get(
      `/service/list?status_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const serviceSave = async (data) => {
  try {
    const response = await Axios.post("/service/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const serviceDelete = async (id) => {
  try {
    const response = await Axios.get(`service/delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDriver = async (supplier_id) => {
  try {
    const response = await Axios.get(`/supplier/drivers/${supplier_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveServiceSorting = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_services_sort", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

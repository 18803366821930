import React, { useRef, useCallback } from 'react';
import { Polygon } from '@react-google-maps/api';

const polygonOptions = {
  fillColor: 'lightblue',
  fillOpacity: 0.35,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: true,
  editable: true,
  geodesic: true,
  zIndex: 1
};
const DrawPolygon = props => {
  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(
    () => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current.getPath().getArray().map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
        const selectedFence = nextPath;
        props.updateFence(props.index, selectedFence);
      }
    },
    [props]
  );

  // Bind refs to current Polygon and listeners
  const onPolygonLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  const onPolygonRightClick = polygon => {
    const position = {
      lat: polygon.latLng.lat(),
      lng: polygon.latLng.lng()
    };
    props.setOverlay(true);
    props.setOverlayPosition(position);
    props.setOverlayIndex(props.index);
  };

  return (
    <div>
      <Polygon
        onLoad={onPolygonLoad}
        paths={props.fence}
        options={polygonOptions}
        onRightClick={onPolygonRightClick}
        onDragEnd={onEdit}
        onUnmount={onUnmount}
        onMouseUp={onEdit}
      />
    </div>
  );
};

export default DrawPolygon;

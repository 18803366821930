import React, { Fragment } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PageNotFound from "../containers/PageNotFound";
import { toast, ToastContainer } from "react-toastify";
import Dashboard from "../containers/Dashboard";
import Partner from "../containers/Partner/index";
import Complains from "../containers/Complains/index";
import BookingTransactions from "../containers/BookingTransaction";
import SignUp from "../containers/Auth/SignUp";
import ResetPassword from "../containers/Auth/ResetPassword";
import Staff from "../containers/Staff";
import Client from "../containers/Client";
import Profile from "../containers/Client/Profile";
import Service from "../containers/Service";
import Calender from "../containers/Calender";
import RescheduleAppointment from "../containers/RescheduleAppointment/ViewAppointment";
import Offers from "../containers/Offers";
import "react-toastify/dist/ReactToastify.css";
import Login from "../containers/Auth/Login";
import Invoice from "../containers/Invoice";
import MyBusinesses from "../containers/MyBusinesses";
import Images from "../containers/images";
import Reviews from "../containers/Reviews/index";
import BlacklistedKeywords from "../containers/BlacklistedKeywords/index";
import GlobalSettings from "../containers/Settings/GlobalSettings";
import AddMyBusinesses from "../containers/MyBusinesses/AddMyBusiness";
import SupplierDriver from "../containers/SupplierDriver";
import BankAccount from "../containers/BankAccount";
import Delivery from "../containers/DeliveryFee";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Services from "../containers/Services";
import Bookings from "../containers/Bookings";
import DisputedBookings from "../containers/DisputedBookings";
import Payouts from "../containers/Payout";
import UserType from "../containers/Permission/UserType";
import Permissions from "../containers/Permission";
import LogsScreen from "../containers/LogsScreen";
import ServiceArea from "../containers/ServiceArea";
import PushNotifications from "../containers/PushNotifications";
import Employees from "../containers/Employee";
import Companies from "../containers/Companies";
import AddCompany from "../containers/Companies/CompanyForm";
import Groups from "../containers/Groups";
import AvgServicePriceReport from "../containers/AvgServicePriceReport";
import TransactiosPerDay from "../containers/Reports/TransactionsPerDay";
import TransactiosPerMonth from "../containers/Reports/TransactionsPerMonth";
import TransactionsServicesPerDay from "../containers/Reports/TransactionsServicesPerDay";
import TransactionsServicesPerMonth from "../containers/Reports/TransactionsServicesPerMonth";
import FeatuerdSalons from "../containers/FeaturedSalons";
import "./Styles.scss";
import TransactiosByPartners from "../containers/Reports/TransactionsByPartners";
import TransactiosByPartnerType from "../containers/Reports/TransactionsByPartnersType";
import Advertisements from "../containers/Advertisements";
import IncompleteProfilePartners from "../containers/Reports/IncompleteProfilePartners";
import TransactionsPerWeek from "../containers/Reports/TransactionsPerWeek";
import ServicesPricingReport from "../containers/Reports/ServicesPricingReport";
import SellersListing from "../containers/GiftVouchers/sellers-listing";
import SellerForm from "../containers/GiftVouchers/seller-form";
import SellerProfile from "../containers/GiftVouchers/seller-profile";
import MostUsedServicesReport from "../containers/Reports/MostUsedServices";
import CumulativeCustomersReport from "../containers/Reports/CumulativeCustomers/Index";
import Sales from "../containers/GiftVouchers/sales";
import Categories from "../containers/Categories";
import TopCustomersReports from "../containers/Reports/TopCustomersReports";
import BookingsIncome from "../containers/Reports/BookingsIncome";
import PartnersSummaryReport from "../containers/Reports/PartnersSummary";
import CustomersSummaryReport from "../containers/Reports/CustomersSummary";
import ExpansionReport from "../containers/Reports/ExpansionReport";
import SalesReport from "../containers/Reports/Sales";
import GrossTransactionsReport from "../containers/Reports/GrossTransactions";
import GraphReports from "../containers/Reports/GraphsReports";
import UnAuthorized from "../containers/UnAuthorized";

const Navigation = () => {
	return (
		<Fragment>
			<ToastContainer
				position="bottom-center"
				autoClose={5000}
				hideProgressBar
				newestOnTop
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<BrowserRouter>
				<Switch>
					<Redirect exact from="/" to={"/login"} />
					<PublicRoute exact path="/login" component={Login} />
					<PublicRoute exact path="/signup" component={SignUp} />
					<Route exact path="/reschedule" component={RescheduleAppointment} />
					<Route exact path="/reset_password/:token" component={ResetPassword} />
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
					<PrivateRoute exact path="/partners" component={Partner} />
					<PrivateRoute exact path="/partners/:supplier_id" component={MyBusinesses} />
					<PrivateRoute exact path="/featured-salons" component={FeatuerdSalons} />
					<PrivateRoute exact path="/company/:id" component={AddCompany} />
					<PrivateRoute exact path="/company" component={AddCompany} />
					<PrivateRoute exact path="/groups" component={Groups} />
					<PrivateRoute exact path="/companies" component={Companies} />
					<PrivateRoute exact path="/sellers" component={SellersListing} />
					<PrivateRoute exact path="/seller-profile/:id" component={SellerProfile} />
					<PrivateRoute exact path="/seller/:id" component={SellerForm} />
					<PrivateRoute exact path="/seller" component={SellerForm} />
					<PrivateRoute exact path="/voucher-sales" component={Sales} />
					<PrivateRoute exact path="/complains" component={Complains} />
					<PrivateRoute exact path="/booking-transactions" component={BookingTransactions} />
					<PrivateRoute exact path="/avg-service-price-report" component={AvgServicePriceReport} />
					<PrivateRoute exact path="/bookings" component={Bookings} />
					<PrivateRoute exact path="/bookings/:id" component={Bookings} />
					<PrivateRoute exact path="/disputed-bookings" component={DisputedBookings} />
					<PrivateRoute exact path="/staff" component={Staff} />
					<PrivateRoute exact path="/staff/:id" component={Staff} />
					<PrivateRoute exact path="/clients" component={Client} />
					<PrivateRoute exact path="/clients/:id" component={Client} />
					<Route exact path="/invoice/:id" component={Invoice} />
					<PrivateRoute exact path="/profile/:id" component={Profile} />
					<PrivateRoute exact path="/permissions/:user_type_id" component={Permissions} />
					<PrivateRoute exact path="/service" component={Service} />
					<PrivateRoute exact path="/service/:id" component={Service} />
					<PrivateRoute exact path="/calendar" component={Calender} />
					<PrivateRoute exact path="/calendar/:id" component={Calender} />
					<PrivateRoute exact path="/my-businesses" component={MyBusinesses} />
					<PrivateRoute exact path="/my-businesses/add" component={AddMyBusinesses} />
					<PrivateRoute exact path="/images" component={Images} />
					<PrivateRoute exact path="/images/:id" component={Images} />
					<PrivateRoute exact path="/offers" component={Offers} />
					<PrivateRoute exact path="/offers/:id" component={Offers} />
					<PrivateRoute exact path="/logs" component={LogsScreen} />
					{/* <PrivateRoute
            exact
            path="/search-phrases"
            component={SearchPhrases}
          /> */}
					<PrivateRoute exact path="/payouts" component={Payouts} />
					<PrivateRoute exact path="/payouts/:id" component={Payouts} />
					<PrivateRoute exact path="/reviews" component={Reviews} />
					<PrivateRoute exact path="/reviews/:id" component={Reviews} />
					<PrivateRoute exact path="/blacklisted-keywords" component={BlacklistedKeywords} />
					<PrivateRoute exact path="/push-notifications" component={PushNotifications} />
					<PrivateRoute exact path="/services" component={Services} />
					<PrivateRoute exact path="/user-types" component={UserType} />
					<PrivateRoute exact path="/global-settings" component={GlobalSettings} />
					<PrivateRoute exact path="/supplier-driver" component={SupplierDriver} />
					<PrivateRoute exact path="/supplier-driver/:id" component={SupplierDriver} />
					<PrivateRoute exact path="/bank-account" component={BankAccount} />
					<PrivateRoute exact path="/delivery-fee" component={Delivery} />
					<PrivateRoute exact path="/delivery-fee/:id" component={Delivery} />
					<PrivateRoute exact path="/service-area" component={ServiceArea} />
					<PrivateRoute exact path="/service-area/:id" component={ServiceArea} />
					<PrivateRoute exact path="/advertisements" component={Advertisements} />
					<PrivateRoute exact path="/categories" component={Categories} />
					<PrivateRoute exact path="/employees" component={Employees} />
					<PrivateRoute exact path="/employee/:id" component={Employees} />
					<PrivateRoute exact path="/reports/transactions-per-day" component={TransactiosPerDay} />
					<PrivateRoute exact path="/reports/transactions-per-week" component={TransactionsPerWeek} />
					<PrivateRoute exact path="/reports/transactions-per-month" component={TransactiosPerMonth} />
					<PrivateRoute exact path="/reports/transactions-services-per-day" component={TransactionsServicesPerDay} />
					<PrivateRoute exact path="/reports/transactions-services-per-month" component={TransactionsServicesPerMonth} />
					<PrivateRoute exact path="/reports/transactions-by-partners" component={TransactiosByPartners} />
					<PrivateRoute exact path="/reports/transactions-by-partner-type" component={TransactiosByPartnerType} />
					<PrivateRoute exact path="/reports/incomplete-profile-partners" component={IncompleteProfilePartners} />
					<PrivateRoute exact path="/reports/services-pricing" component={ServicesPricingReport} />
					<PrivateRoute exact path="/reports/most-used-services-report" component={MostUsedServicesReport} />
					<PrivateRoute exact path="/reports/cumulative-customers" component={CumulativeCustomersReport} />
					<PrivateRoute exact path="/reports/top-customers-report" component={TopCustomersReports} />
					<PrivateRoute exact path="/reports/bookings-income-report" component={BookingsIncome} />
					<PrivateRoute exact path="/reports/partners-summary-report" component={PartnersSummaryReport} />
					<PrivateRoute exact path="/reports/customers-summary-report" component={CustomersSummaryReport} />
					<PrivateRoute exact path="/reports/expansion-report" component={ExpansionReport} />
					<PrivateRoute exact path="/reports/sales-report" component={SalesReport} />
					<PrivateRoute exact path="/reports/gross-transactions-report" component={GrossTransactionsReport} />
					<PrivateRoute exact path="/reports" component={GraphReports} />
					<Route path="/un-authorized" component={UnAuthorized} />
					<Route component={PageNotFound} />
				</Switch>
			</BrowserRouter>
		</Fragment>
	);
};

export default Navigation;

import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryButton from "../../../components/Buttons/Primary";
import FullPageSpinner from "../../../components/FullPageSpinner";
import Hoc from "../../Hoc";
import "./Styles.scss";
import CustomTableComponent from "../../../components/CustomTableComponent";
import DateInput from "../../../components/DateInput";
import { getCumulativeCustomersReport } from "../../../core/Api/Reports";
import moment from "moment";

const CumulativeCustomersReport = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [csvLoader, setCSVLoader] = useState(false);
  const [filterSchema, setFilterSchema] = useState({
    from_date: null,
    to_date: null,
  });

  useEffect(() => {
    fetchData(filterSchema);
  }, []);

  const fetchData = async (params = {}) => {
    setLoading(true);
    const partners = await getCumulativeCustomersReport({
      from_date: params?.from_date || '',
      to_date: params?.to_date || '',
    });
    if (partners.code == 200) {
      setData(partners?.result || []);
    } else {
      toast.error(partners && partners.message);
    }
    setLoading(false);
  };

  const handleDateChange = (date, name) => {
    setFilterSchema({
      ...filterSchema,
      [name]: date && new Date(date) || null,
    });
  };

  const handleSearch = async () => {
    setLoading(true)
    const response = await getCumulativeCustomersReport({
      from_date: filterSchema?.from_date && moment(filterSchema?.from_date).format('YYYY-MM-DD')  || '',
      to_date: filterSchema?.to_date && moment(filterSchema?.to_date).format('YYYY-MM-DD') || '',
    });
    setLoading(false)
    if (response.code == 200) {
      setData(response?.result || []);
    } else {
      toast.error(response && response.message);
    }
  };

  const handleCSVExport = async () => {
    try {
      setCSVLoader(true);
      const bookings = data || [];
      let csv = "";
      const rows = [];
      csv = "Year, Month, Monthly Customers, Total Customers, Active Customers\r\n";
      for (let i = 0; i < bookings?.length; i++) {
        const item = bookings[i];
        rows.push(`${item.year},${item.month_name},${item?.monthly_customers},${item?.total_customers},${item?.active_customers}\r\n`)
      }
      rows.forEach((row) => {
        csv += row;
      });
      const generationDate = new Date().toLocaleDateString("en-US");
      const link = document.createElement("a");
      link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      link.target = "_blank";
      link.download = `Cumulative_Customers_Report_${generationDate}.csv`;
      link.click();
      setCSVLoader(false);
    } catch (error) {
      toast.error(error && error.message);
      setCSVLoader(false);
    }
  };

  const columns = [
    {
      name: t("Year"),
      key: 'year',
      formatter: null,
    },
    {
      name: t("Month"),
      key: 'month_name',
      formatter: null,
    },
    {
      name: t("Monthly Customers"),
      key: 'monthly_customers',
      formatter: null,
    },
    {
      name: t("Total Customers"),
      key: 'total_customers',
      formatter: null,
    },
    {
      name: t("Active Customers"),
      key: 'active_customers',
      formatter: null,
    },
  ]

  return (
    // <Hoc activeMenu="cumulativeCustomers">
      <div className="mostUsedServicesReport">
        {csvLoader && <FullPageSpinner /> || null}
        <div className="table-wrapper pr-3">
          <Row className="m-0">
            <Col className="filter-item" lg="2" md="6" sm="12">
              <DateInput
                label={t("From date")}
                value={filterSchema.from_date}
                onChange={(date) => handleDateChange(date, "from_date")}
                isClearable={true}
              />
            </Col>
            <Col className="filter-item" lg="2" md="6" sm="12">
              <DateInput
                label={t("To date")}
                value={filterSchema.to_date}
                onChange={(date) => handleDateChange(date, "to_date")}
                isClearable={true}
              />
            </Col>
            <Col className="filter-item" lg="2" md="6" sm="12">
              <PrimaryButton
                text={t("Search")}
                onClick={handleSearch}
                style={{ width: "100%" }}
              />
            </Col>
            <Col className="filter-item" lg="2" md="6" sm="12">
              <PrimaryButton
                disabled={isLoading || csvLoader}
                onClick={handleCSVExport}
                text={t('exportAsCSV')}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row className="w-100 text-center m-0 mt-4">
            <Col>
              {isLoading && (
                <div>
                  <FullPageSpinner animation="border" />
                  <span>
                    {t("Please wait, we're loading data")}
                  </span>
                </div>)
              }
              {!isLoading && data?.length == 0 && (
                <div className="no-data-text text-center mt-2">
                  {t("No data available")}
                </div>
              ) || null}
            </Col>
          </Row>
          <Row className="w-100 m-0">
            {
              data.length && (
                <div className="booking-table w-100">
                  <CustomTableComponent
                    responsive={true}
                    hover={true}
                    showSequence={true}
                    pageSize={10}
                    pageNo={1}
                    columns={columns || []}
                    data={data || []}
                  />
                </div>
              ) || null
            }
          </Row>
          <Row>
            {/* <Pagination
              totalCount={totalCount}
              viewCount={data?.length || 0}
              onChangePage={handlePageChange}
              pageNo={pageNo}
            /> */}
          </Row>
        </div>

      </div>
    // </Hoc>
  );
};

export default CumulativeCustomersReport;

import React, { useEffect, useState } from "react";
import Hoc from "../Hoc";
import {
  Col,
  Container,
  Row,
  ProgressBar,
  Image,
  Tabs,
  Tab,
  Button,
} from "react-bootstrap";
import "./Styles.scss";
import { getAuthAttribute } from "../../utils/helper";
import { getReviews } from "../../core/Api/supplier";
import star from "../../assets/images/star.svg";
import filledStar from "../../assets/images/star-fill.svg";
import ReviewTable from "./Reviews";
import Panel from "../../components/Panel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const Reviews = ({ match }) => {
  const { t } = useTranslation();

  const supplier_id = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const [reviews, setReviews] = useState({});
  const [key, setKey] = useState("verified");
  const verified = reviews?.approved_reviews ?? [];
  const pending = reviews?.unapproved_reviews ?? [];

  useEffect(() => {
    fetchReviews();
    // eslint-disable-next-line
  }, []);

  const fetchReviews = async () => {
    try {
      const result = await getReviews(supplier_id);
      if (result.code == 200) {
        setReviews(result.result);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const getRating = (index, review) => {
    switch (index) {
      case 0:
        return review?.rating?.poor ?? "NA";
      case 1:
        return review?.rating?.average ?? "NA";
      case 2:
        return review?.rating?.good ?? "NA";
      case 3:
        return review?.rating?.very_good ?? "NA";
      case 4:
        return review?.rating?.excellent ?? "NA";
      default:
        return "NA";
    }
  };

  const getVariant = (index) => {
    switch (index) {
      case 0:
        return "danger";
      case 1:
        return "warning";
      case 2:
        return "info";
      case 3:
        return "";
      case 4:
        return "success";
      default:
        return "NA";
    }
  };

  return (
    <React.Fragment>
      <Hoc activeMenu={match.params.id ? "supplier_reviews" : "supplier_reviews"}>
        <Container fluid className="reviews-contaiiner">
          <div className="rating-panel">
            <Panel subtitle={t("Reviews")}>
              <Row>
                <Col md="5">
                  <div className="left-rating-box">
                    <h1>
                      {reviews?.rating
                        ? reviews?.rating.overall.toFixed(2)
                        : "0.00"}
                      /5
                    </h1>
                    <div>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Button
                          key={index}
                          size="sm"
                          variant={
                            index < reviews?.rating?.overall?.toFixed()
                              ? "warning"
                              : "outline-secondary"
                          }
                        >
                          <Image
                            src={
                              index < reviews?.rating?.overall?.toFixed()
                                ? star
                                : filledStar
                            }
                          />
                        </Button>
                      ))}
                    </div>
                    <strong>
                      ({reviews?.rating ? reviews?.rating?.total : 0} Reviews)
                    </strong>
                  </div>
                </Col>
                <Col md="7" className="right-rating-box">
                  {Array.from({ length: 5 })
                    .map((_, index) => (
                      <div key={index} className="progress-box">
                        <div md="2" className="stars">
                          {index + 1}
                          <Image src={filledStar}></Image>
                        </div>
                        <div className="progress-bar-row">
                          <ProgressBar
                            now={
                              (getRating(index, reviews) /
                                (reviews?.rating?.total ?? 1)) *
                              100
                            }
                            variant={getVariant(index)}
                          />
                        </div>
                        <div className="star-count">
                          {getRating(index, reviews)}
                        </div>
                      </div>
                    ))
                    .reverse()}
                </Col>
              </Row>
            </Panel>
          </div>
          <div className="table-panel">
            <Panel subtitle={t("Reviews")}>
              <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey="pending" title={t("Pending Approval")}></Tab>
                <Tab eventKey="verified" title={t("Verified")}></Tab>
              </Tabs>
              {key == "pending" ? (
                pending.length ? (
                  <ReviewTable
                    isPending={key == "pending"}
                    reviewData={pending}
                    fetchReviews={fetchReviews}
                  />
                ) : (
                  <h5 className="no-data">{t("No reviews pending for approval")}</h5>
                )
              ) : null}
              {key == "verified" ? (
                verified.length ? (
                  <ReviewTable
                    isPending={key == "pending"}
                    reviewData={verified}
                  />
                ) : (
                  <h5 className="no-data">{t("No approved reviews pending")}</h5>
                )
              ) : null}
            </Panel>
          </div>
        </Container>
      </Hoc>
    </React.Fragment>
  );
};

export default Reviews;

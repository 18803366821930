import Axios from "../Axios";

export const getFences = async (supplier_id) => {
  try {
    const response = await Axios.get(
      `supplier/geofence/${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveFence = async (data) => {
  try {
    const response = await Axios.post("supplier/save_geofence", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
import React, { useState, useEffect } from "react";
import { Spinner, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { capitalCase } from "capital-case";
import moment from "moment";
import SearchInput from "../../components/Inputs/Search";
import { toast } from "react-toastify";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import locationIcon from "../../assets/images/geo-alt-fill.svg";
import { getAllDisputedBookings } from "../../core/Api/bookings";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Styles.scss";
import CustomTableComponent from "../../components/CustomTableComponent";
import Pagination from "../../components/Pagination";
import FullPageSpinner from "../../components/FullPageSpinner";

const DisputedBookingsTable = ({ history }) => {
  const { t } = useTranslation();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [allTransactions, setAllTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [currTransactions, setCurrTransactions] = useState([]);
  const [searchInput, setSearchInput] = useState("");


  useEffect(() => {
    fetchDisputedBookings();
  }, []);

  const fetchDisputedBookings = async () => {
    setIsLoading(true);
    const result = await getAllDisputedBookings();
    try {
      if (result.code == 200) {
        setTransactions(result?.result ?? []);
        setAllTransactions(result?.result ?? []);
        setCurrTransactions(result?.result.slice(0, pageSize) ?? []);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value?.length) {
      const result = allTransactions.filter((item) =>
        `${item.customer_name.toLowerCase()} ${item.supplier_name.toLowerCase()} 
    ${item.payment_mode.toLowerCase()} ${item.notes.toLowerCase()} ${item.reference_number.toLowerCase()}
    ${item.status.toLowerCase()} `.includes(
          e.target.value.toLowerCase()
        )
      );
      setTransactions(result);
      setPageNo(1)
      setCurrTransactions(result?.slice(0, pageSize) ?? [])
    } else {
      setTransactions(allTransactions);
      setPageNo(1)
      setCurrTransactions(allTransactions?.slice(0, pageSize) ?? [])
    }
  }


  const handlePageChange = (pageNo, pageSize) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
    setCurrTransactions(transactions?.slice((pageSize * (pageNo - 1)), ((pageSize * (pageNo - 1)) + pageSize)));
  }


  const formatCustomer = (row) => {
    return `${row?.customer_name}`
  };

  const formatBookingFrom = (row, key) => {
    switch (row?.application_type?.toLowerCase()) {
      case "customer/android":
        return "Android";
      case "customer/ios":
        return "iOS";
      case "backoffice":
        return "Backoffice";
      default:
        return "N/A";
    }
  };


  const getPaymentModes = (row) => {
    let mode = ''
    if (Array.isArray(row?.payment_mode) && row?.payment_mode.length) {
      row?.payment_mode?.forEach((r, i) => {
        mode += `${i > 0 && ' - ' || ''}${r?.pay_mode?.toUpperCase()}`
      })
    } else {
      mode = row?.payment_mode;
    }
    return mode;
  }

  const amountFormatter = (row, key) => {
    return <span className="text-nowrap">{`${row[key]} SAR`}</span>
  }

  const renderGuestInfo = (props, row) => {
    return (
      <Tooltip {...props}>
        <div className="p-2"
        >
          <div className="row">
            <div className="col-4 text-left text-nowrap">{t('Name')}</div>
            <div className="col-8 text-left text-nowrap">{row?.guest_name}</div>
          </div>
          <div className="row">
            <div className="col-4 text-left text-nowrap">{t('Mobile#')}</div>
            <div className="col-8 text-left text-nowrap">{row?.guest_mobile}</div>
          </div>
          <div className="row">
            <div className="col-4 text-left text-nowrap">{t('Email')}</div>
            <div className="col-8 text-left text-nowrap">{row?.guest_email}</div>
          </div>
        </div>
      </Tooltip>
    )
  };

  const guestInfoFormatter = (row) => {
    if (row?.is_guest_booking == "1") {
      return <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 1 }}
        overlay={(props) => renderGuestInfo(props, row)}
      >
        <p style={{ width: 'fit-content' }}>{t(`Yes`)}</p>
      </OverlayTrigger>
    } else {
      return <p style={{ width: 'fit-content' }}>{t(`No`)}</p>
    }
  }

  const addressFormatter = (row) => {
    if (!row.lat_lng) {
      return "";
    }
    return (
      <div
        className="addressWrapper"
        onClick={() => handleRedirectToLocation(row.lat_lng)}
      >
        <img src={locationIcon} alt="location" />
        <span>Map View</span>
      </div>
    );
  };

  const handleRedirectToLocation = (lat_lng) => {
    lat_lng = lat_lng.split(",").map((val) => val?.trim());
    window.open(
      `https://maps.google.com/maps?z=12&t=m&q=${lat_lng[0]}+${lat_lng[1]}`,
      "_blank"
    );
  };

  const dateFormatter = (row, key) => {
    return <span className="text-nowrap">{moment(row[key]).format("YYYY-MM-DD")}</span>
  }

  const statusFormatter = (row, key) => {
    const style = ((status) => {
      switch (status) {
        case "In Progress":
          return {
            color: "#7effd7",
            fontFamily: "Nunito Sans-extrabols",
            fontSize: "16px",
          };
        case "Cancelled":
          return {
            color: "#dd3333",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
        case "Completed":
          return {
            color: "#097969",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
        default:
          return {
            color: "#ffa470",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
      }
    })(row?.status);
    return (
      <div className="status-formatter" style={style}>
        {t(row?.status)}
      </div>
    );
  };


  const columns = [
    {
      name: t("Reference #"),
      key: 'reference_number',
      formatter: null,
    },
    {
      name: t("Customer"),
      key: 'first_name',
      formatter: formatCustomer,
    },
    {
      name: t("Supplier"),
      key: 'supplier_name',
      formatter: null,
    },
    {
      name: t("Booking Type"),
      key: 'location_type',
      formatter: null,
    },
    {
      name: t("Special Instructions"),
      key: 'notes',
      formatter: (row, key) => {
        if (!row[key]) {
          return <span>N/A</span>
        }
        return <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">{row[key]}</Tooltip>}
        >
          <span style={{
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: "inline-block",
            cursor: 'pointer'
          }}>{row[key]}</span>
        </OverlayTrigger>
      },
    },
    {
      name: t("Booking From"),
      key: 'application_type',
      formatter: formatBookingFrom,
    },
    {
      name: t("Amount"),
      key: 'total_amount',
      formatter: amountFormatter,
    },
    {
      name: t("Payment Mode"),
      key: 'payment_mode',
      formatter: getPaymentModes,
    },
    {
      name: t("Guest Booking"),
      key: 'is_guest_booking',
      formatter: guestInfoFormatter,
    },
    {
      name: t("Address"),
      key: 'lat_lng',
      formatter: addressFormatter,
    },
    {
      name: t("Date Created"),
      key: 'date',
      formatter: dateFormatter,
    },
    {
      name: t("Appointment Date"),
      key: 'appointment_date',
      formatter: dateFormatter,
    },
    {
      name: t("Status"),
      key: 'status',
      formatter: statusFormatter,
    },
  ]


  return (
    <div className="disputed-bookings">
      <div className="table-wrapper">
        <div className="search-wrap align-items-end">
          <SearchInput
            classNames="pt-0"
            onChange={handleSearchChange}
            placeholder={t("Search")}
          />
        </div>
        <Row>
            <Col>
              {isLoading && (
                <div className="spinner-wrap">
                  <FullPageSpinner animation="border" />
                  <span>
                    {t("Please wait, we're loading data")}
                  </span>
                </div>)
              }
              {!isLoading && transactions?.length == 0 && (
                <div className="no-data-text text-center mt-2">
                  {t("No data available")}
                </div>
              ) || null}
            </Col>
          </Row>

        <Row className="m-0 mt-3">
          {currTransactions?.length > 0 &&
            (<CustomTableComponent
              responsive={true}
              hover={true}
              showSequence={true}
              pageSize={pageSize}
              pageNo={pageNo}
              columns={columns || []}
              data={currTransactions || []}
            // onRowClick={onRowClick}
            />) || null}
        </Row>
        <Row>
          <Pagination
            totalCount={transactions?.length || 0}
            viewCount={currTransactions?.length || 0}
            pageNo={pageNo}
            onChangePage={handlePageChange}
          />
        </Row>

      </div>
    </div>
  );
};

export default DisputedBookingsTable;

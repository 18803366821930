import React from "react";
import "./Styles.scss";
const SwitchInput = (props) => {
  return (
    <label className="switch">
      <input
        disabled={props.disabled || false}
        hidden
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked && props.checked}
      />
      <span className="slider round"></span>
    </label>
  );
};

SwitchInput.propTypes = {};

export default SwitchInput;

import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/Buttons/Primary';
import AssetsImg from "images-col";


const DrawOverLayView = props => {
  const { t } = useTranslation();

  const handleDelete = () => {
    Swal.fire({
      text: t(
        `Are you sure you want to delete.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        props.removeFence(props.index);
        props.removeOverlay();
      } else {
        props.removeOverlay()
      }
    });
  };

  const handleClose = () => props.removeOverlay();

  const divStyle = {
    background: 'white',
    border: '1px solid #ccc',
    padding: 10
  };

  return (
    <div>
      <OverlayView
        position={props.position}
        mapPaneName={OverlayView.FLOAT_PANE}
      >
        <div style={divStyle} className='overlay-cont'>
          <div>
            <img
              src={AssetsImg?.ic_cancel}
              className="del-fence-btn"
              alt="staff-member-pi"
              onClick={handleClose}
            />
          </div>
          <div>
            <PrimaryButton
              text="Delete"
              onClick={() => handleDelete()}
              style={{
                width: '80px',
                marginTop: '30px',
                height:'20px',
              }} />
          </div>
        </div>
      </OverlayView>
    </div>
  );
};

export default DrawOverLayView;

import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/Primary";
import FullPageSpinner from "../../../components/FullPageSpinner";
import CustomTableComponent from "../../../components/CustomTableComponent";
import Pagination from "../../../components/Pagination";
import Hoc from "../../Hoc";
import { getAllVoucherSales } from "../../../core/Api/giftvouchers";
import "./Styles.scss";
import SaleDetails from "./details";
import CustomDropdown from "../../../components/Dropdown";
import { checkActionPermission } from "../../../utils/helper";

const Sales = () => {
	const { t } = useTranslation();
	const offset = 0;
	const pageSize = 10;

	const [pageNo, setPageNo] = useState(1);
	const [voucherSales, setVoucherSales] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedSale, setSelectedSale] = useState(null);
	const [filterSchema, setFilterSchema] = useState({
		status_id: 0,
	});

	const statusOptions = [
		{ label: t("Status"), value: 0 },
		{ label: t("redeemed"), value: 23 },
		{ label: t("confirmed"), value: 10 },
		{ label: t("paymentPending"), value: 16 },
		{ label: t("paymentCancelled"), value: 17 },
	];

	useEffect(() => {
		loadData(pageSize, offset);
	}, []);

	const loadData = async (per_page = 10, offset = 0, status_id = 0) => {
		setIsLoading(true);
		const result = await getAllVoucherSales({
			per_page,
			offset,
			status_id,
		});
		if (result && result.code === 200) {
			setVoucherSales(result?.result?.result_data || []);
			setTotalCount(result?.result?.total_records || 0);
		} else {
			toast.error(result && result.message);
		}
		setIsLoading(false);
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		loadData(pageSize, (pageNo - 1) * pageSize);
	};

	const onRowClick = (row, index) => {
		if (checkActionPermission("voucher_sales", "canEdit")) {
			setOpen(true);
			setSelectedSale(row);
		}
	};

	const getBackgroundColor = (status_id) => {
		switch (status_id) {
			case "23":
				return "green";
			case "10":
				return "#7f7f09";
			case "16":
				return "orange";
			case "17":
				return "orange";
			default:
				return "red";
		}
	};

	const getStatus = (status_id) => {
		switch (status_id) {
			case "23":
				return t("redeemed");
			case "10":
				return t("confirmed");
			case "16":
				return t("paymentPending");
			case "17":
				return t("paymentCancelled");
			default:
				return "N/A";
		}
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleSearch = async () => {
		await loadData(10, 0, filterSchema?.status_id || 0);
		setPageNo(1);
	};

	const columns = [
		{
			name: t("Sale ID"),
			key: "v_sale_id",
			formatter: null,
		},
		{
			name: t("Ref. #"),
			key: "reference_no",
			formatter: null,
		},
		{
			name: t("customerName"),
			key: "cust_first_name",
		},
		{
			name: t("Mobile"),
			key: "cust_mobile",
		},
		{
			name: t("Voucher Code"),
			key: "voucher_code",
		},
		{
			name: t("Voucher Name"),
			key: "voucher_name",
			formatter: null,
		},
		{
			name: t("Voucher Name(arb)"),
			key: "voucher_name_arb",
			formatter: null,
		},
		{
			name: t("Application Type"),
			key: "application_type",
			formatter: null,
		},
		{
			name: t("Amount"),
			key: "ext_amount",
			formatter: (row) => {
				return <p>{`${row?.ext_amount || 0} SAR`}</p>;
			},
		},
		{
			name: t("Seller Name"),
			key: "seller_name",
			formatter: null,
		},
		{
			name: t("Seller Category"),
			key: "seller_category",
			formatter: null,
		},
		{
			name: t("Seller City"),
			key: "seller_city_name",
			formatter: null,
		},
		{
			name: t("Gift to"),
			key: "gift_to",
			formatter: null,
		},
		{
			name: t("Gift from"),
			key: "gift_from",
			formatter: null,
		},
		{
			name: t("Status"),
			key: "status_id",
			formatter: (row) => {
				return (
					<p
						style={{
							color: getBackgroundColor(row?.status_id),
						}}
					>
						<b>{getStatus(row?.status_id)}</b>
					</p>
				);
			},
		},
	];

	return (
		<React.Fragment>
			<Hoc activeMenu="voucher_sales">
				<Container fluid className="sellers-listing">
					<Row className="heading-row">
						<Col xl="3" lg="4" md="6" sm="12">
							<div className="d-flex align-items-center h-100">
								<b>{t("Voucher Sales")}</b>
							</div>
						</Col>
						<Col md="auto" className="cta-col">
							{/* <PrimaryButton text={t("Add new")}
                onClick={() => { history.push('/seller') }}
              /> */}
						</Col>
					</Row>
					<Row className="mt-4">
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								options={statusOptions}
								onChange={(value) => handleChange(value.value, "status_id")}
								label={t("Sale Status")}
								value={statusOptions?.find((r) => r?.value === filterSchema?.status_id) || null}
								name="status_id"
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="cta-col mt-3 mb-2">
							<PrimaryButton text={t("Search")} onClick={handleSearch} style={{ width: "100%", marginTop: "25px" }} />
						</Col>
					</Row>
					<Row>
						<Col>
							{isLoading && (
								<div className="spinner-wrap">
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && totalCount === 0 && <div className="no-data-text text-center mt-2">{t("No data available")}</div>) || null}
						</Col>
					</Row>
					<Row className="mt-3">
						<Col lg="12">
							{(totalCount > 0 && (
								<CustomTableComponent
									responsive={true}
									hover={true}
									showSequence={true}
									pageSize={pageSize}
									pageNo={pageNo}
									columns={columns || []}
									data={voucherSales || []}
									onRowClick={onRowClick}
								/>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Pagination totalCount={totalCount || 0} viewCount={voucherSales?.length || 0} pageNo={pageNo} onChangePage={handlePageChange} />
					</Row>

					{(selectedSale && open && (
						<Modal
							size="lg"
							show={open}
							onHide={() => {
								setOpen(false);
								setSelectedSale(null);
							}}
						>
							<Modal.Header closeButton>
								<Modal.Title>{t("voucherSaleDetails")}</Modal.Title>
							</Modal.Header>
							<>
								<SaleDetails
									selectedSale={selectedSale}
									getBackgroundColor={getBackgroundColor}
									getStatus={getStatus}
									onRefreshList={() => {
										setPageNo(1);
										loadData();
										setSelectedSale(null);
										setOpen(false);
									}}
								/>
							</>
						</Modal>
					)) ||
						null}
				</Container>
			</Hoc>
		</React.Fragment>
	);
};

export default Sales;

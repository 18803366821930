import React from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InvoiceTable = ({ history, invoices }) => {
  const { t } = useTranslation();
  const handleInvoiceRedirection = (bookingId) => {
    window.open(`/invoice/${bookingId}`, "_blank");
  };
  return (
    <React.Fragment>
      <Table>
        <thead>
          <tr className="invoice-row">
            <th>{t("Status")}</th>
            <th>{t("Invoice#")}</th>
            <th>{t("Invoice date")}</th>
            <th>{t("Payment Mode")}</th>
            <th>{t("Total")}</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((key, index) => (
            <tr
              key={index}
              className="invoice-row"
              onClick={() => handleInvoiceRedirection(key.booking_id)}
            >
              <td>{key.status_id}</td>
              <td>{key.reference_number}</td>
              <td>
                {moment(key.date).format("DD")}
                {moment(key.date).format("MMM")}
              </td>
              <td>{t(`${key.payment_mode}`)}</td>
              <td>{t("SAR ") + key.total_amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {invoices.length && (
        <div className="invoice-total">
          {t("Showing")} {invoices.length} {t("of")} {invoices.length}{" "}
          {t("results")}
        </div>
      )}
    </React.Fragment>
  );
};

export default InvoiceTable;

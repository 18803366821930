import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import "./Styles.scss";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import { getPartners } from "../../../core/Api/partner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { checkActionPermission } from "../../../utils/helper";

const PartnersTable = ({ searchInput, reloadpartners, getCity, getAvailability }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [pageNo, setPageNo] = useState(1);
  const [partners, setPartners] = useState([]);
  const offset = (pageNo - 1) * 10;
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef(null);

  const debouncedLoadData = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      loadData(searchInput);
    }, 500);
  };

  useEffect(() => {
    if (searchInput?.trim()) {
      debouncedLoadData(searchInput);
    } else {
      loadData(searchInput);
    }
    // eslint-disable-next-line
  }, [searchInput]);

  useEffect(() => {
    if (reloadpartners) {
      loadData(searchInput);
    }
    // eslint-disable-next-line
  }, [reloadpartners]);

  const loadData = async (searchInput) => {
    setIsLoading(true);
    const result = await getPartners(offset, searchInput);
    if (result && result.code == 200) {
      const newPartners = [...partners];
      newPartners[pageNo - 1] = result.result;
      setPartners(newPartners);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const handlePageChange = async (type) => {
    setIsLoading(true);
    const page = type == "next" ? pageNo + 1 : pageNo - 1;
    if (page != 0) {
      setPageNo(page);
      if (!partners[page - 1]?.length) {
        const result = await getPartners((page - 1) * 10, searchInput);
        if (result && result.code == 200) {
          const newPartners = [...partners];
          newPartners[page - 1] = result.result;
          setPartners(newPartners);
        } else {
          toast.error(result && result.message);
        }
      }
    }
    setIsLoading(false);
  };

  const statusFormatter = (row) => {
    const status = ((status_id) => {
      switch (status_id) {
        case "1":
          return "Active";
        case "2":
          return "Inactive";
        case "3":
          return "Deleted";
        default:
          return "NA";
      }
    })(row?.status_id);
    let style = ((status_id) => {
      switch (status_id) {
        case "1":
          return { color: "#00a65a" };
        case "2":
          return { color: "#ffa470" };
          return { color: "#dd3333" };
        default:
          break;
      }
    })(row?.status_id);
    return (
      <div className="status-formatter" style={style}>
        {status}
      </div>
    );
  };


  return (
    <Container fluid className="partner-table">
      <Row>
        <Col>
          {partners[pageNo - 1]?.length ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("Name")}</th>
                  <th>{t("Registration No")}</th>
                  <th>{t("Payout Threshold")}</th>
                  <th>{t("Availability")}</th>
                  <th>{t("Type")}</th>
                  <th>{t("Payment Methods")}</th>
                  <th>{t("Date Joined")}</th>
                  <th>{t("City")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {partners[pageNo - 1].map((row, index) => (
                  <tr
                    onClick={() =>{
                      if(checkActionPermission("my_businesses","canView"))
                       history.push(`/partners/${row.supplier_id}`)
                      }}
                    key={index}
                  >
                    <td>{index + offset + 1}</td>
                    <td>{row.name}</td>
                    <td>{row.registration_number}</td>
                    <td>{row.payout_threshold}</td>
                    <td>{getAvailability(row.availability, true)}</td>
                    <td>{row.type?.toUpperCase() ?? 'N/A'}</td>
                    <td>{getAvailability(row.payment_method, false)}</td>
                    <td>{moment(row.date).format('YYYY-MM-DD hh:ss A')}</td>
                    <td>{getCity(row?.supplier_city)}</td>
                    <td>{statusFormatter(row)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : isLoading ? (
            <div className="spinner-wrap">
              <Spinner animation="border" />
              <span>
                {t(
                  `${
                    offset
                      ? `Fecthing data for Page ${pageNo}`
                      : t("Please wait, we're loading partners data")
                  }`
                )}
              </span>
            </div>
          ) : (
            <div className="no-data-text">{t("No data available")}</div>
          )}
        </Col>
      </Row>
      {!isLoading && (partners[pageNo - 1]?.length || searchInput) && (
        <Row className="pagination">
          <Col md="3 result-text">
            {partners[pageNo - 1]?.length
              ? t(
                  `Showing ${partners[pageNo - 1]?.length} out of ${
                    pageNo * 10
                  } Results`
                )
              : t("No Resuts found")}
          </Col>
          <Col md="5" className="count">
            <div className="previous" onClick={() => handlePageChange("prev")}>
              <img src={leftArrow} alt="left-arrow"></img>
            </div>
            <div className="pages">
              <span className={`cursor-pointer active `}>{pageNo}</span>
            </div>
            <div className="next" onClick={() => handlePageChange("next")}>
              <img src={rightArrow} alt="right-arrow"></img>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};
export default PartnersTable;

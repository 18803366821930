import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Container, Row, Col, Table, Modal, Image, Spinner, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import SecondaryButton from "../../../components/Buttons/Secondary";
import "./Styles.scss";
import PrimaryButton from "../../../components/Buttons/Primary";
import DangerButton from "../../../components/Buttons/Danger";
import DateInput from "../../../components/DateInput";
import UserInput from "../../../components/Inputs";
import { getAuthAttribute, getDateOptions } from "../../../utils/helper";
import trash from "../../../assets/images/trash.svg";
import Swal from "sweetalert2";

import {
  getStaffCloseDates,
  saveCloseDates,
  deleteStaffCloseDates,
} from "../../../core/Api/staff";
import { useTranslation } from "react-i18next";
import FullPageSpinner from "../../../components/FullPageSpinner";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import CheckBoxInput from "../../../components/Inputs/CheckBox";

const ClosedDates = (props) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        time: {
          message: t("End time must be greater than Start time"),
          rule: (endTime, params) => {
            if (!endTime || params[0] == "") {
              return false;
            }
            const startTime = params.join(" ", ":").replace(" ", ":");
            return moment(endTime, "LT").isAfter(moment(startTime, "LT"));
          },
          required: true,
        },
      },
    })
  );
  const [modalShow, setModalShow] = useState(false);
  const [closedDates, setClosedDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [start_time, setStartTime] = useState(null);
  const [end_time, setEndTime] = useState(null);
  const [all_day, setAllDay] = useState("1");
  const [supplierUnavailabilityId, setSupplierUnavailabilityId] =
    useState(null);
  const [edit, setEdit] = useState(false);
  const deleteClickRef = useRef(false);
  const supplierId = props.supplierId
    ? props.supplierId
    : getAuthAttribute("supplier_id");
  const [isLoading, setIsLoading] = useState(true);

  const dateOptions = [...getDateOptions()];



  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    const result = await getStaffCloseDates(supplierId);
    if (result && result.code == 200) {
      setClosedDates(result.result);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const saveCloseDate = async () => {
    toast.dismiss();
    if (
      simpleValidator.current.allValid() &&
      moment(startDate).isSameOrBefore(endDate)
    ) {
      const data = {
        supplier_id: supplierId,
        supplier_unavailability_id: edit ? supplierUnavailabilityId : "0",
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        description,
        is_active: true,
        all_day,
      }
      if(start_time?.value){
        data.start_time = moment(start_time?.value, "hh:mm A").format(
          "HH:mm"
        )
      }
      if(end_time?.value){
        data.end_time = moment(end_time?.value, "hh:mm A").format(
          "HH:mm"
        )
      }
      const result = await saveCloseDates({
        ...data
      });

      if (result && result.code == 200) {
        toast.success(result.message);
        handleModalClose();
        loadData();
        setAllDay("1")
      } else {
        toast.error(result && result.message);
      }
    } else {
      toast.error("Please enter valid details");
    }
  };

  const handleRowClick = (rowData) => {
    const startDate = moment(rowData?.start_date).isValid()
      ? new Date(rowData?.start_date)
      : undefined;
    const endDate = moment(rowData?.end_date).isValid()
      ? new Date(rowData?.end_date)
      : undefined;
      const startTime = moment(rowData?.start_time, "HH:mm:ss").isValid()
      ? {
        value:moment(rowData?.start_time, "HH:mm:ss").format('hh:mm A'),
        label:moment(rowData?.start_time, "HH:mm:ss").format('hh:mm A'),
      }
      : null;
      const endTime = moment(rowData?.end_time, "HH:mm:ss").isValid()
      ? {
        value:moment(rowData?.end_time, "HH:mm:ss").format('hh:mm A'),
        label:moment(rowData?.end_time, "HH:mm:ss").format('hh:mm A'),
      }
      : null;
    const description = rowData?.description;
    setStartDate(startDate);
    setEndDate(endDate);
    setDescription(description);
    setSupplierUnavailabilityId(rowData?.supplier_unavailability_id ?? null);
    setAllDay(rowData?.all_day || '1')
    setStartTime(startTime)
    setEndTime(endTime)
    setModalShow(true);
    setEdit(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    edit && setEdit(false);
    setStartDate(null);
    setEndDate(null);
    setDescription("");
    setStartTime(null);
    setEndTime(null)
    setAllDay("1")
    setSupplierUnavailabilityId(null);
  };

  const handleDelete = async (id) => {
    const Id = id ?? supplierUnavailabilityId;
    try {
      const response = await deleteStaffCloseDates(Id);
      if (response && response.code == 200) {
        toast.success(response.message);
        const updatedClosedDates = [...closedDates].filter(
          (value) => value.supplier_unavailability_id != Id
        );
        setClosedDates(updatedClosedDates);
        setModalShow(false);
        return true;
      }
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  const confirmDelete = async (id) => {
    Swal.fire({
      text: t(
        "Are you sure you won't be able to delete this service?This action cannot be undone."
      ),

      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await handleDelete(id);
        if (result) {
          Swal.fire("Deleted Successfully", "", "success");
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
      deleteClickRef.current = false;
    });
  };

  const formatDate = (value) => {
    if (moment(value).isValid()) return moment(value).format("dd, DD MMM YYYY");
    return "NA";
  };

  const getNumberOfDays = (start, end) => {
    if (moment(start).isValid() && moment(end).isValid()) {
      return moment(end).diff(moment(start), "days") + 1 + " " + t("Days");
    }
    return "_";
  };

  const handleSetAllDay = (value) => {
    setAllDay(value.target.checked ? "1" : "0")
  }

  return (
    <React.Fragment>
      <Container className="closed-dates" fluid>
        <Row className="cta-row">
          <Col xl="3" lg="5" md="6" xs="12">
            <PrimaryButton
              classNames="new-closed-date-button"
              text={t("New Closed Date")}
              onClick={() => setModalShow(true)}
            />
          </Col>
        </Row>
        <Row className="closed-dates-table">
          <Col>
            {isLoading && (
              <div className="spinner-wrap">
                <Spinner animation="border" />
                <span>{t("Please wait, we're loading data")}</span>
              </div>
            ) || (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t("From Date")}</th>
                      <th>{t("To Date")}</th>
                      <th>{t("No.Of Days")}</th>
                      <th>{t("Description")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {closedDates.map((row, index) => {
                      console.log(row);
                      return (
                        <tr
                          key={index}
                          onClick={() =>
                            !deleteClickRef.current && handleRowClick(row)
                          }
                        >
                          <td>{formatDate(row.start_date)}</td>
                          <td>{formatDate(row.end_date)}</td>
                          <td>{getNumberOfDays(row.start_date, row.end_date)}</td>
                          <td>{row.description}</td>
                          <td
                            style={{
                              padding: 0,
                              textAlign: "center",
                            }}
                          >
                            <Image
                              src={trash}
                              alt="delete"
                              onClick={() => {
                                deleteClickRef.current = true;
                                confirmDelete(row?.supplier_unavailability_id);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
          </Col>
        </Row>
      </Container>

      {/* Modals */}

      <Modal
        className="closed-dates-popup"
        centered
        size="lg"
        show={modalShow}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("New Closed Date")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row className="instruction mt-0">
              <Col>
                <div className="inst-text">
                  {t("Online bookings can not be placed during closed dates.")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mb-3">
                <DateInput
                  required
                  label={t("Start Date")}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholder={t("Select start date")}
                />
                {simpleValidator.current.message(
                  "startDate",
                  startDate,
                  "required"
                )}
              </Col>
              <Col md="4" className="mb-3">
                <DateInput
                  required
                  label={t("End Date")}
                  placeholder={t("Select end date")}
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                />
                {simpleValidator.current.message(
                  "endDate",
                  endDate,
                  "required"
                )}
              </Col>
              <Col md="4" sm="12" className="mb-3">
                <div className="display-cb-cont">
                  {(true) && <div className="label-cont">
                  </div> || null}
                  <div className="checkbox-label display">
                    <CheckBoxInput
                      checked={all_day == "1" && true || false}
                      onChange={(e) => handleSetAllDay(e)}
                    />
                    <Form.Label>{`${t("All Day")} `}&nbsp; </Form.Label>
                  </div>
                </div>
              </Col>
              {all_day == '0' && (
                <>
                  <Col md="6" className="mb-3">
                    <SearchableDropdown
                      required
                      label={t("Start Time")}
                      placeholder={t("Select start time")}
                      onChange={(value) => setStartTime(value)}
                      options={dateOptions}
                      value={dateOptions.find(
                        (value) => value.value == start_time?.value
                      )}
                    />
                    {simpleValidator.current.message(
                      "start_time",
                      start_time,
                      "required"
                    )}
                  </Col>
                  <Col md="6" className="mb-3">
                    <SearchableDropdown
                      required
                      label={t("End Time")}
                      placeholder={t("Select end time")}
                      onChange={(value) => setEndTime(value)}
                      options={dateOptions}
                      value={dateOptions.find(
                        (value) => value.value == end_time?.value
                      )}
                    />
                    {simpleValidator.current.message(
                      "end_time",
                      end_time,
                      `required`
                    )}
                  </Col>
                </>
              ) || null}

              <Col md="12" className="bottom">
                <UserInput
                  customInputClass="user-input"
                  label={t("Description (Optional)")}
                  placeholder={t(" eg . Public Holiday")}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer
          className={edit ? "justify-content-between" : "justify-flex-end"}
        >
          {edit && (
            <DangerButton text={t("Delete")} onClick={() => handleDelete()} />
          )}
          <div className="d-flex">
            <PrimaryButton text={t("Save")} onClick={saveCloseDate} />
            <SecondaryButton text={t("Cancel")} onClick={handleModalClose} />
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

ClosedDates.propTypes = {};

export default ClosedDates;

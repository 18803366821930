import React from "react";
import BlacklistedTable from "./Blacklist";
import Hoc from "../Hoc";

const BlackList = () => {
  return (
    <Hoc activeMenu="blacklisted_keywords">
      <BlacklistedTable />
    </Hoc>
  );
};

export default BlackList;

import Axios from "../Axios";

export const getStaffShifts = async (data) => {
  try {
    const response = await Axios.post("/staff/staff_shifts", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveStaffShift = async (data) => {
  try {
    const response = await Axios.post("/staff/save_shift", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServicesList = async (id) => {
  try {
    const response = await Axios.get(
      `/supplier/service_list?supplier_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStaffCloseDates = async (id) => {
  try {
    const response = await Axios.get(
      `/supplier/supplier_unavailabilities?supplier_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSupplierUnvailability = async (id) => {
  try {
    const response = await Axios.get(
      `/supplier/supplier_unavailabilities?supplier_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteStaffCloseDates = async (supplier_unavailability_id) => {
  try {
    const response = await Axios.get(
      `/supplier/delete_unavailability/${supplier_unavailability_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCloseDates = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_unavailability", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStaffMembersList = async (id) => {
  try {
    const response = await Axios.get(`staff/list?supplier_id=${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveStaffMember = async (data) => {
  try {
    const response = await Axios.post("/staff/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteStaffMember = async (id) => {
  try {
    const response = await Axios.get(`/staff/delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStaffMember = async (id, supplierId) => {
  try {
    const response = await Axios.get(
      `/staff/get/${id}?supplier_id=${supplierId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadImage = async (data) => {
  try {
    const response = await Axios.post("/common/upload_file", data,{
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveShift = async (data) => {
  try {
    const response = await Axios.post("/staff/save_shift", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteStaffShift = async (data) => {
  try {
    const response = await Axios.post("/staff/delete_shift", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStaffAndServiceCount = async (supplier_id) => {
  try {
    const response = await Axios.get(
      `/supplier/staffnservice_count/${supplier_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Container, Row, Col, Image, Modal } from "react-bootstrap";
import CustomDropdown from "../../../components/Dropdown";
import "./Styles.scss";
import moment from "moment";
import {
  getBookingById,
  getBookingHistoryByBookingId,
  cancelBooking,
  manageGuestBooking,
} from "../../../core/Api/bookings";
import { getInvoiceById } from "../../../core/Api/customer";
import { getSupplierDriver } from "../../../core/Api/supplier";
import BillIcon from "../../../assets/images/bill.png";
import locationIcon from "../../../assets/images/geo-alt-fill.svg";
import RescheduleAppointment from "../index";
import FullScreenPopup from "../../../components/FullScreenPopup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { getAuthAttribute } from "../../../utils/helper";
import { getToken, sendOtp, verifyOtp } from "../../../core/Api/Auth";
import PrimaryButton from "../../../components/Buttons/Primary";
import UserInput from "../../../components/Inputs";
import SimpleReactValidator from "simple-react-validator";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import { getSupplierAddress } from "../../../core/Api/supplier";
import AssetsImg from "images-col";
import FullPageSpinner from "../../../components/FullPageSpinner";


const ViewAppointment = ({ history }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        checkMobileNumber: {
          message: "Enter a valid mobile number",
          rule: (val) => {
            if (!val) {
              return false;
            } else if (val.split("-").length != 2) {
              return false;
            } else if (
              val.split("-")[1].length != 9 ||
              Number.isNaN(+val.split("-")[1])
            ) {
              return false;
            }
            return true;
          },
        },
      },
    })
  );
  simpleValidator.current.purgeFields();
  const urlParams = new URLSearchParams(history.location.search);
  const booking_id = urlParams.get("booking_id");
  const userTypeId = getAuthAttribute("user_type_id");
  const [guestInfo, setGuestInfo] = useState(null);
  const [show, setShow] = useState(true);
  const [, forceUpdate] = useState(0);
  const [authdata, setAuthData] = useState({
    otp: null,
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [status, setStatus] = useState(0);



  const [booking, setBooking] = useState({});
  const [driverData, setDriverData] = useState({});
  const [bookingHistory, setBookingisory] = useState([]);
  const [invoicePresent, setInvoicePresent] = useState(false);
  const [editAppointment, setEditAppointment] = useState(false);
  const [totalDuration, setDuration] = useState(0);
  const [supplierData, setSupplierData] = useState({});
  const [selectedOption, setSelectedOption] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [otpSent, setOtpSent] = useState(false);


  const menuOptions = useRef([
    { label: t("Reschedule"), value: "Reschedule" },
    { label: t("Cancel"), value: "Cancel" },
  ]);
  const statusOptions = [
    { label: t("Status"), value: 0 },
    { label: t("Confirmed"), value: 10 },
    { label: t("On The Way"), value: 19 },
    { label: t("In Progress"), value: 13 },
    { label: t("Completed"), value: 6 },
    { label: t("Pending"), value: 5 },
  ];

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption) {
        case "Cancel":
          Swal.fire({
            text: t("Are you sure you want to cancel the booking?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            cancelButtonText: t("Cancel"),
            confirmButtonText: t("Yes"),
          }).then(async (result) => {
            if (result.isConfirmed) {
              handleCancelBooking(booking_id);
            } else {
              setSelectedOption("");
            }
          });
          break;
        case "Reschedule":
          openOverlay();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    const state = history.location.state;
    if (state?.editAppointment) {
      setEditAppointment(true);
    } else {
      setEditAppointment(false);
    }
  }, [history.location.state]);

  useEffect(() => {
    loadInitialData(booking_id)
    return () => {
      localStorage.removeItem("boon-temp-token");
      closeOverlay();
    };
  }, [booking_id]);

  const loadInitialData = async (bookingId) => {
    await getPublicToken();
    await handleManageGuestBooking(bookingId);
  }

  const handleManageGuestBooking = async (bookingId) => {
    const result = await manageGuestBooking(bookingId);
    if (result?.code < 400) {
      setGuestInfo(result?.result?.guest_info || null);
    } else {
      toast.error(result?.message);
    }
    setIsLoading(false);
  }

  const handleOtpChange = (value) => {
    setAuthData({ ...authdata, otp: value });
  };

  const getPublicToken = async () => {
    const result = await getToken();
    if (result && result.code == 200) {
      const token = result.result.token;
      localStorage.setItem("boon-temp-token", token);
    } else {
      toast.error(result && result.message);
    }
  };

  const submitOTP = async () => {
    toast.dismiss();
    if (authdata?.otp) {
      setIsLoading(true);
      const data = {
        code: authdata.otp,
        mobile: guestInfo?.mobile,
      };
      const result = await verifyOtp(data);
      if (result && result.code == 200) {
        setShow(false);
        toast.success(result.message);
        await loadBookingData();
      } else {
        toast.error(result && result.message);
      }
      setIsLoading(false);
    } else {
      toast.error("Please enter OTP")
    }
  };

  const onEnterOtp = (event) => {
    if (event.code == "Enter") {
      submitOTP();
    }
  };

  const loadBookingData = async () => {
    if (booking_id) {
      setPageLoading(true);
      const [result, historyResult, invoiceResult] = await Promise.all([
        getBookingById(booking_id),
        getBookingHistoryByBookingId(booking_id),
        getInvoiceById(booking_id),
      ]);
      if (result && result.code == 200) {
        const status = t(result.result.status);
        setStatus(statusOptions.find((item) => item.label == status));
        setBooking(result.result);
        await fetchSupplier(result.result.supplier_id);
        const booking = result?.result || [];
        let tempDuration = 0;
        if (booking && booking.services && booking.services.length) {
          booking.services.forEach((service) => {
            tempDuration = tempDuration + Number(service.duration);
          });
          setDuration(tempDuration);
        }
      } else {
        toast.error(result && result.message);
      }
      if (historyResult && historyResult.code == 200) {
        setBookingisory(historyResult.result);
      } else {
        toast.error(historyResult && historyResult.message);
      }
      if (invoiceResult && invoiceResult.code == 200) {
        setInvoicePresent(true);
      } else {
        setInvoicePresent(false);
      }
      setPageLoading(false);
    } else {
      toast.error("Invalid Booking");
    }
  };

  const handleCancelBooking = async () => {
    const result = await cancelBooking(booking_id, userTypeId);
    if (result && result.code == 200) {
      toast.success(result.message);
    } else {
      toast.error(result && result.message);
    }
  };

  const openOverlay = () => {
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      editAppointment: true,
    });
  };

  const closeOverlay = () => {
    history.go(-1);
    setSelectedOption("");
    loadBookingData();
  };

  const handleRedirectToLocation = () => {
    if (booking.customer_address?.lat_lng) {
      const lat_lng = booking.customer_address.lat_lng
        .split(",")
        .map((val) => val?.trim());
      window.open(
        `https://maps.google.com/maps?z=12&t=m&q=${lat_lng[0]}+${lat_lng[1]}`,
        "_blank"
      );
    }
  };

  const fetchSupplier = async (supplier_id) => {
    toast.dismiss();
    try {
      const result = await getSupplierAddress(supplier_id);
      if (result.code == 200) {
        setSupplierData(result.result ?? {});
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  return (
    <React.Fragment>
      {pageLoading && <FullPageSpinner /> || null}
      <Modal show={show} onHide={false} centered>
        <Modal.Header>
          <Modal.Title>Enter Your OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <UserInput
              label="Enter OTP"
              placeholder="Enter OTP"
              onChange={(e) => handleOtpChange(e.target.value)}
              value={authdata?.otp}
              name="otp"
              required={true}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer className="opt-btn">
          <Col>
            <PrimaryButton
              text={t("Verify OTP")}
              style={{ width: "100%" }}
              onClick={submitOTP}
              onKeyDown={onEnterOtp}
              isLoading={isLoading}
            />
          </Col>
        </Modal.Footer>
      </Modal>
      {!show && (
        <div className="view-appointment">
          <h2>
            {t("View Appointment")}
            <h5> {booking?.name} </h5>
          </h2>
          <Container fluid className="main-container">
            <Row className="h-100">
              <Col md={{ span: 6, offset: 1 }}>
                <Container className="history-block">
                  <div className="name">
                    {booking &&
                      booking.customer &&
                      `${booking.customer.first_name} ${booking.customer.last_name}`}
                  </div>
                  <div className="product-name">
                    {booking &&
                      (booking.customer_address?.location ?? booking.location)}
                    {booking.customer_address && (
                      <Image
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRedirectToLocation()}
                        src={locationIcon}
                        className="mx-2 mb-2"
                        alt="location"
                      />
                    )}
                  </div>
                  {booking?.services && booking?.services?.length
                    ? booking.services.map((service, index) => {
                      return (
                        <Row className="calculation">
                          <Col md="3" className="time">
                            {`${moment(
                              service && service.appointment_date
                            ).format("ddd")} ${moment(
                              service && service.appointment_date
                            ).format("hh:mm A")} `}
                          </Col>
                          <Col md="6" className="product-name">
                            {service.service_name}
                            <div className="duration">
                              {`${service.duration} mins with ${service.staff_name}`}
                            </div>
                          </Col>
                          <Col md="3" className="product-amount">
                            {t("SAR")} {service && service.amount}
                          </Col>
                        </Row>
                      );
                    })
                    : null}
                  {booking &&
                    booking.location_type == "home" &&
                    booking.summary &&
                    booking.summary.delivery_fee ? (
                    <Row>
                      <Col md={{ span: 6, offset: 3 }}>
                        <div>{t("Delivery")}</div>
                      </Col>
                      <Col md="3">
                        <div className="amount">
                          {t("SAR")} {booking && booking.summary.delivery_fee}
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <Row className="total">
                    <Col md={{ span: 6, offset: 3 }} className="duration">
                      {`${totalDuration} mins`}
                    </Col>
                    <Col md="3" className="amount">
                      {t("SAR")} {booking && booking.total_amount}
                    </Col>
                  </Row>
                  <Row className="appointment-timeline">
                    <Col md="12">
                      <div className="heading">{t("Appointment history")}</div>
                      {bookingHistory.map((row) => (
                        <React.Fragment key={row.service_name}>
                          <p>
                            <strong>{row.service_name}</strong>
                          </p>
                          <ul>
                            {row.flow.map((flow) => (
                              <li key={flow.description}>
                                {`${flow.description} at ${moment(
                                  flow.date
                                ).format("ddd")}, ${moment(flow.date).format(
                                  "DD  MMM YYYY"
                                )} at ${moment(flow.date).format("HH:mm A")}`}
                              </li>
                            ))}
                          </ul>
                        </React.Fragment>
                      ))}
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={{ span: 3, offset: 2 }} className="invoice-panel">
                <div
                  className="client-details"
                  onClick={() =>
                    window.open(
                      window.location.origin +
                      `/profile/${booking?.customer.customer_id}`,
                      "_blank"
                    )
                  }
                >
                  <Image
                    src={AssetsImg?.img_default}
                  />
                  <div className="email-cont">
                    <label>
                      {booking &&
                        booking.customer &&
                        `${booking.customer.first_name} ${booking.customer.last_name}`}
                    </label>
                    <div className="value">
                      {booking &&
                        booking.customer &&
                        `${booking.customer.mobile}`}
                    </div>
                  </div>
                </div>
                <div className="invoice">
                  <Container fluid>
                    <Row>
                      {
                        <Col>
                          {invoicePresent ? (
                            <Link
                              className="invoice-link"
                              to={`/invoice/${booking_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("View Invoice")}
                            </Link>
                          ) : (
                            <div className="no-invoice-wrap">
                              <Image
                                className="bill-image"
                                src={BillIcon}
                                alt="No Invoice"
                              />
                              <div className="no-invoice">
                                {t("No invoice issued yet")}
                              </div>
                            </div>
                          )}
                        </Col>
                      }
                      <div className="service-menu">
                        <CustomDropdown
                          options={menuOptions.current}
                          value={menuOptions.current.find(
                            (value) => value.label == selectedOption
                          )}
                          highlightedOptions={["Cancel", "No show"]}
                          onChange={(value) => setSelectedOption(value.value)}
                        />
                      </div>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {(!show && editAppointment )&& (
        <FullScreenPopup open={editAppointment} onClose={closeOverlay}>
          <RescheduleAppointment
            supplierData={supplierData}
            isEdit={true}
            onCancel={closeOverlay}
            booking_id={booking_id}
            customerId={booking?.customer_id}
            status={status}
            history={history}
            selectedPartner={booking.supplier_id}
            supplier_id={booking.supplier_id}
          />
        </FullScreenPopup>
      ) || null}
    </React.Fragment>
  );
};

export default ViewAppointment;

import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';

const ServicesBarGraph = ({ graphsData }) => {
	const { services = {} } = graphsData;
	const [data, setData] = useState({});
	useEffect(() => {
		if (!_.isEmpty(services)) {
			setData({
				labels: ['Home', 'Salon', 'Both'],
				datasets: [
					{
						label: 'Home',
						data: [services?.at_home ?? 0],
						backgroundColor: '#a5aeb5',
						barPercentage: 7,
						// barThickness:500
					},
					{
						label: 'Salon',
						data: [services?.at_salon ?? 0],
						backgroundColor: '#3a5f71',
						barPercentage: 7,
					},
					{
						label: 'Both',
						data: [services?.both ?? 0],
						backgroundColor: '#2d4d5a',
						barPercentage: 7,
					},
				],
			});
		}
	}, [graphsData]);

	return (
		<>
			{!_.isEmpty(data) && (
				<Bar
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								stacked: true,
								display: false,
								barPercentage: 1,
								barThickness: 100,
							},
							y: {
								stacked: true,
								display: false,
							},
							xAxes: [
								{
									barPercentage: 1,
									barThickness: 100,
								},
							],
						},
						plugins: {
							legend: {
								display: true,
								position: 'bottom',
							},
							customBarWidth: {
								width: 50, // Specify the desired bar width
							},
							datalabels: {
								display: true, // Enable or disable data labels for all datasets
								anchor: 'center', // Position of the label relative to the data point (center, start, end, auto)
								align: 'center', // Text alignment (center, start, end, left, right)
								color: 'white', // Label text color
								font: {
									weight: 'bold', // Label font weight
									size: '20px',
								},
								formatter: (value, context) => {
									// Custom function to format the label text
									return value;
								},
							},
						},
						elements: {
							bar: {
								borderWidth: 0,
							},
						},
					}}
					data={data}
					plugins={[ChartDataLabels]}

				/>
			)}
		</>
	);
};

export default ServicesBarGraph;

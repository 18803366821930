import React, { useState } from "react";
import { Form, InputGroup, FormControl, Image } from "react-bootstrap";
import "./Styles.scss";
import clsx from "clsx";
import eyeFill from "../../../assets/images/eye-fill.svg";
import eyeSlash from "../../../assets/images/eye-slash-fill.svg";

const UserInput = (props) => {
  const { label, placeholder, customInputClass, autoComplete, required } =
    props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={clsx("custom-input", customInputClass)}>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
        </Form.Label>
      )}
      <InputGroup>
        <FormControl
          autoComplete={autoComplete}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          {...props}
        />
        <Image
          src={!showPassword ? eyeSlash : eyeFill}
          alt=""
          className="eye"
          onClick={() => setShowPassword(!showPassword)}
        />
      </InputGroup>
    </div>
  );
};

export default UserInput;

import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProfileCompletionDetails from "../profileCompletionDetail";
import PC1 from "../../assets/images/pc-1.svg";
import BoonContext from "../../context";
import { useTranslation } from "react-i18next";

const ProfileStatus = ({
	profileStats,
	showProfileStatus,
	setShowProfileStatus
}) => {
	const { t } = useTranslation();
	const [showProfileDetailsModal, setShowProfileDetailsModal] = useState(false);
	const [show, setShow] = useState(false);
	const { setIWillDoLater } = useContext(BoonContext);

	useEffect(() => {
		setShow(showProfileStatus)
	}, [showProfileStatus])
	return (
		<>
			<Modal
				size="lg"
				show={show}
				onHide={() => {
					setShow(false);
					setShowProfileDetailsModal(false);
				}}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Body>
					<div className='incomplete-profile-status-cont'>
						<div className="profile-completion-body">
							<div className="profile-completion-body-cont">
								<div className="profile-completion-header">
									<p>{t('Complete your profile')}</p>
								</div>
								<div className="img-cont">
									<img
										src={PC1}
										className="PC1"
										alt="PC1"
										width="200px"
									/>
								</div>
								<div className="profile-completion-desc1">
									<p>{t('Please complete your profile')}</p>
								</div>
								<div className="profile-completion-desc2">
									<p>{t('Customers prefer to look at profiles that are complete. A complete profile helps us make accurate recommendation and increase visibility')}</p>
								</div>
							</div>
						</div>
						<div className="profile-completion-footer">
							<div className="profile-completion-footer-cont">
								<div className="profile-completion-do-later" onClick={() => {
									setIWillDoLater(true);
									setShow(false);
									setShowProfileStatus(false)
								}}>
									<p >{t("I'll do later")}</p>
								</div>
								<div className="profile-completion-lets-do" onClick={() => {
									setShow(false);
									setShowProfileDetailsModal(true);
								}}>
									<p >{t("Let's do it")}</p>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{showProfileDetailsModal &&
				<ProfileCompletionDetails
					showProfileDetailsModal={showProfileDetailsModal}
					setShowProfileDetailsModal={(value) => {
						setShowProfileDetailsModal(value);
						setShowProfileStatus(false)
					}}
					profileStats={profileStats}
				/> || null}
		</>
	)
}

export default ProfileStatus;
import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Form,
  Overlay,
  Popover,
  PopoverContent,
} from "react-bootstrap";
import SearchableDropdown from "../../components/Dropdown/SearchableDropdown";
import SearchInput from "../../components/Inputs/Search";
import CustomTextArea from "../../components/Textarea";
import UserInput from "../../components/Inputs";
import SecondaryButton from "../../components/Buttons/Secondary";
import PrimaryButton from "../../components/Buttons/Primary";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Styles.scss";
import "../../components/Dropdown/Styles.scss";
import { getAllCustomers, getSelectedCustomer } from "../../core/Api/customer";
import { getServices, getStartTimebyStaff } from "../../core/Api/services";
import { toast } from "react-toastify";
import {
  getBookingById,
  rescheduleBooking,
  getAllBookingsByCustomerId,
} from "../../core/Api/bookings";
import { getInvoicesByCustomerId } from "../../core/Api/customer";
import { getAuthAttribute } from "../../utils/helper";
import { capitalCase } from "change-case";
import menuIcon from "../../assets/images/three-dots.svg";
import clearIcon from "../../assets/images/ic_cancel.svg";
import plusIcon from "../../assets/images/plus-circle.svg";
import billIcon from "../../assets/images/bill.png";
import downArrow from "../../assets/images/chevron-down.svg";
// eslint-disable-next-line
import InvoiceTable from "../Client/Profile/InvoiceTable";
import AppointmentList from "../Client/Profile/appointment";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import FullPageSpinner from "../../components/FullPageSpinner";

const RescheduleAppointment = (props) => {
  const { t } = useTranslation();
  const { onCancel, isEdit, history, selectedStaffId, supplierData, supplier_id } = props;
  const booking = useRef(null);
  const menuRef = useRef(null);
  const [startdate, setStartDate] = useState(props?.startDate ?? moment()._d);
  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState({
    service: [[]],
    price: [[]],
    staff: [[]],
    startTime: [[]],
    duration: [],
  });
  const [selectedOptions, setSelectedOptions] = useState({
    startTime: [],
    service: [],
    duration: [],
    staff: [],
    price: [],
  });
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedRadioBtn, setSelectedRadioBtn] = useState(
    (supplierData.is_home == "1" && supplierData.is_salon == "1") ||
      supplierData.is_home == "0"
      ? "Salon"
      : "Home"
  );
  // eslint-disable-next-line
  const [selectedPartner, setSelectedPartner] = useState(
    props.selectedPartner ?? null
  );
  const [notes, setNotes] = useState("");
  const radioOptions = ["Salon", "Home"];
  const [serviceLength, setserviceLength] = useState(1);
  const [target, setTarget] = useState(null);
  const tabs = ["Appointments", "Products", "Invoices", "Info"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [appointments, setAppointments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const fetchTimeslotOptions = async (supplier_service_id, duration) => {
    try {
      const results = await getStartTimebyStaff(
        supplier_service_id,
        duration,
        moment(startdate).format("YYYY-MM-DD")
      );
      let available_timeslots = results?.result?.available_timeslots ?? [];
      const staffOptions = available_timeslots.map((item) => ({
        ...item,
        label: item.staff_name,
        value: item.supplier_staff_id,
      }));
      return staffOptions;
    } catch {
      return [];
    }
  };
  
  useEffect(() => {
    const updatedSelectedOptions = { ...selectedOptions };
    updatedSelectedOptions.service.forEach((_, index) => {
      updatedSelectedOptions.startTime[index] = undefined;
      updatedSelectedOptions.staff[index] = undefined;
      updatedSelectedOptions.price[index] = undefined;
      updatedSelectedOptions.duration[index] = undefined;
    });
    setSelectedOptions(updatedSelectedOptions);
    // eslint-disable-next-line
  }, [startdate]);

  const handleServiceChange = (index, newValue) => {
    const updatedSelectedOptions = { ...selectedOptions };
    updatedSelectedOptions.startTime[index] = undefined;
    updatedSelectedOptions.staff[index] = undefined;
    updatedSelectedOptions.price[index] = undefined;
    updatedSelectedOptions.duration[index] = undefined;
    updatedSelectedOptions.service[index] = newValue;
    const price = newValue.price.map((item) => ({
      ...item,
      label: `${item.amount} SAR`,
      value: item.supplier_service_opt_id,
      supplier_service_opt_id: item.supplier_service_opt_id,
      duration: item.duration,
      amount: item.amount,
      supplier_service_id: item.supplier_service_id,
      original_amount: item.original_amount,
    }));
    const newStartTime = [...options.startTime];
    const newPrice = [...options.price];
    newStartTime[index] = [];
    newPrice[index] = price;
    const newOptions = {
      ...options,
      startTime: newStartTime,
      price: newPrice,
    };
    setSelectedOptions(updatedSelectedOptions);
    if (price.length == 1) {
      const newValue = price[0];
      handlePriceChange(index, newValue, newOptions);
    } else {
      setOptions(newOptions);
    }
  };

  const handleStaffChange = (index, newValue) => {
    const updatedSelectedOptions = { ...selectedOptions };
    const timeslots =
      newValue?.timeslots?.map((item) => ({
        value: item,
        label: item,
      })) ?? [];
    const newStartTime = [...options.startTime];
    newStartTime[index] = [...timeslots];
    updatedSelectedOptions.staff[index] = newValue;
    setOptions({
      ...options,
      startTime: newStartTime,
    });
    setSelectedOptions(updatedSelectedOptions);
  };

  const handlePriceChange = async (index, newValue, options) => {
    setIsLoading(true);
    const updatedSelectedOptions = { ...selectedOptions };
    updatedSelectedOptions.staff[index] = undefined;
    updatedSelectedOptions.duration[index] = newValue.duration;
    updatedSelectedOptions.price[index] = newValue;
    const timeslots =
      newValue?.timeslots?.map((item) => ({
        value: item,
        label: item,
      })) ?? [];
    const staff = await fetchTimeslotOptions(
      newValue.supplier_service_id,
      newValue.duration
    );
    const newStartTime = [...options.startTime];
    newStartTime[index] = [...timeslots];
    const newStaff = [...options.staff];
    newStaff[index] = staff;
    setOptions({
      ...options,
      startTime: newStartTime,
      staff: newStaff,
    });
    setSelectedOptions(updatedSelectedOptions);
    setIsLoading(false);
  };

  const handleStartTimeChange = (index, newValue) => {
    const updatedSelectedOptions = { ...selectedOptions };
    updatedSelectedOptions.startTime[index] = newValue;
    setSelectedOptions(updatedSelectedOptions);
  };

  const handleChange = (key, index, newValue) => {
    switch (key) {
      case "service":
        handleServiceChange(index, newValue);
        break;
      case "staff":
        handleStaffChange(index, newValue);
        break;
      case "startTime":
        handleStartTimeChange(index, newValue);
        break;
      case "price":
        handlePriceChange(index, newValue, options);
        break;
      default:
        break;
    }
  };

  const addService = () => {
    setserviceLength((prev) => prev + 1);
    const newOptions = { ...options };
    let newServiceList = newOptions.service[serviceLength - 1];
    const selectedService = selectedOptions.service[serviceLength - 1];
    newServiceList = newServiceList.filter(
      (item) =>
        item.supplier_service_id != selectedService?.supplier_service_id
    );
    if (!newServiceList.find((item) => item.value == null)) {
      newOptions.service.push([...newServiceList]);
    } else {
      newOptions.service.push(newServiceList);
    }
    newOptions.startTime.push([]);
    newOptions.staff.push([]);
    setOptions(newOptions);
  };

  const removeService = (filterIndex) => {
    const updatedOptions = { ...selectedOptions };
    Object.keys(selectedOptions).forEach((key) => {
      const newValue = { ...updatedOptions }[key].filter(
        (_, index) => index != filterIndex
      );
      updatedOptions[key] = newValue;
    });
    const newOptions = { ...options };
    Object.keys(options).forEach((key) => {
      const newValue = { ...newOptions }[key].filter(
        (_, index) => index != filterIndex
      );
      newOptions[key] = newValue;
    });
    setOptions(newOptions);
    setSelectedOptions(updatedOptions);
    setserviceLength((prev) => prev - 1);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line
  }, [selectedPartner, selectedRadioBtn]);

  useEffect(() => {
    if (search.trim()) {
      debouncedGetCustomer.cancel();
      debouncedGetCustomer(search.trim());
    } else {
      setCustomerList([]);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (props.customerId) {
      (async () => {
        const { result } = await getSelectedCustomer(props.customerId);
        if (result) setSelectedCustomer(result);
      })();
    }
  }, [props.customerId]);

  useEffect(() => {
    if (selectedStaffId) {
      const value = options?.staff[serviceLength - 1]?.find(
        (value) => value.supplier_staff_id == selectedStaffId
      );
      if (value) {
        handleChange("staff", 0, value);
      }
    }
    // eslint-disable-next-line
  }, [selectedStaffId]);

  async function fetchCustomerData(supplier_id, customer_id) {
    const [invoicesResult, bookingsResult] = await Promise.all([
      getInvoicesByCustomerId(customer_id),
      getAllBookingsByCustomerId(supplier_id, customer_id),
    ]);
    if (invoicesResult && invoicesResult.code == 200) {
      setInvoices(invoicesResult.result);
    } else {
      toast.error(invoicesResult && invoicesResult.message);
    }
    if (bookingsResult && bookingsResult.code == 200) {
      setAppointments(bookingsResult.result);
    }
  }

  useEffect(() => {
    if (selectedCustomer?.customer_id && supplier_id) {
      fetchCustomerData(supplier_id, selectedCustomer.customer_id);
    }
  }, [selectedCustomer, supplier_id]);

  useEffect(() => {
    (async () => {
      if (props.booking_id) {
        const appointmentData = await getBookingById(props.booking_id);
        const location_type =
          appointmentData?.result?.location_type ?? selectedRadioBtn;
        setSelectedRadioBtn(capitalCase(location_type));
        setStartDate(
          new Date(appointmentData?.result?.services[0]?.appointment_date)
        );

        const services = appointmentData?.result?.services ?? [];
        const defaultOptions = { ...selectedOptions };
        booking.current = {
          booking_id: appointmentData?.result?.booking_id,
          bookingDate: moment().format("YYYY-MM-DD"),
        };
        services.forEach((service) => {
          const {
            start_time,
            duration,
            staff_name,
            supplier_staff_id,
            ...rest
          } = service;
          let date = start_time.split(":");
          let suffix = "AM";
          if (+date[0] > 12 && date[2]) {
            suffix = "PM";
            date[0] = String(+date[0] - 12);
          }
          date = date.slice(0, 2).join(":") + " " + suffix;
          defaultOptions.startTime.push({
            value: date,
            label: date,
          });
          defaultOptions.duration.push(duration);
          defaultOptions.service.push({
            value: rest.supplier_service_id,
            label: rest.service_name,
            ...rest,
          });
          defaultOptions.price.push({
            amount: rest.amount,
            duration: duration,
            label: `${rest.amount} SAR`,
            supplier_service_id: rest.supplier_service_id,
            supplier_service_opt_id: rest.supplier_service_opt_id,
            value: rest.supplier_service_opt_id,
          });
          defaultOptions.staff.push({
            value: supplier_staff_id,
            label: staff_name,
            supplier_staff_id: service?.supplier_staff_id,
          });
        });
        setSelectedOptions(defaultOptions);
        setserviceLength(defaultOptions.service.length);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const getAmount = (serviceArray, isTotal = false) => {
    let amount = 0;
    if (isTotal) {
      serviceArray.price.forEach(
        (serv) => (amount = amount + (serv?.amount ? Number(serv.amount) : 0))
      );
    } else {
      serviceArray.service.forEach(
        (serv) =>
          (amount =
            amount + (serv?.original_amount ? Number(serv.original_amount) : 0))
      );
    }
    return amount;
  };

  const getDuration = (durationArray) => {
    let durationCount = 0;
    durationArray.forEach(
      (duration) =>
        (durationCount =
          durationCount + (!Number.isNaN(+duration) ? +duration : 0))
    );
    if (durationCount < 60) {
      return durationCount + " mins";
    } else {
      let hours = durationCount / 60;
      return hours.toFixed(2) + " h";
    }
  };

  const handleSave = async () => {
    toast.dismiss();
    let a = selectedOptions.startTime.length;
    let b = selectedOptions.service.length;
    let c = selectedOptions.duration.length;
    let d = selectedOptions.staff.length;

    if (a != b || a != c || a != d || a == 0) {
      toast.error("Please Enter The Required Details");
      return;
    }
    const allSelectedServices = selectedOptions.service.map((value, index) => ({
      amount: selectedOptions.price[index].amount,
      duration: selectedOptions.duration[index],
      isSelected: true,
      booking_service_id: isEdit ? value.booking_service_id : 0,
      is_section: value.is_section,
      name: value.name,
      offers: value.offers,
      original_amount: selectedOptions.price[index].original_amount,
      priceWithOffer: selectedOptions.price[index].priceWithOffer ?? 0,
      service_date: moment(startdate).format("YYYY-MM-DD"),

      service_time: selectedOptions?.startTime[index]?.value,
      supplier_service_id: value.supplier_service_id,
      supplier_staff_id: selectedOptions.staff[index].supplier_staff_id,
      supplier_service_opt_id:
        selectedOptions.price[index].supplier_service_opt_id,
    }));
    let payload = {
      application_type: "Backoffice",
      booking_id: "",
      services: allSelectedServices,
    };
    if (booking.current) {
      payload = {
        ...payload,
        booking_id: booking.current.booking_id,
        booking_date: booking.current.bookingDate,
        booking_status_id: props.status ?? 0,
      };
    }
    const response = await rescheduleBooking(payload);
    if (response && response.code == 200) {
      toast.success("Successfully Saved!");
      onCancel && onCancel();
    } else {
      toast.error(response.message);
    }
  };

  const fetchServices = async () => {
    let data = [];
    try {
      const locationType = selectedRadioBtn == "Home" ? "at_home" : "at_salon";
      const response = await getServices(supplier_id, locationType);
      setIsPageLoading(false);
      data =
        response?.result
          .flatMap((category) => category?.services)
          .map((service) => ({
            label: service.name,
            value: service.supplier_service_id,
            ...service,
          })) ?? [];
    } catch (error) {
      console.log("ERROR:", error.message);
    }
    const newOptions = { ...options };
    newOptions.service[0] = data.map((item) => {
      const updatedItem = {
        ...item,
        price: item.options,
      };
      delete updatedItem.options;
      return updatedItem;
    });
    setOptions(newOptions);
  };

  const getCustomerList = async (searchInput) => {
    const response = await getAllCustomers(searchInput);
    const customerList = response?.result?.data ?? [];
    setCustomerList(customerList);
  };
  // eslint-disable-next-line
  const debouncedGetCustomer = useCallback(
    debounce((searchInput) => {
      getCustomerList(searchInput);
    }, 100),
    [props.customerId]
  );

  const renderTabContent = (key) => {
    switch (key) {
      case "Info":
        return (
          <div className="filled-data">
            <label>{t("Name")}</label>
            <div className="response">
              {selectedCustomer?.first_name + " " + selectedCustomer?.last_name}
            </div>
            <label>{t("Email")}</label>
            <div className="response">
              {selectedCustomer?.email ? selectedCustomer.email : "NA"}
            </div>
            <label>{t("Marketing notifications")} </label>
            <div className="response">
              {t(
                `${
                  selectedCustomer?.accept_marketing_notification == "1"
                    ? " Accepts marketing notifications"
                    : "NA"
                }`
              )}
            </div>
          </div>
        );
      case "Invoices":
        if (invoices.length) {
          return <InvoiceTable history={history} invoices={invoices} />;
        } else {
          return (
            <>
              <Image
                className="no-history-image"
                alt="box-icon"
                src={billIcon}
              />
              <h3>{t("No Sales History")}</h3>
              <span>
                {t(
                  "Use the checkout feature to create a new sale for this client"
                )}
              </span>
            </>
          );
        }
      case "Appointments":
        if (appointments?.status_list?.length) {
          return <AppointmentList appointments={appointments} />;
        } else {
          return (
            <>
              <Image
                className="no-history-image"
                alt="box-icon"
                src={billIcon}
              />
              <h3>{t("No Sales History")}</h3>
              <span>
                {t(
                  "Use the checkout feature to create a new sale for this client"
                )}
              </span>
            </>
          );
        }
      default:
        return (
          <>
            <Image className="no-history-image" alt="box-icon" src={billIcon} />
            <h3>{t("No Sales History")}</h3>
            <span>
              {t(
                "Use the checkout feature to create a new sale for this client"
              )}
            </span>
          </>
        );
    }
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {moment(value).format("dddd ,")} {value}{" "}
      {<Image className="ml-1" alt="down-icon" src={downArrow} />}
    </button>
  ));

  const renderSearchList = () => {
    if (selectedCustomer) {
      return (
        <div key={selectedCustomer.id} className="customer-details-wrapper">
          <Image
            src={selectedCustomer.profile_pic}
            data-holder-rendered="true"
          />
          <div className="customer-details">
            <p>{selectedCustomer.first_name}</p>
            <span>
              {selectedCustomer.email
                ? selectedCustomer.email
                : selectedCustomer.mobile}
            </span>
          </div>
        </div>
      );
    }
    return customerList.map((item) => {
      return (
        <div
          key={item.id}
          onClick={() => {
            setCustomerList([]);
            setSelectedCustomer(item);
            setSearch("");
          }}
          className="customer-details-wrapper"
        >
          <Image src={item.profile_pic} data-holder-rendered="true" />
          <div className="customer-details">
            <p>{item.first_name}</p>
            <span>
              {item.email} {item.mobile}
            </span>
          </div>
        </div>
      );
    });
  };

  const formatDuration = (value) => {
    if (value) {
      return `${value} min`;
    }
    return value;
  };

  return (
    <div className="add-appointment">
      {isPageLoading && <FullPageSpinner /> || null}
      <Container fluid>
        <Row className="h-100">
          <Col md={{ span: 5, offset: 1 }}>
            <h2> {t("Edit Appointment")} </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-container">
        <Row className="h-100 w-100">
          <Col md={{ span: 6, offset: 1 }}>
            <Container className="left-block w-100">
              <div className="divider-line"></div>
              <div className="radio-group-row">
                <DatePicker
                  selected={startdate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  customInput={<CustomInput />}
                  closeOnScroll={(e) => e.target == document}
                  dateFormat=" d MMM yyyy"
                />
                {supplierData.is_home == "1" && supplierData.is_home == "1" && (
                  <div className="radio-group">
                    {radioOptions.map((value, index) => (
                      <Form.Check
                        inline
                        key={value}
                        type="radio"
                        aria-label={`radio ${index}`}
                        onChange={() => setSelectedRadioBtn(value)}
                        checked={selectedRadioBtn == value}
                        label={t(`${value}`)}
                        id={`inline-${value}`}
                      />
                    ))}
                  </div>
                )}
              </div>
              {Array.from({ length: serviceLength }).map((_, i) => (
                <div className="service-block" key={i}>
                  <Row className="mt-4">
                    {Object.keys(options).map((key, index) => {
                      const dropdownOptions = options[key][i]?.length
                        ? [...options[key][i]]
                        : [];
                      return (
                        <Col key={index} md={index % 2 === 0 ? "8" : "4"}>
                          {index == 4 ? (
                            <UserInput
                              required
                              disabled={true}
                              label={t("Duration")}
                              placeholder={t("Service Duration")}
                              value={
                                formatDuration(selectedOptions[key][i]) ?? ""
                              }
                            />
                          ) : index == 2 &&
                            selectedOptions.service[i] &&
                            !dropdownOptions.length &&
                            !selectedOptions.staff[i] ? (
                            <span className="warning">
                              <Link to={`/staff/${supplier_id}`}>
                                <Button className="mr-1">Add staff</Button>
                              </Link>
                              No Staff available for selected service and date
                            </span>
                          ) : (
                            <SearchableDropdown
                              required
                              value={selectedOptions[key][i] ?? null}
                              disabled={(() => {
                                switch (key) {
                                  case "staff":
                                  case "startTime":
                                    return isLoading;
                                  default:
                                    return false;
                                }
                              })()}
                              placeholder={(() => {
                                switch (key) {
                                  case "service":
                                    return t("Select Services");
                                  case "staff":
                                    return t("Select Staff");
                                  case "startTime":
                                    return t("Select Time");
                                  case "price":
                                    return t("Select Price");
                                  default:
                                    break;
                                }
                              })()}
                              options={dropdownOptions}
                              label={t(`${capitalCase(key)}`)}
                              isMulti={false}
                              onChange={(newValue) =>
                                handleChange(key, i, newValue)
                              }
                            />
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                  {i < serviceLength - 1 ? (
                    <Button
                      className="remove-service-button"
                      onClick={() => removeService(i)}
                    >
                      <Image alt="remove-icon" src={clearIcon} />
                    </Button>
                  ) : (
                    <Button
                      className="add-service-button"
                      onClick={() => addService(i)}
                    >
                      <Image src={plusIcon} alt="plus-Icon" />
                    </Button>
                  )}
                </div>
              ))}
              <Row className="mt-4">
                <Col className="notes">
                  <CustomTextArea
                    value={notes}
                    onChange={handleNotesChange}
                    label={t("Appointment notes")}
                  />
                </Col>
              </Row>
              <Row className="mt-5 justify-content-end mb-3">
                <Col md="auto">
                  <SecondaryButton text={t("Cancel")} onClick={onCancel} />
                  <PrimaryButton
                    text={t("Save")}
                    onClick={handleSave}
                    disabled={isLoading}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={{ span: 4, offset: 1 }} className="search-panel">
            <div className="right-container">
              {!selectedCustomer && (
                <SearchInput
                  placeholder={t("Search Client")}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              {selectedCustomer && (
                <Image
                  alt="menu-icon"
                  className="three-dot-icon"
                  onClick={(event) => setTarget(target ? null : event.target)}
                  src={menuIcon}
                />
              )}
              <Overlay
                show={Boolean(target)}
                target={target}
                placement="bottom"
                container={menuRef.current}
              >
                <Popover bsPrefix="remove-selected-item">
                  <PopoverContent
                    onClick={() => {
                      setSelectedCustomer("");
                      setTarget(null);
                    }}
                  >
                    {t("Remove Customer")}
                  </PopoverContent>
                </Popover>
              </Overlay>
              <div
                className="bottom-sec"
                style={{ height: selectedCustomer ? "100%" : undefined }}
              >
                {customerList.length || selectedCustomer ? (
                  <div className="search-list">
                    {renderSearchList()}
                    {selectedCustomer && (
                      <div className="client-info-block">
                        <div className="summary-block">
                          <div>
                            <h5>0</h5>
                            <span>{t("Total Bookings")}</span>
                          </div>
                          <div>
                            <h5>{`${t("SAR")} ${getAmount(
                              selectedOptions,
                              true
                            )}`}</h5>
                            <span>{t("Total Sales")}</span>
                          </div>
                        </div>
                        <div className="info-tabs">
                          {tabs.map((value) => (
                            <span
                              key={value}
                              className={
                                value == selectedTab
                                  ? "selected-tab"
                                  : undefined
                              }
                              onClick={() => setSelectedTab(value)}
                            >
                              {t(`${value}`)}
                            </span>
                          ))}
                        </div>
                        <div className="tab-content">
                          {renderTabContent(selectedTab)}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="empty-1">
                    <span className="px-3">
                      {t(
                        `${
                          search.length && !customerList.length
                            ? "No Clients Found"
                            : "Use the search to add a client, or keep empty to save as walk-in."
                        }`
                      )}
                    </span>
                  </div>
                )}
                <div className="empty-2">
                  <PrimaryButton
                    classNames="save-button-side-panel"
                    onClick={handleSave}
                    text={t("Save")}
                  />
                  <div className="total">
                    {`${t("Total")}: ${t("SAR")} ${getAmount(
                      selectedOptions,
                      true
                    )} (
                     ${getDuration(selectedOptions.duration)} )`}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RescheduleAppointment;

import React, { useState, useRef, useContext, useEffect } from "react";
import { Container, Row, Col, Image, Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import UserInput from "../../../components/Inputs";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/Primary";
import CheckBoxInput from "../../../components/Inputs/CheckBox";
import Logo from "../../../assets/images/logo.png";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { setLanguage } from "../../../core/Api/Settings";
import BoonContext from "../../../context";
import { login, sendResetLink, sendActivationEmail, getUserPermissions } from "../../../core/Api/Auth";
import PasswordInput from "../../../components/Inputs/Password/index";
import { getAuthAttribute } from "../../../utils/helper";

const Login = (props) => {
	const { t } = useTranslation();
	const { setIWillDoLater, setUserPermissions } = useContext(BoonContext);
	const { history } = props;
	const location = history.location;
	const [modalShow, setModalShow] = React.useState(false);
	const [showActivationEmailModal, setShowActivationEmailModal] = useState(false);
	const [sendActivationEmailLoading, setSendAMLoading] = useState(false);
	const [loginId, setLoginId] = useState(null);
	const [emailAddForResetPass, setEmailAddForResetPass] = useState("");
	const [sendLinkLoading, setSendLinkLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [currLang, setCurrLang] = useState("English(USA)");

	const simpleValidator = useRef(
		new SimpleReactValidator({
			autoForceUpdate: this,
			validators: {
				customPassword: {
					message: t("Must contain at least one number, one uppercase and lowercase letter,one special character and at least 5 or more characters"),
					rule: (val) => {
						const isPassword = /^.*(?=.{5,})(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).*$/;
						return isPassword.test(val);
					},
					required: true,
				},
				spaces: {
					message: t("Must not contain spaces"),
					rule: (val) => {
						const isSpace = /[^\S]/;
						return !isSpace.test(val);
					},
					required: true,
				},
				arabic: {
					message: t("Must not be arabic"),
					rule: (val) => {
						const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
						return !isArabic.test(val);
					},
					required: true,
				},
				emailOrMobile: {
					message: t("Please enter valid details"),
					rule: (val) => {
						const emailRegex =
							// /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
							/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
						const phoneRegex = /^(\+966-)?\d{9}$/;
						return emailRegex.test(val) || phoneRegex.test(val);
					},
					required: true,
				},
			},
		})
	);
	const resetPassValidator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
	const [, forceUpdate] = useState(0);
	const disabled = !Boolean(password.trim() && email.trim());
	const [show, setShow] = useState(null);

	useEffect(() => {
		if (i18next) setCurrLang(i18next?.language === "ar" ? "Arabic" : "English(USA)");
	}, []);

	useEffect(() => {
		forceUpdate(1);
	}, [location.pathname]);

	useEffect(() => {
		const url = new URLSearchParams(history.location.search);
		const emailVerified = url.get("email_verified");
		const type = url.get("type");
		if (type === "app") {
			if (emailVerified === "1") {
				setShow(1);
			} else if (emailVerified === "0") {
				setShow(0);
			}
		}
	}, [history.location.search]);

	const validateUser = (params) => {
		const users = {
			2: "2",
			3: "3",
			4: "4",
			5: "5",
			7: "7",
		};

		return (users[params?.user_type_id] && true) || false;
	};

	const submitForm = async () => {
		toast.dismiss();
		if (disabled) {
			return;
		}
		if (simpleValidator.current.allValid()) {
			const payload = {
				username: email,
				password,
			};
			setIsLoading(true);
			const result = await login(payload);
			setIsLoading(false);
			if (result && result.code === 200) {
				if (!result.result.is_active) {
					setShowActivationEmailModal(true);
					setLoginId(result.result.login_id);
					return;
				}

				if (!result.result.allow_backoffice_access) {
					toast?.error("Invalid credentials");
					return;
				}
        localStorage.setItem("boon-react-auth-result", JSON.stringify(result.result));


				const permissions = await getUserPermissions(result.result?.user_type_id);
				if (permissions && permissions.code === 200) {
					setUserPermissions(permissions.result ?? []);
					localStorage.setItem(
						"boon-react-user-permissions",
						JSON.stringify({
							permissions: permissions.result ?? [],
						})
					);
				}

				setIsLoading(false);
				setIWillDoLater(false);
				if (result?.result?.user_type_id === 7) {
					history.push({
						pathname: "/companies",
					});
				} else if (result?.result?.user_type_id === 5) {
					history.push({
						pathname: "/booking-transactions",
					});
				} else {
					history.push({
						pathname: "/dashboard",
						state: {
							supplierName: result.result.supplier_name,
						},
					});
				}
			} else {
				localStorage.removeItem("boon-react-user-permissions")
				toast.error((result && result.message) || "Something wrong");
				setIsLoading(false);
			}
		} else {
			forceUpdate(1);
			simpleValidator.current.showMessages();
			toast.error("Please enter valid details");
			setIsLoading(false);
		}
	};

	const onKeyDown = (event) => {
		if (event.code === "Enter") {
			submitForm();
		}
	};

	const handleSendResetPassLink = async (event) => {
		toast.dismiss();
		if (resetPassValidator.current.allValid()) {
			setSendLinkLoading(true);
			const result = await sendResetLink({
				username: emailAddForResetPass,
			});
			if (result && result.code === 200) {
				setModalShow(false);
				setEmailAddForResetPass("");
				toast.success("Reset link sent successfully");
			} else {
				toast.error((result && result.message) || "Something wrong");
			}
			setSendLinkLoading(false);
		} else {
			resetPassValidator.current.showMessages();
			forceUpdate(1);
			toast.error("Please enter valid details");
		}
	};

	const handleSendActivationEmail = async (event) => {
		setSendAMLoading(true);
		const result = await sendActivationEmail(loginId);
		if (result && result.code === 200) {
			setShowActivationEmailModal(false);
			setLoginId(null);
			toast.success("Reset email sent successfully");
		} else {
			toast.error((result && result.message) || "Something wrong");
		}
		setSendAMLoading(false);
	};

	const handleLangChange = async (language) => {
		try {
			const response = await setLanguage(language);
			// toast.success(response.message);
			i18next.changeLanguage(language);
			setCurrLang(language === "ar" ? "Arabic" : "English(USA)");
		} catch (error) {
			toast.error(error.message);
		}
	};
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<p
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			style={{ cursor: "pointer" }}
		>
			{children}
			&#x25bc;
		</p>
	));

	return (
		<Container fluid className="signInPage">
			<Row>
				<Col className="left-sec-content ">
					<div className="left-img-cont"></div>
				</Col>
				<Col className="right-sec-cont ">
					<div className="right-sec-content step-2">
						<div className="header">
							<Image src={Logo} className="logo" />
							<div className="lang-cont">
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
										{currLang}
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item eventKey="1" onClick={(e) => handleLangChange("en")}>
											English (USA)
										</Dropdown.Item>
										<Dropdown.Item eventKey="2" onClick={(e) => handleLangChange("ar")}>
											Arabic
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className="heading-cont">
							<p className="heading">{t("Hello Partners!")} </p>
							<p className="subheading-cont">
								{t("Welcome back to")} <span>{t("Boon")}</span>{" "}
							</p>
						</div>

						<div className="form-cont">
							<p className="sign-in-heading">{t("Sign in to your account")}</p>
							<Row>
								<Col md="12">
									<UserInput
										required
										value={email}
										type="text"
										placeholder={t("Email address / mobile")}
										label={t("Email address / mobile")}
										onBlur={() => simpleValidator.current.showMessageFor("email")}
										onChange={(e) => setEmail(e.target.value)}
									/>
									{simpleValidator.current.message("email", email, "required|emailOrMobile")}
								</Col>
								<Col md="12">
									<PasswordInput
										required
										value={password}
										placeholder="********"
										label={t("Password")}
										onChange={(e) => setPassword(e.target.value)}
										onKeyDown={onKeyDown}
									/>
									{simpleValidator.current.message("password", password, "required|arabic")}
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="keep-looged-cont">
										<CheckBoxInput
											classNames="m-0 mt-2"
											// checked={acceptPolicy}
											// onChange={() => setAcceptPolicy(!acceptPolicy)}
										>
											{t("Keep me login")}
										</CheckBoxInput>
										<div className="forget-pass-link">
											<Link
												to=""
												onClick={(event) => {
													event.preventDefault();
													setModalShow(true);
												}}
											>
												{t("Forgot password")}
											</Link>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md="12">
									<PrimaryButton
										text={t("Login")}
										disabled={disabled}
										onKeyDown={onKeyDown}
										isLoading={isLoading}
										onClick={submitForm}
										style={{ marginTop: 30, width: "100%" }}
									/>
								</Col>
								<Col md="12">
									<div className="signup-link mt-3">
										{t("Don’t have a partner account?")}&nbsp;
										<Link to="/signup">{t("Register")} </Link>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
			<Modal
				size="md"
				show={show !== null}
				onHide={() => {
					setShow(null);
					history.replace("/login");
				}}
			>
				<Modal.Body>
					{show === 1
						? "Email has been verified and you can now login from the mobile app."
						: "Please make sure verification code is valid and not used already."}
				</Modal.Body>
			</Modal>
			<Modal
				size="md"
				centered
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					setEmailAddForResetPass("");
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{t("Reset Your Password")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md="12" lg="12" sm="12">
							<UserInput
								required
								value={emailAddForResetPass}
								type="text"
								label={t("Enter Your Email Address")}
								placeholder={t("Enter Your Email Address")}
								onChange={(e) => setEmailAddForResetPass(e.target.value)}
							/>
							{resetPassValidator.current.message("email", emailAddForResetPass, "required|email")}
						</Col>
						<Col md="12" lg="12" sm="12">
							<p>{t("An email with rest link will be sent to email address entered above.So please make sure that it's valid")}</p>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<PrimaryButton
						text={t("Send Reset Link")}
						isLoading={sendLinkLoading}
						onClick={handleSendResetPassLink}
						style={{ marginTop: 30, width: "100%" }}
					/>
				</Modal.Footer>
			</Modal>

			{/* send activation email */}
			{showActivationEmailModal && (
				<Modal
					size="md"
					centered
					show={showActivationEmailModal}
					onHide={() => {
						setShowActivationEmailModal(false);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">{t("Send Activation Email")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md="12" lg="12" sm="12">
								<p>{t("Your account is inActive.Do you want to send the activation email again?")}</p>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<PrimaryButton
							text="Send Activation Email"
							isLoading={sendActivationEmailLoading}
							onClick={handleSendActivationEmail}
							style={{ marginTop: 30, width: "100%" }}
						/>
					</Modal.Footer>
				</Modal>
			)}
		</Container>
	);
};

export default Login;

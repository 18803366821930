import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/Primary";
import FullPageSpinner from "../../../components/FullPageSpinner";
import CustomTableComponent from "../../../components/CustomTableComponent";
import Pagination from "../../../components/Pagination";
import Hoc from "../../Hoc";
import { activateSeller, deactivateSeller, deleteSeller, getSellers } from "../../../core/Api/giftvouchers";
import deactivateIcon from "../../../assets/images/eye-slash-fill.svg";
import activateIcon from "../../../assets/images/eye-fill.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import "./Styles.scss";
import { checkActionPermission } from "../../../utils/helper";

const SellersListing = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const deleteClickRef = useRef(null);
	const offset = 0;
	const pageSize = 10;

	const [pageNo, setPageNo] = useState(1);
	const [companies, setSellers] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		loadData(pageSize, offset);
	}, []);

	const loadData = async (per_page = 10, offset = 0, search_phrase = "") => {
		setIsLoading(true);
		const result = await getSellers({
			per_page,
			offset,
			search_phrase,
		});
		if (result && result.code === 200) {
			setSellers(result?.result?.result_data || []);
			setTotalCount(result?.result?.total_records || 0);
		} else {
			toast.error(result && result.message);
		}
		setIsLoading(false);
	};

	const handleToggleActive = (id, status) => {
		toast.dismiss();
		Swal.fire({
			text: t(`Are you sure you want to ${(status === "2" && "activate") || "block"} this Seller?`),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#D11A2A",
			cancelButtonColor: "#ffffff",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Ok"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				let result = {};
				if (status === "2") {
					result = await activateSeller({
						seller_id: id,
					});
				} else {
					result = await deactivateSeller({
						seller_id: id,
					});
				}
				if (result && result.code === 200) {
					Swal.fire(`${(status === "2" && "Activated") || "Blocked"} Successfully`, "", "success");
					loadData(pageSize, offset);
					setPageNo(1);
				} else {
					Swal.fire((result && result.message) || "Something wrong", "", "error");
				}
			}
			deleteClickRef.current = false;
		});
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		loadData(pageSize, (pageNo - 1) * pageSize);
	};

	const onRowClick = (row, index) => {
		return !deleteClickRef.current && history.push(`/seller-profile/${row?.seller_id}`);
	};

	const handleConfirm = async (seller_id) => {
		toast.dismiss();
		Swal.fire({
			text: t(`Are you sure you want to delete this? This action cannot be undone.`),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#D11A2A",
			cancelButtonColor: "#ffffff",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Delete"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				setIsLoading(true);
				const result = await deleteSeller({
					seller_id,
				});
				setIsLoading(false);
				if (result && result.code === 200) {
					await loadData(10, 0, "");
					setPageNo(1);
					toast.success(result.message);
				} else {
					Swal.fire((result && result.message) || "Something wrong", "", "error");
				}
			}
			deleteClickRef.current = null;
		});
	};

	const statusColFormatter = (row, key) => {
		return (
			<div>
				{checkActionPermission("gift_voucher_sellers", "canEdit") && (
					<>
						{(row.status_id === "1" && (
							<Image
								className="delete"
								src={deactivateIcon}
								alt="delete"
								onClick={() => {
									deleteClickRef.current = true;
									handleToggleActive(row.seller_id, row.status_id);
								}}
							/>
						)) || (
							<Image
								className="delete"
								src={activateIcon}
								alt="delete"
								onClick={() => {
									deleteClickRef.current = true;
									handleToggleActive(row.seller_id, row.status_id);
								}}
							/>
						)}
					</>
				)}

				{(checkActionPermission("gift_voucher_sellers", "canDelete") && (
					<Image
						className="delete ml-2"
						onClick={() => {
							deleteClickRef.current = true;
							handleConfirm(row.seller_id);
						}}
						src={deleteIcon}
						alt="delete"
					/>
				)) ||
					null}
			</div>
		);
	};

	const columns = [
		{
			name: t("Seller Name"),
			key: "seller_name",
			formatter: null,
		},
		{
			name: t("Seller Desc"),
			key: "seller_desc",
		},
		{
			name: t("Contact Person"),
			key: "contact_person_1",
			formatter: null,
		},
		{
			name: t("Category"),
			key: "category",
			formatter: null,
		},
		{
			name: t("Location"),
			key: "location",
			formatter: (row, key) => {
				return (
					<span
						style={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "block",
							whiteSpace: "nowrap",
							maxWidth: "400px",
						}}
					>
						{row?.address?.location?.toUpperCase() ?? "N/A"}
					</span>
				);
			},
		},
		{
			name: t("Actions"),
			key: "application_type",
			formatter: statusColFormatter,
		},
	];

	return (
		<React.Fragment>
			<Hoc activeMenu="gift_voucher_sellers">
				<Container fluid className="sellers-listing">
					<Row className="heading-row">
						<Col xl="3" lg="4" md="6" sm="12">
							<div className="d-flex align-items-center h-100">
								<b>{t("Sellers")}</b>
							</div>
						</Col>
						<Col md="auto" className="cta-col">
							{(checkActionPermission("gift_voucher_sellers", "canAdd") && (
								<PrimaryButton
									text={t("Add new seller")}
									onClick={() => {
										history.push("/seller");
									}}
								/>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							{isLoading && (
								<div className="spinner-wrap">
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading companies data")}</span>
								</div>
							)}
							{(!isLoading && totalCount === 0 && <div className="no-data-text text-center mt-2">{t("No data available")}</div>) || null}
						</Col>
					</Row>
					<Row className="mt-3">
						{(totalCount > 0 && (
							<CustomTableComponent
								responsive={true}
								hover={true}
								showSequence={true}
								pageSize={pageSize}
								pageNo={pageNo}
								columns={columns || []}
								data={companies || []}
								onRowClick={onRowClick}
							/>
						)) ||
							null}
					</Row>
					<Row>
						<Pagination totalCount={totalCount || 0} viewCount={companies?.length || 0} pageNo={pageNo} onChangePage={handlePageChange} />
					</Row>
				</Container>
			</Hoc>
		</React.Fragment>
	);
};

export default SellersListing;

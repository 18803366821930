import React from "react";

import "./Styles.scss";
const Card = (props) => {
  return (
    <div className={"custom-card"} style={props.styles} {...props}>
      {props.children}
    </div>
  );
};

export default Card;

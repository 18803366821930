import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { Container, Col, Row, Modal } from "react-bootstrap";
import Card from "../../../components/Card";
import Panel from "../../../components/Panel";
import UserInput from "../../../components/Inputs";
import CustomDropdown from "../../../components/Dropdown";
import CustomTextArea from "../../../components/Textarea";
import DateInput from "../../../components/DateInput";
import SwitchInput from "../../../components/SwitchInput";
import PrimaryButton from "../../../components/Buttons/Primary";
import DangerButton from "../../../components/Buttons/Danger";
import AssetsImg from "images-col";
import SearchInput from "../../../components/Inputs/Search";
import SecondaryButton from "../../../components/Buttons/Secondary";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import "./Styles.scss";
import {
  getServicesList,
  saveStaffMember,
  getStaffMember,
  uploadImage,
  deleteStaffMember,
} from "../../../core/Api/staff";
import { useTranslation } from "react-i18next";
import FullPageSpinner from  '../../../components/FullPageSpinner'

const DefaultImage =
  "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg";

const AddMember = (props) => {
  const { t } = useTranslation();
  const supplierId = props.supplierId;
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        endDate: {
          message: t("End date must be greater than Start date"),
          rule: (endDate, params) => {
            if (!endDate) {
              return true;
            } else if (!endDate || params[0] == "") {
              return false;
            }
            const startDate = params[0];
            return moment(endDate).isAfter(moment(startDate));
          },
          required: false,
        },
        checkMobileNumber: {
          message: "Enter a valid mobile number",
          rule: (val) => {
            if (!val) {
              return false;
            } else if (val.split("-").length != 2) {
              return false;
            } else if (
              val.split("-")[1].length != 9 ||
              Number.isNaN(+val.split("-")[1])
            ) {
              return false;
            }
            return true;
          },
        },
      },
    })
  );
  const [, forceUpdate] = useState();
  const [addChoice, setAddChoice] = useState("manually");
  const [calenderColor, setCalenderColor] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectAllServices, setSelectAllServices] = useState(false);
  const [imagePreviewURL, setImagePreviewURL] = useState(DefaultImage);
  const [edit, setEdit] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [staffMemberModel, setStaffMemberModel] = useState({
    supplier_staff_id: "0",
    supplier_id: supplierId,
    first_name: "",
    last_name: "",
    first_name_arb: "",
    last_name_arb: "",
    profile_pic: "",
    profile_pic_url: "",
    notify_by: "ALL",
    notes: "",
    employment_start_date: null,
    employment_end_date: null,
    contact_email: "",
    contact_phone: "",
    title: "",
    arb_title: "",
    allow_calendar_bookings: 1,
    calendar_color: "#FF7EFF",
    service_ids: "",
    work_location_ids: "",
    status_id: 1,
  });

  const colorSet = [
    "#FF7EFF",
    "#7E8FFF",
    "#7EBAFF",
    "#7EF2FF",
    "#7EFFD7",
    "#D9FF7E",
    "#FFD07E",
  ];
  const sendByOptions = [
    { label: t("None"), value: "0" },
    { label: t("ALL"), value: "ALL" },
    { label: t("EML"), value: "EML" },
    { label: t("SMS"), value: "SMS" },
  ];
  useEffect(() => {
    (async () => {
      if (props && props.selectedMember) {
        setEdit(true);
        setAddChoice("manually");
        const result = await getStaffMember(
          props.selectedMember.supplier_staff_id,
          props.selectedMember.supplier_id
        );
        if (result && result.code == 200) {
          setStaffMemberModel({
            ...staffMemberModel,
            supplier_staff_id: result.result.supplier_staff_id,
            supplier_id: result.result.supplier_id,
            first_name: result.result.first_name,
            first_name_arb: result.result.first_name_arb,
            last_name: result.result.last_name,
            last_name_arb: result.result.last_name_arb,
            profile_pic: result.result.profile_pic,
            profile_pic_url: result.result.profile_pic_url,
            notify_by: result.result.notify_by,
            notes: result.result.notes,
            employment_start_date: new Date(
              result.result.employment_start_date
            ),
            employment_end_date:
              result.result.employment_end_date == "0000-00-00"
                ? null
                : new Date(result.result.employment_end_date),
            contact_email: result.result.contact_email,
            contact_phone: result.result.contact_phone,
            title: result.result.title,
            arb_title: result?.result?.arb_title,
            allow_calendar_bookings:
              result?.result?.allow_calendar_bookings == "1" ? true : false,
            calendar_color: result.result.calendar_color || "#FF7EFF",
            service_ids: result?.result?.services || [],
            work_location_ids: result.result.work_location_ids,
            status_id: 1,
          });
          setImagePreviewURL(result.result.profile_pic_url || DefaultImage);
          setSelectedServices(
            result.result.services.map((row) => row.supplier_service_id)
          );
          setCalenderColor(result?.result?.calendar_color || "#FF7EFF");
        } else {
          toast.error(result && result.message);
        }
      }
      const result = await getServicesList(supplierId);
      if (result && result.code == 200) {
        setServicesList(result.result);
      } else {
        toast.error(result && result.message);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    setStaffMemberModel({
      ...staffMemberModel,
      [event.target.name]: event.target.value,
    });
    console.log("log", staffMemberModel);
  };

  const handleNotifyByChange = (value) => {
    setStaffMemberModel({
      ...staffMemberModel,
      notify_by: value,
    });
  };

  // const handleWorkingHoursStartTimeChange = (value) => {
  //   setStaffMemberModel({
  //     ...staffMemberModel,
  //     working_hours_start_time: value,
  //   });
  // };

  // const handleWorkingHoursEndTimeChange = (value) => {
  //   setStaffMemberModel({
  //     ...staffMemberModel,
  //     working_hours_end_time: value,
  //   });
  // };

  const handleNotesChange = (event) => {
    setStaffMemberModel({
      ...staffMemberModel,
      notes: event.target.value,
    });
  };

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setStaffMemberModel({
      ...staffMemberModel,
      contact_phone: `${value.countryCode}-${value.phoneNumber}`,
    });
  };

  const handleDateChange = (date, name) => {
    setStaffMemberModel({
      ...staffMemberModel,
      [name]: new Date(date),
    });
  };

  const handleServiceSelectionChange = (value, data) => {
    if (value) {
      setSelectedServices([...selectedServices, data.supplier_service_id]);
    } else {
      setSelectedServices(
        selectedServices.filter((id) => id != data.supplier_service_id)
      );
    }
  };

  const handleSelectAllServiceChange = (value) => {
    setSelectAllServices(value);
    if (value) {
      setSelectedServices(servicesList.map((row) => row.supplier_service_id));
    } else {
      setSelectedServices([]);
    }
  };

  const handleSaveStaffMember = async () => {
    toast.dismiss();
    const data = { ...staffMemberModel };
    if (simpleValidator.current.allValid()) {
      data.calendar_color =
        (calenderColor && calenderColor) || staffMemberModel.calendar_color;
      data.service_ids = selectedServices.toString();
      data.employment_start_date = moment(data.employment_start_date).format(
        "YYYY-MM-DD"
      );
      data.employment_end_date = data.employment_end_date
        ? moment(data.employment_end_date).format("YYYY-MM-DD")
        : null;
      data.work_location_ids = "";
      const result = await saveStaffMember(data);
      if (result && result.code == 200) {
        toast.success("Saved Successfully");
        props.onClose();
      } else {
        toast.error((result && result.message) || "Something wrong");
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      toast.error("Please enter valid details");
    }
  };

  const handleDeleteStaffMember = async () => {
    toast.dismiss();
    Swal.fire({
      text: t("Are you sure you won't be able to delete this?"),

      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleteLoader(true);
        const result = await deleteStaffMember(
          props.selectedMember && props.selectedMember.supplier_staff_id
        );
        if (result && result.code == 200) {
          Swal.fire("Deleted Successfully", "", "success");
          props.onClose();
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
        setDeleteLoader(false);
      }
    });
  };

  const handleImageUpload = async (event) => {
    const image = event.target.files[0];
    const data = new FormData();
    data.append("file", image);
    const imageUrl = await uploadImage(data);
    if (imageUrl && imageUrl.code == 200) {
      setStaffMemberModel({
        ...staffMemberModel,
        profile_pic: imageUrl.result && imageUrl.result.file_name,
      });
      toast.success("Uploaded Successfully");
      const img = await URL.createObjectURL(event.target.files[0]);
      setImagePreviewURL(img);
    } else {
      toast.error((imageUrl && imageUrl.message) || "Something wrong");
    }
  };

  const getServicesTitle = () => {
    if (selectAllServices) {
      return t("All services");
    } else if (!selectedServices.length) {
      return t("Press to select services");
    } else {
      let serviceTitle = "";
      selectedServices.forEach((id) => {
        const service = servicesList.find(
          (item) => item.supplier_service_id == id
        );
        if (service) {
          serviceTitle = serviceTitle
            ? serviceTitle + ", " + (service?.service_label ?? "")
            : service?.service_label ?? "";
        }
      });
      return serviceTitle;
    }
  };
  simpleValidator.current.purgeFields();

  return (
    <React.Fragment>
      {isLoading && <FullPageSpinner /> || null}
      <Container className="add-member">
        <Row className="justify-content-center">
          <Col md="10">
            <div className="choice-section">
              <div className="title">
                {props.selectedMember
                  ? props.viewOnly
                    ? t("View staff member")
                    : t("Edit staff member")
                  : t("Add staff members")}
              </div>
              {!addChoice && (
                <Row className="choice-card-row">
                  <Col md="6">
                    <Card onClick={() => setAddChoice("manually")}>
                      <img src={AssetsImg.ic_feather_edit} alt="edit" />
                      <div className="way">{t("Add manually")}</div>
                      <div className="dis">
                        {t("Add info about the new staff member manually.")}
                      </div>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <img
                        src={AssetsImg.ic_material_mail_outline}
                        alt="delete"
                      />
                      <div className="way">{t("Invite via email")}</div>
                      <div className="dis">
                        {t(
                          "Invite one or more new staff members to create their account."
                        )}
                      </div>
                    </Card>
                  </Col>
                </Row>
              )}
              {addChoice == "manually" && (
                <Row className="member-info-row">
                  <Col>
                    <Panel title={t("Basic info")}>
                      <Container fluid className="basic-info">
                        <Row className="align-items-center">
                          <Col md="2" className="member-pic">
                            <label
                              for="profile-input"
                              className="profile-pic-label"
                            >
                              <img
                                src={imagePreviewURL}
                                className="profile-pic"
                                alt="staff-member-pi"
                              />
                              <div className="overlay"></div>
                              <input
                                name='"profile-input'
                                disabled={props.viewOnly}
                                visibility="hidden"
                                type="file"
                                id="profile-input"
                                accept=".png, .jpg, .jpeg"
                                onChange={handleImageUpload}
                              />
                            </label>
                          </Col>
                          <Col md="10">
                            <Row className="mt-0">
                              <Col md="6" className="mt-3">
                                <UserInput
                                  required
                                  label={t("First Name")}
                                  placeholder="Ex. Doe"
                                  disabled={props.viewOnly}
                                  name="first_name"
                                  value={staffMemberModel.first_name}
                                  onChange={handleChange}
                                />
                                {simpleValidator.current.message(
                                  "first_name",
                                  staffMemberModel &&
                                    staffMemberModel.first_name,
                                  "required"
                                )}
                              </Col>
                              <Col md="6" className="mt-3">
                                <UserInput
                                  required
                                  label={t("Last Name")}
                                  placeholder={t("Last Name")}
                                  disabled={props.viewOnly}
                                  name="last_name"
                                  value={staffMemberModel.last_name}
                                  onChange={handleChange}
                                />
                                {simpleValidator.current.message(
                                  "last_name",
                                  staffMemberModel &&
                                    staffMemberModel.last_name,
                                  "required"
                                )}
                              </Col>
                              <Col md="6" className="mt-3">
                                <UserInput
                                  required
                                  label={t("First Name(arb)")}
                                  placeholder="Ex. Doe"
                                  disabled={props.viewOnly}
                                  name="first_name_arb"
                                  value={staffMemberModel.first_name_arb}
                                  onChange={handleChange}
                                />
                                {simpleValidator.current.message(
                                  "arabic_first_name",
                                  staffMemberModel &&
                                    staffMemberModel.first_name_arb,
                                  "required"
                                )}
                              </Col>

                              <Col md="6" className="mt-3">
                                <UserInput
                                  required
                                  label={t("Last Name(arb)")}
                                  placeholder={t("Last Name(arb)")}
                                  disabled={props.viewOnly}
                                  name="last_name_arb"
                                  value={staffMemberModel.last_name_arb}
                                  onChange={handleChange}
                                />
                                {simpleValidator.current.message(
                                  "arabic_last_name",
                                  staffMemberModel &&
                                    staffMemberModel.last_name_arb,
                                  "required"
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-0">
                              <Col md="6" className="mt-3">
                                <CustomDropdown
                                  onChange={(value) =>
                                    handleNotifyByChange(value.value)
                                  }
                                  disabled={props.viewOnly}
                                  name="notify_by"
                                  options={sendByOptions}
                                  label={t("Send notifications by")}
                                  value={sendByOptions.find(
                                    (row) =>
                                      row.value ==
                                      (staffMemberModel &&
                                        staffMemberModel.notify_by)
                                  )}
                                />
                              </Col>
                              <Col md="6" className="mt-3">
                                <UserInput
                                  label={t("Staff Title")}
                                  placeholder={t("Staff Title")}
                                  disabled={props.viewOnly}
                                  name="title"
                                  value={staffMemberModel.title}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md="6" className="mt-3">
                                <UserInput
                                  label={t("Staff Title(arb)")}
                                  placeholder={t("Staff Title(arb)")}
                                  disabled={props.viewOnly}
                                  name="arb_title"
                                  value={staffMemberModel.arb_title}
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <CustomTextArea
                              label={t("Notes (optional)")}
                              disabled={props.viewOnly}
                              name="notes"
                              value={staffMemberModel.notes}
                              onChange={handleNotesChange}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Panel>
                    <Panel title={t("Employment")}>
                      <Container fluid className="employment">
                        <Row>
                          <Col md="6" className="mb-3">
                            <DateInput
                              required
                              disabled={props.viewOnly}
                              label={t("Start date")}
                              placeholder={t("Select start date")}
                              value={staffMemberModel.employment_start_date}
                              onChange={(date) =>
                                handleDateChange(date, "employment_start_date")
                              }
                            />
                            {simpleValidator.current.message(
                              "employment_start_date",
                              staffMemberModel &&
                                staffMemberModel.employment_start_date,
                              "required"
                            )}
                          </Col>
                          {props?.selectedMember && (
                            <Col md="6" className="mb-3">
                              <DateInput
                                label={t("End date")}
                                value={staffMemberModel.employment_end_date}
                                onChange={(date) =>
                                  handleDateChange(date, "employment_end_date")
                                }
                              />
                              {simpleValidator.current.message(
                                "employment_end_date",
                                staffMemberModel &&
                                  staffMemberModel.employment_end_date,
                                `endDate:"${
                                  staffMemberModel.employment_start_date ?? ""
                                }"`
                              )}
                            </Col>
                          )}
                        </Row>
                      </Container>
                    </Panel>
                    {staffMemberModel.notify_by != "0" && (
                      <Panel
                        title={t("Contact")}
                        subtitle={t(
                          "Staff contacts are confidential and won't be shared with your clients."
                        )}
                      >
                        <Container fluid className="contact">
                          <Row>
                            {staffMemberModel.notify_by == "ALL" ||
                            staffMemberModel.notify_by == "EML" ? (
                              <Col md="6" className="mb-3">
                                <UserInput
                                  required
                                  label={t("Contact Email")}
                                  placeholder={t("Contact Email")}
                                  disabled={props.viewOnly}
                                  name="contact_email"
                                  value={staffMemberModel.contact_email}
                                  onChange={handleChange}
                                />
                                {staffMemberModel.notify_by == "EML" ||
                                staffMemberModel.notify_by == "ALL"
                                  ? simpleValidator.current.message(
                                      "contact_email",
                                      staffMemberModel &&
                                        staffMemberModel.contact_email,
                                      "required|email"
                                    )
                                  : null}
                              </Col>
                            ) : null}
                            {staffMemberModel.notify_by == "ALL" ||
                            staffMemberModel.notify_by == "SMS" ? (
                              <Col md="6" className="mb-3">
                                <PhoneNumberInput
                                  required
                                  label={t("Mobile Number")}
                                  selectedValues={(values) =>
                                    handlePhoneNumberChange(values)
                                  }
                                  disabled={props.viewOnly}
                                  value={staffMemberModel.contact_phone}
                                  placeholder="5xxxxxxx"
                                  maxLength="9"
                                />
                                {/* <UserInput
                              label="Mobile Number"
                              name="contact_phone"
                              value={staffMemberModel.contact_phone}
                              onChange={handleChange}
                            /> */}
                                {(staffMemberModel &&
                                  staffMemberModel.notify_by == "SMS") ||
                                staffMemberModel.notify_by == "ALL"
                                  ? simpleValidator.current.message(
                                      "contact_phone",
                                      staffMemberModel.contact_phone,
                                      "required|checkMobileNumber"
                                    )
                                  : null}
                              </Col>
                            ) : null}
                          </Row>
                        </Container>
                      </Panel>
                    )}

                    {/* <Panel
                      title="Works at"
                      subtitle="Choose the locations where this staff member works."
                    >
                      <Container fluid className="work-at">
                        <Row>
                          <Col>
                            <div className="image-object">
                              <img
                                src={
                                  "https://images.unsplash.com/photo-1514924013411-cbf25faa35bb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Y2l0eXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                                }
                                alt="staff-pic"
                              />

                              <div className="content">
                                <div className="caption">{t("Nouf Boon")}</div>
                                <div className="desc">
                                  {t(
                                    "Al Waha Alley, Riyadh (Al Khuzama), Riyadh Province"
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Panel> */}

                    <Panel title={t("Booking")}>
                      <Container fluid className="booking">
                        <Row>
                          <Col>
                            <div className="image-object">
                              <div className="checkbox">
                                <SwitchInput
                                  onChange={(event) => {
                                    setStaffMemberModel({
                                      ...staffMemberModel,
                                      allow_calendar_bookings:
                                        event.target.checked,
                                    });
                                  }}
                                  checked={
                                    staffMemberModel.allow_calendar_bookings
                                  }
                                  disabled={props.viewOnly}
                                />
                              </div>

                              <div className="content">
                                <div className="caption">
                                  {t("Allow calendar bookings")}
                                </div>
                                <div className="desc">
                                  {t(
                                    "Allow this staff member to receive bookings on the calendar."
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Panel>

                    <Panel
                      title={t("Calendar color")}
                      subtitle={t(
                        "Choose a colour to see this staff members appointments in the calendar."
                      )}
                    >
                      <Container fluid className="color">
                        <Row>
                          <Col>
                            <div className="label">{t("Select color")}</div>
                            <div className="ball-cont">
                              {colorSet.map((item, index) => (
                                <div
                                  key={index}
                                  style={{ background: item }}
                                  className={`color-ball ${
                                    item == calenderColor ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    !props.viewOnly && setCalenderColor(item)
                                  }
                                />
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Panel>

                    <Panel
                      title={t("Services")}
                      subtitle={t(
                        "Add the services this staff member can offer."
                      )}
                    >
                      <Container fluid className="services">
                        <Col
                          md="12"
                          onClick={() => !props.viewOnly && setModalShow(true)}
                        >
                          <UserInput value={getServicesTitle()} disabled />
                        </Col>
                      </Container>
                    </Panel>

                    {!props.viewOnly && (
                      <Container fluid className="services mb-5 pb-5">
                        <PrimaryButton
                          text={t("Save")}
                          onClick={() => handleSaveStaffMember()}
                        />
                        {(edit && (
                          <DangerButton
                            classNames="ml-3"
                            text={t("Delete")}
                            onClick={() => handleDeleteStaffMember()}
                            isLoading={deleteLoader}
                          />
                        )) ||
                          null}
                      </Container>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        className="staff-select-service-popup"
        centered
        size="lg"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Select Services")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <SearchInput placeholder={"Search Services"} />

                <ul>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          selectAllServices
                            ? selectAllServices
                            : selectedServices.length == servicesList.length
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          handleSelectAllServiceChange(event.target.checked)
                        }
                      />
                      {t("All Services")}{" "}
                      <small>({selectedServices.length})</small>
                      <br />
                      <small className="second"></small>
                    </label>
                  </li>
                  {servicesList?.length ? (
                    servicesList.map((row, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            checked={
                              selectAllServices
                                ? true
                                : selectedServices.find(
                                    (id) => id == row.supplier_service_id
                                  )
                                ? true
                                : false
                            }
                            onChange={(event) =>
                              handleServiceSelectionChange(
                                event.target.checked,
                                row
                              )
                            }
                          />
                          {row.service_label}
                        </label>
                      </li>
                    ))
                  ) : (
                    <div className="no-service-container">
                      <h4>{t("No services available")}</h4>
                    </div>
                  )}
                </ul>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            text={t("Cancel")}
            onClick={() => {
              setModalShow(false);
            }}
          />
          <PrimaryButton text={t("OK")} onClick={() => setModalShow(false)} />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

AddMember.propTypes = {};

export default AddMember;

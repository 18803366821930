import Axios from "../Axios";

export const getGroups = async (params = {}) => {

  try {
    const { company_id } = params;
    const response = await Axios.get(
      `/settings/group?company_id=${company_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveCompany = async (data) => {
  try {
    const response = await Axios.post("/company/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getGroupById = async (id) => {
  try {
    const response = await Axios.get(`/settings/group/${id}`)
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveGroup = async (data) => {
  try {
    const response = await Axios.post("/settings/save_group", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteGroup = async (data) => {
  try {
    const response = await Axios.post("/settings/delete_group", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getGroupByCustomerId = async (id) => {
  try {
    const response = await Axios.get(`/customer/groups_by_customer/${id}`)
    return response.data;
  } catch (error) {
    return error;
  }
};
export const assignCustomerGroups = async (data) => {
  try {
    const response = await Axios.post("/customer/assign_groups", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
import React, { useState, useEffect, useRef } from "react";
import "./Styles.scss";
import { Container, Image, Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Buttons/Primary";
import FeaturedSalonsForm from "./CategoryForm";
import FullPageSpinner from "../../components/FullPageSpinner";
import { getAllCategories, saveCategory } from "../../core/Api/category";
import CustomTableComponent from "../../components/CustomTableComponent";
import activateIcon from "../../assets/images/eye-fill.svg";
import deactivateIcon from "../../assets/images/eye-slash-fill.svg";
import { checkActionPermission } from "../../utils/helper";

const model = {
	category_id: 0,
	category: null,
	category_ar: null,
	description: null,
	priority: null,
	image: null,
};

const CategoriesList = () => {
	const { t } = useTranslation();
	const categoryRef = useRef(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [data, setData] = useState([]);
	const [pageLoading, setPageLoading] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchCategories();
	}, []);

	const fetchCategories = async () => {
		toast.dismiss();
		setPageLoading(true);
		try {
			const result = await getAllCategories();
			if (result.code == 200) {
				setData(result?.result ?? []);
			} else {
				throw new Error(result?.message ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
		setPageLoading(false);
	};

	const handleClose = () => {
		setSelectedCategory(null);
		fetchCategories();
	};

	const handleOnError = (e) => {
		e.target.src = "https://via.placeholder.com/140x100";
	};

	const imageFormater = (row, key) => {
		return (
			<span>
				<img alt="client-img" src={row[key]} className="profile-pic" onError={handleOnError} />
				{/* {`${row.first_name} ${row.last_name} `} */}
			</span>
		);
	};

	const handleToggleActive = (row) => {
		const category = { ...row };
		console.log("category :", category);
		// category.image_name = category.image;
		// delete category.image;
		delete category.image_name;
		categoryRef.current = true;
		toast.dismiss();
		Swal.fire({
			text: t(`Are you sure you want to ${(category?.status_id == "2" && "activate") || "block"} this?`),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#D11A2A",
			cancelButtonColor: "#ffffff",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Ok"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				let result = {};
				setIsLoading(true);
				if (category.status_id == "2") {
					result = await saveCategory({
						...category,
						status_id: 1,
					});
				} else {
					result = await saveCategory({
						...category,
						status_id: 2,
					});
				}
				setIsLoading(false);
				if (result && result.code === 200) {
					Swal.fire(`${(category.status_id === "2" && "Activated") || "Blocked"} Successfully`, "", "success");
					fetchCategories();
				} else {
					Swal.fire((result && result.message) || "Something wrong", "", "error");
				}
			}
			categoryRef.current = false;
		});
	};

	const actionsFormatter = (row) => {
		return (
			<div className="otherWrap">
				{(checkActionPermission("services_category", "canEdit") && row.status_id === "1" && (
					<Image className="delete" onClick={() => handleToggleActive(row)} src={deactivateIcon} alt="delete" />
				)) || <Image className="delete" onClick={() => handleToggleActive(row)} src={activateIcon} alt="delete" />}
			</div>
		);
	};

	const columns = [
		{
			name: t("Image"),
			key: "image_name",
			formatter: imageFormater,
		},
		{
			name: t("Name"),
			key: "category",
			formatter: null,
		},
		{
			name: t("Name(arb)"),
			key: "category_ar",
			formatter: null,
		},
		{
			name: t("Priority"),
			key: "priority",
			formatter: null,
		},
		{
			name: t("Actions"),
			key: "priority",
			formatter: actionsFormatter,
		},
	];

	return (
		<Container className="categories m-0 w-100">
			{isLoading && <FullPageSpinner />}
			<div className="table-wrapper">
				<Row className="search-wrap m-0">
					<Col xl="4" lg="5" md="6"></Col>
					<Col xl="6" lg="6" md="5" sm="12" className="cta-col">
						{checkActionPermission("services_category", "canAdd") && (
							<PrimaryButton onClick={() => setSelectedCategory({ ...model })} classNames="add-new-offer" text={t("Add New")} />
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						{(pageLoading && (
							<div className="spinner-wrap">
								<FullPageSpinner animation="border" />
								<span>{t("Please wait, we're loading data")}</span>
							</div>
						)) ||
							null}
						{(!pageLoading && data?.length === 0 && <div className="no-data-text text-center mt-2">{t("No data available")}</div>) || null}
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<CustomTableComponent
							responsive={true}
							striped={false}
							bordered={false}
							hover={true}
							size="sm"
							columns={columns || []}
							data={data || []}
							// styles={{ maxHeight: `${window.innerHeight - 240}px` }}
							onRowClick={(row) => {
								if (checkActionPermission("services_category", "canEdit") && !categoryRef.current) setSelectedCategory(row);
							}}
						/>
					</Col>
				</Row>
			</div>
			{(selectedCategory && (
				<Modal size="lg" show={selectedCategory} onHide={handleClose}>
					<FeaturedSalonsForm selectedCategory={selectedCategory} handleClose={handleClose} />
				</Modal>
			)) ||
				null}
		</Container>
	);
};

export default CategoriesList;

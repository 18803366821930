import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DateInput from '../../../components/DateInput';
import CustomDropdown from '../../../components/Dropdown';
import FullPageSpinner from '../../../components/FullPageSpinner';
import {
	getTopCancelersReport,
	getTopCompletersReport,
	getTopCustomersReport,
	getTopHomersReport,
	getTopSalonVisitorsReport,
} from '../../../core/Api/Reports';
import Hoc from '../../Hoc';
import TopCustomers from './TopCustomers';
import './Styles.scss';

const TopCustomersReports = () => {
	const { t } = useTranslation();
	const pageSize = 5;
	const [data, setData] = useState([]);
	const [filterSchema, setFilterSchema] = useState({
		from_date: new Date(),
		to_date: new Date(),
		rpt_type: 'top_customers',
	});
	const [isLoading, setLoading] = useState(false);

	const options = [
		{ label: t('Top Customers'), value: 'top_customers' },
		{ label: t('Top Completers'), value: 'top_completers' },
		{ label: t('Top Cancelers'), value: 'top_cancelers' },
		{ label: t('Top Homers'), value: 'top_homers' },
		{ label: t('Top Salon Visitors'), value: 'top_salon_visitors' },
	];

	useEffect(() => {
		fetchTopCustomersByType(filterSchema);
	}, []);

	const fetchTopCustomersByType = async (filters) => {
		setLoading(true);
		let response = null;
		if (filters?.rpt_type == 'top_customers') {
			response = await getTopCustomersReport({
				from_date: moment(filters?.from_date).format('YYYY-MM-DD'),
				to_date: moment(filters?.to_date).format('YYYY-MM-DD'),
				rpt_type: filters?.rpt_type,
				per_page: pageSize,
			});
		} else if (filters?.rpt_type == 'top_completers') {
			response = await getTopCompletersReport({
				from_date: moment(filters?.from_date).format('YYYY-MM-DD'),
				to_date: moment(filters?.to_date).format('YYYY-MM-DD'),
				rpt_type: filters?.rpt_type,
				per_page: pageSize,
			});
		} else if (filters?.rpt_type == 'top_cancelers') {
			response = await getTopCancelersReport({
				from_date: moment(filters?.from_date).format('YYYY-MM-DD'),
				to_date: moment(filters?.to_date).format('YYYY-MM-DD'),
				rpt_type: filters?.rpt_type,
				per_page: pageSize,
			});
		} else if (filters?.rpt_type == 'top_homers') {
			response = await getTopHomersReport({
				from_date: moment(filters?.from_date).format('YYYY-MM-DD'),
				to_date: moment(filters?.to_date).format('YYYY-MM-DD'),
				rpt_type: filters?.rpt_type,
				per_page: pageSize,
			});
		} else if (filters?.rpt_type == 'top_salon_visitors') {
			response = await getTopSalonVisitorsReport({
				from_date: moment(filters?.from_date).format('YYYY-MM-DD'),
				to_date: moment(filters?.to_date).format('YYYY-MM-DD'),
				rpt_type: filters?.rpt_type,
				per_page: pageSize,
			});
		}

		if (response.code == 200) {
			setData(response?.result || []);
		} else {
			toast.error(response && response.message);
		}
		setLoading(false);
	};

	const handleDateChange = (date, name) => {
		fetchTopCustomersByType({
			...filterSchema,
			[name]: new Date(date),
		});
		setFilterSchema({
			...filterSchema,
			[name]: new Date(date),
		});
	};

	const handleChange = (value, name) => {
		fetchTopCustomersByType({
			...filterSchema,
			[name]: value,
		});
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	return (
		// <Hoc activeMenu="topCustomersReport">
			<div className="topCustomersReport">
				<div className="table-wrapper pr-3">
					<Row className=" ">
						<Col className="mt-3" lg="3" md="6" sm="12">
							<DateInput
								label={t('From date')}
								value={filterSchema.from_date}
								onChange={(date) => handleDateChange(date, 'from_date')}
							/>
						</Col>
						<Col className="mt-3" lg="3" md="6" sm="12">
							<DateInput
								label={t('To date')}
								// className="ml-3"
								value={filterSchema.to_date}
								onChange={(date) => handleDateChange(date, 'to_date')}
							/>
						</Col>
						<Col className="mt-3" lg="3" md="6" sm="12">
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'rpt_type')}
								name="rpt_type"
								value={
									options?.find((r) => r.value == filterSchema?.rpt_type) ||
									null
								}
								options={options}
								label={t('Type')}
							/>
						</Col>
					</Row>
					<Row className="w-100 text-center mt-4">
						<Col>
							{isLoading && (
								<div>
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && data?.length == 0 && (
								<div className="no-data-text text-center mt-2">
									{t('No data available')}
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row className="w-100 m-0">
						{data.length && (
							<div className="booking-table w-100">
								<TopCustomers data={data} type={filterSchema?.rpt_type} />
							</div>
						) || null}
					</Row>
				</div>
			</div>
		// </Hoc>
	);
};

export default TopCustomersReports;

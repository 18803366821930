import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import clsx from "clsx";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Overlay,
  Popover,
  PopoverContent,
} from "react-bootstrap";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import locationIcon from "../../../assets/images/geo-alt-fill.svg";
import CustomTextArea from "../../../components/Textarea";
import UserInput from "../../../components/Inputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Styles.scss";
import "../../../components/Dropdown/Styles.scss";
import FullScreenPopup from "../../../components/FullScreenPopup";
import { getSelectedCustomer } from "../../../core/Api/customer";
import { getServices } from "../../../core/Api/services";
import { toast } from "react-toastify";
import {
  saveOfflineBooking,
  getBookingById,
  rescheduleBooking,
  getDeliveryFeeCharges,
} from "../../../core/Api/bookings";
import {
  getAmount,
  getAuthAttribute,
  getDuration,
} from "../../../utils/helper";
import { capitalCase } from "change-case";
import redClearIcon from "../../../assets/images/ic_red_cancel.svg";
import downArrow from "../../../assets/images/chevron-down.svg";
import arrowBack from "../../../assets/images/Icon ionic-ios-arrow-back.svg";
import PrimaryButton from "../../../components/Buttons/Primary";
import { components } from "react-select";
import menuIcon from "../../../assets/images/three-dots.svg";

// eslint-disable-next-line
import AddClient from "../../Client/AddClient";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CustomDropdown from "../../../components/Dropdown";
import SearchPanel from "./SearchPanel";
import SelectClient from "./SelectClient";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { getStaffMembersList } from "../../../core/Api/staff";
import * as _ from 'lodash'
import SimpleReactValidator from "simple-react-validator";
import { checkStaffTimeSlotAvaiability } from "../../../core/Api/partner";

const CustomMenu = (props) => {
  const { options, handleServiceChange, serviceIndex, locationType, ...rest } =
    props;
  const handleServiceClick = (service) => {
    const selectedOption = {
      ...service,
      label: `${service.name}-${service?.optionLabel}`,
      value: service.supplier_service_opt_id,
    };
    props.selectOption(selectedOption);
  };

  return (
    <components.Menu {...rest} className="service-menu-custom">
      {options.length ? (
        options.map((item) => (
          <div className="category-block" key={item.category}>
            <h4 className="category-name">{item.category}</h4>
            {item.services.map((service) => (
              <div
                className="custom-service-block"
                key={service.supplier_service_id}
                onClick={() => handleServiceClick(service)}
              >
                <div className="service-name">
                  <span>{service.name}</span>
                  <span className="duration">
                    {service.optionLabel + ", " + getDuration([service.duration])}
                  </span>
                </div>
                <div className="service-amount">
                  {locationType == "Home" ? (
                    <span>{"SAR " + service.home_amount}</span>
                  ) : (
                    <span>{"SAR " + service.salon_amount}</span>
                  )}
                  {locationType == "Home" &&
                    Number(service.home_amount) ==
                    !Number(service.original_amount) && (
                      <span className="original-amount">
                        {"SAR " + service.original_amount}
                      </span>
                    )}
                  {locationType == "Salon" &&
                    Number(service.salon_amount) ==
                    !Number(service.original_amount) && (
                      <span className="original-amount">
                        {"SAR " + service.original_amount}
                      </span>
                    )}
                  {locationType == "Home" &&
                    Number(service.home_amount) ==
                    !Number(service.original_amount) && (
                      <span className="original-amount">
                        {"SAR " + service.original_amount}
                      </span>
                    )}
                  {locationType == "Salon" &&
                    Number(service.salon_amount) ==
                    !Number(service.original_amount) && (
                      <span className="original-amount">
                        {"SAR " + service.original_amount}
                      </span>
                    )}
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <div className="no-options">No Options</div>
      )}
    </components.Menu>
  );
};

const NewAppointment = (props) => {
  const { t } = useTranslation();
  const { onCancel, isEdit, history, selectedStaffId, supplierData, startDate } = props;

  const validator = useRef(
    new SimpleReactValidator({
    })
  );

  const isUserAdmin = getAuthAttribute("user_type_id") == 4;
  const supplierId = isUserAdmin
    ? props.selectedPartner
    : getAuthAttribute("supplier_id");

  const booking = useRef(null);
  const menuRef = useRef(null);

  const [startdate, setStartDate] = useState(startDate ?? moment()._d);
  const [notes, setNotes] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRadioBtn, setSelectedRadioBtn] = useState(null);
  const [bookingInfo, setBookingInfo] = useState([{
    staffId: selectedStaffId || null,
    serviceId: null,
    startTime: null,
    duration: null,
    category_id: null
  }]);
  const [staffList, setStaffList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [addClient, setAddClient] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [height, setHeight] = useState("0px");
  const [target, setTarget] = useState(null);
  const [loader, setLoader] = useState(false);
  const [, forceUpdate] = useState('');
  const [bookingDetails, setBookingDetails] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);

  // eslint-disable-next-line
  const totalAmount = getAmount(bookingInfo, true);
  const duration = 0;
  const radioOptions = [
    { label: "Salon", value: "Salon" },
    { label: "Home", value: "Home" },
  ];
  useEffect(() => {
    const state = history?.location?.state;
    if (state?.openPopup) {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [history?.location?.state]);

  useEffect(() => {
    const updatedData = bookingInfo?.map(r => ({
      ...r,
      error_msg: null,
      category_id: null,
      serviceData: null,
      serviceId: null,
      duration: null,
    }));
    setBookingInfo(updatedData);
    loadInitialData(selectedRadioBtn)
    // eslint-disable-next-line
  }, [selectedRadioBtn]);

  useEffect(() => {
    const updatedData = bookingInfo?.map(r => ({
      ...r,
      staffId: null,
      startTime: null,
      error_msg: null
    }));
    setBookingInfo(updatedData);
  }, [startdate]);

  useEffect(() => {
    if (props.customerId) {
      (async () => {
        const { result } = await getSelectedCustomer(props.customerId);
        if (result) setSelectedCustomer(result);
      })();
    }
  }, [props.customerId]);

  //useEffect to calculate divider line height
  useEffect(() => {
    let markEnd = document.getElementById("mark-end");
    const markStart = document.getElementById("mark-1");
    if (!markEnd) {
      markEnd = document.getElementById("mark-2");
    }
    const startTop = markStart?.getBoundingClientRect().top;
    const endTop = markEnd?.getBoundingClientRect().top;
    setHeight(`${endTop - startTop}px`);
  }, [bookingInfo?.length]);

  const handleChangeLoc = (event) => {
    setSelectedRadioBtn(event);
    if (event?.value == 'Home' && selectedCustomer && !selectedCustomer?.has_primary_address) {
      toast.error('This customer does not have a primary address.Please add primary address for home bookings')
    }
    getDeliveryFee(event?.value, selectedCustomer);
  }

  useEffect(()=>{
    if (supplierData?.is_salon == "1" && supplierData?.is_home == '1') {
      setSelectedRadioBtn({ label: "Salon", value: "Salon" })
    } else if (supplierData?.is_salon == "1") {
      setSelectedRadioBtn({ label: "Salon", value: "Salon" })
    } else if (supplierData?.is_home == "1") {
      setSelectedRadioBtn({ label: "Home", value: "Home" })
    }
  }, [supplierData])

  // get booking by id
  useEffect(()=>{
    loadInitialData(selectedRadioBtn)
    getBookingDetails();
  }, []);

  const getBookingDetails = async()=>{
    if (props.booking_id) {
      setLoader(true);
      const appointmentData = await getBookingById(props.booking_id);
      setBookingDetails(appointmentData?.result)
      const location_type =
        appointmentData?.result?.location_type ?? selectedRadioBtn;
      setSelectedRadioBtn(capitalCase(location_type));
      setStartDate(
        new Date(appointmentData?.result?.services[0]?.appointment_date)
      );
      const services = appointmentData?.result?.services ?? [];
      booking.current = {
        booking_id: appointmentData?.result?.booking_id,
        bookingDate: moment().format("YYYY-MM-DD"),
      };
      const servicesData = services?.map((service) => {
        return {
          ...service,
          serviceData: { ...service },
          serviceId: service?.supplier_service_opt_id || null,
          duration: service?.duration || null,
          category_id: service?.category_id || null,
          staffId: null,
          startTime: null,

          // staffId: service?.supplier_staff_id || null,
          // startTime: startDate && moment(service?.appointment_date).format('hh:mm A'),
          // startTime:moment(service?.start_time, 'hh:mm A').format('hh:mm A'),
        }
      });
      setBookingInfo(_.cloneDeep(servicesData));
    } else {
      setBookingInfo([
        {
          staffId: selectedStaffId || null,
          startTime: startDate && moment(startDate).format('hh:mm A'),
          serviceId: null,
          duration: null,
        }
      ])
    }
  }


  const getDeliveryFee = async (locType, customer) => {
    if (locType == 'Home' && customer?.has_primary_address) {
      setLoader(true);
      const deliveryRes = await getDeliveryFeeCharges(supplierId, customer?.address_id);
      setLoader(false);
      if (deliveryRes.code == 200) {
        setDeliveryFee(deliveryRes?.result?.fee || 0);
      }
    }else{
      setDeliveryFee(0)
    }
  }

  const loadInitialData = async (serviceLoc) => {
    const locationType = serviceLoc == "Home" ? "at_home" : "at_salon";
    try {
      setLoader(true);
      console.log('supplierId330 :', props?.selectedPartner, '-' , supplierId);
      const [staffRes, servicesRes] = await Promise.all([
        getStaffMembersList(props?.selectedPartner || supplierId),
        getServices(props?.selectedPartner || supplierId, locationType)
      ]);
      setLoader(false);

      if (staffRes?.code < 400) {
        setStaffList((staffRes?.result || [])?.map(r => ({
          ...r,
          value: r?.supplier_staff_id,
          label: r.staff_name
        })) || [])
      }

      let serviceData = [];
      if (servicesRes?.code < 400) {
        serviceData = servicesRes?.result || [];
        serviceData.forEach((category) => {
          category.services = category.services?.flatMap((service) => {
            const { options } = service;
            const serviceOption = [];
            options.forEach((option) => {
              serviceOption.push({
                ...option,
                inital_amount: service.amount,
                category_id: service.category_id,
                is_home: service.is_home,
                is_salon: service.is_salon,
                name: `${service?.service_label}`,
                status_id: service.status_id,
                supplier_id: service.supplier_id,
                supplier_service_id: service.supplier_service_id,
                supplier_staff_id: service.supplier_staff_id,
                value: option?.supplier_service_opt_id,
                label: `${service?.service_label}-${option?.label}`,
                optionLabel: option?.label
              });
            });
            return serviceOption;
          });
        });
      }
      setServicesList(serviceData || []);
      setTimeSlots(getTimeSlots() || [])
    } catch (error) {
      setLoader(false);
    }
  };

  const handleChange = async (index, key, newValue) => {
    const data = _.cloneDeep(bookingInfo);
    if (_.find(data, (r, i) => (r?.serviceId == newValue?.value && i != index))) {
      toast.error(t('Can not add same service again'));
      return;
    }
    data[index][key] = newValue?.value;
    if (key == 'serviceId') {
      data[index]['duration'] = newValue?.duration;
      data[index]['category_id'] = newValue?.category_id;
      data[index]['serviceData'] = { ...newValue };
    }
    if (data[index]?.staffId && data[index]?.serviceId && data[index]?.startTime && startdate) {
      const message = await handleCheckStaffAvailability(data[index]);
      data[index]['error_msg'] = message;
    }
    setBookingInfo(_.cloneDeep(data));
  };

  const CustomMenuWrapper = useCallback((props, serviceIndex, locationType) => {
    return (
      <CustomMenu
        {...props}
        serviceIndex={serviceIndex}
        locationType={locationType}
      />
    );
  }, []);

  const addService = () => {
    validator.current.hideMessages();
    setBookingInfo([
      ...bookingInfo,
      {
        staffId: selectedStaffId || null,
        startTime: startDate && moment(startDate).format('hh:mm A'),
        serviceId: null,
        duration: null,
      }
    ]);
    setTimeout(() => {
      const ele = document.getElementById(
        `main-service-block-${bookingInfo?.length + 1}`
      );
      if (ele) {
        addTranisitionClass(bookingInfo?.length + 1);
      }
    }, 0);
  };

  function addTranisitionClass(bookingInfoLength, retries = 5) {
    if (retries > 0) {
      const ele = document.getElementById(
        `main-service-block-${bookingInfoLength}`
      );
      if (ele) {
        ele.classList.add("service-block-tranisition");
      } else {
        setTimeout(() => {
          addTranisitionClass(bookingInfoLength, retries - 1);
        }, 100);
      }
    }
  }

  const removeService = (filterIndex) => {
    setBookingInfo(
      bookingInfo?.filter((r, i) => i != filterIndex)
    );
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleSave = async () => {
    validator.current.showMessages();
    toast.dismiss();
    if (validator.current.allValid()) {
      if (selectedRadioBtn?.value == 'Home'  &&  !selectedCustomer?.has_primary_address) {
        toast.error('Please add primary address of selected customer for home booking');
        return
      }
      const allSelectedServices = bookingInfo.map((value, index) => ({
        amount: value?.serviceData?.amount,
        duration: value?.duration,
        isSelected: true,
        booking_service_id: isEdit ? value.booking_service_id : 0,
        is_section: value?.is_section || false,
        name: value?.serviceData?.name,
        offers: value?.serviceData.offers,
        original_amount: value?.serviceData?.original_amount,
        serviceWithOffer: value.priceWithOffer ?? 0,
        service_date: moment(startdate).format("YYYY-MM-DD"),
        service_time: moment(value?.startTime, 'hh:mm A').format('HH:mm'),
        supplier_service_id: value?.serviceData?.supplier_service_id,
        supplier_staff_id: value?.staffId,
        supplier_service_opt_id: value?.serviceData?.supplier_service_opt_id,
      }));

      let payload = {
        application_type: "Backoffice",
        booking_id: "",
        booked_services: [...allSelectedServices],
      };
      if (booking.current) {
        payload = {
          ...payload,
          booking_id: booking.current.booking_id,
          booking_date: booking.current.bookingDate,
          booking_status_id: props.status ?? 0,
        };
      } else if (!isEdit) {
        payload = {
          ..._.cloneDeep(payload),
          booking_status_id: "1",
          customer_id: selectedCustomer?.customer_id || "1",
          supplier_id: isUserAdmin
            ? props.selectedPartner
            : getAuthAttribute("supplier_id"),
          location_type: selectedRadioBtn == "Home" && 'at_home' || 'at_salon',
          // location_type: selectedRadioBtn,
          promotion: "",
          supplier_driver_id: "",
          payment_card_id: "0",
          promotion_code: "",
          calendar_event_id: "",
          guest: "",
          original_booking_amount: getAmount(allSelectedServices),
          notes: notes,
          total_amount: totalAmount,
          address_id: "",
          payment_mode: {
            cash: totalAmount
          }
        };
        if (selectedRadioBtn?.value == 'Home' && selectedCustomer?.has_primary_address, selectedCustomer?.address){
          payload.address_id = selectedCustomer?.address?.address_id;
          payload.delivery_fee = deliveryFee || 0

        }
      }
      let response;
      setLoader(true)
      if (isEdit) {
        response = await rescheduleBooking({
          ...payload,
          services: payload?.booked_services,
          booked_services: []
        });
      } else {
        response = await saveOfflineBooking({
          ...payload,
        });
      }
      setLoader(false)
      if (response && response.code == 200) {
        toast.success("Successfully Saved!");
        onCancel && onCancel();
      } else {
        toast.error(response.message || t('Something went wrong.Please try again'));
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setBookingInfo(_.cloneDeep(bookingInfo))
      toast.error("Please Enter The Required Details");
    }
  };

  const getTimeSlots = () => {
    const slots = [];
    let startTime = moment().startOf('day').format('hh:mm A')
    for (let i = 0; i < 96; i++) {
      slots.push({
        value: `${startTime}`,
        label: `${startTime}`
      });
      startTime = moment(startTime, 'hh:mm A').add(15, 'minute').format('hh:mm A')
    }
    return slots;
  }

  const confirmClose = async () => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to discard the changes? This action cannot be undone."
      ),
      // icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Discard"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        history.goBack();
        setIsSaved(false);
      }
    });
  };

  const closeOverlay = () => {
    if (!isSaved) {
      setIsSaved(false);
    } else {
      confirmClose();
    }
    onCancel()
  };

  const updateSaved = (saved) => {
    if (saved) {
      setIsSaved(saved);
    } else if (!isSaved) {
      setIsSaved(true);
    }
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {moment(value).format("dddd ,")} {value}{" "}
      {<Image className="ml-1" alt="down-icon" src={downArrow} />}
    </button>
  ));

  const formatDuration = (value) => {
    if (value) {
      return `${value} min`;
    }
    return value;
  };

  const closePopUp = () => {
    setAddClient(false);
    closeOverlay();
  };

  const handleCheckStaffAvailability = async (params = {}) => {
    setLoader(true);
    const res = await checkStaffTimeSlotAvaiability({
      staff_id: params?.staffId,
      option_id: params?.serviceId,
      time: moment(params?.startTime, 'hh:mm A').format('HH:mm'),
      date: moment(startdate).format("YYYY-MM-DD"),
    });
    setLoader(false);
    if (res?.code < 400) {
      return res?.result?.message
    } else {
      toast.error(res?.message)
    }
    return null;
  }

  const getMessage = (type) => {
    return type;
    switch (type) {
      case 'PAST_DATE':
        return t('Invalid Date');
      case 'SUPPLIER_UNAVAILABLE_DATE':
        return t('Supplier is not available on selected date');
      case 'SUPPLIER_UNDEFINED_WORKING_DAYS':
        return t('Please add working days');
      case 'SUPPLIER_NOT_WORKING_DAY':
        return t('Supplier off day');
      case 'UNKNOWN_SERVICE_OPTION':
        return t('Please select valid service option');
      case 'SUPPLIER_UNAVAILABLE_TIME':
        return t('Supplier is not available at this time');
      case 'SERVICE_STAFF_UNASSIGNED':
        return t('This staff is not assigned to this service');
      case 'STAFF_UNAVAILABLE_DATE':
        return t('This staff is not available on this date');
      case 'STAFF_UNAVAILABLE_WEEKDAY':
        return t('STAFF_UNAVAILABLE_WEEKDAY');
      case 'STAFF_UNAVAILABLE_TIME':
        return t('This staff is not available at this time');
      case 'NO_TIMESLOTS_TO_CHECK':
        return t('No Time slots available')
    }
  }

  const handleSetSelectedCustomer = (customer) => {
    setSelectedCustomer(customer)
    if (selectedRadioBtn?.value == 'Home' && customer && !customer?.has_primary_address) {
      toast.error(t('This customer does not have a primary address.Please add primary address for home bookings'))
    }
    getDeliveryFee(selectedRadioBtn?.value, customer);
  }

  return (
    <div className="add-appointment">
      {loader && <FullPageSpinner /> || null}

      {/* heading container starts */}
      <Container fluid className="app-form-title">
        <Row className="h-100">
          <Col md={{ span: 6 }}>
            <h2 style={{ textAlign: "left" }}>
              <img
                alt="back-arrow"
                onClick={closeOverlay}
                src={arrowBack}
                style={{
                  margin: "0px 38px",
                  height: "25px",
                  cursor: "pointer",
                }}
              />
              {t(`${(isEdit && "Edit") || "Add"} Appointment`)}
            </h2>
          </Col>
        </Row>
      </Container>
      {/* heading container ends */}

      <Container fluid className="main-container">

        {/* select client popup starts */}
        <FullScreenPopup
          open={addClient}
          onClose={closePopUp}
          classNames="scroll-hidden"
        >
          <SelectClient
            getCustomers={true}
            closePopUp={closePopUp}
            customerId={props.customerId}
            duration={duration}
            history={history}
            selectedCustomer={selectedCustomer}
            supplierId={supplierId}
            setSelectedCustomer={setSelectedCustomer}
            totalAmount={totalAmount}
            handleSave={handleSave}
          />
        </FullScreenPopup>
        {/* select client popup ends */}


        <div className="left-block">
          <div className="left-block-wrapper">
            <div></div>

            {/* selected customer fields starts */}
            {selectedCustomer ? (
              <div
                key={selectedCustomer.id}
                className="customer-details-wrapper mb-2"
              >
                <div className="d-flex align-items-center">
                  <Image
                    src={selectedCustomer.profile_pic}
                    data-holder-rendered="true"
                  />
                  <div className="customer-details">
                    <p>{selectedCustomer.first_name}</p>
                    <span>
                      {selectedCustomer.email
                        ? selectedCustomer.email
                        : selectedCustomer.mobile}
                    </span>
                  </div>
                </div>
                <Image
                  alt="menu-icon"
                  className="three-dot-icon"
                  onClick={(event) => setTarget(target ? null : event.target)}
                  src={menuIcon}
                />
                <Overlay
                  show={Boolean(target)}
                  target={target}
                  placement="bottom"
                  container={menuRef.current}
                >
                  <Popover bsPrefix="remove-selected-item">
                    <PopoverContent
                      onClick={() => {
                        setSelectedCustomer("");
                        setTarget(null);
                      }}
                    >
                      {t("Remove Customer")}
                    </PopoverContent>
                  </Popover>
                </Overlay>
              </div>
            ) : (
              <div className="addClient" onClick={() => setAddClient(true)}>
                {t('Add Client')}
              </div>
            )}
            {/* selected customer fields ends */}


            {/* appointment date contaienr starts */}
            <div className="radio-group-row ">
              <DatePicker
                selected={startdate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                customInput={<CustomInput />}
                closeOnScroll={(e) => e.target == document}
                dateFormat="d MMM yyyy"
                dropdownMode="select"
                minDate={new Date()}
              />
              {!isEdit && supplierData &&
                supplierData.is_home == "1" &&
                supplierData.is_salon == "1" && (
                  <div className="d-flex radio-group">
                    <Image
                      className="m-auto"
                      src={locationIcon}
                      alt="location"
                    ></Image>
                    <CustomDropdown
                      classNames="no-padding"
                      options={radioOptions}
                      value={radioOptions.find(
                        (item) => item.value == selectedRadioBtn?.value
                      )}
                      onChange={(value) => handleChangeLoc(value)}
                    />
                  </div>
                )}
            </div>
            {/* appointment date contaienr ends */}


            {/* services list container start */}
            {bookingInfo.map((_, i) => (
              <div className="service-block" key={i}>
                <div className="mark-start">
                  {i + 1}
                  <div
                    style={{
                      height:
                        i != bookingInfo?.length - 1 || i == 0 ? height : undefined,
                    }}
                    id={`mark-${i + 1}`}
                    className="vl"
                  ></div>
                </div>
                <div
                  id={`main-service-block-${i + 1}`}
                  className={clsx("add-services mt-4", i > 0 && "transparent")}
                >
                  <Row className=" ">
                    <Col key={i} md={8}>
                      <SearchableDropdown
                        required
                        value={staffList?.find(s => s.supplier_staff_id == _.staffId) || null}
                        // disabled={disabled}
                        placeholder={t('Select Staff')}
                        options={staffList}
                        label={t('Staff')}
                        isMulti={false}
                        onChange={(newValue) => handleChange(i, 'staffId', newValue)}
                      />
                      {validator.current.message(
                        "staff",
                        _.staffId,
                        "required"
                      )}
                    </Col>
                    <Col key={i} md={4}>
                      <SearchableDropdown
                        required
                        value={timeSlots?.find(t => t?.value == _.startTime) || null}
                        // disabled={disabled}
                        placeholder={t('Select Time')}
                        options={timeSlots || []}
                        label={t('Start Time')}
                        isMulti={false}
                        onChange={(newValue) => handleChange(i, 'startTime', newValue)}
                      />
                      {validator.current.message(
                        "startTime",
                        _.startTime,
                        "required"
                      )}
                    </Col>
                    {/* {servicesList?.find(c => c?.category_id == _.category_id)} */}
                    <Col key={i} md={8}>
                      <SearchableDropdown
                        required
                        value={(_.serviceId && servicesList?.find(c => c?.category_id == _.category_id)?.services?.find(s => s?.value == _.serviceId)) || null}
                        disabled={isEdit && true || false}
                        placeholder={t('Select Service')}
                        options={servicesList}
                        label={t('Service')}
                        isMulti={false}
                        components={{
                          Menu: (props) => CustomMenuWrapper(props, i, selectedRadioBtn),
                        }}
                        onChange={(newValue) => handleChange(i, 'serviceId', newValue)}
                      />
                      {validator.current.message(
                        "serviceId",
                        _.serviceId,
                        "required"
                      )}
                    </Col>
                    <Col key={i} md={4}>
                      <UserInput
                        required
                        disabled={true}
                        label={t("Duration")}
                        placeholder={t("Service Duration")}
                        value={_.duration && formatDuration(_.duration) || ""}
                      />
                    </Col>
                  </Row>
                  <p className="text-center text-warning">{getMessage(_.error_msg)}</p>
                </div>

                {/* add or remove service button container starts */}
                {(!isEdit && bookingInfo?.length > 1) ? (
                  <Button
                    className="remove-service-button"
                    onClick={() => removeService(i)}
                  >
                    <Image alt="remove-icon" src={redClearIcon} />
                  </Button>
                ) : null}
                {!isEdit && i == bookingInfo?.length - 1 &&
                  <div
                    className="add-service-button"
                    onClick={() => addService(i)}
                  >
                    {t('Add Service')}
                  </div> || null}
                {/* add or remove service button container ends */}
              </div>
            ))}
            {/* services list container ends */}


            {/* appointment notes container starts */}
            {!isEdit && <Row className="mt-4 notes-row">
              <Col className="notes">
                <CustomTextArea
                  value={notes}
                  onChange={handleNotesChange}
                  label={t("Appointment notes")}
                  placeholder="Add an appointment note (visible Only to Staff)"
                />
              </Col>
            </Row>}
            {/* appointment notes container ends */}

          </div>
        </div>
        <SearchPanel
          customerId={props.customerId}
          duration={duration}
          history={history}
          selectedCustomer={selectedCustomer}
          supplierId={supplierId}
          setSelectedCustomer={handleSetSelectedCustomer}
          totalAmount={totalAmount}
          handleSave={handleSave}
          isEdit={isEdit}
          bookingDetails={bookingDetails || null}
          bookingInfo={bookingInfo}
          deliveryFee={deliveryFee}
        />
        {openPopup && (
          <FullScreenPopup open={openPopup} onClose={closeOverlay}>
            <AddClient onClose={closeOverlay} updateSaved={updateSaved} />
          </FullScreenPopup>
        )}
      </Container>
    </div>
  );
};

export default NewAppointment;

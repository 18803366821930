import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getAuthAttribute } from "../../../utils/helper";
import {
  Col,
  Container,
  Form,
  Image as BootstrapImage,
  Modal,
  Overlay,
  Popover,
  PopoverContent,
  Row,
} from "react-bootstrap";
import { uploadImage } from "../../../core/Api/staff";
import {
  saveMedia,
  getMedia,
  saveSortedMedia,
  updateMediaCaption,
  saveFeatureMedia,
} from "../../../core/Api/partner";
import Hoc from "../../Hoc";
import Panel from "../../../components/Panel";
import menuIcon from "../../../assets/images/three-dots.svg";
import UserInput from "../../../components/Inputs/index";
import PrimaryButton from "../../../components/Buttons/Primary";
import SecondaryButton from "../../../components/Buttons/Secondary";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Swal from "sweetalert2";
import { deleteMedia, deleteFeatureMedia } from "../../../core/Api/supplier";
import close from "../../../assets/images/ic_cancel.svg";
import config from "../../../config";
import ImageCropper from "./ImageCropper";
import AssetsImg from "images-col";
import FullPageSpinner from "../../../components/FullPageSpinner";

const defaultImage =
  "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg?v=1628698285";


  
const AddImages = ({ match }) => {
  const { t } = useTranslation();
  const supplierId = match.params.id;
  const supplier_id = supplierId ? supplierId : getAuthAttribute("supplier_id");
  const [state, setState] = useState([]);
  const [currentImageURL, setCurrentImageURL] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [caption, setCaption] = useState("");
  const [selectedSupplierMediaId, setSelectedSupplierMediaId] = useState(null);
  const [deletePrimaryIcon, setDeletePrimaryIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultCurrentImageUrl = `${config.BASE_URL
    }/uploads/supplier_media/${supplier_id}/feature.jpg?v=${new Date().toISOString()}`;
  const defaultImageUrl = useRef({
    src: defaultCurrentImageUrl,
  });
  useEffect(() => {
    fetchMedia();
    // eslint-disable-next-line
  }, []);

  const fetchMedia = async () => {
    setLoading(true);
    await getMedia(supplier_id).then((res) => {
      if (res.result && res.result.length) {
        const images = res.result.map((row) => ({
          id: row.supplier_media_id,
          src: row.link,
          isPrimary: row.is_primary == "1",
          caption: row.caption,
        }));
        setState(images);
      } else {
        setState([]);
      }
    });
    setLoading(false);
  };

  const handleDelete = async (supplier_media_id, isFeatureImage) => {
    Swal.fire({
      text: t(
        "Are you sure you want to delete this image? This action cannot be undone."
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = await (isFeatureImage
          ? deleteFeatureMedia(supplier_media_id)
          : deleteMedia(supplier_media_id));
          setLoading(false);
        if (response && response.code == 200) {
          Swal.fire("Deleted Successfully", "", "success");
          removeItem(supplier_media_id);
          fetchMedia();
          setCurrentImageURL(null);
          setUploadedImage(null);
          defaultImageUrl.current.src = AssetsImg?.img_default
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const handleImageUpload = async (event) => {
    const image = event.target.files[0];
    const data = new FormData();
    data.append("file", image);
    const imageUrl = await uploadImage(data);
    if (imageUrl && imageUrl.code == 200) {
      const media = await saveMedia({
        supplier_id: supplier_id,
        media_name: imageUrl.result.file_name,
        is_primary: 0,
      });
      if (media && media.code == 200) {
        toast.success("Uploaded Successfully");
        fetchMedia();
      } else {
        toast.error(
          (media && media.message) || "Something wrong in saving media"
        );
      }
    } else {
      toast.error(
        (imageUrl && imageUrl.message) || "Something wrong in uploading image"
      );
    }
  };

  const handleUpdateCaption = async () => {
    const result = await updateMediaCaption({
      supplier_id: supplier_id,
      supplier_media_id: selectedSupplierMediaId,
      caption: caption,
    });
    if (result && result.code == 200) {
      toast.success(result.message || "Uploaded Successfully");
      setCaption("");
      setSelectedSupplierMediaId(null);
      setModalShow(false);
      fetchMedia();
    } else {
      toast.error(
        (result && result.message) || "Something wrong in saving caption"
      );
    }
  };

  const onImageSelect = async (event) => {
    const image = event.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = function () {
          var width = img.naturalWidth,
            height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          if (width >= 400 && height >= 300) {
            setUploadedImage(reader.result);
          } else {
            toast.error("Image resolution is quite low");
            event.preventDefault();
          }
        };
      });
      reader.readAsDataURL(image);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newState = [...state];
    const temp = newState[oldIndex];
    newState[oldIndex] = newState[newIndex];
    newState[newIndex] = temp;
    setState(newState);
    const data = newState.map((item, index) => ({
      supplier_media_id: item.id,
      sort_order: index,
    }));
    saveSortedMedia(data);
  };

  const removeItem = (id) =>
    setState((state) => state.filter((value) => value.id != id));

  const Photo = ({ item }) => {
    const styles = {
      width: "100%",
      display: "block",
    };
    if (!item.caption) {
      styles["borderRadius"] = "8px";
    }
    return (
      <>
        <img src={item.src} alt="" style={styles} />
        {item.caption && <h6 className="caption">{item.caption}</h6>}
      </>
    );
  };
  const SortablePhoto = SortableElement(({ item }) => <Photo item={item} />);

  const SortableItem = (props) => {
    const { item, index } = props;
    const [showDelete, setShowDelete] = useState(false);
    const [target, setTarget] = useState(false);
    return (
      <div
        key={`${item.id}`}
        className="image"
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
      >
        {showDelete && (
          <div className="menu-button">
            <BootstrapImage
              src={menuIcon}
              alt="menu-icon"
              className="three-dots"
              onClick={(event) => setTarget(target ? null : event.target)}
            />
          </div>
        )}
        <SortablePhoto key={`${item.id}`} index={index} item={item} />
        <Overlay show={Boolean(target)} target={target} placement="bottom-end">
          <Popover bsPrefix="remove-selected-item">
            <PopoverContent>
              <span
                onClick={() => {
                  setModalShow(true);
                  setCaption(item.caption);
                  setSelectedSupplierMediaId(item.id);
                  setTarget(null);
                }}
              >
                Edit
              </span>
              <br />
              <span
                onClick={() => {
                  handleDelete(item.id);
                  setTarget(null);
                }}
              >
                Delete
              </span>
            </PopoverContent>
          </Popover>
        </Overlay>
      </div>
    );
  };

  const SortableGallery = SortableContainer(({ items }) => (
    <div className="image-container">
      {items.map((item, index) => (
        <SortableItem item={item} index={index} />
      ))}
    </div>
  ));

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  const onSave = async () => {
    if (currentImageURL) {
      const file = DataURIToBlob(currentImageURL);
      const formData = new FormData();
      formData.append('file', file, 'image.png');
      setLoading(true);
      const imageUrl = await uploadImage(formData);
      if (imageUrl && imageUrl.code == 200) {
        const media = await saveFeatureMedia({
          supplier_id: supplier_id,
          cropped_image_src: imageUrl.result.file_name,
        });
        if (media && media.code == 200) {
          toast.success("Uploaded Successfully");
          defaultImageUrl.current.src = currentImageURL;
          setCurrentImageURL(null);
          setUploadedImage(null);
          fetchMedia();
        } else {
          toast.error(
            (media && media.message) || "Something wrong in saving media"
          );
        }
      } else {
        toast.error(
          (imageUrl && imageUrl.message) || "Something wrong in uploading image"
        );
      }
      setLoading(false);
    } else {
      toast.error("Something wrong in uploading image");
    }
  };

  const removeImage = () => {
    setCurrentImageURL(null);
    setUploadedImage(null);
    defaultImageUrl.current.src = AssetsImg?.img_default;
  };


  return (
    <Hoc
      // handleImageUpload={handleImageUpload}
      activeMenu={supplierId ? "supplier_media" : "supplier_media"}
    >
      <Container fluid className="images">
        {loading && <FullPageSpinner/>}
        <Panel title={t('Feature image')} className="upload-box">
          <div>
            <Form>
              <Col md="12" className="image-input p-0">
                <label for>{t('Select Feature Image')}</label>
                <input
                  type="file"
                  name="myfile"
                  accept=".png, .jpg, .jpeg"
                  onChange={onImageSelect}
                />
              </Col>
              <Row>
                <Col md="6" className={"current-image"}>
                  <label for>{t('Current Feature Image')}</label>
                  <div
                    className={"selected-image-wrapper"}
                    onMouseEnter={() => setDeletePrimaryIcon(true)}
                    onMouseLeave={() => setDeletePrimaryIcon(false)}
                  >
                    {deletePrimaryIcon &&
                      defaultImageUrl.current?.src != '/static/media/album-default.2e01288c.jpg' && (
                        <div className="delete-icon">
                          <img
                            src={close}
                            alt="delete"
                            onClick={() =>
                              currentImageURL
                                ? removeImage()
                                : handleDelete(
                                  defaultImageUrl.current?.id ?? supplier_id,
                                  !Boolean(defaultImageUrl.current?.id)
                                )
                            }
                          />
                        </div>
                      )}
                    <img
                      src={currentImageURL ?? defaultImageUrl.current.src}
                      alt=""
                      className={currentImageURL && "selected-image"}
                      onError={()=>{
                        defaultImageUrl.current.src = AssetsImg?.img_default
                      }}
                    />
                  </div>
                </Col>
                <Col md="2"></Col>
                {uploadedImage ? (
                  <ImageCropper
                    imageUrl={uploadedImage}
                    setCurrentImageURL={setCurrentImageURL}
                  />
                ) : (
                  <Col md="4" className="upload-msg">
                    {t('Upload a file to start cropping')}
                  </Col>
                )}
              </Row>
              <Col className="btn-col">
                <PrimaryButton
                  disabled={!Boolean(uploadedImage)}
                  text={t("Save")}
                  onClick={onSave}
                />
              </Col>
            </Form>
          </div>
        </Panel>
        <Modal
          centered
          size="lg"
          show={modalShow}
          onHide={() => {
            setCaption("");
            setSelectedSupplierMediaId(null);
            setModalShow(false);
          }}
          className="caption-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Add Caption')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserInput
              label="Image Caption"
              value={caption}
              onChange={(event) => setCaption(event.target.value)}
              type="text"
              name="caption"
              placeholder="enter your caption"
            />
          </Modal.Body>
          <Modal.Footer>
            <PrimaryButton text={t("Save")} onClick={handleUpdateCaption} />
            <SecondaryButton
              text={t("Close")}
              onClick={() => {
                setModalShow(false);
              }}
            />
          </Modal.Footer>
        </Modal>
        <Panel title={t('Image Gallery')} classNames="galleryPanel">
          <Col md="12" className="image-input p-0">
            <label for>{t('Select image')}</label>
            <input
              type="file"
              name="gallery_image"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageUpload}
            />
          </Col>
          <SortableGallery items={state} onSortEnd={onSortEnd} axis={"xy"} />
        </Panel>
      </Container>
    </Hoc>
  );
};

export default AddImages;

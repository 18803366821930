import React from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import "./Styles.scss";
import clsx from "clsx";
const UserInput = (props) => {
  const {
    label,
    placeholder,
    customInputClass,
    autoComplete,
    required,
    onBlur,
    value,
  } = props;
  return (
    <div className={clsx("custom-input", customInputClass)}>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
        </Form.Label>
      )}
      <InputGroup>
        <FormControl
          {...props}
          autoComplete={autoComplete}
          placeholder={placeholder}
          onBlur={onBlur}
          value={value}
        />
      </InputGroup>
    </div>
  );
};

UserInput.propTypes = {};

export default UserInput;

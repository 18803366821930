import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import SimpleReactValidator from "simple-react-validator";
import { Container, Col, Row, Button } from "react-bootstrap";

import UserInput from "../../../components/Inputs";
import CustomDropdown from "../../../components/Dropdown";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import PrimaryButton from "../../../components/Buttons/Primary";
import "./Styles.scss";
import moment from "moment";
import {
  saveCustomer,
  getCustomerById,
  updateAddress,
  saveCustomerAddress,
  deleteCustomerAddress,
} from "../../../core/Api/customer";
import { uploadImage } from "../../../core/Api/staff";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import Swal from "sweetalert2";
import { getDepartments, getDesignations, getEmployeeById, getEmployeeByRole, saveEmployee } from "../../../core/Api/employee";
import FullPageSpinner from "../../../components/FullPageSpinner";

const schema = {
  emp_id: null,
  emp_code: null,
  mgr_id: null,
  first_name: null,
  last_name: null,
  emp_email: null,
  emp_phone: null,
  emp_role: null,
  department: null,
  designation: null,
  emp_gender: null,
  status_id: "1",
  birth_date: null,
  joining_date: null,
  contract_end_date: null,
  employment_end_date: null,
  nid: null,
  iban:null,
};

const AddClient = (props) => {
  const { updateSaved } = props;
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        date: {
          message: "Select valid date",
          rule: (val) => {
            const day = val[0],
              month = val[1],
              year = val[2];
            if (!day && !month && !year) {
              return true;
            } else if (!day || !month || !year) {
              return false;
            }
            const result =
              new Date().getFullYear() -
              new Date(`${year}-${month}-${day}`).getFullYear() >=
              13;
            return result;
          },
        },
        checkMobileNumber: {
          message: "Enter a valid mobile number",
          rule: (val) => {
            if (!val) {
              return false;
            } else if (val.split("-").length != 2) {
              return false;
            } else if (
              val.split("-")[1].length != 9 ||
              Number.isNaN(+val.split("-")[1])
            ) {
              return false;
            }
            return true;
          },
        },
      },
    })
  );
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([
    { label: t("Select Option"), value: null }
  ]);
  const [designationOptions, setDesignationOptions] = useState([
    { label: t("Select Option"), value: null }
  ]);
  const [managerOptions, setManagerOptions] = useState([
    { label: t("Select Option"), value: null }
  ]);
  const [employeeModel, setEmployeeModel] = useState({ ...schema });
  const genderOptions = [
    { label: t("Select Option"), value: null },
    { label: t("Male"), value: "male" },
    { label: t("Female"), value: "female" },
  ];
  const empRoleOptions = [
    { label: t("Select Option"), value: null },
    { label: t("Employee"), value: "EMPLOYEE" },
    { label: t("Admin"), value: "ADMIN" },
    { label: t("Supervisor"), value: "SUPERVISOR" },
    { label: t("Manager"), value: "MANAGER" },
  ];
  const monthOptions = [
    { label: t("Select Month"), value: null },
    { label: t("January"), value: "01" },
    { label: t("February"), value: "02" },
    { label: t("March"), value: "03" },
    { label: t("April"), value: "04" },
    { label: t("May"), value: "05" },
    { label: t("June"), value: "06" },
    { label: t("July"), value: "07" },
    { label: t("August"), value: "08" },
    { label: t("September"), value: "09" },
    { label: t("October"), value: "10" },
    { label: t("November"), value: "11" },
    { label: t("December"), value: "12" },
  ];
  const dayOptions = [
    { label: t("Select Day"), value: null },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
  ];

  const year = moment().format("YYYY");
  const yearOptions = [{ label: t("Select Year"), value: null }];
  for (let i = Number(year) - 13; i >= 1950; i--) {
    yearOptions.push({ label: `${i}`, value: `${i}` });
  }

  useEffect(() => {
    (async () => {
      if (false) {
        const result = await getCustomerById(props.employeeId);
        if (result && result.code == 200) {
          const dateOfBirth = result?.result?.date_of_birth?.split("-") ?? [];
          setEmployeeModel({
            ...result.result.address,
            ...result.result,
            referral_source: result?.result?.referral_source?.replace(" ", ""),
            day: dateOfBirth[2] ?? employeeModel.day,
            month: dateOfBirth[1] ?? employeeModel.month,
            year: dateOfBirth[0] ?? employeeModel.year,
            accept_notifications:
              result.result.accept_marketing_notification == "1",
            is_notes_displayed: result.result.is_notes_displayed == "1",
          });
        } else {
          toast.error(result && result.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      setLoading(true);
      const promises = [
        getDepartments(),
        getDesignations(),
        getEmployeeByRole('MANAGER')
      ]
      if (props?.history?.location?.state?.employeeId) {
        promises.push(getEmployeeById(props?.history?.location?.state?.employeeId))
      }
      const results = await Promise.all([...promises]);
      setDepartmentOptions([
        ...departmentOptions,
        ...results[0]?.result?.map(r => ({
          ...r,
          label: r?.dept_name,
          value: r?.dept_id
        })) || [],
      ])
      setDesignationOptions([
        ...designationOptions,
        ...results[1]?.result?.map(r => ({
          ...r,
          label: r?.desg_name,
          value: r?.desg_id
        })) || [],
      ])
      setManagerOptions([
        ...managerOptions,
        ...results[2]?.result?.map(r => ({
          ...r,
          label: r?.first_name,
          value: r?.emp_id
        })) || [],
      ])
      if (props?.history?.location?.state?.employeeId) {
        const birth_date = results[3]?.result?.birth_date?.split("-") ?? [];
        const joining_date = results[3]?.result?.joining_date?.split("-") ?? [];
        const contract_end_date = results[3]?.result?.contract_end_date?.split("-") ?? [];
        const employment_end_date = results[3]?.result?.employment_end_date?.split("-") ?? [];
        const empData = {
          ...results[3]?.result,
          dob_day: birth_date[2] ?? null,
          dob_month: birth_date[1] ?? null,
          dob_year: birth_date[0] ?? null,
          jd_day: joining_date[2] ?? null,
          jd_month: joining_date[1] ?? null,
          jd_year: joining_date[0] ?? null,
          ced_day: contract_end_date[2] ?? null,
          ced_month: contract_end_date[1] ?? null,
          ced_year: contract_end_date[0] ?? null,
          eed_day: employment_end_date[2] ?? null,
          eed_month: employment_end_date[1] ?? null,
          eed_year: employment_end_date[0] ?? null,
        }
        setEmployeeModel({
          ...empData
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleChange = (value, name) => {
    setEmployeeModel({
      ...employeeModel,
      [name]: value,
    });
    updateSaved && updateSaved();
  };

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setEmployeeModel({
      ...employeeModel,
      emp_phone: `${value.countryCode}-${value.phoneNumber}`,
    });
    updateSaved && updateSaved();
  };

  const handleSaveCustomer = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        setLoading(true);
        let data = {
          emp_id: employeeModel?.emp_id || null,
          emp_code: employeeModel?.emp_code,
          mgr_id: employeeModel?.mgr_id,
          first_name: employeeModel?.first_name,
          last_name: employeeModel?.last_name,
          emp_email: employeeModel?.emp_email,
          emp_phone: employeeModel?.emp_phone,
          emp_role: employeeModel?.emp_role,
          department: employeeModel?.department,
          designation: employeeModel?.designation,
          emp_gender: employeeModel?.emp_gender,
          status_id: employeeModel?.status_id,
          mgr_id: employeeModel?.mgr_id,
          nid:employeeModel?.nid,
          iban:employeeModel?.iban
        };
        if (employeeModel?.dob_year && employeeModel?.dob_month && employeeModel?.dob_day) {
          data.birth_date = `${employeeModel.dob_year}-${employeeModel.dob_month}-${employeeModel.dob_day}`;
        }
        if (employeeModel?.jd_year && employeeModel?.jd_month && employeeModel?.jd_day) {
          data.joining_date = `${employeeModel.jd_year}-${employeeModel.jd_month}-${employeeModel.jd_day}`;
        }
        if (employeeModel?.ced_year && employeeModel?.ced_month && employeeModel?.ced_day) {
          data.contract_end_date = `${employeeModel.ced_year}-${employeeModel.ced_month}-${employeeModel.ced_day}`;
        }
        if (employeeModel?.eed_year && employeeModel?.eed_month && employeeModel?.eed_day) {
          data.employment_end_date = `${employeeModel.eed_year}-${employeeModel.eed_month}-${employeeModel.eed_day}`;
        }
        data = _.pickBy(data, _.identity);
        const result = await saveEmployee(data);
        if (result && result.code == 200) {
          toast.success("Employee saved successfully");
          updateSaved && updateSaved(false);
          props?.onClose(true);
        } else {
          toast.error(result && result.message);
          forceUpdate(1);
        }
        setLoading(false)
      } else {
        simpleValidator.current.showMessages();
        toast.error("Please enter valid details");
        forceUpdate(1);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="add-employee">
      {loading && <FullPageSpinner /> || null}
      <h2>{props.employeeId > 0 ? t("Edit Employee") : t("New Employee")}</h2>
      <Container className="form-cont">
        <Row>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <UserInput
            disabled={employeeModel?.emp_id &&  true || false}
              required
              label={t("Employee Code")}
              placeholder="12345"
              name="emp_code"
              value={employeeModel.emp_code}
              onChange={(event) =>
                handleChange(event.target.value, "emp_code")
              }
            />
            {simpleValidator.current.message(
              "emp_code",
              employeeModel && employeeModel.emp_code,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <UserInput
              required
              label={t("First Name")}
              placeholder="First Name"
              name="first_name"
              value={employeeModel.first_name}
              onChange={(event) =>
                handleChange(event.target.value, "first_name")
              }
            />
            {simpleValidator.current.message(
              "first_name",
              employeeModel && employeeModel.first_name,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <UserInput
              required
              label={t("Last Name")}
              placeholder="Last Name"
              name="last_name"
              value={employeeModel.last_name}
              onChange={(event) =>
                handleChange(event.target.value, "last_name")
              }
            />
            {simpleValidator.current.message(
              "last_name",
              employeeModel && employeeModel.last_name,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <UserInput
              required
              label={t("Email")}
              placeholder="Email"
              name="emp_email"
              value={employeeModel.emp_email}
              onChange={(event) =>
                handleChange(event.target.value, "emp_email")
              }
            />
            {simpleValidator.current.message(
              "emp_email",
              employeeModel && employeeModel.emp_email,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <PhoneNumberInput
              required
              label={t("Mobile")}
              placeholder="5xxxxxxx"
              value={employeeModel.emp_phone}
              selectedValues={(values) => handlePhoneNumberChange(values)}
              maxLength="9"
            />
            {simpleValidator.current.message(
              "emp_phone",
              employeeModel.emp_phone,
              "required|checkMobileNumber"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12} style={{ marginTop: '-20px' }}>
            <CustomDropdown
              required
              options={genderOptions}
              label={t("Gender")}
              onChange={(value) =>
                handleChange(value.value, "emp_gender")
              }
              value={
                genderOptions.find(
                  (row) =>
                    row.value == (employeeModel && employeeModel.emp_gender)
                ) || ""
              }
            />
            {simpleValidator.current.message(
              "emp_gender",
              employeeModel.emp_gender,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <UserInput
              required
              label={t("National ID")}
              placeholder="12345"
              name="nid"
              value={employeeModel.nid}
              onChange={(event) =>
                handleChange(event.target.value, "nid")
              }
            />
            {simpleValidator.current.message(
              "nid",
              employeeModel && employeeModel.nid,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <UserInput
              required
              label={t("IBAN")}
              placeholder="12345"
              name="iban"
              value={employeeModel.iban}
              onChange={(event) =>
                handleChange(event.target.value, "iban")
              }
            />
            {simpleValidator.current.message(
              "iban",
              employeeModel && employeeModel.iban,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <CustomDropdown
              required
              options={empRoleOptions}
              label={t("Employee Role")}
              onChange={(value) =>
                handleChange(value.value, "emp_role")
              }
              value={
                empRoleOptions.find(
                  (row) =>
                    row.value == (employeeModel && employeeModel.emp_role)
                ) || ""
              }
            />
            {simpleValidator.current.message(
              "emp_role",
              employeeModel.emp_role,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <CustomDropdown
              options={managerOptions}
              label={t("Manager")}
              onChange={(value) =>
                handleChange(value.value, "mgr_id")
              }
              value={
                managerOptions.find(
                  (row) =>
                    row.value == (employeeModel && employeeModel.mgr_id)
                ) || ""
              }
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <CustomDropdown
              required
              options={departmentOptions}
              label={t("Department")}
              onChange={(value) =>
                handleChange(value.value, "department")
              }
              value={
                departmentOptions.find(
                  (row) =>
                    row.value == (employeeModel && employeeModel.department)
                ) || ""
              }
            />
            {simpleValidator.current.message(
              "department",
              employeeModel.department,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
            <CustomDropdown
              required
              options={designationOptions}
              label={t("Designation")}
              onChange={(value) =>
                handleChange(value.value, "designation")
              }
              value={
                designationOptions.find(
                  (row) =>
                    row.value == (employeeModel && employeeModel.designation)
                ) || ""
              }
            />
            {simpleValidator.current.message(
              "designation",
              employeeModel.designation,
              "required"
            )}
          </Col>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <Row className="" >
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label={t("Birthday")}
                  placeholder={t("Month")}
                  options={monthOptions}
                  onChange={(value) => handleChange(value.value, "dob_month")}
                  value={
                    monthOptions.find(
                      (row) =>
                        row.value == (employeeModel?.dob_month)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={dayOptions}
                  placeholder={t("Day")}
                  onChange={(value) => handleChange(value.value, "dob_day")}
                  value={
                    dayOptions.find(
                      (row) =>
                        row.value == (employeeModel?.dob_day)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={yearOptions}
                  placeholder={t("Year")}
                  onChange={(value) => handleChange(value.value, "dob_year")}
                  value={
                    yearOptions.find(
                      (row) =>
                        row.value == (employeeModel?.dob_year)
                    ) || ""
                  }
                />
              </Col>
              {/* {simpleValidator.current.message(
              "designation",
              employeeModel.designation,
              "date"
            )} */}
            </Row>

          </Col>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <Row className="">
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label={t("Joining Date")}
                  placeholder={t("Month")}
                  options={monthOptions}
                  onChange={(value) => handleChange(value.value, "jd_month")}
                  value={
                    monthOptions.find(
                      (row) =>
                        row.value == (employeeModel?.jd_month)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={dayOptions}
                  placeholder={t("Day")}
                  onChange={(value) => handleChange(value.value, "jd_day")}
                  value={
                    dayOptions.find(
                      (row) =>
                        row.value == (employeeModel?.jd_day)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={yearOptions}
                  placeholder={t("Year")}
                  onChange={(value) => handleChange(value.value, "jd_year")}
                  value={
                    yearOptions.find(
                      (row) =>
                        row.value == (employeeModel?.jd_year)
                    ) || ""
                  }
                />
              </Col>
            </Row>

          </Col>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <Row className="">
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label={t("Contract End Date")}
                  placeholder={t("Month")}
                  options={monthOptions}
                  onChange={(value) => handleChange(value.value, "ced_month")}
                  value={
                    monthOptions.find(
                      (row) =>
                        row.value == (employeeModel?.ced_month)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={dayOptions}
                  placeholder={t("Day")}
                  onChange={(value) => handleChange(value.value, "ced_day")}
                  value={
                    dayOptions.find(
                      (row) =>
                        row.value == (employeeModel?.ced_day)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={yearOptions}
                  placeholder={t("Year")}
                  onChange={(value) => handleChange(value.value, "ced_year")}
                  value={
                    yearOptions.find(
                      (row) =>
                        row.value == (employeeModel?.ced_year)
                    ) || ""
                  }
                />
              </Col>
            </Row>

          </Col>
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <Row className="">
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label={t("Employment End Date")}
                  placeholder={t("Month")}
                  options={monthOptions}
                  onChange={(value) => handleChange(value.value, "eed_month")}
                  value={
                    monthOptions.find(
                      (row) =>
                        row.value == (employeeModel?.eed_month)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={dayOptions}
                  placeholder={t("Day")}
                  onChange={(value) => handleChange(value.value, "eed_day")}
                  value={
                    dayOptions.find(
                      (row) =>
                        row.value == (employeeModel?.eed_day)
                    ) || ""
                  }
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="4" xs={4}>
                <SearchableDropdown
                  label="&nbsp;"
                  options={yearOptions}
                  placeholder={t("Year")}
                  onChange={(value) => handleChange(value.value, "eed_year")}
                  value={
                    yearOptions.find(
                      (row) =>
                        row.value == (employeeModel?.eed_year)
                    ) || ""
                  }
                />
              </Col>
            </Row>

          </Col>
        </Row>
        <Row className="save-client mt-5">
          <Col md="2">
            <PrimaryButton text={t("Save")} onClick={handleSaveCustomer} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default AddClient;

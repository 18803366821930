import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as _ from 'lodash'
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './MobiScroll.scss'
import { Eventcalendar, formatDate, Datepicker, Popup, Button } from '@mobiscroll/react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function MobiscrollScheduler({
  selectedView,
  staffList = [],
  events = [],
  startDate = moment().format('YYYY-MM-DDT08:00:00'),
  endDate = moment().format('YYYY-MM-DDT08:00:00'),
  businessHrs = [],
  handleAddAppFromSlot,
  handleEventClick,
  eventReschedule,
  handleAddBlockSlot,
  enableBlockSlotSelect
}) {
  const { t } = useTranslation();
  const [myEvents, setEvents] = React.useState();
  const [closedDates, setCloseDates] = useState([])
  const [view, setView] = useState({
    schedule: {
      type: 'day',
      allDay: false,
      startDay: 1,
      endDay: 7,
      startTime: '00:00',
      endTime: '23:59',
      timeCellStep: '15'
    },
  });
  const [resources, setResources] = useState([])
  const [isOpen, setOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const timerRef = React.useRef(null);
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [status, setStatus] = React.useState('');
  const [schedulerRef, setSchedulerRef] = useState(null);
  const [nonBusinessHrs, setNonBusinessHrs] = useState([])

  useEffect(() => {
    if (selectedView == 'month') {
      setView({
        calendar: { labels: true }
      });
    } else if (selectedView == 'week') {
      setView({
        schedule: {
          type: 'week',
          allDay: false,
          startDay: 0,
          endDay: 6,
          startTime: '00:00',
          endTime: '23:59',
          timeCellStep: '15'
        },
      })
    } else if (selectedView == 'day') {
      setView({
        schedule: {
          type: 'day',
          allDay: false,
          startDay: 0,
          endDay: 6,
          startTime: '00:00',
          endTime: '23:59',
          timeCellStep: '15'
        },
      });
    }
    setResources([...staffList])
  }, [selectedView, staffList]);

  useEffect(() => {
    calculateClosedSlots(events, businessHrs)
    setEvents(events && _.filter(events, e => !e.supplierUnavailabilityId) || [])
  }, [events, businessHrs]);

  useEffect(() => {
    if (schedulerRef) {
      schedulerRef.navigate(moment(startDate).format('YYYY-MM-DDT08:30:00'))
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (schedulerRef) {
      schedulerRef.navigate(moment(startDate).format('YYYY-MM-DDT08:30:00'))
    }
  }, [schedulerRef]);

  const calculateClosedSlots = (e, bh) => {
    const closeDates = _.filter(_.cloneDeep(e), e => e.supplierUnavailabilityId).map(row => {
      return {
        start: moment(row.start).format('YYYY-MM-DDTHH:mm'),
        end: moment(row.end).format('YYYY-MM-DDTHH:mm'),
        title: row.title,
        resource: row.resourceId
      }
    })
    const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    let invalidTimeSlots = [];
    days.forEach((day, i) => {
      const wDay = bh.find(wd => wd.dow == i);
      if (wDay) {
        if (wDay.start != '00:00') {
          invalidTimeSlots.push({
            start: '00:00',
            end: `${wDay.start}`,
            recurring: {
              repeat: 'weekly',
              weekDays: days[i]
            }
          });
        }
        invalidTimeSlots.push({
          start: `${wDay.end}`,
          end: '00:00',
          recurring: {
            repeat: 'weekly',
            weekDays: days[i]
          }
        })
      } else {
        invalidTimeSlots.push({
          start: '00:00',
          end: '23:59',
          recurring: {
            repeat: 'weekly',
            weekDays: days[i]
          }
        });
      }
    });
    invalidTimeSlots = [
      ..._.cloneDeep(invalidTimeSlots),
      ..._.cloneDeep(closeDates)
    ]
    setCloseDates(_.cloneDeep(invalidTimeSlots))
  }

  const renderDay = (args) => {
    // const date = args.date;
    // const dayNr = date.getDay();
    // const task = milestones.find((obj) => { return +new Date(obj.date) == +date }) || {};
    // return <div className="header-template-container">
    //   <div className="header-template-date">
    //     <div className="header-template-day-name">{formatDate('DDDD', date)}</div>
    //     <div className="header-template-day">{formatDate('MMMM DD', date)}</div>
    //   </div>
    //   <div className="header-template-task" style={{ background: task.color }}>{task.name}</div>
    // </div>;
  }

  const renderCustomResource = (resource) => {
    console.log('renderCustomResource :', resource);
    return <div className="header-resource-template-content">
      <div className='header-resource-title-cont'>
        <div className='title-border'>
          {resource.image && (
            <img className="header-resource-avatar" src={resource.profile_pic_url} />
          ) || (
              <div className='header-resource-title'>
                {(resource?.title?.length && resource?.title[0]) || ''}
              </div>
            )}
        </div>
      </div>

      <OverlayTrigger
        placement="bottom"
        overlay={(
          <Tooltip id="resource-name-tooltip" >
            <span >{resource?.title || ''}</span>
          </Tooltip>
        )}
      >
        <div
          className="header-resource-name"
        >
          {`${(resource?.title?.length && resource?.title) || ''}`}
        </div>
      </OverlayTrigger>
    </div>;
  }

  const onPageLoaded = async () => {
    try {
      const columns = await document.getElementsByClassName('mbsc-schedule-column');
      for (let i = 0; i < columns.length; i++) {
        const colCont = columns[i].children[0];
        const colItems = await colCont.getElementsByClassName('mbsc-schedule-item')
        let startTime = moment('2013-02-08 00:00:06').format();
        for (let j = 0; j < colItems.length; j++) {
          const time = startTime;
          colItems[j].addEventListener("mouseenter", (t, e = colItems[j], ct = time) => {
            e.innerHTML = `${moment(ct).format('hh:mm a')}`;
            e.style['color'] = '#00baf7'
          });
          colItems[j].addEventListener("mouseleave", (t, e = colItems[j]) => {
            e.innerHTML = '';
          });
          startTime = moment(startTime).add(15, 'minute');
        }
      }
    } catch (error) {
    }
  }

  const onEventHoverIn = React.useCallback((args) => {
    console.log('onEventHoverIn :', args);
    const event = args.event;
    if (event.confirmed) {
      setStatus('Confirmed');
    }
    setCurrentEvent(event)
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setAnchor(args.domEvent.target);
    setOpen(true);
  }, []);

  const onEventHoverOut = React.useCallback(() => {
    timerRef.current = setTimeout(() => { setOpen(false); }, 200);
  }, []);

  const onMouseEnter = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeave = React.useCallback(() => {
    timerRef.current = setTimeout(() => { setOpen(false); }, 200);
  }, []);

  const onEventCreate = (event, inst) => {
    if (moment(event?.event?.start).isBefore()) {
      toast.error(t('Past date can not be selected'));
      return false
    } 
  }

  const onEventCreated = (event, inst) => {
    try {
      const eventsList = inst.getEvents();
      const newEvent = eventsList[eventsList.length - 1];
      setEvents(myEvents.filter(e => e.id == newEvent.id))
    } catch (error) {
    }
    if (enableBlockSlotSelect) {
      handleAddBlockSlot({
        selectedStaffId: event?.event?.resource,
        startDate: event?.event?.start,
        endDate: event?.event?.end,
      })
    } else {
      handleAddAppFromSlot({
        resource: event?.event?.resource,
        dateTime: new Date(event?.event?.start)
      })
    }
  }
  const onEventClick = (event, inst) => {
    handleEventClick(event?.event);
  }
  const handleEventDragEnd = (event, b) => {
    if (!event?.event) return false;
    const newBookingData = {
      booking_id: event?.event?.original?.booking_id,
      booking_date: moment(event?.event?.original?.start).format('YYYY-MM-DD'),
      service_date: event?.event?.original?.start,
      supplier_staff_id: event?.event?.original?.resource,
      resourceId: event?.event?.original?.resourceId,
      supplierUnavailabilityId: event?.event?.original?.supplierUnavailabilityId,
    }
    eventReschedule && eventReschedule({ ...newBookingData });
  }
  const renderScheduleEvent = React.useCallback((data) => {
    return <div className="md-custom-event-cont" style={{ background: data.color }}>
      <div className="md-custom-event-wrapper">
        <div className="md-custom-event-details">
          <div className="md-custom-event-title" style={{ color: 'black' }}>{data.title}</div>
          <div className="md-custom-event-time" style={{ color: 'black' }}>{data.start} - {data.end}</div>
        </div>
      </div>
    </div>
  });

  return (
    <div className={`${selectedView}`}>
      <Eventcalendar
        ref={setSchedulerRef}
        theme="ios"
        themeVariant="light"
        height={"100vh"}
        view={view}
        data={myEvents}
        invalid={closedDates}
        resources={resources}
        renderResource={renderCustomResource}

        // clickToCreate={true}
        dragToCreate={false}
        dragToMove={false}
        dragToResize={false}
        showEventTooltip={false}
        popover={true}
        invalidateEvent="start-end"
        groupBy="date"
        renderDayContent={renderDay}
        onEventHoverIn={onEventHoverIn}
        onEventHoverOut={onEventHoverOut}
        onPageLoaded={onPageLoaded}
        clickToCreate='single'
        onEventCreate={onEventCreate}
        onEventCreated={onEventCreated}
        onEventClick={onEventClick}
        dragTimeStep={15}
        onEventDragEnd={handleEventDragEnd}
      // renderScheduleEvent={renderScheduleEvent} //render event content
      />



      <Popup
        display="anchored"
        isOpen={isOpen}
        anchor={anchor}
        touchUi={false}
        showOverlay={false}
        contentPadding={false}
        closeOnOverlayClick={false}
        width={350}
        cssClass="md-tooltip"
      >
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div className="md-tooltip-header" style={{ backgroundColor: currentEvent?.color }}>
            <span className="md-tooltip-name-age">{`${currentEvent?.title}`}</span>
          </div>
          <div className="md-tooltip-info">
            <div className="md-tooltip-title">
              Time: <span className="md-tooltip-status md-tooltip-text md-tooltip-time">{`${moment(currentEvent?.start).format('hh:mm A')} - ${moment(currentEvent?.end).format('hh:mm A')}`}</span>
            </div>
            <div className="md-tooltip-title">
              <span>Status</span>
              <Button color={'success'} variant="outline" className="md-tooltip-status-button">{currentEvent?.status}</Button>
            </div>
            {/* <div className='md-tooltip-action-cont'>
              <Button color="secondary" className="md-tooltip-view-button">View </Button>
            </div> */}
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default MobiscrollScheduler;
import React from "react";
import Hoc from "../Hoc";
import FeaturedSalonsList from "./FeaturedSalonsList";

const FeatuerdSalons = () => {
  return (
    <Hoc activeMenu="featured_salons">
      <FeaturedSalonsList />
    </Hoc>
  );
};

export default FeatuerdSalons;

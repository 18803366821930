import React, { useState, useEffect, useRef } from "react";
import { getAuthAttribute } from "../../utils/helper";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import { getSupplierPayouts } from "../../core/Api/supplier";
import SearchInput from "../../components/Inputs/Search";
import Panel from "../../components/Panel";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { confirmSupplierPayout } from "../../core/Api/supplier";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import {
  Button,
  Container,
  Spinner,
  Row,
  Col,
  Tab,
  Tabs,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import FullPageSpinner from "../../components/FullPageSpinner";

const PayoutsTable = ({ match }) => {
  const { t } = useTranslation();
  const isUserAdmin = getAuthAttribute("user_type_id") == 4;
  const supplier_id = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const pageSize = 10;
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const offset = pageSize * (selectedPage - 1);
  const startPageRef = useRef(selectedPage);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [key, setKey] = useState("pending");
  const [processing, setProcessing] = useState(false);
  const pending = data.filter((item) => item.status != "Completed");
  const history = data.filter((item) => item.status == "Completed");
  const filteredData = (
    !isUserAdmin ? data : key == "pending" ? pending : history
  ).filter((item) =>
    `${item.reference_no.toLowerCase()} ${item.account_name.toLowerCase()} ${item.account_number.toLowerCase()} ${
      item.branch_name.toLowerCase
    } ${item.iban.toLowerCase()} ${item.status.toLowerCase()} ${item.amount.toLowerCase()} ${item.commission_amount.toLowerCase()} 
    ${item.due.toLowerCase()}`.includes(searchInput.toLowerCase())
  );

  // const resultOptions = [
  //   { label: "10", value: 10 },
  //   { label: "25", value: 25 },
  //   { label: "50", value: 50 },
  //   { label: "100", value: 100 },
  // ];
  // console.log("pageSize", pageSize);

  useEffect(() => {
    fetchPayouts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / pageSize));
  }, [filteredData]);

  const fetchPayouts = async () => {
    toast.dismiss();
    setLoading(true);
    try {
      const result = await getSupplierPayouts(supplier_id, pageSize, offset);
      if (result.code == 200) {
        setData(result?.result ?? []);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order == "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    let style = (() => {
      if (row.status == "Pending") {
        // return { backgroundColor: "#7effd7" };
        return { color: "#ffc107" };
      } else if (row.status == "Completed") {
        return { color: "#00a65a" };
      } else {
        return { color: "#00a65a" };
      }
    })();
    return (
      <div className="status-formatter" style={style}>
        {row.status}
      </div>
    );
  };

  const handlePageChange = (target) => {
    let newPage = selectedPage;
    if (target == "next") {
      newPage = newPage + 1 <= pageCount ? newPage + 1 : newPage;
    } else if (target == "prev") {
      newPage = newPage - 1 > 0 ? newPage - 1 : newPage;
    } else {
      newPage = target;
    }
    setSelectedPage(newPage);
    if (pageCount > 5) {
      if (target == "next" && newPage % 5 == 1) {
        startPageRef.current += 5;
      } else if (target == "prev" && newPage % 5 == 0) {
        startPageRef.current -= 5;
      }
    }
  };

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handleConfirm = async (supplier_payout_id) => {
    toast.dismiss();
    Swal.fire({
      text: t("Are you sure you want to approve this payout?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Approve"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setProcessing(true)
        const result = await confirmSupplierPayout(supplier_payout_id);
        if (result?.code == 200) {
          toast.success(result.message || "Approved successfully");
          fetchPayouts();
          setProcessing(false);
        } else {
          setProcessing(false)
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const approveButton = (cell, row, rowIndex, formatExtraData) => {
    return (row.status == "Pending" && isUserAdmin) ? (
      <Button
        onClick={() => handleConfirm(row.supplier_payout_id)}
        size="sm"
        variant={"outline-secondary"}
      >
        Approve
      </Button>
    ) : null;
  };

  const accountDetails = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ul>
        <li>{row.account_name}</li>
        <li>{row.account_number}</li>
        <li>{row.branch_name}</li>
        <li>{row.iban}</li>
        <li>{row.extra}</li>
      </ul>
    );
  };

  const amountCommission = (cell, row, rowIndex, formatExtraData) => {
    return <div>{+row.amount + +row.commission_amount}</div>;
  };

  const columns = [
    {
      dataField: "reference_no",
      text: "Reference No.",
      sort: true,
      sortCaret,
    },
    {
      dataField: "supplier_id",
      text: "Supplier",
      sort: true,
      sortCaret,
    },
    {
      dataField: "amount_commission",
      text: "Amount",
      sort: true,
      sortCaret,
      formatter: amountCommission,
    },
    {
      dataField: "amount",
      text: "Settlement",
      sort: true,
      sortCaret,
    },
    {
      dataField: "commission_amount",
      text: "Commission",
      sort: true,
      sortCaret,
    },
    {
      dataField: "due",
      text: "Dues Generated",
      sort: true,
      sortCaret,
    },
    {
      dataField: "date",
      text: "Date Created",
      sort: true,
      sortCaret,
    },
    {
      dataField: "account_details",
      text: "Account Details",
      sort: true,
      sortCaret,
      formatter: accountDetails,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret,
      formatter: statusFormatter,
    },
    {
      dataField: "other",
      text: "",
      sort: false,
      formatter: approveButton,
    },
  ];

  // if (isPending) {
  //   columns.push({
  //     dataField: "other",
  //     text: "",
  //     sort: false,
  //     formatter: approveButton,
  //   });
  // }

  return (
    <Container className="payout-table">
      {processing && <FullPageSpinner /> || null}
      {!isUserAdmin ? (
        <div className="table-wrapper">
          <Row className="payouts-heading">
            <Col>
              <h5>Checkout your payout status and history</h5>
            </Col>
          </Row>
          <Row className="search-wrap">
            <Col xl="3" lg="4" md="6" sm="12">
              <SearchInput
                classNames="pt-0"
                onChange={handleSearchChange}
                placeholder={t("Search")}
              />
            </Col>
          </Row>

          {filteredData.length ? (
            <BootstrapTable
              keyField={columns[0]}
              data={filteredData}
              columns={columns}
            />
          ) : isLoading ? (
            <div className="spinner-wrap">
              <Spinner animation="border" />
              <span>{t("Please wait, we're loading payouts")}</span>
            </div>
          ) : (
            <div className="no-data-container">
              <h5>
                {searchInput
                  ? t("No matching payouts found")
                  : t("No payouts available")}
              </h5>
            </div>
          )}
        </div>
      ) : (
        <div className="table-panel">
          <Panel subtitle="Payouts">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="pending" title="Pending Approval"></Tab>
              <Tab eventKey="history" title="History"></Tab>
            </Tabs>
            <Row className="search-wrap">
              <Col md="6">
                <SearchInput
                  classNames="pt-0"
                  onChange={handleSearchChange}
                  placeholder={t("Search")}
                />
              </Col>
            </Row>
            {key == "pending" ? (
              filteredData.length ? (
                <BootstrapTable
                  keyField={columns[0]}
                  data={filteredData}
                  columns={columns}
                />
              ) : isLoading ? (
                <div className="spinner-wrap">
                  <Spinner animation="border" />
                  <span>{t("Please wait, we're loading payouts")}</span>
                </div>
              ) : (
                <div className="no-data-container">
                  <h5>
                    {searchInput
                      ? t("No matching payouts found")
                      : t("Good Job! No pending settlements")}
                  </h5>
                </div>
              )
            ) : null}
            {key == "history" ? (
              filteredData.length ? (
                <BootstrapTable
                  keyField={columns[0]}
                  data={filteredData}
                  columns={columns}
                />
              ) : isLoading ? (
                <div className="spinner-wrap">
                  <Spinner animation="border" />
                  <span>{t("Please wait, we're loading payouts")}</span>
                </div>
              ) : (
                <div className="no-data-container">
                  <h5>
                    {searchInput
                      ? t("No matching payouts found")
                      : t("No payouts available")}
                  </h5>
                </div>
              )
            ) : null}
          </Panel>
        </div>
      )}

      {!isUserAdmin && !isLoading && Boolean(filteredData.length) && (
        <Row className="pagination">
          <Col md="3 result-text">
            {t("Showing")} {filteredData ? offset + 1 : filteredData.length}{" "}
            {t("to")}
            &nbsp;
            {offset + filteredData.length} {t("of")} {data.length}{" "}
            {t("Results")}
          </Col>
          <Col md="5" className="count">
            <div className="previous" onClick={() => handlePageChange("prev")}>
              <img src={leftArrow} alt="left-arrow"></img>
            </div>
            <div className="pages">
              {Array.from({ length: pageCount > 5 ? 5 : pageCount }).map(
                (_, index) => {
                  const pageNumber = startPageRef.current + index;
                  return (
                    <span
                      key={index}
                      className={` cursor-pointer ${
                        selectedPage == pageNumber && "active"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </span>
                  );
                }
              )}
            </div>
            <div className="next" onClick={() => handlePageChange("next")}>
              <img src={rightArrow} alt="right-arrow"></img>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PayoutsTable;

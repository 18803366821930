import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Modal, Image, Container, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "./Styles.scss";
import editIcon from "../../../assets/images/Icon material-mode-edit.png";
import caretDown from "../../../assets/images/caret-down.svg";
import caretUp from "../../../assets/images/caret-up.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import SearchInput from "../../../components/Inputs/Search";
import PrimaryButton from "../../../components/Buttons/Primary";
import Pagination from "../../../components/Pagination";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { getGroups, deleteGroup } from "../../../core/Api/groups";
import GroupForm from "./GroupForm";
import { useHistory, useRouteMatch } from "react-router-dom";
import CustomTableComponent from "../../../components/CustomTableComponent";
import { checkActionPermission } from "../../../utils/helper";

const CompanyGroups = () => {
	const match = useRouteMatch();
	const { t } = useTranslation();
	const [selectedAdmin, setSelectedAdmin] = useState(null);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [allAdminsList, setAllAdminsList] = useState([]);
	const [adminsList, setAdminsList] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchInput, setSearchInput] = useState("");

	const adminsState = {
		company_id: null,
		grp_id: null,
		grp_code: null,
		grp_name: "",
		grp_type: "",
		status_id: "1",
	};

	useEffect(() => {
		getCompanyGroups();
		// eslint-disable-next-line
	}, []);

	const getCompanyGroups = async () => {
		setIsLoading(true);
		toast.dismiss();
		try {
			const result = await getGroups();
			if (result.code === 200) {
				setAdminsList(result?.result);
				setAllAdminsList(result?.result);
				setFilteredData(result?.result?.slice(0, pageSize));
				setPageNo(1);
			} else {
				throw new Error(result?.message ?? "Something went wrong");
			}
		} catch (error) {
			toast.error(error.message ?? "Something went wrong");
		}
		setIsLoading(false);
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
		if (e.target.value) {
			const filteredData = allAdminsList.filter((item) =>
				`${item.grp_name.toLowerCase()} ${item.grp_code.toLowerCase()}`.includes(e.target.value.toLowerCase())
			);
			setAdminsList(filteredData);
			setFilteredData(filteredData?.slice(0, pageSize));
		} else {
			setAdminsList(allAdminsList);
			setFilteredData(allAdminsList?.slice(0, pageSize));
		}
		setPageNo(1);
	};

	const handlePageChange = async (pageNo, pageSize) => {
		setPageNo(pageNo);
		setFilteredData(adminsList?.slice(pageSize * (pageNo - 1), pageSize * (pageNo - 1) + pageSize));
	};

	const handleConfirm = async (grp_id) => {
		toast.dismiss();
		Swal.fire({
			text: t(`Are you sure you want to delete this? This action cannot be undone.`),
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: "#D11A2A",
			cancelButtonColor: "#ffffff",
			cancelButtonText: t("Cancel"),
			confirmButtonText: t("Delete"),
		}).then(async (result) => {
			if (result.isConfirmed) {
				setIsLoading(true);
				const result = await deleteGroup({
					grp_id,
				});
				setIsLoading(false);
				if (result && result.code === 200) {
					await getCompanyGroups();
					toast.success(result.message);
				} else {
					Swal.fire((result && result.message) || "Something wrong", "", "error");
				}
			}
		});
	};

	const formatter = (row) => {
		return (
			<div className="otherWrap">
				{(checkActionPermission("groups", "canEdit") && (
					<Image className="edit mr-4" onClick={() => setSelectedAdmin(row)} src={editIcon} alt="edit" />
				)) ||
					null}
				{(checkActionPermission("groups", "canDelete") && (
					<Image className="delete" onClick={() => handleConfirm(row.grp_id)} src={deleteIcon} alt="delete" />
				)) ||
					null}
			</div>
		);
	};

	const statusFormatter = (row) => {
		const statuses = {
			1: "Active",
			2: "In Active",
			3: "Deleted",
		};

		return <span>{statuses[row?.status_id]}</span>;
	};

	const handleClose = () => setSelectedAdmin(null);

	const columns = [
		{
			name: t("Group Code"),
			key: "grp_code",
		},
		{
			name: t("Group Name"),
			key: "grp_name",
		},
		{
			name: t("Company Name"),
			key: "company_name",
		},
		{
			name: t("Status"),
			key: "status_id",
			formatter: statusFormatter,
		},
		{
			name: t("Status"),
			key: "status",
			formatter: formatter,
		},
	];

	return (
		<Container fluid>
			<div className="table-wrapper">
				{/* {isLoading && <FullPageSpinner /> || null} */}
				<Row className="search-wrap">
					<Col xl="3" lg="5" md="7" sm="12">
						<SearchInput classNames="pt-0" onChange={handleSearchChange} placeholder={t("Search")} />
					</Col>
					<Col xl="6" lg="6" md="5" sm="12" className="add-btn d-flex justify-content-end">
						{(checkActionPermission("groups", "canAdd") && (
							<PrimaryButton onClick={() => setSelectedAdmin({ ...adminsState })} classNames="add-new-Admin" text={t("Add Group")} />
						)) ||
							null}
					</Col>
				</Row>
				<Row>
					<Col>
						{isLoading && (
							<div className="spinner-wrap">
								<FullPageSpinner animation="border" />
								<span>{t("Please wait, we're loading data")}</span>
							</div>
						)}
						{(!isLoading && filteredData?.length === 0 && <div className="no-data-text text-center mt-2">{t("No data available")}</div>) || null}
					</Col>
				</Row>
				<Row className="mt-3">
					{(filteredData?.length > 0 && (
						<CustomTableComponent
							responsive={true}
							hover={true}
							showSequence={true}
							pageSize={pageSize}
							pageNo={pageNo}
							columns={columns || []}
							data={filteredData || []}
						/>
					)) ||
						null}
				</Row>
			</div>
			<Row className="pagination">
				<Pagination totalCount={adminsList?.length || 0} viewCount={filteredData?.length || 0} pageNo={pageNo} onChangePage={handlePageChange} />
			</Row>

			{(selectedAdmin && (
				<Modal show={selectedAdmin} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{t("Group")}</Modal.Title>
					</Modal.Header>
					<GroupForm getGroups={getCompanyGroups} handleClose={handleClose} selectedAdmin={selectedAdmin} />
				</Modal>
			)) ||
				null}
		</Container>
	);
};

export default CompanyGroups;

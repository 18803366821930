import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

const HorizontalBarGraph = ({ graphsData }) => {
	const { t } = useTranslation();

	const {labels=[], revenue=[]} = graphsData;
	const [data, setData] = useState({});
	useEffect(() => {
		setData({
			labels: [...labels],
			datasets: [
				{
					label: 'Revenue 2023',
					data: [...revenue],
					backgroundColor: '#01AF50',
					// barPercentage: 7,
					// barThickness:500
				},
			],
		});
	}, [graphsData]);

	return (
		<>
			{!_.isEmpty(data) && (
				<Bar
					options={{
						responsive: true,
						maintainAspectRatio: false,
						indexAxis: 'y',
						scales: {
							x: {
								// stacked: true,
								display: false,
								barPercentage: 1,
							},
							y: {
								// stacked: true,
								display: true,
							},
							xAxes: [
								{
									barPercentage: 1,
									// barThickness: 100,
								},
							],
						},
						plugins: {
							legend: {
								display: true,
								position: 'top',
							},
							customBarWidth: {
								width: 50, // Specify the desired bar width
							},
							datalabels: {
								display: true, // Enable or disable data labels for all datasets
								anchor: 'auto', // Position of the label relative to the data point (center, start, end, auto)
								align: 'center', // Text alignment (center, start, end, left, right)
								color: 'black', // Label text color
								font: {
									weight: 'bold', // Label font weight
									size: '20px',
								},
								formatter: (value, context) => {
									// Custom function to format the label text
									return value;
								},
							},
						},
						elements: {
							bar: {
								borderWidth: 2,
							},
						},
					}}
					data={data}
					plugins={[ChartDataLabels]}
				/>
			)}
		</>
	);
};

export default HorizontalBarGraph;

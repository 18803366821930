import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../components/Buttons/Primary';
import DateInput from '../../../components/DateInput';
import CustomDropdown from '../../../components/Dropdown';
import FullPageSpinner from '../../../components/FullPageSpinner';
import Pagination from '../../../components/Pagination';
import {
	getPerDayTransactionsReport,
	getTransactionsByPartnersReport,
} from '../../../core/Api/Reports';
import { getAuthAttribute } from '../../../utils/helper';
import Hoc from '../../Hoc';
import FlashReport from './Flash';
import DetailReport from './Detail';
import BriefReport from './Brief';
import './Styles.scss';

const TransactiosByPartners = () => {
	const { t } = useTranslation();
	const match = useRouteMatch();
	const supplier_id = match?.params?.id || getAuthAttribute('supplier_id');
	const pageSize = 10;
	const offset = 0;
	const [pageNo, setPageNo] = useState(1);
	const [data, setData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [filterSchema, setFilterSchema] = useState({
		from_date: new Date(),
		to_date: new Date(),
		rpt_type: 'flash',
	});
	const [isLoading, setLoading] = useState(false);
	const [csvLoader, setCSVLoader] = useState(false);

	const options = [
		{ label: t('Flash'), value: 'flash' },
		{ label: t('Brief'), value: 'brief' },
		{ label: t('Details'), value: 'detail' },
	];

	useEffect(() => {
		fetchBookings(pageSize, offset, filterSchema);
	}, []);

	const fetchBookings = async (pageSize, offset, filters) => {
		setLoading(true);
		const response = await getTransactionsByPartnersReport({
			from_date: moment(filters?.from_date).format('YYYY-MM-DD'),
			to_date: moment(filters?.to_date).format('YYYY-MM-DD'),
			rpt_type: filters?.rpt_type,
			per_page: pageSize,
			offset: offset,
			supplier_id: supplier_id,
		});
		if (response.code == 200) {
			setData(response?.result?.result_data || []);
			setTotalCount(response?.result?.total_count || 0);
		} else {
			toast.error(response && response.message);
		}
		setLoading(false);
	};

	const handleDateChange = (date, name) => {
		fetchBookings(pageSize, offset, {
			...filterSchema,
			[name]: new Date(date),
		});
		setFilterSchema({
			...filterSchema,
			[name]: new Date(date),
		});
	};

	const handleChange = (value, name) => {
		fetchBookings(pageSize, offset, {
			...filterSchema,
			[name]: value,
		});
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleCSVExport = async () => {
		try {
			setCSVLoader(true);
			const response = await getTransactionsByPartnersReport({
				from_date: moment(filterSchema?.from_date).format('YYYY-MM-DD'),
				to_date: moment(filterSchema?.to_date).format('YYYY-MM-DD'),
				rpt_type: filterSchema?.rpt_type,
				per_page: totalCount,
				offset: offset,
				supplier_id: supplier_id,
			});
			if (response.code == 200) {
				const bookings = response?.result?.result_data || [];
				let csv = '';
				const rows = [];
				if (filterSchema?.rpt_type == 'flash') {
					csv = 'Name, Total Bookings, Total Amount\r\n';
					for (let i = 0; i < bookings?.length; i++) {
						const item = bookings[i];
						rows.push(
							`${item?.name},${item?.total_bookings},${item?.total_amount}\r\n`
						);
					}
				} else if (filterSchema?.rpt_type == 'brief') {
					csv = 'Date, Location Type, Total Bookings, Total Amount\r\n';
					for (let i = 0; i < bookings?.length; i++) {
						const item = bookings[i];
						rows.push(
							`${item?.name},${item?.location_type},${item?.total_bookings},${item?.total_amount}\r\n`
						);
					}
				} else if (filterSchema?.rpt_type == 'detail') {
					csv = 'Date, Name, Location Type, Total Bookings, Total Amount\r\n';
					for (let i = 0; i < bookings?.length; i++) {
						const item = bookings[i];
						rows.push(
							`${item?.date},${item?.name || ''},${item?.location_type || ''},${
								item?.total_bookings || ''
							},${item?.total_amount}\r\n`
						);
					}
				}
				rows.forEach((row) => {
					csv += row;
				});
				const generationDate = new Date().toLocaleDateString('en-US');
				const link = document.createElement('a');
				link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
				link.target = '_blank';
				link.download = `Transaction_By_Partners_Report_${generationDate}.csv`;
				link.click();
				setCSVLoader(false);
			} else {
				toast.error(response && response.message);
				setCSVLoader(false);
			}
		} catch (error) {
			toast.error(error && error.message);
			setCSVLoader(false);
		}
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		fetchBookings(pageSize, (pageNo - 1) * pageSize, filterSchema);
	};

	return (
		// <Hoc activeMenu="transactionsByPartners">
			<div className="transactionsPyPartners">
				{(csvLoader && <FullPageSpinner />) || null}
				<div className="table-wrapper">
					<Row className="">
						<Col className="mt-3" lg="3" md="6" sm="12">
							<DateInput
								label={t('From date')}
								value={filterSchema.from_date}
								onChange={(date) => handleDateChange(date, 'from_date')}
							/>
						</Col>
						<Col className="mt-3" lg="3" md="6" sm="12">
							<DateInput
								label={t('To date')}
								value={filterSchema.to_date}
								onChange={(date) => handleDateChange(date, 'to_date')}
							/>
						</Col>
						<Col className="mt-3" lg="3" md="6" sm="12">
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'rpt_type')}
								name="rpt_type"
								value={
									options?.find((r) => r.value == filterSchema?.rpt_type) ||
									null
								}
								options={options}
								label={t('Type')}
							/>
						</Col>
						<Col className="mt-3" lg="3" md="6" sm="12">
							<PrimaryButton
								disabled={isLoading || csvLoader}
								onClick={handleCSVExport}
								text={t('exportAsCSV')}
							/>
						</Col>
					</Row>
					<Row className="w-100 text-center mt-4">
						<Col>
							{isLoading && (
								<div>
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && data?.length == 0 && (
								<div className="no-data-text text-center mt-2">
									{t('No data available')}
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row className="w-100 m-0">
						<Col>
							{(data.length && (
								<div className="booking-table w-100 mt-3">
									{(filterSchema?.rpt_type == 'flash' && (
										<FlashReport
											data={data}
											type={filterSchema?.rpt_type}
											pageNo={pageNo}
											pageSize={pageSize}
										/>
									)) ||
										null}
									{(filterSchema?.rpt_type == 'brief' && (
										<BriefReport
											data={data}
											type={filterSchema?.rpt_type}
											pageNo={pageNo}
											pageSize={pageSize}
										/>
									)) ||
										null}
									{(filterSchema?.rpt_type == 'detail' && (
										<DetailReport
											data={data}
											type={filterSchema?.rpt_type}
											pageNo={pageNo}
											pageSize={pageSize}
										/>
									)) ||
										null}
								</div>
							)) ||
								null}
						</Col>
					</Row>

					<Row>
						<Col>
							<Pagination
								totalCount={totalCount}
								viewCount={data?.length || 0}
								onChangePage={handlePageChange}
								pageNo={pageNo}
							/>
						</Col>
					</Row>
				</div>
			</div>
		// </Hoc>
	);
};

export default TransactiosByPartners;

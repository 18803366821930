import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';


const BarGraph = ({ graphsData }) => {
	const {t} = useTranslation();
	const { activeCustomers = 0, inactiveCustomers = 0 } = graphsData;
	const [data, setData] = useState({});
	useEffect(() => {
		setData({
			labels: [t('Active Customers'), t('Inactive Customers')],
			datasets: [
				{
					label: t('Active Customers'),
					data: [activeCustomers],
					backgroundColor: '#a5aeb5',
					barPercentage: 7,
					// barThickness:500
				},
				{
					label: t('Inactive Customers'),
					data: [inactiveCustomers],
					backgroundColor: '#3a5f71',
					barPercentage: 7,
				},
			],
		});
	}, [graphsData]);
  
	return (
		<>
			{!_.isEmpty(data) && (
				<Bar
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								stacked: true,
								display: false,
								barPercentage: 1,
								barThickness: 100,
							},
							y: {
								stacked: true,
								display: false,
							},
							xAxes: [
								{
									barPercentage: 1,
									barThickness: 100,
								},
							],
						},
						plugins: {
							legend: {
								display: true,
								position: 'bottom',
							},
							customBarWidth: {
								width: 50, // Specify the desired bar width
							},
							datalabels: {
								display: true, // Enable or disable data labels for all datasets
								anchor: 'auto', // Position of the label relative to the data point (center, start, end, auto)
								align: 'center', // Text alignment (center, start, end, left, right)
								color: 'white', // Label text color
								font: {
									weight: 'bold', // Label font weight
									size: '30px',
								},
								formatter: (value, context) => {
									// Custom function to format the label text
									return value;
								},
							},
						},
						elements: {
							bar: {
								borderWidth: 0,
							},
						},
					}}
					data={data}
					plugins={[ChartDataLabels]}
				/>
			)}
		</>
	);
};

export default BarGraph;

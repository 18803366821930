import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomTableComponent from "../../../components/CustomTableComponent";

const FlashReport = ({ data, pageNo = 1, pageSize = 10 }) => {
  const { t } = useTranslation();

  const getWeekNumber = (date) => date ? (date.split('-')?.length > 0 && date.split('-')[1]) : 0;

  const getYear = (date) => date ? (date.split('-')?.length && date.split('-')[0]) : 1970;

  const getWeekRange = (row, key) => {
    return `${moment(getYear(row[key])).add(getWeekNumber(row[key]) - 1, 'weeks').startOf('week').format('DD/MM/YYYY')} -
     ${moment(getYear(row[key])).add(getWeekNumber(row[key]) - 1, 'weeks').endOf('week').format('DD/MM/YYYY')}`
  }

  
  const columns = [
    {
      name: t("Week"),
      key: 'week',
      formatter: getWeekRange,
    },
    {
      name: t("totalbookings"),
      key: 'total_bookings',
      formatter: null,
    },
    {
      name: t("totalAmount"),
      key: 'total_amount',
      formatter: (row, key) => {
        return row?.total_amount && parseFloat(row?.total_amount).toFixed(2) || 0
      },
    },
  ]

 

  return (
    <>
      <CustomTableComponent
        responsive={true}
        hover={true}
        showSequence={true}
        pageSize={pageSize}
        pageNo={pageNo}
        columns={columns || []}
        data={data || []}
      />
    </>
  )
}

export default FlashReport;